import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AutoCursorModes,
  AxisTickStrategies,
  ColorHEX,
  PointShape,
  SolidFill,
  SolidLine,
  Themes,
  emptyFill,
} from '@lightningchart/lcjs';
import html2canvas from 'html2canvas';
import { EMPTY, debounceTime, fromEvent, switchMap } from 'rxjs';
import { CONFIG_CURVES } from 'src/app/constants/curves';
import { ParamsCurves } from 'src/app/models/curves';
import { IHistoryCurvesCard } from 'src/app/models/historic';
import { IRealtimeWave } from 'src/app/models/monitoring';
import { CurvesService } from 'src/app/services/curves/curves.service';
import { LcContextService } from 'src/app/services/lc-context/lc-context.service';

export interface IDateWaves {
  start_date: string;
  finish_date: string;
}
@Component({
  selector: 'curves-history-card',
  templateUrl: './curves-history-card.component.html',
  styleUrls: ['./curves-history-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurvesHistoryCardComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() waves = {} as IRealtimeWave;
  @Input() history = {} as IHistoryCurvesCard;
  @Output() paramsWaves = new EventEmitter<string[]>();
  @Output() dateWaves = new EventEmitter<IDateWaves>();

  image = '';
  start_date = '';
  finish_date = '';

  containerId = '';
  destroyLC?: () => unknown;

  constructor(
    private curvesService: CurvesService,
    private lcContextService: LcContextService
  ) {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.containerId = (Math.random() * 1_000_000_000).toFixed(0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const waves = changes['waves'];
    if (!waves) return;
    const { currentValue, previousValue } = waves;
    if (!previousValue) return;

    const hasDiffSize =
      Object.keys(previousValue).length !== Object.keys(currentValue).length;

    const hasDiff = Object.keys(previousValue).some((key) => {
      return currentValue[key] !== previousValue[key];
    });

    if (hasDiff || hasDiffSize) {
      this.waves = currentValue;
      this.ngOnDestroy();
      setTimeout(() => {
        this.ngAfterViewInit();
      });
    }
  }

  setStartAndFinishDate(start_date: string, finish_date: string) {
    const body = {
      start_date,
      finish_date,
    };
    this.dateWaves.emit(body);
  }

  ngAfterViewInit(): void {
    const activeWaves = Object.entries(CONFIG_CURVES.waves).filter(
      (entry) => this.waves[entry[0] as keyof IRealtimeWave]
    );
    const lc = this.lcContextService.getLightningChartContext();
    const layout = document.getElementById(this.containerId) as HTMLDivElement;
    const channels = activeWaves.map((entry, i) => {
      const [id, info] = entry;
      const timeViewSamplesApprox = Math.ceil(
        (this.curvesService.timeViewMs() * info.rate) / 1000
      );
      const solidLine = new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorHEX(info.color) }),
      });
      const container = document.createElement('div');
      container.style.flexGrow = '1';
      layout.append(container);
      const chart = lc
        .ChartXY({
          container,
          animationsEnabled: false,
          interactable: false,
          theme: Themes.darkGold,
        })
        .setBackgroundFillStyle(
          new SolidFill({
            color: ColorHEX('#181818'),
          })
        )
        .setAutoCursorMode(AutoCursorModes.disabled)
        .setTitle(id)
        .setTitlePosition('series-left-top')
        .setPadding(0);
      chart.forEachAxis((axis) => axis.setThickness(0));
      chart.axisX
        .setTickStrategy(AxisTickStrategies.Empty)
        .setInterval({ start: 0, end: timeViewSamplesApprox });
      chart.axisY.setTickStrategy(AxisTickStrategies.Empty);
      const series = chart
        .addPointLineAreaSeries({
          dataPattern: 'ProgressiveX',
          automaticColorIndex: i,
        })
        .setAreaFillStyle(emptyFill)
        .setStrokeStyle(solidLine)
        .setMaxSampleCount({ mode: 'auto' });
      const pointSeries = chart
        .addPointLineAreaSeries({
          dataPattern: null,
          automaticColorIndex: i,
        })
        .setAreaFillStyle(emptyFill)
        .setStrokeStyle(solidLine)
        .setPointShape(PointShape.Circle)
        .setPointSize(10)
        .setPointFillStyle(new SolidFill({ color: ColorHEX(info.color) }));
      return {
        id,
        info,
        chart,
        series,
        pointSeries,
        timeViewSamplesApprox,
        lastSampleIndex: -1,
        bufferModulus: 0,
      };
    });
    const pushDataToChart = (ch: (typeof channels)[0], chData: number[]) => {
      // Derived from https://lightningchart.com/js-charts/interactive-examples/edit/lcjs-example-0041-sweepingLineChartNew.html
      const newSamplesCount = chData.length;
      // Calculate which samples can be appended to right side, and which have to be started again from left side of sweeping history.
      const space = ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1);
      // Put first set of samples to extend previous samples.
      const countRight = Math.min(space, newSamplesCount);
      ch.series.alterSamples(ch.lastSampleIndex + 1, {
        yValues: chData,
        count: countRight,
      });
      ch.lastSampleIndex += countRight;
      // Remove the few oldest points that would be connected to last points pushed just now, to leave a gap between newest and oldest data.
      const gapRightCount =
        countRight < space
          ? Math.min(
              Math.round(ch.timeViewSamplesApprox * 0.05),
              ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1)
            )
          : 0;
      if (gapRightCount > 0) {
        ch.series.alterSamples(ch.lastSampleIndex + 1, {
          yValues: new Array(gapRightCount).fill(Number.NaN),
        });
      }
      // Put other samples (if any) to beginning of sweeping history.
      const countLeft = newSamplesCount - countRight;
      if (countLeft > 0) {
        ch.series.alterSamples(0, { yValues: chData, offset: countRight });
        ch.lastSampleIndex = countLeft - 1;
      }
      // Same gap but for "left" data
      const gapLeftCount = Math.min(
        Math.round(ch.timeViewSamplesApprox * 0.05),
        ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1)
      );
      if (gapLeftCount > 0) {
        ch.series.alterSamples(ch.lastSampleIndex + 1, {
          yValues: new Array(gapLeftCount).fill(Number.NaN),
        });
      }
      ch.pointSeries.setSamples({
        xValues: [ch.lastSampleIndex],
        yValues: [chData[chData.length - 1]],
      });
    };
    //
    // Implement custom buffering logic for desired behavior:
    // - data arrives roughly every ~5 seconds
    // - data is buffered and pushed to charts in small bits
    const dataBuffer = new Map();
    const dataObservable = this.curvesService.getHistory(this.history);
    if (this.history.date === undefined && this.history.equipment === undefined)
      return;
    const dataSubscription = dataObservable.subscribe((data: any) => {
      this.setStartAndFinishDate(data.waves.start_date, data.waves.finish_date);
      const listParamsWaves = this.extractArrayNumbers(data.waves);

      this.paramsWaves.emit(listParamsWaves);
      channels.forEach((ch) => {
        const chData = data.waves[ch.id];
        if (!chData) return;
        let buffer = dataBuffer.get(ch.id);
        if (!buffer) {
          buffer = [];
          dataBuffer.set(ch.id, buffer);
        }
        if (buffer.length > 0) {
          // in case where buffer still had empty points, immediately push them to the chart to avoid situation where charts slowly go out of sync
          // when testing whether the app works in perfect sync, ideally this message should never be triggered
          // console.log('force empty buffer', buffer.length);
          pushDataToChart(ch, buffer);
          buffer.length = 0;
        }
        buffer.push(...chData);
      });
    });
    //
    let tPrevUpdate = performance.now();
    const updateBufferToChart = () => {
      const tNow = performance.now();
      const tDelta = tNow - tPrevUpdate;
      channels.forEach((ch) => {
        const buffer: number[] | undefined = dataBuffer.get(ch.id);
        if (!buffer) return;
        const pushPointsPerSecond = ch.info.rate;
        let pushPointsCount =
          pushPointsPerSecond * (tDelta / 1000) + ch.bufferModulus;
        ch.bufferModulus = pushPointsCount % 1;
        pushPointsCount = Math.floor(pushPointsCount);
        if (pushPointsCount > 0) {
          const chData: number[] = [];
          for (let i = 0; i < pushPointsCount; i += 1) {
            let value = buffer.shift() ?? Number.NaN;
            if (value === undefined) {
              // when testing whether the app works in perfect sync, ideally this message should never be triggered
              // its worth noting that Number.NaN will be displayed as a GAP in the line series (!)
              // console.log('push gap', ch.id);
              value = Number.NaN;
            }
            chData.push(value);
          }
          pushDataToChart(ch, chData);
        }
      });
      tPrevUpdate = tNow;
      requestAnimationFrame(updateBufferToChart);
    };
    updateBufferToChart();

    // Cache function that destroys created LC resources
    this.destroyLC = () => {
      // NOTE: Important to dispose `chart` here, instead of `lc`. Otherwise we would dispose the shared LC context which may be used by other LC based components
      channels.forEach((ch) => ch.chart.dispose());
      layout.innerHTML = '';
      dataSubscription.unsubscribe();
    };
  }

  ngOnDestroy(): void {
    if (this.destroyLC) this.destroyLC();
  }

  async printscreenChart(e: Event) {
    e.stopPropagation();
    const layout = document.getElementById(this.containerId) as HTMLDivElement;

    if (!layout) return;

    const width = layout.offsetWidth;
    const height = layout.offsetHeight;
    const canvas = document.createElement('canvas');
    const scale = 2;

    canvas.width = width * scale;
    canvas.height = height * scale;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    const context = canvas.getContext('2d');
    if (!context) return;

    context.scale(scale, scale);
    context.fillRect(0, 0, width, height);

    const elCanvas = await html2canvas(layout);
    context.drawImage(elCanvas, 0, 0, width, height, 0, 0, width, height);

    const img = canvas.toDataURL('image/png');
    this.image = img;
  }

  restartTimer() {
    // console.log('restartTimer');

    fromEvent(document, 'mousemove')
      .pipe(
        debounceTime(10000),
        switchMap(() => {
          this.closeImage();
          return EMPTY;
        })
      )
      .subscribe();
  }

  closeImage(e?: Event) {
    e?.stopPropagation();
    this.image = '';
  }

  // Referente a selecionar os parâmetros das curvas individualmente
  extractArrayNumbers(objet: ParamsCurves) {
    const keysArray: string[] = [];
    for (const key in objet) {
      if (Array.isArray(objet[key])) {
        const allAreNumbers = objet[key].every(
          (element) => typeof element === 'number'
        );

        if (allAreNumbers && objet[key].length > 0) {
          keysArray.push(key);
        }
      }
    }

    return keysArray;
  }
}
