<section>
  <div class="skeleton" *ngIf="loading">
    <div class="line w3 s"></div>
    <div class="line w3 s"></div>
    <div class="line w3 s"></div>
    <div class="line w1 s"></div>
    <div class="line w2 s"></div>
    <div class="line w2 s"></div>
    <div class="line w2 s"></div>
    <div class="line w2 s"></div>
    <div class="line check s"></div>
    <div style="width: 100%"></div>
    <div class="line button s"></div>
  </div>

  <h1 *ngIf="!loading" class="f-16 centerTitle">
    {{ "Recuperação de senha" | translate }}
  </h1>

  <main *ngIf="!loading" id="password_recovery">
    <div class="content">
      <header>
        <!-- <img src="assets/images/as-logo.png" alt="Logo" /> -->
        <!-- <h1 class="f-16">{{ "Recuperação de senha" | translate }}</h1> -->
        <h1 class="f-16">{{ "Digite seu e-mail cadastrado!" | translate }}</h1>
      </header>

      <div class="form">
        <form
          (ngSubmit)="emailRecoverySubmitHandler()"
          [formGroup]="recovery_form"
        >
          <div class="card-input">
            <input
              type="text"
              id="email"
              [placeholder]="'login.placeholder_email' | translate"
              formControlName="email"
            />
          </div>

          <div class="card-input button">
            <button
              mat-button
              type="submit"
              class="primary"
              [disabled]="recovery_form.invalid || loading"
            >
              {{ "Recuperar" | translate }}
              <loading *ngIf="loading"></loading>
            </button>
            <button
              type="submit"
              mat-button
              (click)="close()"
              class="close_button"
            >
              {{ "close" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</section>
