<section class="list-page skeleton">
  <div class="accordion">
    <div class="thead"></div>
    <div class="tbody">
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="pagination">
      <div class="pg s"></div>
    </div>
    </div>
  </div>
</section>