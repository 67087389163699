import { ICreateDepartment, IDepartmentData } from "./../../models/department";
import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";

@Injectable({
  providedIn: "root",
})
export class DepartmentService {
  constructor(private http: HttpService) {}
  getDepartments() {
    return this.http.get<IDepartmentData[]>("core/list-department/");
  }

  postDepartment(department: ICreateDepartment) {
    const body = {
      name: department.name,
    };

    return this.http.post<IDepartmentData[]>("core/create-department/", body);
  }

  deletDepartment(id: number) {
    return this.http.delete<any>(`core/delete-department/?id=${id}`);
  }

  patchDepartment(department: any) {
    const body = {
      name: department.name,
    };

    return this.http.patch<ICreateDepartment[]>(
      `core/update-department/?id=${department.id}`,
      body
    );
  }
}
