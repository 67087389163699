<section class="list-page skeleton">
  <h1 class="title-page"><span class="s"></span></h1>
  <form class="s"></form>
  <div class="table">
    <div class="thead"></div>
    <div class="tbody">
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
      <div class="tr s"></div>
    </div>
  </div>
</section>