<footer [ngClass]="{ margin }">
  <div class="container">
    <div class="content">
      <div class="side links">
        <span> [ <a routerLink="/terms-of-use">Termos de Uso</a> ] </span>
      </div>
      <div class="side">
        <span>&copy; {{ company }}</span>
      </div>
    </div>
  </div>
</footer>
