export const vitalSignsEcgData = {
  xValues: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
      65,
      66,
      67,
      68,
      69,
      70,
      71,
      72,
      73,
      74,
      75,
      76,
      77,
      78,
      79,
      80,
      81,
      82,
      83,
      84,
      85,
      86,
      87,
      88,
      89,
      90,
      91,
      92,
      93,
      94,
      95,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      106,
      107,
      108,
      109,
      110,
      111,
      112,
      113,
      114,
      115,
      116,
      117,
      118,
      119,
      120,
      121,
      122,
      123,
      124,
      125,
      126,
      127,
      128,
      129,
      130,
      131,
      132,
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      142,
      143,
      144,
      145,
      146,
      147,
      148,
      149,
      150,
      151,
      152,
      153,
      154,
      155,
      156,
      157,
      158,
      159,
      160,
      161,
      162,
      163,
      164,
      165,
      166,
      167,
      168,
      169,
      170,
      171,
      172,
      173,
      174,
      175,
      176,
      177,
      178,
      179,
      180,
      181,
      182,
      183,
      184,
      185,
      186,
      187,
      188,
      189,
      190,
      191,
      192,
      193,
      194,
      195,
      196,
      197,
      198,
      199,
      200,
      201,
      202,
      203,
      204,
      205,
      206,
      207,
      208,
      209,
      210,
      211,
      212,
      213,
      214,
      215,
      216,
      217,
      218,
      219,
      220,
      221,
      222,
      223,
      224,
      225,
      226,
      227,
      228,
      229,
      230,
      231,
      232,
      233,
      234,
      235,
      236,
      237,
      238,
      239,
      240,
      241,
      242,
      243,
      244,
      245,
      246,
      247,
      248,
      249,
      250,
      251,
      252,
      253,
      254,
      255,
      256,
      257,
      258,
      259,
      260,
      261,
      262,
      263,
      264,
      265,
      266,
      267,
      268,
      269,
      270,
      271,
      272,
      273,
      274,
      275,
      276,
      277,
      278,
      279,
      280,
      281,
      282,
      283,
      284,
      285,
      286,
      287,
      288,
      289,
      290,
      291,
      292,
      293,
      294,
      295,
      296,
      297,
      298,
      299,
      300,
      301,
      302,
      303,
      304,
      305,
      306,
      307,
      308,
      309,
      310,
      311,
      312,
      313,
      314,
      315,
      316,
      317,
      318,
      319,
      320,
      321,
      322,
      323,
      324,
      325,
      326,
      327,
      328,
      329,
      330,
      331,
      332,
      333,
      334,
      335,
      336,
      337,
      338,
      339,
      340,
      341,
      342,
      343,
      344,
      345,
      346,
      347,
      348,
      349,
      350,
      351,
      352,
      353,
      354,
      355,
      356,
      357,
      358,
      359,
      360,
      361,
      362,
      363,
      364,
      365,
      366,
      367,
      368,
      369,
      370,
      371,
      372,
      373,
      374,
      375,
      376,
      377,
      378,
      379,
      380,
      381,
      382,
      383,
      384,
      385,
      386,
      387,
      388,
      389,
      390,
      391,
      392,
      393,
      394,
      395,
      396,
      397,
      398,
      399,
      400,
      401,
      402,
      403,
      404,
      405,
      406,
      407,
      408,
      409,
      410,
      411,
      412,
      413,
      414,
      415,
      416,
      417,
      418,
      419,
      420,
      421,
      422,
      423,
      424,
      425,
      426,
      427,
      428,
      429,
      430,
      431,
      432,
      433,
      434,
      435,
      436,
      437,
      438,
      439,
      440,
      441,
      442,
      443,
      444,
      445,
      446,
      447,
      448,
      449,
      450,
      451,
      452,
      453,
      454,
      455,
      456,
      457,
      458,
      459,
      460,
      461,
      462,
      463,
      464,
      465,
      466,
      467,
      468,
      469,
      470,
      471,
      472,
      473,
      474,
      475,
      476,
      477,
      478,
      479,
      480,
      481,
      482,
      483,
      484,
      485,
      486,
      487,
      488,
      489,
      490,
      491,
      492,
      493,
      494,
      495,
      496,
      497,
      498,
      499,
      500,
      501,
      502,
      503,
      504,
      505,
      506,
      507,
      508,
      509,
      510,
      511,
      512,
      513,
      514,
      515,
      516,
      517,
      518,
      519,
      520,
      521,
      522,
      523,
      524,
      525,
      526,
      527,
      528,
      529,
      530,
      531,
      532,
      533,
      534,
      535,
      536,
      537,
      538,
      539,
      540,
      541,
      542,
      543,
      544,
      545,
      546,
      547,
      548,
      549,
      550,
      551,
      552,
      553,
      554,
      555,
      556,
      557,
      558,
      559,
      560,
      561,
      562,
      563,
      564,
      565,
      566,
      567,
      568,
      569,
      570,
      571,
      572,
      573,
      574,
      575,
      576,
      577,
      578,
      579,
      580,
      581,
      582,
      583,
      584,
      585,
      586,
      587,
      588,
      589,
      590,
      591,
      592,
      593,
      594,
      595,
      596,
      597,
      598,
      599,
      600,
      601,
      602,
      603,
      604,
      605,
      606,
      607,
      608,
      609,
      610,
      611,
      612,
      613,
      614,
      615,
      616,
      617,
      618,
      619,
      620,
      621,
      622,
      623,
      624,
      625,
      626,
      627,
      628,
      629,
      630,
      631,
      632,
      633,
      634,
      635,
      636,
      637,
      638,
      639,
      640,
      641,
      642,
      643,
      644,
      645,
      646,
      647,
      648,
      649,
      650,
      651,
      652,
      653,
      654,
      655,
      656,
      657,
      658,
      659,
      660,
      661,
      662,
      663,
      664,
      665,
      666,
      667,
      668,
      669,
      670,
      671,
      672,
      673,
      674,
      675,
      676,
      677,
      678,
      679,
      680,
      681,
      682,
      683,
      684,
      685,
      686,
      687,
      688,
      689,
      690,
      691,
      692,
      693,
      694,
      695,
      696,
      697,
      698,
      699,
      700,
      701,
      702,
      703,
      704,
      705,
      706,
      707,
      708,
      709,
      710,
      711,
      712,
      713,
      714,
      715,
      716,
      717,
      718,
      719,
      720,
      721,
      722,
      723,
      724,
      725,
      726,
      727,
      728,
      729,
      730,
      731,
      732,
      733,
      734,
      735,
      736,
      737,
      738,
      739,
      740,
      741,
      742,
      743,
      744,
      745,
      746,
      747,
      748,
      749,
      750,
      751,
      752,
      753,
      754,
      755,
      756,
      757,
      758,
      759,
      760,
      761,
      762,
      763,
      764,
      765,
      766,
      767,
      768,
      769,
      770,
      771,
      772,
      773,
      774,
      775,
      776,
      777,
      778,
      779,
      780,
      781,
      782,
      783,
      784,
      785,
      786,
      787,
      788,
      789,
      790,
      791,
      792,
      793,
      794,
      795,
      796,
      797,
      798,
      799,
      800,
      801,
      802,
      803,
      804,
      805,
      806,
      807,
      808,
      809,
      810,
      811,
      812,
      813,
      814,
      815,
      816,
      817,
      818,
      819,
      820,
      821,
      822,
      823,
      824,
      825,
      826,
      827,
      828,
      829,
      830,
      831,
      832,
      833,
      834,
      835,
      836,
      837,
      838,
      839,
      840,
      841,
      842,
      843,
      844,
      845,
      846,
      847,
      848,
      849,
      850,
      851,
      852,
      853,
      854,
      855,
      856,
      857,
      858,
      859,
      860,
      861,
      862,
      863,
      864,
      865,
      866,
      867,
      868,
      869,
      870,
      871,
      872,
      873,
      874,
      875,
      876,
      877,
      878,
      879,
      880,
      881,
      882,
      883,
      884,
      885,
      886,
      887,
      888,
      889,
      890,
      891,
      892,
      893,
      894,
      895,
      896,
      897,
      898,
      899,
      900,
      901,
      902,
      903,
      904,
      905,
      906,
      907,
      908,
      909,
      910,
      911,
      912,
      913,
      914,
      915,
      916,
      917,
      918,
      919,
      920,
      921,
      922,
      923,
      924,
      925,
      926,
      927,
      928,
      929,
      930,
      931,
      932,
      933,
      934,
      935,
      936,
      937,
      938,
      939,
      940,
      941,
      942,
      943,
      944,
      945,
      946,
      947,
      948,
      949,
      950,
      951,
      952,
      953,
      954,
      955,
      956,
      957,
      958,
      959,
      960,
      961,
      962,
      963,
      964,
      965,
      966,
      967,
      968,
      969,
      970,
      971,
      972,
      973,
      974,
      975,
      976,
      977,
      978,
      979,
      980,
      981,
      982,
      983,
      984,
      985,
      986,
      987,
      988,
      989,
      990,
      991,
      992,
      993,
      994,
      995,
      996,
      997,
      998,
      999,
      1000,
      1001,
      1002,
      1003,
      1004,
      1005,
      1006,
      1007,
      1008,
      1009,
      1010,
      1011,
      1012,
      1013,
      1014,
      1015,
      1016,
      1017,
      1018,
      1019,
      1020,
      1021,
      1022,
      1023,
      1024,
      1025,
      1026,
      1027,
      1028,
      1029,
      1030,
      1031,
      1032,
      1033,
      1034,
      1035,
      1036,
      1037,
      1038,
      1039,
      1040,
      1041,
      1042,
      1043,
      1044,
      1045,
      1046,
      1047,
      1048,
      1049,
      1050,
      1051,
      1052,
      1053,
      1054,
      1055,
      1056,
      1057,
      1058,
      1059,
      1060,
      1061,
      1062,
      1063,
      1064,
      1065,
      1066,
      1067,
      1068,
      1069,
      1070,
      1071,
      1072,
      1073,
      1074,
      1075,
      1076,
      1077,
      1078,
      1079,
      1080,
      1081,
      1082,
      1083,
      1084,
      1085,
      1086,
      1087,
      1088,
      1089,
      1090,
      1091,
      1092,
      1093,
      1094,
      1095,
      1096,
      1097,
      1098,
      1099,
      1100,
      1101,
      1102,
      1103,
      1104,
      1105,
      1106,
      1107,
      1108,
      1109,
      1110,
      1111,
      1112,
      1113,
      1114,
      1115,
      1116,
      1117,
      1118,
      1119,
      1120,
      1121,
      1122,
      1123,
      1124,
      1125,
      1126,
      1127,
      1128,
      1129,
      1130,
      1131,
      1132,
      1133,
      1134,
      1135,
      1136,
      1137,
      1138,
      1139,
      1140,
      1141,
      1142,
      1143,
      1144,
      1145,
      1146,
      1147,
      1148,
      1149,
      1150,
      1151,
      1152,
      1153,
      1154,
      1155,
      1156,
      1157,
      1158,
      1159,
      1160,
      1161,
      1162,
      1163,
      1164,
      1165,
      1166,
      1167,
      1168,
      1169,
      1170,
      1171,
      1172,
      1173,
      1174,
      1175,
      1176,
      1177,
      1178,
      1179,
      1180,
      1181,
      1182,
      1183,
      1184,
      1185,
      1186,
      1187,
      1188,
      1189,
      1190,
      1191,
      1192,
      1193,
      1194,
      1195,
      1196,
      1197,
      1198,
      1199,
      1200,
      1201,
      1202,
      1203,
      1204,
      1205,
      1206,
      1207,
      1208,
      1209,
      1210,
      1211,
      1212,
      1213,
      1214,
      1215,
      1216,
      1217,
      1218,
      1219,
      1220,
      1221,
      1222,
      1223,
      1224,
      1225,
      1226,
      1227,
      1228,
      1229,
      1230,
      1231,
      1232,
      1233,
      1234,
      1235,
      1236,
      1237,
      1238,
      1239,
      1240,
      1241,
      1242,
      1243,
      1244,
      1245,
      1246,
      1247,
      1248,
      1249,
      1250,
      1251,
      1252,
      1253,
      1254,
      1255,
      1256,
      1257,
      1258,
      1259,
      1260,
      1261,
      1262,
      1263,
      1264,
      1265,
      1266,
      1267,
      1268,
      1269,
      1270,
      1271,
      1272,
      1273,
      1274,
      1275,
      1276,
      1277,
      1278,
      1279,
      1280,
      1281,
      1282,
      1283,
      1284,
      1285,
      1286,
      1287,
      1288,
      1289,
      1290,
      1291,
      1292,
      1293,
      1294,
      1295,
      1296,
      1297,
      1298,
      1299,
      1300,
      1301,
      1302,
      1303,
      1304,
      1305,
      1306,
      1307,
      1308,
      1309,
      1310,
      1311,
      1312,
      1313,
      1314,
      1315,
      1316,
      1317,
      1318,
      1319,
      1320,
      1321,
      1322,
      1323,
      1324,
      1325,
      1326,
      1327,
      1328,
      1329,
      1330,
      1331,
      1332,
      1333,
      1334,
      1335,
      1336,
      1337,
      1338,
      1339,
      1340,
      1341,
      1342,
      1343,
      1344,
      1345,
      1346,
      1347,
      1348,
      1349,
      1350,
      1351,
      1352,
      1353,
      1354,
      1355,
      1356,
      1357,
      1358,
      1359,
      1360,
      1361,
      1362,
      1363,
      1364,
      1365,
      1366,
      1367,
      1368,
      1369,
      1370,
      1371,
      1372,
      1373,
      1374,
      1375,
      1376,
      1377,
      1378,
      1379,
      1380,
      1381,
      1382,
      1383,
      1384,
      1385,
      1386,
      1387,
      1388,
      1389,
      1390,
      1391,
      1392,
      1393,
      1394,
      1395,
      1396,
      1397,
      1398,
      1399,
      1400,
      1401,
      1402,
      1403,
      1404,
      1405,
      1406,
      1407,
      1408,
      1409,
      1410,
      1411,
      1412,
      1413,
      1414,
      1415,
      1416,
      1417,
      1418,
      1419,
      1420,
      1421,
      1422,
      1423,
      1424,
      1425,
      1426,
      1427,
      1428,
      1429,
      1430,
      1431,
      1432,
      1433,
      1434,
      1435,
      1436,
      1437,
      1438,
      1439,
      1440,
      1441,
      1442,
      1443,
      1444,
      1445,
      1446,
      1447,
      1448,
      1449,
      1450,
      1451,
      1452,
      1453,
      1454,
      1455,
      1456,
      1457,
      1458,
      1459,
      1460,
      1461,
      1462,
      1463,
      1464,
      1465,
      1466,
      1467,
      1468,
      1469,
      1470,
      1471,
      1472,
      1473,
      1474,
      1475,
      1476,
      1477,
      1478,
      1479,
      1480,
      1481,
      1482,
      1483,
      1484,
      1485,
      1486,
      1487,
      1488,
      1489,
      1490,
      1491,
      1492,
      1493,
      1494,
      1495,
      1496,
      1497,
      1498,
      1499,
      1500,
      1501,
      1502,
      1503,
      1504,
      1505,
      1506,
      1507,
      1508,
      1509,
      1510,
      1511,
      1512,
      1513,
      1514,
      1515,
      1516,
      1517,
      1518,
      1519,
      1520,
      1521,
      1522,
      1523,
      1524,
      1525,
      1526,
      1527,
      1528,
      1529,
      1530,
      1531,
      1532,
      1533,
      1534,
      1535,
      1536,
      1537,
      1538,
      1539,
      1540,
      1541,
      1542,
      1543,
      1544,
      1545,
      1546,
      1547,
      1548,
      1549,
      1550,
      1551,
      1552,
      1553,
      1554,
      1555,
      1556,
      1557,
      1558,
      1559,
      1560,
      1561,
      1562,
      1563,
      1564,
      1565,
      1566,
      1567,
      1568,
      1569,
      1570,
      1571,
      1572,
      1573,
      1574,
      1575,
      1576,
      1577,
      1578,
      1579,
      1580,
      1581,
      1582,
      1583,
      1584,
      1585,
      1586,
      1587,
      1588,
      1589,
      1590,
      1591,
      1592,
      1593,
      1594,
      1595,
      1596,
      1597,
      1598,
      1599,
      1600,
      1601,
      1602,
      1603,
      1604,
      1605,
      1606,
      1607,
      1608,
      1609,
      1610,
      1611,
      1612,
      1613,
      1614,
      1615,
      1616,
      1617,
      1618,
      1619,
      1620,
      1621,
      1622,
      1623,
      1624,
      1625,
      1626,
      1627,
      1628,
      1629,
      1630,
      1631,
      1632,
      1633,
      1634,
      1635,
      1636,
      1637,
      1638,
      1639,
      1640,
      1641,
      1642,
      1643,
      1644,
      1645,
      1646,
      1647,
      1648,
      1649,
      1650,
      1651,
      1652,
      1653,
      1654,
      1655,
      1656,
      1657,
      1658,
      1659,
      1660,
      1661,
      1662,
      1663,
      1664,
      1665,
      1666,
      1667,
      1668,
      1669,
      1670,
      1671,
      1672,
      1673,
      1674,
      1675,
      1676,
      1677,
      1678,
      1679,
      1680,
      1681,
      1682,
      1683,
      1684,
      1685,
      1686,
      1687,
      1688,
      1689,
      1690,
      1691,
      1692,
      1693,
      1694,
      1695,
      1696,
      1697,
      1698,
      1699,
      1700,
      1701,
      1702,
      1703,
      1704,
      1705,
      1706,
      1707,
      1708,
      1709,
      1710,
      1711,
      1712,
      1713,
      1714,
      1715,
      1716,
      1717,
      1718,
      1719,
      1720,
      1721,
      1722,
      1723,
      1724,
      1725,
      1726,
      1727,
      1728,
      1729,
      1730,
      1731,
      1732,
      1733,
      1734,
      1735,
      1736,
      1737,
      1738,
      1739,
      1740,
      1741,
      1742,
      1743,
      1744,
      1745,
      1746,
      1747,
      1748,
      1749,
      1750,
      1751,
      1752,
      1753,
      1754,
      1755,
      1756,
      1757,
      1758,
      1759,
      1760,
      1761,
      1762,
      1763,
      1764,
      1765,
      1766,
      1767,
      1768,
      1769,
      1770,
      1771,
      1772,
      1773,
      1774,
      1775,
      1776,
      1777,
      1778,
      1779,
      1780,
      1781,
      1782,
      1783,
      1784,
      1785,
      1786,
      1787,
      1788,
      1789,
      1790,
      1791,
      1792,
      1793,
      1794,
      1795,
      1796,
      1797,
      1798,
      1799,
      1800,
      1801,
      1802,
      1803,
      1804,
      1805,
      1806,
      1807,
      1808,
      1809,
      1810,
      1811,
      1812,
      1813,
      1814,
      1815,
      1816,
      1817,
      1818,
      1819,
      1820,
      1821,
      1822,
      1823,
      1824,
      1825,
      1826,
      1827,
      1828,
      1829,
      1830,
      1831,
      1832,
      1833,
      1834,
      1835,
      1836,
      1837,
      1838,
      1839,
      1840,
      1841,
      1842,
      1843,
      1844,
      1845,
      1846,
      1847,
      1848,
      1849,
      1850,
      1851,
      1852,
      1853,
      1854,
      1855,
      1856,
      1857,
      1858,
      1859,
      1860,
      1861,
      1862,
      1863,
      1864,
      1865,
      1866,
      1867,
      1868,
      1869,
      1870,
      1871,
      1872,
      1873,
      1874,
      1875,
      1876,
      1877,
      1878,
      1879,
      1880,
      1881,
      1882,
      1883,
      1884,
      1885,
      1886,
      1887,
      1888,
      1889,
      1890,
      1891,
      1892,
      1893,
      1894,
      1895,
      1896,
      1897,
      1898,
      1899,
      1900,
      1901,
      1902,
      1903,
      1904,
      1905,
      1906,
      1907,
      1908,
      1909,
      1910,
      1911,
      1912,
      1913,
      1914,
      1915,
      1916,
      1917,
      1918,
      1919,
      1920,
      1921,
      1922,
      1923,
      1924,
      1925,
      1926,
      1927,
      1928,
      1929,
      1930,
      1931,
      1932,
      1933,
      1934,
      1935,
      1936,
      1937,
      1938,
      1939,
      1940,
      1941,
      1942,
      1943,
      1944,
      1945,
      1946,
      1947,
      1948,
      1949,
      1950,
      1951,
      1952,
      1953,
      1954,
      1955,
      1956,
      1957,
      1958,
      1959,
      1960,
      1961,
      1962,
      1963,
      1964,
      1965,
      1966,
      1967,
      1968,
      1969,
      1970,
      1971,
      1972,
      1973,
      1974,
      1975,
      1976,
      1977,
      1978,
      1979,
      1980,
      1981,
      1982,
      1983,
      1984,
      1985,
      1986,
      1987,
      1988,
      1989,
      1990,
      1991,
      1992,
      1993,
      1994,
      1995,
      1996,
      1997,
      1998,
      1999,
      2000,
      2001,
      2002,
      2003,
      2004,
      2005,
      2006,
      2007,
      2008,
      2009,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2017,
      2018,
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2030,
      2031,
      2032,
      2033,
      2034,
      2035,
      2036,
      2037,
      2038,
      2039,
      2040,
      2041,
      2042,
      2043,
      2044,
      2045,
      2046,
      2047,
      2048,
      2049,
      2050,
      2051,
      2052,
      2053,
      2054,
      2055,
      2056,
      2057,
      2058,
      2059,
      2060,
      2061,
      2062,
      2063,
      2064,
      2065,
      2066,
      2067,
      2068,
      2069,
      2070,
      2071,
      2072,
      2073,
      2074,
      2075,
      2076,
      2077,
      2078,
      2079,
      2080,
      2081,
      2082,
      2083,
      2084,
      2085,
      2086,
      2087,
      2088,
      2089,
      2090,
      2091,
      2092,
      2093,
      2094,
      2095,
      2096,
      2097,
      2098,
      2099,
      2100,
      2101,
      2102,
      2103,
      2104,
      2105,
      2106,
      2107,
      2108,
      2109,
      2110,
      2111,
      2112,
      2113,
      2114,
      2115,
      2116,
      2117,
      2118,
      2119,
      2120,
      2121,
      2122,
      2123,
      2124,
      2125,
      2126,
      2127,
      2128,
      2129,
      2130,
      2131,
      2132,
      2133,
      2134,
      2135,
      2136,
      2137,
      2138,
      2139,
      2140,
      2141,
      2142,
      2143,
      2144,
      2145,
      2146,
      2147,
      2148,
      2149,
      2150,
      2151,
      2152,
      2153,
      2154,
      2155,
      2156,
      2157,
      2158,
      2159,
      2160,
      2161,
      2162,
      2163,
      2164,
      2165,
      2166,
      2167,
      2168,
      2169,
      2170,
      2171,
      2172,
      2173,
      2174,
      2175,
      2176,
      2177,
      2178,
      2179,
      2180,
      2181,
      2182,
      2183,
      2184,
      2185,
      2186,
      2187,
      2188,
      2189,
      2190,
      2191,
      2192,
      2193,
      2194,
      2195,
      2196,
      2197,
      2198,
      2199,
      2200,
      2201,
      2202,
      2203,
      2204,
      2205,
      2206,
      2207,
      2208,
      2209,
      2210,
      2211,
      2212,
      2213,
      2214,
      2215,
      2216,
      2217,
      2218,
      2219,
      2220,
      2221,
      2222,
      2223,
      2224,
      2225,
      2226,
      2227,
      2228,
      2229,
      2230,
      2231,
      2232,
      2233,
      2234,
      2235,
      2236,
      2237,
      2238,
      2239,
      2240,
      2241,
      2242,
      2243,
      2244,
      2245,
      2246,
      2247,
      2248,
      2249,
      2250,
      2251,
      2252,
      2253,
      2254,
      2255,
      2256,
      2257,
      2258,
      2259,
      2260,
      2261,
      2262,
      2263,
      2264,
      2265,
      2266,
      2267,
      2268,
      2269,
      2270,
      2271,
      2272,
      2273,
      2274,
      2275,
      2276,
      2277,
      2278,
      2279,
      2280,
      2281,
      2282,
      2283,
      2284,
      2285,
      2286,
      2287,
      2288,
      2289,
      2290,
      2291,
      2292,
      2293,
      2294,
      2295,
      2296,
      2297,
      2298,
      2299,
      2300,
      2301,
      2302,
      2303,
      2304,
      2305,
      2306,
      2307,
      2308,
      2309,
      2310,
      2311,
      2312,
      2313,
      2314,
      2315,
      2316,
      2317,
      2318,
      2319,
      2320,
      2321,
      2322,
      2323,
      2324,
      2325,
      2326,
      2327,
      2328,
      2329,
      2330,
      2331,
      2332,
      2333,
      2334,
      2335,
      2336,
      2337,
      2338,
      2339,
      2340,
      2341,
      2342,
      2343,
      2344,
      2345,
      2346,
      2347,
      2348,
      2349,
      2350,
      2351,
      2352,
      2353,
      2354,
      2355,
      2356,
      2357,
      2358,
      2359,
      2360,
      2361,
      2362,
      2363,
      2364,
      2365,
      2366,
      2367,
      2368,
      2369,
      2370,
      2371,
      2372,
      2373,
      2374,
      2375,
      2376,
      2377,
      2378,
      2379,
      2380,
      2381,
      2382,
      2383,
      2384,
      2385,
      2386,
      2387,
      2388,
      2389,
      2390,
      2391,
      2392,
      2393,
      2394,
      2395,
      2396,
      2397,
      2398,
      2399,
      2400,
      2401,
      2402,
      2403,
      2404,
      2405,
      2406,
      2407,
      2408,
      2409,
      2410,
      2411,
      2412,
      2413,
      2414,
      2415,
      2416,
      2417,
      2418,
      2419,
      2420,
      2421,
      2422,
      2423,
      2424,
      2425,
      2426,
      2427,
      2428,
      2429,
      2430,
      2431,
      2432,
      2433,
      2434,
      2435,
      2436,
      2437,
      2438,
      2439,
      2440,
      2441,
      2442,
      2443,
      2444,
      2445,
      2446,
      2447,
      2448,
      2449,
      2450,
      2451,
      2452,
      2453,
      2454,
      2455,
      2456,
      2457,
      2458,
      2459,
      2460,
      2461,
      2462,
      2463,
      2464,
      2465,
      2466,
      2467,
      2468,
      2469,
      2470,
      2471,
      2472,
      2473,
      2474,
      2475,
      2476,
      2477,
      2478,
      2479,
      2480,
      2481,
      2482,
      2483,
      2484,
      2485,
      2486,
      2487,
      2488,
      2489,
      2490,
      2491,
      2492,
      2493,
      2494,
      2495,
      2496,
      2497,
      2498,
      2499,
      2500,
      2501,
      2502,
      2503,
      2504,
      2505,
      2506,
      2507,
      2508,
      2509,
      2510,
      2511,
      2512,
      2513,
      2514,
      2515,
      2516,
      2517,
      2518,
      2519,
      2520,
      2521,
      2522,
      2523,
      2524,
      2525,
      2526,
      2527,
      2528,
      2529,
      2530,
      2531,
      2532,
      2533,
      2534,
      2535,
      2536,
      2537,
      2538,
      2539,
      2540,
      2541,
      2542,
      2543,
      2544,
      2545,
      2546,
      2547,
      2548,
      2549,
      2550,
      2551,
      2552,
      2553,
      2554,
      2555,
      2556,
      2557,
      2558,
      2559,
      2560,
      2561,
      2562,
      2563,
      2564,
      2565,
      2566,
      2567,
      2568,
      2569,
      2570,
      2571,
      2572,
      2573,
      2574,
      2575,
      2576,
      2577,
      2578,
      2579,
      2580,
      2581,
      2582,
      2583,
      2584,
      2585,
      2586,
      2587,
      2588,
      2589,
      2590,
      2591,
      2592,
      2593,
      2594,
      2595,
      2596,
      2597,
      2598,
      2599,
      2600,
      2601,
      2602,
      2603,
      2604,
      2605,
      2606,
      2607,
      2608,
      2609,
      2610,
      2611,
      2612,
      2613,
      2614,
      2615,
      2616,
      2617,
      2618,
      2619,
      2620,
      2621,
      2622,
      2623,
      2624,
      2625,
      2626,
      2627,
      2628,
      2629,
      2630,
      2631,
      2632,
      2633,
      2634,
      2635,
      2636,
      2637,
      2638,
      2639,
      2640,
      2641,
      2642,
      2643,
      2644,
      2645,
      2646,
      2647,
      2648,
      2649,
      2650,
      2651,
      2652,
      2653,
      2654,
      2655,
      2656,
      2657,
      2658,
      2659,
      2660,
      2661,
      2662,
      2663,
      2664,
      2665,
      2666,
      2667,
      2668,
      2669,
      2670,
      2671,
      2672,
      2673,
      2674,
      2675,
      2676,
      2677,
      2678,
      2679,
      2680,
      2681,
      2682,
      2683,
      2684,
      2685,
      2686,
      2687,
      2688,
      2689,
      2690,
      2691,
      2692,
      2693,
      2694,
      2695,
      2696,
      2697,
      2698,
      2699,
      2700,
      2701,
      2702,
      2703,
      2704,
      2705,
      2706,
      2707,
      2708,
      2709,
      2710,
      2711,
      2712,
      2713,
      2714,
      2715,
      2716,
      2717,
      2718,
      2719,
      2720,
      2721,
      2722,
      2723,
      2724,
      2725,
      2726,
      2727,
      2728,
      2729,
      2730,
      2731,
      2732,
      2733,
      2734,
      2735,
      2736,
      2737,
      2738,
      2739,
      2740,
      2741,
      2742,
      2743,
      2744,
      2745,
      2746,
      2747,
      2748,
      2749,
      2750,
      2751,
      2752,
      2753,
      2754,
      2755,
      2756,
      2757,
      2758,
      2759,
      2760,
      2761,
      2762,
      2763,
      2764,
      2765,
      2766,
      2767,
      2768,
      2769,
      2770,
      2771,
      2772,
      2773,
      2774,
      2775,
      2776,
      2777,
      2778,
      2779,
      2780,
      2781,
      2782,
      2783,
      2784,
      2785,
      2786,
      2787,
      2788,
      2789,
      2790,
      2791,
      2792,
      2793,
      2794,
      2795,
      2796,
      2797,
      2798,
      2799,
      2800,
      2801,
      2802,
      2803,
      2804,
      2805,
      2806,
      2807,
      2808,
      2809,
      2810,
      2811,
      2812,
      2813,
      2814,
      2815,
      2816,
      2817,
      2818,
      2819,
      2820,
      2821,
      2822,
      2823,
      2824,
      2825,
      2826,
      2827,
      2828,
      2829,
      2830,
      2831,
      2832,
      2833,
      2834,
      2835,
      2836,
      2837,
      2838,
      2839,
      2840,
      2841,
      2842,
      2843,
      2844,
      2845,
      2846,
      2847,
      2848,
      2849,
      2850,
      2851,
      2852,
      2853,
      2854,
      2855,
      2856,
      2857,
      2858,
      2859,
      2860,
      2861,
      2862,
      2863,
      2864,
      2865,
      2866,
      2867,
      2868,
      2869,
      2870,
      2871,
      2872,
      2873,
      2874,
      2875,
      2876,
      2877,
      2878,
      2879,
      2880,
      2881,
      2882,
      2883,
      2884,
      2885,
      2886,
      2887,
      2888,
      2889,
      2890,
      2891,
      2892,
      2893,
      2894,
      2895,
      2896,
      2897,
      2898,
      2899,
      2900,
      2901,
      2902,
      2903,
      2904,
      2905,
      2906,
      2907,
      2908,
      2909,
      2910,
      2911,
      2912,
      2913,
      2914,
      2915,
      2916,
      2917,
      2918,
      2919,
      2920,
      2921,
      2922,
      2923,
      2924,
      2925,
      2926,
      2927,
      2928,
      2929,
      2930,
      2931,
      2932,
      2933,
      2934,
      2935,
      2936,
      2937,
      2938,
      2939,
      2940,
      2941,
      2942,
      2943,
      2944,
      2945,
      2946,
      2947,
      2948,
      2949,
      2950,
      2951,
      2952,
      2953,
      2954,
      2955,
      2956,
      2957,
      2958,
      2959,
      2960,
      2961,
      2962,
      2963,
      2964,
      2965,
      2966,
      2967,
      2968,
      2969,
      2970,
      2971,
      2972,
      2973,
      2974,
      2975,
      2976,
      2977,
      2978,
      2979,
      2980,
      2981,
      2982,
      2983,
      2984,
      2985,
      2986,
      2987,
      2988,
      2989,
      2990,
      2991,
      2992,
      2993,
      2994,
      2995,
      2996,
      2997,
      2998,
      2999,
      3000,
      3001,
      3002,
      3003,
      3004,
      3005,
      3006,
      3007,
      3008,
      3009,
      3010,
      3011,
      3012,
      3013,
      3014,
      3015,
      3016,
      3017,
      3018,
      3019,
      3020,
      3021,
      3022,
      3023,
      3024,
      3025,
      3026,
      3027,
      3028,
      3029,
      3030,
      3031,
      3032,
      3033,
      3034,
      3035,
      3036,
      3037,
      3038,
      3039,
      3040,
      3041,
      3042,
      3043,
      3044,
      3045,
      3046,
      3047,
      3048,
      3049,
      3050,
      3051,
      3052,
      3053,
      3054,
      3055,
      3056,
      3057,
      3058,
      3059,
      3060,
      3061,
      3062,
      3063,
      3064,
      3065,
      3066,
      3067,
      3068,
      3069,
      3070,
      3071,
      3072,
      3073,
      3074,
      3075,
      3076,
      3077,
      3078,
      3079,
      3080,
      3081,
      3082,
      3083,
      3084,
      3085,
      3086,
      3087,
      3088,
      3089,
      3090,
      3091,
      3092,
      3093,
      3094,
      3095,
      3096,
      3097,
      3098,
      3099,
      3100,
      3101,
      3102,
      3103,
      3104,
      3105,
      3106,
      3107,
      3108,
      3109,
      3110,
      3111,
      3112,
      3113,
      3114,
      3115,
      3116,
      3117,
      3118,
      3119,
      3120,
      3121,
      3122,
      3123,
      3124,
      3125,
      3126,
      3127,
      3128,
      3129,
      3130,
      3131,
      3132,
      3133,
      3134,
      3135,
      3136,
      3137,
      3138,
      3139,
      3140,
      3141,
      3142,
      3143,
      3144,
      3145,
      3146,
      3147,
      3148,
      3149,
      3150,
      3151,
      3152,
      3153,
      3154,
      3155,
      3156,
      3157,
      3158,
      3159,
      3160,
      3161,
      3162,
      3163,
      3164,
      3165,
      3166,
      3167,
      3168,
      3169,
      3170,
      3171,
      3172,
      3173,
      3174,
      3175,
      3176,
      3177,
      3178,
      3179,
      3180,
      3181,
      3182,
      3183,
      3184,
      3185,
      3186,
      3187,
      3188,
      3189,
      3190,
      3191,
      3192,
      3193,
      3194,
      3195,
      3196,
      3197,
      3198,
      3199,
      3200,
      3201,
      3202,
      3203,
      3204,
      3205,
      3206,
      3207,
      3208,
      3209,
      3210,
      3211,
      3212,
      3213,
      3214,
      3215,
      3216,
      3217,
      3218,
      3219,
      3220,
      3221,
      3222,
      3223,
      3224,
      3225,
      3226,
      3227,
      3228,
      3229,
      3230,
      3231,
      3232,
      3233,
      3234,
      3235,
      3236,
      3237,
      3238,
      3239,
      3240,
      3241,
      3242,
      3243,
      3244,
      3245,
      3246,
      3247,
      3248,
      3249,
      3250,
      3251,
      3252,
      3253,
      3254,
      3255,
      3256,
      3257,
      3258,
      3259,
      3260,
      3261,
      3262,
      3263,
      3264,
      3265,
      3266,
      3267,
      3268,
      3269,
      3270,
      3271,
      3272,
      3273,
      3274,
      3275,
      3276,
      3277,
      3278,
      3279,
      3280,
      3281,
      3282,
      3283,
      3284,
      3285,
      3286,
      3287,
      3288,
      3289,
      3290,
      3291,
      3292,
      3293,
      3294,
      3295,
      3296,
      3297,
      3298,
      3299,
      3300,
      3301,
      3302,
      3303,
      3304,
      3305,
      3306,
      3307,
      3308,
      3309,
      3310,
      3311,
      3312,
      3313,
      3314,
      3315,
      3316,
      3317,
      3318,
      3319,
      3320,
      3321,
      3322,
      3323,
      3324,
      3325,
      3326,
      3327,
      3328,
      3329,
      3330,
      3331,
      3332,
      3333,
      3334,
      3335,
      3336,
      3337,
      3338,
      3339,
      3340,
      3341,
      3342,
      3343,
      3344,
      3345,
      3346,
      3347,
      3348,
      3349,
      3350,
      3351,
      3352,
      3353,
      3354,
      3355,
      3356,
      3357,
      3358,
      3359,
      3360,
      3361,
      3362,
      3363,
      3364,
      3365,
      3366,
      3367,
      3368,
      3369,
      3370,
      3371,
      3372,
      3373,
      3374,
      3375,
      3376,
      3377,
      3378,
      3379,
      3380,
      3381,
      3382,
      3383,
      3384,
      3385,
      3386,
      3387,
      3388,
      3389,
      3390,
      3391,
      3392,
      3393,
      3394,
      3395,
      3396,
      3397,
      3398,
      3399,
      3400,
      3401,
      3402,
      3403,
      3404,
      3405,
      3406,
      3407,
      3408,
      3409,
      3410,
      3411,
      3412,
      3413,
      3414,
      3415,
      3416,
      3417,
      3418,
      3419,
      3420,
      3421,
      3422,
      3423,
      3424,
      3425,
      3426,
      3427,
      3428,
      3429,
      3430,
      3431,
      3432,
      3433,
      3434,
      3435,
      3436,
      3437,
      3438,
      3439,
      3440,
      3441,
      3442,
      3443,
      3444,
      3445,
      3446,
      3447,
      3448,
      3449,
      3450,
      3451,
      3452,
      3453,
      3454,
      3455,
      3456,
      3457,
      3458,
      3459,
      3460,
      3461,
      3462,
      3463,
      3464,
      3465,
      3466,
      3467,
      3468,
      3469,
      3470,
      3471,
      3472,
      3473,
      3474,
      3475,
      3476,
      3477,
      3478,
      3479,
      3480,
      3481,
      3482,
      3483,
      3484,
      3485,
      3486,
      3487,
      3488,
      3489,
      3490,
      3491,
      3492,
      3493,
      3494,
      3495,
      3496,
      3497,
      3498,
      3499,
      3500,
      3501,
      3502,
      3503,
      3504,
      3505,
      3506,
      3507,
      3508,
      3509,
      3510,
      3511,
      3512,
      3513,
      3514,
      3515,
      3516,
      3517,
      3518,
      3519,
      3520,
      3521,
      3522,
      3523,
      3524,
      3525,
      3526,
      3527,
      3528,
      3529,
      3530,
      3531,
      3532,
      3533,
      3534,
      3535,
      3536,
      3537,
      3538,
      3539,
      3540,
      3541,
      3542,
      3543,
      3544,
      3545,
      3546,
      3547,
      3548,
      3549,
      3550,
      3551,
      3552,
      3553,
      3554,
      3555,
      3556,
      3557,
      3558,
      3559,
      3560,
      3561,
      3562,
      3563,
      3564,
      3565,
      3566,
      3567,
      3568,
      3569,
      3570,
      3571,
      3572,
      3573,
      3574,
      3575,
      3576,
      3577,
      3578,
      3579,
      3580,
      3581,
      3582,
      3583,
      3584,
      3585,
      3586,
      3587,
      3588,
      3589,
      3590,
      3591,
      3592,
      3593,
      3594,
      3595,
      3596,
      3597,
      3598,
      3599,
      3600,
      3601,
      3602,
      3603,
      3604,
      3605,
      3606,
      3607,
      3608,
      3609,
      3610,
      3611,
      3612,
      3613,
      3614,
      3615,
      3616,
      3617,
      3618,
      3619,
      3620,
      3621,
      3622,
      3623,
      3624,
      3625,
      3626,
      3627,
      3628,
      3629,
      3630,
      3631,
      3632,
      3633,
      3634,
      3635,
      3636,
      3637,
      3638,
      3639,
      3640,
      3641,
      3642,
      3643,
      3644,
      3645,
      3646,
      3647,
      3648,
      3649,
      3650,
      3651,
      3652,
      3653,
      3654,
      3655,
      3656,
      3657,
      3658,
      3659,
      3660,
      3661,
      3662,
      3663,
      3664,
      3665,
      3666,
      3667,
      3668,
      3669,
      3670,
      3671,
      3672,
      3673,
      3674,
      3675,
      3676,
      3677,
      3678,
      3679,
      3680,
      3681,
      3682,
      3683,
      3684,
      3685,
      3686,
      3687,
      3688,
      3689,
      3690,
      3691,
      3692,
      3693,
      3694,
      3695,
      3696,
      3697,
      3698,
      3699,
      3700,
      3701,
      3702,
      3703,
      3704,
      3705,
      3706,
      3707,
      3708,
      3709,
      3710,
      3711,
      3712,
      3713,
      3714,
      3715,
      3716,
      3717,
      3718,
      3719,
      3720,
      3721,
      3722,
      3723,
      3724,
      3725,
      3726,
      3727,
      3728,
      3729,
      3730,
      3731,
      3732,
      3733,
      3734,
      3735,
      3736,
      3737,
      3738,
      3739,
      3740,
      3741,
      3742,
      3743,
      3744,
      3745,
      3746,
      3747,
      3748,
      3749,
      3750,
      3751,
      3752,
      3753,
      3754,
      3755,
      3756,
      3757,
      3758,
      3759,
      3760,
      3761,
      3762,
      3763,
      3764,
      3765,
      3766,
      3767,
      3768,
      3769,
      3770,
      3771,
      3772,
      3773,
      3774,
      3775,
      3776,
      3777,
      3778,
      3779,
      3780,
      3781,
      3782,
      3783,
      3784,
      3785,
      3786,
      3787,
      3788,
      3789,
      3790,
      3791,
      3792,
      3793,
      3794,
      3795,
      3796,
      3797,
      3798,
      3799,
      3800,
      3801,
      3802,
      3803,
      3804,
      3805,
      3806,
      3807,
      3808,
      3809,
      3810,
      3811,
      3812,
      3813,
      3814,
      3815,
      3816,
      3817,
      3818,
      3819,
      3820,
      3821,
      3822,
      3823,
      3824,
      3825,
      3826,
      3827,
      3828,
      3829,
      3830,
      3831,
      3832,
      3833,
      3834,
      3835,
      3836,
      3837,
      3838,
      3839,
      3840,
      3841,
      3842,
      3843,
      3844,
      3845,
      3846,
      3847,
      3848,
      3849,
      3850,
      3851,
      3852,
      3853,
      3854,
      3855,
      3856,
      3857,
      3858,
      3859,
      3860,
      3861,
      3862,
      3863,
      3864,
      3865,
      3866,
      3867,
      3868,
      3869,
      3870,
      3871,
      3872,
      3873,
      3874,
      3875,
      3876,
      3877,
      3878,
      3879,
      3880,
      3881,
      3882,
      3883,
      3884,
      3885,
      3886,
      3887,
      3888,
      3889,
      3890,
      3891,
      3892,
      3893,
      3894,
      3895,
      3896,
      3897,
      3898,
      3899,
      3900,
      3901,
      3902,
      3903,
      3904,
      3905,
      3906,
      3907,
      3908,
      3909,
      3910,
      3911,
      3912,
      3913,
      3914,
      3915,
      3916,
      3917,
      3918,
      3919,
      3920,
      3921,
      3922,
      3923,
      3924,
      3925,
      3926,
      3927,
      3928,
      3929,
      3930,
      3931,
      3932,
      3933,
      3934,
      3935,
      3936,
      3937,
      3938,
      3939,
      3940,
      3941,
      3942,
      3943,
      3944,
      3945,
      3946,
      3947,
      3948,
      3949,
      3950,
      3951,
      3952,
      3953,
      3954,
      3955,
      3956,
      3957,
      3958,
      3959,
      3960,
      3961,
      3962,
      3963,
      3964,
      3965,
      3966,
      3967,
      3968,
      3969,
      3970,
      3971,
      3972,
      3973,
      3974,
      3975,
      3976,
      3977,
      3978,
      3979,
      3980,
      3981,
      3982,
      3983,
      3984,
      3985,
      3986,
      3987,
      3988,
      3989,
      3990,
      3991,
      3992,
      3993,
      3994,
      3995,
      3996,
      3997,
      3998,
      3999,
      4000,
      4001,
      4002,
      4003,
      4004,
      4005,
      4006,
      4007,
      4008,
      4009,
      4010,
      4011,
      4012,
      4013,
      4014,
      4015,
      4016,
      4017,
      4018,
      4019,
      4020,
      4021,
      4022,
      4023,
      4024,
      4025,
      4026,
      4027,
      4028,
      4029,
      4030,
      4031,
      4032,
      4033,
      4034,
      4035,
      4036,
      4037,
      4038,
      4039,
      4040,
      4041,
      4042,
      4043,
      4044,
      4045,
      4046,
      4047,
      4048,
      4049,
      4050,
      4051,
      4052,
      4053,
      4054,
      4055,
      4056,
      4057,
      4058,
      4059,
      4060,
      4061,
      4062,
      4063,
      4064,
      4065,
      4066,
      4067,
      4068,
      4069,
      4070,
      4071,
      4072,
      4073,
      4074,
      4075,
      4076,
      4077,
      4078,
      4079,
      4080,
      4081,
      4082,
      4083,
      4084,
      4085,
      4086,
      4087,
      4088,
      4089,
      4090,
      4091,
      4092,
      4093,
      4094,
      4095,
      4096,
      4097,
      4098,
      4099,
      4100,
      4101,
      4102,
      4103,
      4104,
      4105,
      4106,
      4107,
      4108,
      4109,
      4110,
      4111,
      4112,
      4113,
      4114,
      4115,
      4116,
      4117,
      4118,
      4119,
      4120,
      4121,
      4122,
      4123,
      4124,
      4125,
      4126,
      4127,
      4128,
      4129,
      4130,
      4131,
      4132,
      4133,
      4134,
      4135,
      4136,
      4137,
      4138,
      4139,
      4140,
      4141,
      4142,
      4143,
      4144,
      4145,
      4146,
      4147,
      4148,
      4149,
      4150,
      4151,
      4152,
      4153,
      4154,
      4155,
      4156,
      4157,
      4158,
      4159,
      4160,
      4161,
      4162,
      4163,
      4164,
      4165,
      4166,
      4167,
      4168,
      4169,
      4170,
      4171,
      4172,
      4173,
      4174,
      4175,
      4176,
      4177,
      4178,
      4179,
      4180,
      4181,
      4182,
      4183,
      4184,
      4185,
      4186,
      4187,
      4188,
      4189,
      4190,
      4191,
      4192,
      4193,
      4194,
      4195,
      4196,
      4197,
      4198,
      4199,
      4200,
      4201,
      4202,
      4203,
      4204,
      4205,
      4206,
      4207,
      4208,
      4209,
      4210,
      4211,
      4212,
      4213,
      4214,
      4215,
      4216,
      4217,
      4218,
      4219,
      4220,
      4221,
      4222,
      4223,
      4224,
      4225,
      4226,
      4227,
      4228,
      4229,
      4230,
      4231,
      4232,
      4233,
      4234,
      4235,
      4236,
      4237,
      4238,
      4239,
      4240,
      4241,
      4242,
      4243,
      4244,
      4245,
      4246,
      4247,
      4248,
      4249,
      4250,
      4251,
      4252,
      4253,
      4254,
      4255,
      4256,
      4257,
      4258,
      4259,
      4260,
      4261,
      4262,
      4263,
      4264,
      4265,
      4266,
      4267,
      4268,
      4269,
      4270,
      4271,
      4272,
      4273,
      4274,
      4275,
      4276,
      4277,
      4278,
      4279,
      4280,
      4281,
      4282,
      4283,
      4284,
      4285,
      4286,
      4287,
      4288,
      4289,
      4290,
      4291,
      4292,
      4293,
      4294,
      4295,
      4296,
      4297,
      4298,
      4299,
      4300,
      4301,
      4302,
      4303,
      4304,
      4305,
      4306,
      4307,
      4308,
      4309,
      4310,
      4311,
      4312,
      4313,
      4314,
      4315,
      4316,
      4317,
      4318,
      4319,
      4320,
      4321,
      4322,
      4323,
      4324,
      4325,
      4326,
      4327,
      4328,
      4329,
      4330,
      4331,
      4332,
      4333,
      4334,
      4335,
      4336,
      4337,
      4338,
      4339,
      4340,
      4341,
      4342,
      4343,
      4344,
      4345,
      4346,
      4347,
      4348,
      4349,
      4350,
      4351,
      4352,
      4353,
      4354,
      4355,
      4356,
      4357,
      4358,
      4359,
      4360,
      4361,
      4362,
      4363,
      4364,
      4365,
      4366,
      4367,
      4368,
      4369,
      4370,
      4371,
      4372,
      4373,
      4374,
      4375,
      4376,
      4377,
      4378,
      4379,
      4380,
      4381,
      4382,
      4383,
      4384,
      4385,
      4386,
      4387,
      4388,
      4389,
      4390,
      4391,
      4392,
      4393,
      4394,
      4395,
      4396,
      4397,
      4398,
      4399,
      4400,
      4401,
      4402,
      4403,
      4404,
      4405,
      4406,
      4407,
      4408,
      4409,
      4410,
      4411,
      4412,
      4413,
      4414,
      4415,
      4416,
      4417,
      4418,
      4419,
      4420,
      4421,
      4422,
      4423,
      4424,
      4425,
      4426,
      4427,
      4428,
      4429,
      4430,
      4431,
      4432,
      4433,
      4434,
      4435,
      4436,
      4437,
      4438,
      4439,
      4440,
      4441,
      4442,
      4443,
      4444,
      4445,
      4446,
      4447,
      4448,
      4449,
      4450,
      4451,
      4452,
      4453,
      4454,
      4455,
      4456,
      4457,
      4458,
      4459,
      4460,
      4461,
      4462,
      4463,
      4464,
      4465,
      4466,
      4467,
      4468,
      4469,
      4470,
      4471,
      4472,
      4473,
      4474,
      4475,
      4476,
      4477,
      4478,
      4479,
      4480,
      4481,
      4482,
      4483,
      4484,
      4485,
      4486,
      4487,
      4488,
      4489,
      4490,
      4491,
      4492,
      4493,
      4494,
      4495,
      4496,
      4497,
      4498,
      4499,
      4500,
      4501,
      4502,
      4503,
      4504,
      4505,
      4506,
      4507,
      4508,
      4509,
      4510,
      4511,
      4512,
      4513,
      4514,
      4515,
      4516,
      4517,
      4518,
      4519,
      4520,
      4521,
      4522,
      4523,
      4524,
      4525,
      4526,
      4527,
      4528,
      4529,
      4530,
      4531,
      4532,
      4533,
      4534,
      4535,
      4536,
      4537,
      4538,
      4539,
      4540,
      4541,
      4542,
      4543,
      4544,
      4545,
      4546,
      4547,
      4548,
      4549,
      4550,
      4551,
      4552,
      4553,
      4554,
      4555,
      4556,
      4557,
      4558,
      4559,
      4560,
      4561,
      4562,
      4563,
      4564,
      4565,
      4566,
      4567,
      4568,
      4569,
      4570,
      4571,
      4572,
      4573,
      4574,
      4575,
      4576,
      4577,
      4578,
      4579,
      4580,
      4581,
      4582,
      4583,
      4584,
      4585,
      4586,
      4587,
      4588,
      4589,
      4590,
      4591,
      4592,
      4593,
      4594,
      4595,
      4596,
      4597,
      4598,
      4599,
      4600,
      4601,
      4602,
      4603,
      4604,
      4605,
      4606,
      4607,
      4608,
      4609,
      4610,
      4611,
      4612,
      4613,
      4614,
      4615,
      4616,
      4617,
      4618,
      4619,
      4620,
      4621,
      4622,
      4623,
      4624,
      4625,
      4626,
      4627,
      4628,
      4629,
      4630,
      4631,
      4632,
      4633,
      4634,
      4635,
      4636,
      4637,
      4638,
      4639,
      4640,
      4641,
      4642,
      4643,
      4644,
      4645,
      4646,
      4647,
      4648,
      4649,
      4650,
      4651,
      4652,
      4653,
      4654,
      4655,
      4656,
      4657,
      4658,
      4659,
      4660,
      4661,
      4662,
      4663,
      4664,
      4665,
      4666,
      4667,
      4668,
      4669,
      4670,
      4671,
      4672,
      4673,
      4674,
      4675,
      4676,
      4677,
      4678,
      4679,
      4680,
      4681,
      4682,
      4683,
      4684,
      4685,
      4686,
      4687,
      4688,
      4689,
      4690,
      4691,
      4692,
      4693,
      4694,
      4695,
      4696,
      4697,
      4698,
      4699,
      4700,
      4701,
      4702,
      4703,
      4704,
      4705,
      4706,
      4707,
      4708,
      4709,
      4710,
      4711,
      4712,
      4713,
      4714,
      4715,
      4716,
      4717,
      4718,
      4719,
      4720,
      4721,
      4722,
      4723,
      4724,
      4725,
      4726,
      4727,
      4728,
      4729,
      4730,
      4731,
      4732,
      4733,
      4734,
      4735,
      4736,
      4737,
      4738,
      4739,
      4740,
      4741,
      4742,
      4743,
      4744,
      4745,
      4746,
      4747,
      4748,
      4749,
      4750,
      4751,
      4752,
      4753,
      4754,
      4755,
      4756,
      4757,
      4758,
      4759,
      4760,
      4761,
      4762,
      4763,
      4764,
      4765,
      4766,
      4767,
      4768,
      4769,
      4770,
      4771,
      4772,
      4773,
      4774,
      4775,
      4776,
      4777,
      4778,
      4779,
      4780,
      4781,
      4782,
      4783,
      4784,
      4785,
      4786,
      4787,
      4788,
      4789,
      4790,
      4791,
      4792,
      4793,
      4794,
      4795,
      4796,
      4797,
      4798,
      4799,
      4800,
      4801,
      4802,
      4803,
      4804,
      4805,
      4806,
      4807,
      4808,
      4809,
      4810,
      4811,
      4812,
      4813,
      4814,
      4815,
      4816,
      4817,
      4818,
      4819,
      4820,
      4821,
      4822,
      4823,
      4824,
      4825,
      4826,
      4827,
      4828,
      4829,
      4830,
      4831,
      4832,
      4833,
      4834,
      4835,
      4836,
      4837,
      4838,
      4839,
      4840,
      4841,
      4842,
      4843,
      4844,
      4845,
      4846,
      4847,
      4848,
      4849,
      4850,
      4851,
      4852,
      4853,
      4854,
      4855,
      4856,
      4857,
      4858,
      4859,
      4860,
      4861,
      4862,
      4863,
      4864,
      4865,
      4866,
      4867,
      4868,
      4869,
      4870,
      4871,
      4872,
      4873,
      4874,
      4875,
      4876,
      4877,
      4878,
      4879,
      4880,
      4881,
      4882,
      4883,
      4884,
      4885,
      4886,
      4887,
      4888,
      4889,
      4890,
      4891,
      4892,
      4893,
      4894,
      4895,
      4896,
      4897,
      4898,
      4899,
      4900,
      4901,
      4902,
      4903,
      4904,
      4905,
      4906,
      4907,
      4908,
      4909,
      4910,
      4911,
      4912,
      4913,
      4914,
      4915,
      4916,
      4917,
      4918,
      4919,
      4920,
      4921,
      4922,
      4923,
      4924,
      4925,
      4926,
      4927,
      4928,
      4929,
      4930,
      4931,
      4932,
      4933,
      4934,
      4935,
      4936,
      4937,
      4938,
      4939,
      4940,
      4941,
      4942,
      4943,
      4944,
      4945,
      4946,
      4947,
      4948,
      4949,
      4950,
      4951,
      4952,
      4953,
      4954,
      4955,
      4956,
      4957,
      4958,
      4959,
      4960,
      4961,
      4962,
      4963,
      4964,
      4965,
      4966,
      4967,
      4968,
      4969,
      4970,
      4971,
      4972,
      4973,
      4974,
      4975,
      4976,
      4977,
      4978,
      4979,
      4980,
      4981,
      4982,
      4983,
      4984,
      4985,
      4986,
      4987,
      4988,
      4989,
      4990,
      4991,
      4992,
      4993,
      4994,
      4995,
      4996,
      4997,
      4998,
      4999,
      5000,
      5001,
      5002,
      5003,
      5004,
      5005,
      5006,
      5007,
      5008,
      5009,
      5010,
      5011,
      5012,
      5013,
      5014,
      5015,
      5016,
      5017,
      5018,
      5019,
      5020,
      5021,
      5022,
      5023,
      5024,
      5025,
      5026,
      5027,
      5028,
      5029,
      5030,
      5031,
      5032,
      5033,
      5034,
      5035,
      5036,
      5037,
      5038,
      5039,
      5040,
      5041,
      5042,
      5043,
      5044,
      5045,
      5046,
      5047,
      5048,
      5049,
      5050,
      5051,
      5052,
      5053,
      5054,
      5055,
      5056,
      5057,
      5058,
      5059,
      5060,
      5061,
      5062,
      5063,
      5064,
      5065,
      5066,
      5067,
      5068,
      5069,
      5070,
      5071,
      5072,
      5073,
      5074,
      5075,
      5076,
      5077,
      5078,
      5079,
      5080,
      5081,
      5082,
      5083,
      5084,
      5085,
      5086,
      5087,
      5088,
      5089,
      5090,
      5091,
      5092,
      5093,
      5094,
      5095,
      5096,
      5097,
      5098,
      5099,
      5100,
      5101,
      5102,
      5103,
      5104,
      5105,
      5106,
      5107,
      5108,
      5109,
      5110,
      5111,
      5112,
      5113,
      5114,
      5115,
      5116,
      5117,
      5118,
      5119,
      5120,
      5121,
      5122,
      5123,
      5124,
      5125,
      5126,
      5127,
      5128,
      5129,
      5130,
      5131,
      5132,
      5133,
      5134,
      5135,
      5136,
      5137,
      5138,
      5139,
      5140,
      5141,
      5142,
      5143,
      5144,
      5145,
      5146,
      5147,
      5148,
      5149,
      5150,
      5151,
      5152,
      5153,
      5154,
      5155,
      5156,
      5157,
      5158,
      5159,
      5160,
      5161,
      5162,
      5163,
      5164,
      5165,
      5166,
      5167,
      5168,
      5169,
      5170,
      5171,
      5172,
      5173,
      5174,
      5175,
      5176,
      5177,
      5178,
      5179,
      5180,
      5181,
      5182,
      5183,
      5184,
      5185,
      5186,
      5187,
      5188,
      5189,
      5190,
      5191,
      5192,
      5193,
      5194,
      5195,
      5196,
      5197,
      5198,
      5199,
      5200,
      5201,
      5202,
      5203,
      5204,
      5205,
      5206,
      5207,
      5208,
      5209,
      5210,
      5211,
      5212,
      5213,
      5214,
      5215,
      5216,
      5217,
      5218,
      5219,
      5220,
      5221,
      5222,
      5223,
      5224,
      5225,
      5226,
      5227,
      5228,
      5229,
      5230,
      5231,
      5232,
      5233,
      5234,
      5235,
      5236,
      5237,
      5238,
      5239,
      5240,
      5241,
      5242,
      5243,
      5244,
      5245,
      5246,
      5247,
      5248,
      5249,
      5250,
      5251,
      5252,
      5253,
      5254,
      5255,
      5256,
      5257,
      5258,
      5259,
      5260,
      5261,
      5262,
      5263,
      5264,
      5265,
      5266,
      5267,
      5268,
      5269,
      5270,
      5271,
      5272,
      5273,
      5274,
      5275,
      5276,
      5277,
      5278,
      5279,
      5280,
      5281,
      5282,
      5283,
      5284,
      5285,
      5286,
      5287,
      5288,
      5289,
      5290,
      5291,
      5292,
      5293,
      5294,
      5295,
      5296,
      5297,
      5298,
      5299,
      5300,
      5301,
      5302,
      5303,
      5304,
      5305,
      5306,
      5307,
      5308,
      5309,
      5310,
      5311,
      5312,
      5313,
      5314,
      5315,
      5316,
      5317,
      5318,
      5319,
      5320,
      5321,
      5322,
      5323,
      5324,
      5325,
      5326,
      5327,
      5328,
      5329,
      5330,
      5331,
      5332,
      5333,
      5334,
      5335,
      5336,
      5337,
      5338,
      5339,
      5340,
      5341,
      5342,
      5343,
      5344,
      5345,
      5346,
      5347,
      5348,
      5349,
      5350,
      5351,
      5352,
      5353,
      5354,
      5355,
      5356,
      5357,
      5358,
      5359,
      5360,
      5361,
      5362,
      5363,
      5364,
      5365,
      5366,
      5367,
      5368,
      5369,
      5370,
      5371,
      5372,
      5373,
      5374,
      5375,
      5376,
      5377,
      5378,
      5379,
      5380,
      5381,
      5382,
      5383,
      5384,
      5385,
      5386,
      5387,
      5388,
      5389,
      5390,
      5391,
      5392,
      5393,
      5394,
      5395,
      5396,
      5397,
      5398,
      5399,
      5400,
      5401,
      5402,
      5403,
      5404,
      5405,
      5406,
      5407,
      5408,
      5409,
      5410,
      5411,
      5412,
      5413,
      5414,
      5415,
      5416,
      5417,
      5418,
      5419,
      5420,
      5421,
      5422,
      5423,
      5424,
      5425,
      5426,
      5427,
      5428,
      5429,
      5430,
      5431,
      5432,
      5433,
      5434,
      5435,
      5436,
      5437,
      5438,
      5439,
      5440,
      5441,
      5442,
      5443,
      5444,
      5445,
      5446,
      5447,
      5448,
      5449,
      5450,
      5451,
      5452,
      5453,
      5454,
      5455,
      5456,
      5457,
      5458,
      5459,
      5460,
      5461,
      5462,
      5463,
      5464,
      5465,
      5466,
      5467,
      5468,
      5469,
      5470,
      5471,
      5472,
      5473,
      5474,
      5475,
      5476,
      5477,
      5478,
      5479,
      5480,
      5481,
      5482,
      5483,
      5484,
      5485,
      5486,
      5487,
      5488,
      5489,
      5490,
      5491,
      5492,
      5493,
      5494,
      5495,
      5496,
      5497,
      5498,
      5499,
      5500,
      5501,
      5502,
      5503,
      5504,
      5505,
      5506,
      5507,
      5508,
      5509,
      5510,
      5511,
      5512,
      5513,
      5514,
      5515,
      5516,
      5517,
      5518,
      5519,
      5520,
      5521,
      5522,
      5523,
      5524,
      5525,
      5526,
      5527,
      5528,
      5529,
      5530,
      5531,
      5532,
      5533,
      5534,
      5535,
      5536,
      5537,
      5538,
      5539,
      5540,
      5541,
      5542,
      5543,
      5544,
      5545,
      5546,
      5547,
      5548,
      5549,
      5550,
      5551,
      5552,
      5553,
      5554,
      5555,
      5556,
      5557,
      5558,
      5559,
      5560,
      5561,
      5562,
      5563,
      5564,
      5565,
      5566,
      5567,
      5568,
      5569,
      5570,
      5571,
      5572,
      5573,
      5574,
      5575,
      5576,
      5577,
      5578,
      5579,
      5580,
      5581,
      5582,
      5583,
      5584,
      5585,
      5586,
      5587,
      5588,
      5589,
      5590,
      5591,
      5592,
      5593,
      5594,
      5595,
      5596,
      5597,
      5598,
      5599,
      5600,
      5601,
      5602,
      5603,
      5604,
      5605,
      5606,
      5607,
      5608,
      5609,
      5610,
      5611,
      5612,
      5613,
      5614,
      5615,
      5616,
      5617,
      5618,
      5619,
      5620,
      5621,
      5622,
      5623,
      5624,
      5625,
      5626,
      5627,
      5628,
      5629,
      5630,
      5631,
      5632,
      5633,
      5634,
      5635,
      5636,
      5637,
      5638,
      5639,
      5640,
      5641,
      5642,
      5643,
      5644,
      5645,
      5646,
      5647,
      5648,
      5649,
      5650,
      5651,
      5652,
      5653,
      5654,
      5655,
      5656,
      5657,
      5658,
      5659,
      5660,
      5661,
      5662,
      5663,
      5664,
      5665,
      5666,
      5667,
      5668,
      5669,
      5670,
      5671,
      5672,
      5673,
      5674,
      5675,
      5676,
      5677,
      5678,
      5679,
      5680,
      5681,
      5682,
      5683,
      5684,
      5685,
      5686,
      5687,
      5688,
      5689,
      5690,
      5691,
      5692,
      5693,
      5694,
      5695,
      5696,
      5697,
      5698,
      5699,
      5700,
      5701,
      5702,
      5703,
      5704,
      5705,
      5706,
      5707
  ],
  ecgHeartRateValues: [
      0.873118,
      0.873034,
      0.87292,
      0.872909,
      0.872889,
      0.871736,
      0.871729,
      0.870627,
      0.871388,
      0.871325,
      0.870847,
      0.869976,
      0.869866,
      0.869787,
      0.868345,
      0.868308,
      0.867808,
      0.867733,
      0.8704315,
      0.8701074,
      0.86973985,
      0.8693673,
      0.86889605,
      0.8684216,
      0.86804285,
      0.8675123,
      0.86711295,
      0.86665865,
      0.8660722,
      0.86549985,
      0.8649501,
      0.86436135,
      0.86377575,
      0.8632461,
      0.8626203,
      0.86205755,
      0.8614751,
      0.8607904,
      0.86018795,
      0.8595858,
      0.8589965,
      0.8583415,
      0.85778445,
      0.8571568,
      0.8564697,
      0.855842,
      0.8551763,
      0.85451235,
      0.85392275,
      0.8533399,
      0.85276495,
      0.8522159,
      0.8516263,
      0.8510358,
      0.85048425,
      0.84989255,
      0.84931745,
      0.84883275,
      0.8482717,
      0.84770405,
      0.84714655,
      0.8466449,
      0.84609465,
      0.84565725,
      0.8452359,
      0.84489215,
      0.84445475,
      0.8440218,
      0.84358445,
      0.84314905,
      0.8427241,
      0.8423152,
      0.84193745,
      0.8415552,
      0.8412329,
      0.84091475,
      0.84060525,
      0.84034185,
      0.84008615,
      0.8398611,
      0.8396703,
      0.83950655,
      0.83939475,
      0.839246,
      0.8391064,
      0.83898345,
      0.8389161,
      0.83887005,
      0.83889405,
      0.83892495,
      0.8389607,
      0.83900535,
      0.83907925,
      0.8391752,
      0.83927215,
      0.83937105,
      0.8394766,
      0.83957625,
      0.83967085,
      0.83976335,
      0.83984635,
      0.83992285,
      0.8399973,
      0.84007065,
      0.84014025,
      0.84020835,
      0.8402765,
      0.84033455,
      0.84038895,
      0.8404432,
      0.8405039,
      0.84056145,
      0.84060215,
      0.84064985,
      0.840705,
      0.84076875,
      0.84083055,
      0.8408919,
      0.84096855,
      0.84105545,
      0.841151,
      0.8412487,
      0.8413691,
      0.84149335,
      0.841646,
      0.84180185,
      0.84199145,
      0.8421865,
      0.84239465,
      0.84260945,
      0.84282335,
      0.84303785,
      0.84325755,
      0.8434766,
      0.84368635,
      0.8438835,
      0.84407415,
      0.84426335,
      0.8444349,
      0.84459095,
      0.84473745,
      0.8448807,
      0.84499295,
      0.8450979,
      0.8451744,
      0.8452477,
      0.84528715,
      0.8453207,
      0.84534015,
      0.8453473,
      0.84534665,
      0.8453422,
      0.8453304,
      0.8453118,
      0.84529315,
      0.8452745,
      0.845257,
      0.8452409,
      0.8452281,
      0.8452296,
      0.8452324,
      0.8452363,
      0.84524995,
      0.8452693,
      0.84528865,
      0.84530805,
      0.84532745,
      0.8453468,
      0.8453661,
      0.8453854,
      0.84540475,
      0.8454241,
      0.8454434,
      0.84546265,
      0.84548195,
      0.84550125,
      0.84551945,
      0.84553625,
      0.8455497,
      0.8455558,
      0.8455745,
      0.8455987,
      0.84562185,
      0.84565225,
      0.8456864,
      0.84572475,
      0.84576755,
      0.84583795,
      0.8459089,
      0.84598005,
      0.84607545,
      0.84617595,
      0.8462924,
      0.84643065,
      0.8466022,
      0.84679275,
      0.8469876,
      0.84719905,
      0.84742765,
      0.84765855,
      0.84787655,
      0.84814385,
      0.8484279,
      0.84870515,
      0.84900695,
      0.84931775,
      0.849637,
      0.8499315,
      0.8502533,
      0.85058845,
      0.85091215,
      0.85127135,
      0.851633,
      0.85199005,
      0.8523518,
      0.85269655,
      0.8530382,
      0.85339805,
      0.8537615,
      0.8541399,
      0.85455455,
      0.8549154,
      0.8552604,
      0.85561595,
      0.8559848,
      0.85634215,
      0.856761,
      0.85718725,
      0.85759465,
      0.85798955,
      0.85838945,
      0.858759,
      0.8591189,
      0.85948055,
      0.85980425,
      0.86013115,
      0.86046105,
      0.86075685,
      0.86104085,
      0.8613163,
      0.86156355,
      0.86182875,
      0.86211155,
      0.86238175,
      0.86261035,
      0.8628372,
      0.8629897,
      0.86313195,
      0.86326525,
      0.8633975,
      0.8635119,
      0.86361605,
      0.8637104,
      0.8637499,
      0.86378825,
      0.8637998,
      0.86379285,
      0.8637473,
      0.8636905,
      0.8636209,
      0.8635234,
      0.8633933,
      0.863242,
      0.86307105,
      0.8628833,
      0.86268565,
      0.86245955,
      0.86223155,
      0.8620007,
      0.8617499,
      0.8614483,
      0.8611442,
      0.8608179,
      0.86050965,
      0.86017,
      0.85984945,
      0.85948995,
      0.8591645,
      0.85879355,
      0.8583967,
      0.85805755,
      0.8576787,
      0.85729295,
      0.85689525,
      0.8564975,
      0.8561059,
      0.8556957,
      0.85527925,
      0.85486375,
      0.8544261,
      0.8540078,
      0.8535895,
      0.85317555,
      0.8527432,
      0.85234145,
      0.8519166,
      0.8515325,
      0.8511478,
      0.85077735,
      0.8504233,
      0.85002175,
      0.8496569,
      0.84930125,
      0.8489766,
      0.8486688,
      0.8483648,
      0.8481078,
      0.847859,
      0.84761145,
      0.8474022,
      0.84722075,
      0.84703505,
      0.8468655,
      0.8467308,
      0.84658625,
      0.84645735,
      0.8463246,
      0.84618675,
      0.8460369,
      0.84588335,
      0.84573385,
      0.8456391,
      0.84544925,
      0.84514605,
      0.84480395,
      0.8445379,
      0.8441274,
      0.8437091,
      0.84327975,
      0.84280775,
      0.84232145,
      0.84187995,
      0.84132705,
      0.84071615,
      0.84010285,
      0.83949695,
      0.8389009,
      0.83825265,
      0.8376421,
      0.83697045,
      0.8362858,
      0.8356011,
      0.83493695,
      0.83438455,
      0.83387285,
      0.83329105,
      0.832865,
      0.83252055,
      0.83219005,
      0.8319821,
      0.83185315,
      0.8317012,
      0.8317015,
      0.83179925,
      0.8319872,
      0.8321892,
      0.83246225,
      0.83287315,
      0.833368,
      0.8339746,
      0.83460265,
      0.8352307,
      0.8360094,
      0.83684725,
      0.83770185,
      0.83862655,
      0.83961605,
      0.84056975,
      0.8415592,
      0.8425487,
      0.84355325,
      0.84461885,
      0.84572245,
      0.8467907,
      0.84780735,
      0.8488179,
      0.8497693,
      0.8506827,
      0.8515961,
      0.85255055,
      0.85357805,
      0.8546436,
      0.8556711,
      0.85667905,
      0.8577446,
      0.85881015,
      0.8598757,
      0.8609793,
      0.86204525,
      0.8630598,
      0.8640032,
      0.8648785,
      0.86571575,
      0.8666264,
      0.86758865,
      0.8686216,
      0.8697252,
      0.87082875,
      0.87189425,
      0.8729598,
      0.87402535,
      0.8750909,
      0.8761945,
      0.8772981,
      0.8783636,
      0.8793984,
      0.8803663,
      0.88127965,
      0.8822687,
      0.8833091,
      0.88442065,
      0.88556225,
      0.88670385,
      0.88784545,
      0.8889795,
      0.89004505,
      0.8911106,
      0.8922142,
      0.8933178,
      0.8943833,
      0.8954488,
      0.8965143,
      0.8975798,
      0.89865555,
      0.8997211,
      0.9007793,
      0.90186635,
      0.90300795,
      0.90414955,
      0.90529115,
      0.90643275,
      0.90757435,
      0.90871595,
      0.90981785,
      0.91089095,
      0.91203255,
      0.91317415,
      0.91431575,
      0.91545735,
      0.91659895,
      0.9177406,
      0.91888225,
      0.9200239,
      0.92115825,
      0.92228205,
      0.92338565,
      0.92448925,
      0.9255548,
      0.9265764,
      0.9275659,
      0.9285554,
      0.92958295,
      0.93061065,
      0.93163985,
      0.93266735,
      0.9336568,
      0.9345473,
      0.93534295,
      0.9360851,
      0.936694,
      0.93720765,
      0.93753125,
      0.93785485,
      0.93821355,
      0.9384011,
      0.93842025,
      0.93847745,
      0.93853465,
      0.9383971,
      0.9380167,
      0.93759825,
      0.9371798,
      0.93678025,
      0.93615015,
      0.93538965,
      0.9345146,
      0.9337385,
      0.9330573,
      0.9321672,
      0.93114005,
      0.9302081,
      0.92942815,
      0.92861015,
      0.92779215,
      0.9268231,
      0.9257768,
      0.92469245,
      0.9236081,
      0.92276245,
      0.92219175,
      0.9212717,
      0.9200944,
      0.9187817,
      0.9176997,
      0.91678615,
      0.9160252,
      0.91526425,
      0.9140233,
      0.9127637,
      0.9117744,
      0.9107851,
      0.9098339,
      0.90892075,
      0.9080076,
      0.906959,
      0.9058556,
      0.9047522,
      0.90368685,
      0.9026215,
      0.90155615,
      0.9008782,
      0.89986215,
      0.89868265,
      0.89750315,
      0.8962856,
      0.89506805,
      0.8938505,
      0.8931129,
      0.8926944,
      0.8917186,
      0.8904299,
      0.8890601,
      0.88765225,
      0.88620635,
      0.8852769,
      0.88470615,
      0.88417345,
      0.88308495,
      0.8817532,
      0.8803834,
      0.8790136,
      0.87823915,
      0.87778255,
      0.8769496,
      0.87596035,
      0.8749711,
      0.87398185,
      0.87256015,
      0.8709621,
      0.8699594,
      0.86923155,
      0.8685467,
      0.8678999,
      0.8667916,
      0.86549795,
      0.86416625,
      0.8627965,
      0.86198255,
      0.86141185,
      0.86076125,
      0.85957085,
      0.8582011,
      0.8567933,
      0.85576185,
      0.85492475,
      0.8540877,
      0.85325065,
      0.8522478,
      0.85125855,
      0.8502693,
      0.8493181,
      0.84840495,
      0.8470175,
      0.8459951,
      0.84515805,
      0.84435905,
      0.84356005,
      0.8426136,
      0.84121325,
      0.83980925,
      0.83894505,
      0.83822215,
      0.83749925,
      0.8368144,
      0.8357094,
      0.83449185,
      0.8332743,
      0.83245475,
      0.83171285,
      0.83092925,
      0.8300732,
      0.8291483,
      0.82865245,
      0.82826795,
      0.8278115,
      0.8273128,
      0.82678705,
      0.82636395,
      0.826345,
      0.82639885,
      0.8263801,
      0.82636765,
      0.82634385,
      0.82624595,
      0.8264952,
      0.8268189,
      0.8270848,
      0.82747565,
      0.8278999,
      0.8282983,
      0.8287172,
      0.82914495,
      0.82954325,
      0.82990615,
      0.8302708,
      0.8306145,
      0.8309473,
      0.83125205,
      0.8315479,
      0.8318336,
      0.8321368,
      0.8323972,
      0.8326327,
      0.8328874,
      0.8331531,
      0.8333818,
      0.83359475,
      0.83381965,
      0.8340239,
      0.8342538,
      0.8344619,
      0.83465545,
      0.83486195,
      0.8350718,
      0.8352776,
      0.83547155,
      0.8356868,
      0.835911,
      0.83613975,
      0.8363529,
      0.83655465,
      0.83677215,
      0.8369991,
      0.8372191,
      0.8374282,
      0.837659,
      0.83789925,
      0.8381317,
      0.8383778,
      0.83859135,
      0.8388191,
      0.83904785,
      0.8392642,
      0.83949055,
      0.8397186,
      0.83996635,
      0.84019455,
      0.84042235,
      0.8406661,
      0.84091205,
      0.8411502,
      0.84140215,
      0.8416603,
      0.8419128,
      0.84217525,
      0.84243805,
      0.84269225,
      0.842959,
      0.8432343,
      0.8435201,
      0.8438084,
      0.8441198,
      0.84443755,
      0.8447312,
      0.8450567,
      0.8454376,
      0.8458354,
      0.84622785,
      0.84658735,
      0.84697,
      0.84737555,
      0.8478015,
      0.84822665,
      0.8486456,
      0.8490531,
      0.84946,
      0.84987735,
      0.8503534,
      0.8508045,
      0.8512604,
      0.851704,
      0.85215435,
      0.85267255,
      0.85320765,
      0.8537224,
      0.85419275,
      0.85465,
      0.8551389,
      0.8556418,
      0.85614115,
      0.85663075,
      0.8571007,
      0.85756,
      0.85804025,
      0.85857165,
      0.859098,
      0.85962135,
      0.86009265,
      0.8605676,
      0.86107215,
      0.86159955,
      0.8621319,
      0.86258885,
      0.86306015,
      0.8635337,
      0.8639817,
      0.86445765,
      0.86491855,
      0.8654122,
      0.8658839,
      0.8663639,
      0.8668346,
      0.8673106,
      0.8678092,
      0.86828145,
      0.86873995,
      0.8692183,
      0.8696799,
      0.87015975,
      0.8705933,
      0.8709975,
      0.87136455,
      0.8717453,
      0.87213535,
      0.87250855,
      0.872876,
      0.87323775,
      0.8735946,
      0.8739082,
      0.8742406,
      0.87455415,
      0.874851,
      0.8751853,
      0.8754741,
      0.87576335,
      0.87606745,
      0.876341,
      0.8766148,
      0.87688675,
      0.8771682,
      0.87747925,
      0.8777983,
      0.878128,
      0.8784106,
      0.8787021,
      0.87899655,
      0.8792885,
      0.87956255,
      0.8798663,
      0.88016215,
      0.8804923,
      0.88082195,
      0.8811159,
      0.8814353,
      0.88173595,
      0.88204805,
      0.88236025,
      0.88267665,
      0.88299175,
      0.8832973,
      0.88357485,
      0.883871,
      0.88414225,
      0.8844183,
      0.88468825,
      0.8849684,
      0.88522325,
      0.885497,
      0.88573265,
      0.88596115,
      0.88616495,
      0.8863635,
      0.88654935,
      0.8867139,
      0.88688145,
      0.88703,
      0.88717835,
      0.88731495,
      0.8874446,
      0.8875741,
      0.8877047,
      0.88780855,
      0.8879122,
      0.8880108,
      0.88810615,
      0.8881879,
      0.8882696,
      0.88833215,
      0.88833365,
      0.8883242,
      0.8882914,
      0.8882368,
      0.8881805,
      0.88809085,
      0.8879848,
      0.88783635,
      0.88768125,
      0.88749425,
      0.88729255,
      0.88708645,
      0.88681845,
      0.88654495,
      0.8862598,
      0.8859176,
      0.88554125,
      0.88512745,
      0.88470915,
      0.8842002,
      0.88374045,
      0.88327475,
      0.88285645,
      0.8823909,
      0.8819196,
      0.88140735,
      0.8809001,
      0.88042375,
      0.8799483,
      0.8793793,
      0.87881135,
      0.8782408,
      0.8777863,
      0.87722825,
      0.876663,
      0.876154,
      0.8756785,
      0.8752366,
      0.87472785,
      0.87430625,
      0.8738959,
      0.8734965,
      0.8730971,
      0.8725764,
      0.87213895,
      0.87177095,
      0.8714022,
      0.87088295,
      0.8703664,
      0.8699745,
      0.86948985,
      0.8689953,
      0.8684817,
      0.86807375,
      0.8676145,
      0.8671535,
      0.86661705,
      0.8660797,
      0.86554235,
      0.8650028,
      0.86440295,
      0.8637957,
      0.863149,
      0.86268425,
      0.86205655,
      0.86146715,
      0.8608714,
      0.8603579,
      0.85983915,
      0.85925325,
      0.85875175,
      0.85826665,
      0.8576849,
      0.8570987,
      0.8565824,
      0.8560583,
      0.8555448,
      0.85498125,
      0.8544887,
      0.85400155,
      0.85353555,
      0.853096,
      0.8526487,
      0.85220935,
      0.85185645,
      0.85146515,
      0.85104005,
      0.85067875,
      0.8503102,
      0.8499489,
      0.8495886,
      0.849198,
      0.8488319,
      0.8484706,
      0.8481067,
      0.8477404,
      0.84741825,
      0.8471499,
      0.84688185,
      0.8466141,
      0.84638605,
      0.84613975,
      0.8459026,
      0.84566585,
      0.84543185,
      0.84521405,
      0.8450382,
      0.8448673,
      0.8447119,
      0.84461715,
      0.8445306,
      0.84447485,
      0.8444532,
      0.8444346,
      0.84441885,
      0.8444159,
      0.8444343,
      0.84445375,
      0.8444732,
      0.8444927,
      0.84451215,
      0.8445316,
      0.84455115,
      0.84457065,
      0.84458775,
      0.84458905,
      0.84458955,
      0.84459005,
      0.84459055,
      0.84459105,
      0.84459155,
      0.844592,
      0.8445925,
      0.84459295,
      0.84458145,
      0.8445692,
      0.84455545,
      0.84453685,
      0.84450935,
      0.8444801,
      0.84444725,
      0.8444097,
      0.84436975,
      0.8443237,
      0.84427435,
      0.8442176,
      0.8441492,
      0.8440746,
      0.8439989,
      0.8439104,
      0.84381915,
      0.8437069,
      0.84359315,
      0.8434794,
      0.84337765,
      0.8432766,
      0.843177,
      0.8430802,
      0.84298285,
      0.84287975,
      0.84277825,
      0.8426751,
      0.84257305,
      0.8424657,
      0.84235535,
      0.84224925,
      0.84214715,
      0.84204575,
      0.8419446,
      0.841855,
      0.8417619,
      0.84168465,
      0.841608,
      0.8415281,
      0.8414475,
      0.8413661,
      0.84127675,
      0.84118795,
      0.8411047,
      0.8410281,
      0.84094955,
      0.8408766,
      0.84080155,
      0.8407365,
      0.8406697,
      0.84060535,
      0.8405486,
      0.84049735,
      0.84044695,
      0.84039775,
      0.84035485,
      0.84032525,
      0.8402979,
      0.8402751,
      0.8402612,
      0.8402497,
      0.84025885,
      0.84027865,
      0.8403058,
      0.8403449,
      0.840388,
      0.840435,
      0.8405136,
      0.8406146,
      0.84072835,
      0.84084625,
      0.8409982,
      0.8411821,
      0.84137265,
      0.84156555,
      0.84178655,
      0.8419996,
      0.8422377,
      0.84247675,
      0.84273635,
      0.8430004,
      0.84325275,
      0.843515,
      0.8437757,
      0.84406295,
      0.84437925,
      0.84469845,
      0.84499075,
      0.84526325,
      0.84559815,
      0.8459374,
      0.8462438,
      0.8465713,
      0.846895,
      0.84721675,
      0.8475481,
      0.84790885,
      0.8482513,
      0.84859175,
      0.8489415,
      0.84932325,
      0.84973075,
      0.8501204,
      0.85053055,
      0.85090895,
      0.8512591,
      0.8516764,
      0.8520937,
      0.85251135,
      0.85288335,
      0.85325325,
      0.85365025,
      0.85399585,
      0.854356,
      0.8547315,
      0.85507295,
      0.8554051,
      0.85574675,
      0.85608955,
      0.85640125,
      0.85667655,
      0.8569348,
      0.8571926,
      0.85740605,
      0.85761135,
      0.85781565,
      0.85793305,
      0.8580404,
      0.85813845,
      0.85821335,
      0.85827305,
      0.85829495,
      0.8583143,
      0.85828335,
      0.8582334,
      0.85817965,
      0.8580849,
      0.8579468,
      0.8577834,
      0.85760805,
      0.85742855,
      0.85720515,
      0.8569772,
      0.856737,
      0.85649515,
      0.85620355,
      0.85592145,
      0.8556473,
      0.8553787,
      0.8550777,
      0.8547442,
      0.85439465,
      0.8540451,
      0.8537046,
      0.85336065,
      0.85297415,
      0.8525559,
      0.8521824,
      0.85178155,
      0.8513834,
      0.8509842,
      0.8505896,
      0.8501562,
      0.84975315,
      0.849327,
      0.8489236,
      0.84852145,
      0.84808995,
      0.8476185,
      0.84717915,
      0.8467799,
      0.84635485,
      0.84592505,
      0.8454848,
      0.8450475,
      0.8446347,
      0.8442735,
      0.84385895,
      0.843461,
      0.84305825,
      0.84264685,
      0.8422416,
      0.84187775,
      0.8415166,
      0.8411641,
      0.8408033,
      0.8404422,
      0.84009965,
      0.83978585,
      0.839461,
      0.83910615,
      0.8387965,
      0.8384838,
      0.8382049,
      0.83791995,
      0.83762095,
      0.83727895,
      0.83690055,
      0.83655295,
      0.8362109,
      0.83583175,
      0.83546835,
      0.83503,
      0.8345258,
      0.8340312,
      0.8335717,
      0.8331535,
      0.8325645,
      0.83198095,
      0.83148655,
      0.83087185,
      0.8301593,
      0.8294453,
      0.82876985,
      0.82802815,
      0.8273173,
      0.8265957,
      0.8259437,
      0.8253685,
      0.824798,
      0.8243529,
      0.8239166,
      0.82362835,
      0.82341025,
      0.8232389,
      0.82310595,
      0.82301105,
      0.82317455,
      0.8234221,
      0.8236697,
      0.8241075,
      0.82468735,
      0.82535345,
      0.8260576,
      0.82690285,
      0.8277605,
      0.82874885,
      0.82977635,
      0.830769,
      0.83183455,
      0.83289065,
      0.8339495,
      0.83494205,
      0.83600755,
      0.8371111,
      0.83821465,
      0.8392802,
      0.84034575,
      0.8414113,
      0.8424768,
      0.84358035,
      0.84467585,
      0.8456967,
      0.8466481,
      0.8475725,
      0.84856195,
      0.8495514,
      0.8505789,
      0.85163315,
      0.8526226,
      0.85358345,
      0.85457855,
      0.8556441,
      0.85670965,
      0.85777525,
      0.8588789,
      0.85996345,
      0.86100995,
      0.86205645,
      0.8630816,
      0.8640328,
      0.86497325,
      0.86598835,
      0.8670729,
      0.86815745,
      0.86920395,
      0.8702337,
      0.87120415,
      0.87218585,
      0.87323235,
      0.8743169,
      0.87540145,
      0.87644795,
      0.87747075,
      0.87844115,
      0.87941155,
      0.88043905,
      0.8815046,
      0.8825321,
      0.8835429,
      0.88462765,
      0.8857412,
      0.88680675,
      0.8878723,
      0.8889759,
      0.8900795,
      0.8911618,
      0.8923034,
      0.8934159,
      0.89448145,
      0.895547,
      0.8966506,
      0.8977542,
      0.89884345,
      0.89998505,
      0.90112665,
      0.9022411,
      0.90330665,
      0.90441025,
      0.90551385,
      0.9065794,
      0.907673,
      0.9088146,
      0.90992155,
      0.9109871,
      0.9120907,
      0.9131943,
      0.91425985,
      0.9153179,
      0.91638345,
      0.91733315,
      0.9182014,
      0.9190006,
      0.91970195,
      0.92027285,
      0.92080575,
      0.92116615,
      0.9213946,
      0.92162305,
      0.92188955,
      0.92178095,
      0.9215456,
      0.92101305,
      0.92051515,
      0.92005865,
      0.91960215,
      0.91914565,
      0.91868915,
      0.91826925,
      0.9170517,
      0.91595,
      0.91492975,
      0.9140166,
      0.9132013,
      0.9124784,
      0.9113492,
      0.9103309,
      0.9091894,
      0.90804795,
      0.9069065,
      0.90614015,
      0.9054625,
      0.9049133,
      0.90412035,
      0.90320725,
      0.9020862,
      0.90084965,
      0.89965115,
      0.89841465,
      0.8979772,
      0.89709775,
      0.8959754,
      0.89485305,
      0.8937307,
      0.89260835,
      0.8919303,
      0.891341,
      0.89051755,
      0.88954845,
      0.88834995,
      0.8871895,
      0.88602905,
      0.8849993,
      0.8842133,
      0.88354745,
      0.88232855,
      0.8812632,
      0.8801598,
      0.87905635,
      0.87799095,
      0.87736755,
      0.87646775,
      0.87546165,
      0.8742821,
      0.87310255,
      0.87188495,
      0.87066735,
      0.86998795,
      0.86876925,
      0.86774195,
      0.8666766,
      0.8656493,
      0.86466005,
      0.8636708,
      0.8619404,
      0.8607993,
      0.85950565,
      0.85825005,
      0.8570325,
      0.8557769,
      0.85448325,
      0.8537089,
      0.85304085,
      0.8525843,
      0.8512526,
      0.84995895,
      0.8486653,
      0.8473336,
      0.84664875,
      0.8460019,
      0.84473025,
      0.8433384,
      0.8418164,
      0.8402944,
      0.83951355,
      0.83886635,
      0.83833365,
      0.83780095,
      0.83650725,
      0.8352326,
      0.8339389,
      0.8326452,
      0.83131345,
      0.82994365,
      0.82948705,
      0.82850835,
      0.82740495,
      0.82630155,
      0.8252362,
      0.8239616,
      0.8232994,
      0.82268335,
      0.82215075,
      0.8215426,
      0.8208917,
      0.820245,
      0.8195854,
      0.818852,
      0.81880475,
      0.81872885,
      0.818667,
      0.81857195,
      0.81843905,
      0.8182763,
      0.81803835,
      0.8182564,
      0.81856115,
      0.818844,
      0.81905255,
      0.81939505,
      0.81974725,
      0.82010045,
      0.8204429,
      0.820803,
      0.821131,
      0.8214247,
      0.82170275,
      0.8219894,
      0.8222829,
      0.822549,
      0.8227827,
      0.8229966,
      0.82321225,
      0.82343185,
      0.8236515,
      0.82386885,
      0.8240595,
      0.8242644,
      0.82447365,
      0.8246836,
      0.8248699,
      0.82507905,
      0.8252701,
      0.82545775,
      0.82566585,
      0.82588225,
      0.82609355,
      0.826306,
      0.8265227,
      0.82674905,
      0.8269778,
      0.8272014,
      0.8274385,
      0.82768555,
      0.8279333,
      0.82820165,
      0.8284684,
      0.82871365,
      0.8289604,
      0.8292082,
      0.82946505,
      0.82970115,
      0.8299658,
      0.83021775,
      0.83048425,
      0.83075005,
      0.8310153,
      0.83127005,
      0.83156955,
      0.83187185,
      0.83217465,
      0.83247885,
      0.8327884,
      0.8331139,
      0.8334511,
      0.8337636,
      0.83406655,
      0.83436245,
      0.83472685,
      0.8351369,
      0.83553175,
      0.8359217,
      0.8362857,
      0.83672375,
      0.83714495,
      0.83755635,
      0.8379922,
      0.8384302,
      0.8388492,
      0.83929275,
      0.8397519,
      0.84019725,
      0.84062185,
      0.8410408,
      0.84152255,
      0.84203315,
      0.8425742,
      0.84312075,
      0.8435968,
      0.8440818,
      0.8445993,
      0.845129,
      0.84565905,
      0.84611605,
      0.84658565,
      0.84708035,
      0.84756005,
      0.8480676,
      0.84858165,
      0.8490945,
      0.84959875,
      0.85010715,
      0.8506845,
      0.8512366,
      0.85173695,
      0.8522118,
      0.8526627,
      0.85313075,
      0.85360675,
      0.85406245,
      0.85448045,
      0.85489455,
      0.8553165,
      0.8557982,
      0.85625405,
      0.8566862,
      0.8571237,
      0.85755835,
      0.85794855,
      0.85830545,
      0.8586579,
      0.85906395,
      0.85940625,
      0.859749,
      0.8600927,
      0.8604337,
      0.8607764,
      0.86113265,
      0.86149435,
      0.8618479,
      0.8622105,
      0.8625945,
      0.86300075,
      0.8633444,
      0.8636852,
      0.8640279,
      0.86437135,
      0.8647148,
      0.86505825,
      0.86540065,
      0.86574335,
      0.8660514,
      0.8663898,
      0.8667325,
      0.8670879,
      0.86746525,
      0.86783435,
      0.8681771,
      0.8685207,
      0.8688358,
      0.86914305,
      0.8694483,
      0.8697275,
      0.87003325,
      0.87033595,
      0.87066775,
      0.8709804,
      0.8712654,
      0.8715599,
      0.87187255,
      0.8721789,
      0.872493,
      0.872799,
      0.8731313,
      0.87342575,
      0.87369405,
      0.87397235,
      0.87425255,
      0.87451325,
      0.8747788,
      0.87508085,
      0.8753825,
      0.8756771,
      0.87598175,
      0.8762875,
      0.87655525,
      0.87683255,
      0.87713015,
      0.87744675,
      0.8777429,
      0.87804585,
      0.87830335,
      0.87853275,
      0.8787239,
      0.8789031,
      0.87908125,
      0.8792491,
      0.8793906,
      0.87952905,
      0.8796321,
      0.87966205,
      0.8796611,
      0.87965945,
      0.87961385,
      0.87951295,
      0.87940305,
      0.8792829,
      0.8791286,
      0.8789837,
      0.8787585,
      0.8785258,
      0.87828775,
      0.87802245,
      0.87771965,
      0.87742325,
      0.87712765,
      0.87682705,
      0.87647245,
      0.87611115,
      0.87567835,
      0.8752799,
      0.87487325,
      0.87450485,
      0.8740876,
      0.87372315,
      0.87334855,
      0.87290485,
      0.8724735,
      0.87199805,
      0.87153175,
      0.87106555,
      0.8706133,
      0.8701847,
      0.86969125,
      0.8691775,
      0.86865885,
      0.8682214,
      0.8677351,
      0.86722555,
      0.8668139,
      0.866441,
      0.8659655,
      0.8653759,
      0.8648413,
      0.86424855,
      0.86365895,
      0.86313515,
      0.86249525,
      0.8618295,
      0.8612728,
      0.86075465,
      0.8602313,
      0.8597112,
      0.8591479,
      0.8586056,
      0.8580679,
      0.85738315,
      0.85680795,
      0.8562649,
      0.85572535,
      0.8550932,
      0.8545743,
      0.8541178,
      0.85365235,
      0.8532456,
      0.8527701,
      0.8523364,
      0.85194315,
      0.85157225,
      0.8511628,
      0.85066185,
      0.85015395,
      0.84963605,
      0.8492633,
      0.84884355,
      0.84841125,
      0.84803755,
      0.8476062,
      0.847162,
      0.84665545,
      0.84617145,
      0.8456879,
      0.84521245,
      0.8447433,
      0.84425935,
      0.8438451,
      0.84333165,
      0.84287795,
      0.84241495,
      0.8419453,
      0.8415144,
      0.84108725,
      0.84064715,
      0.84019305,
      0.83976175,
      0.8393435,
      0.8389376,
      0.8385954,
      0.83826655,
      0.8380005,
      0.83776645,
      0.8375329,
      0.8373049,
      0.83708055,
      0.8368716,
      0.83667755,
      0.8365447,
      0.8364282,
      0.83633055,
      0.83624545,
      0.8361878,
      0.83613995,
      0.8361173,
      0.83611185,
      0.83614685,
      0.83618455,
      0.8362223,
      0.83626075,
      0.836328,
      0.8364025,
      0.8364771,
      0.8365536,
      0.8366517,
      0.83675515,
      0.83686825,
      0.83698285,
      0.83709745,
      0.8372121,
      0.83732915,
      0.83745585,
      0.83757135,
      0.8376871,
      0.8378043,
      0.83792555,
      0.83803805,
      0.8381525,
      0.8382678,
      0.8383824,
      0.8384682,
      0.83854675,
      0.83862525,
      0.8387018,
      0.8387524,
      0.83876755,
      0.83875355,
      0.83873495,
      0.8387134,
      0.8386792,
      0.8386352,
      0.838561,
      0.8384811,
      0.83838885,
      0.8382932,
      0.83818365,
      0.83805635,
      0.83792035,
      0.8377685,
      0.83759365,
      0.83740735,
      0.8372204,
      0.83701145,
      0.83679855,
      0.83657855,
      0.83638115,
      0.836191,
      0.83598205,
      0.83577345,
      0.83556975,
      0.83536855,
      0.8351615,
      0.83495675,
      0.8347595,
      0.83455115,
      0.83432365,
      0.8340957,
      0.83386825,
      0.8336439,
      0.8334417,
      0.83324815,
      0.83305525,
      0.83288435,
      0.8327191,
      0.8325713,
      0.83244095,
      0.83232585,
      0.8322482,
      0.832175,
      0.8321126,
      0.8320682,
      0.8320696,
      0.832089,
      0.8321147,
      0.8321523,
      0.8322284,
      0.83232625,
      0.8324278,
      0.83255465,
      0.83268765,
      0.8328402,
      0.83299595,
      0.8331708,
      0.83335315,
      0.83355035,
      0.83374225,
      0.833933,
      0.83412545,
      0.83431935,
      0.83453625,
      0.83474865,
      0.8349385,
      0.83514055,
      0.8353614,
      0.83558715,
      0.83580225,
      0.8360362,
      0.83628795,
      0.83655765,
      0.8368234,
      0.8370943,
      0.83736835,
      0.8376507,
      0.83792475,
      0.83819865,
      0.8384755,
      0.8387784,
      0.8390644,
      0.8393473,
      0.83963415,
      0.83991985,
      0.8402413,
      0.84060935,
      0.8409571,
      0.841356,
      0.84177115,
      0.84216315,
      0.84255965,
      0.8429582,
      0.8433556,
      0.8437321,
      0.84412085,
      0.84448765,
      0.84489995,
      0.8452957,
      0.8456853,
      0.8460661,
      0.84647425,
      0.84688985,
      0.84727805,
      0.84770925,
      0.8481091,
      0.84846155,
      0.84882025,
      0.84916395,
      0.8495329,
      0.8499084,
      0.8502668,
      0.85059635,
      0.85093955,
      0.85128235,
      0.85159945,
      0.85190185,
      0.8521562,
      0.8524054,
      0.8526151,
      0.85279435,
      0.85293535,
      0.85306825,
      0.8531828,
      0.8532214,
      0.8532218,
      0.8531759,
      0.853122,
      0.8529982,
      0.8528033,
      0.85255695,
      0.8523041,
      0.8520394,
      0.851755,
      0.85143195,
      0.85107795,
      0.85071145,
      0.8503426,
      0.84994965,
      0.8495885,
      0.84916985,
      0.84875455,
      0.8482719,
      0.84779665,
      0.8473242,
      0.84688,
      0.84642345,
      0.8459374,
      0.84547555,
      0.8450383,
      0.8446058,
      0.84415155,
      0.84367605,
      0.84320325,
      0.842766,
      0.84234365,
      0.84191425,
      0.8414862,
      0.84108075,
      0.8406506,
      0.8402475,
      0.8398483,
      0.8394931,
      0.8391129,
      0.83873975,
      0.8383695,
      0.838029,
      0.83769795,
      0.83735405,
      0.8370119,
      0.83670785,
      0.8364267,
      0.8361741,
      0.83592465,
      0.8356777,
      0.83544995,
      0.8352508,
      0.83504935,
      0.8348459,
      0.83466845,
      0.8345323,
      0.83439425,
      0.83427335,
      0.83417925,
      0.83410015,
      0.83391675,
      0.833732,
      0.83356675,
      0.83341325,
      0.8332995,
      0.8330335,
      0.8328436,
      0.83250145,
      0.8320893,
      0.83167105,
      0.83112475,
      0.8305711,
      0.8300097,
      0.82949085,
      0.82901495,
      0.8283977,
      0.82777535,
      0.8270074,
      0.82632205,
      0.82557495,
      0.82486915,
      0.82416235,
      0.82345305,
      0.8227874,
      0.8221217,
      0.82168435,
      0.82124115,
      0.820956,
      0.82081695,
      0.8207495,
      0.8208183,
      0.82090095,
      0.82103435,
      0.82116775,
      0.8213392,
      0.82172,
      0.82211975,
      0.8227478,
      0.82337585,
      0.82410435,
      0.82493425,
      0.82584395,
      0.8268334,
      0.82786095,
      0.82892655,
      0.82995405,
      0.83094935,
      0.8320149,
      0.83308045,
      0.83414405,
      0.83521595,
      0.8363576,
      0.83749925,
      0.8386409,
      0.83978255,
      0.84091395,
      0.8420398,
      0.84310535,
      0.8441709,
      0.8452745,
      0.8463781,
      0.84744365,
      0.8485092,
      0.8497865,
      0.85137575,
      0.85303115,
      0.85483875,
      0.8566918,
      0.85847445,
      0.86025655,
      0.86221635,
      0.86429035,
      0.86651655,
      0.868895,
      0.8713876,
      0.8739322,
      0.87648055,
      0.8790873,
      0.8818082,
      0.8845514,
      0.8873484,
      0.89029765,
      0.8932675,
      0.896043,
      0.89862075,
      0.90128455,
      0.9038454,
      0.90629385,
      0.90883045,
      0.91136925,
      0.9138048,
      0.91601265,
      0.91793935,
      0.91963285,
      0.92105085,
      0.92225465,
      0.92336455,
      0.92429705,
      0.92485595,
      0.9251193,
      0.92548105,
      0.9253248,
      0.9248288,
      0.92442955,
      0.92353595,
      0.9221854,
      0.9209378,
      0.9194684,
      0.9177373,
      0.9153702,
      0.91310635,
      0.91107015,
      0.9086562,
      0.906221,
      0.90364285,
      0.9013599,
      0.89859325,
      0.8961201,
      0.89357525,
      0.89122215,
      0.88855875,
      0.8860328,
      0.88347875,
      0.88069915,
      0.8781495,
      0.87605685,
      0.8732032,
      0.87075245,
      0.86818225,
      0.8661738,
      0.8638909,
      0.86125165,
      0.8590569,
      0.8566229,
      0.85434005,
      0.8520572,
      0.84977435,
      0.84749145,
      0.8451315,
      0.8427465,
      0.8404636,
      0.83820805,
      0.83619635,
      0.8341846,
      0.8322272,
      0.83007795,
      0.82849905,
      0.82676625,
      0.82540595,
      0.82409945,
      0.8228805,
      0.8218293,
      0.8208274,
      0.82015915,
      0.8196025,
      0.81888365,
      0.818764,
      0.81846,
      0.8185016,
      0.81858585,
      0.81870335,
      0.81899695,
      0.8193342,
      0.8197189,
      0.8201388,
      0.82057645,
      0.82102275,
      0.8214343,
      0.82178115,
      0.8220928,
      0.82240275,
      0.82273155,
      0.8230448,
      0.82334005,
      0.8236122,
      0.82386865,
      0.8240983,
      0.8243557,
      0.8246132,
      0.82487655,
      0.8251391,
      0.82540755,
      0.8256611,
      0.82591465,
      0.8261654,
      0.82641665,
      0.82668575,
      0.82695495,
      0.827233,
      0.82751315,
      0.82779345,
      0.8280555,
      0.8283813,
      0.82871565,
      0.8290419,
      0.8294076,
      0.8297747,
      0.8301295,
      0.83047475,
      0.83089445,
      0.83132475,
      0.83174005,
      0.83215915,
      0.83264805,
      0.83312835,
      0.83359305,
      0.8340969,
      0.83462845,
      0.8351375,
      0.83567625,
      0.83625045,
      0.83687885,
      0.83747225,
      0.83804615,
      0.8386568,
      0.8393107,
      0.8399674,
      0.8406125,
      0.84128855,
      0.8419087,
      0.8425893,
      0.84327075,
      0.8439562,
      0.8445942,
      0.8452651,
      0.8459824,
      0.8466726,
      0.8473336,
      0.8480018,
      0.8486575,
      0.84928095,
      0.84988985,
      0.85049915,
      0.8511501,
      0.8517612,
      0.85229085,
      0.852844,
      0.8533907,
      0.85390315,
      0.8544605,
      0.8549326,
      0.8554427,
      0.8559462,
      0.8564257,
      0.85690135,
      0.85733295,
      0.85777065,
      0.85825585,
      0.8587746,
      0.85926655,
      0.8597542,
      0.86021145,
      0.86066005,
      0.86110865,
      0.86155725,
      0.8620352,
      0.86248475,
      0.86293165,
      0.86341915,
      0.8638254,
      0.86424325,
      0.86464025,
      0.8650652,
      0.8654797,
      0.8658612,
      0.86626985,
      0.8666344,
      0.8669513,
      0.8672208,
      0.86751875,
      0.8678162,
      0.8681316,
      0.868418,
      0.86866165,
      0.86891205,
      0.8691449,
      0.86937795,
      0.86964465,
      0.8698702,
      0.8701278,
      0.8703756,
      0.8706015,
      0.87082915,
      0.8710588,
      0.87132275,
      0.871563,
      0.8718214,
      0.87208185,
      0.87234225,
      0.87259555,
      0.8728507,
      0.87307655,
      0.8733268,
      0.87359955,
      0.8738681,
      0.8741342,
      0.8744017,
      0.874635,
      0.8748936,
      0.87512005,
      0.87534455,
      0.87557265,
      0.8757616,
      0.87594715,
      0.87609825,
      0.8762342,
      0.87635055,
      0.87645675,
      0.8765613,
      0.87664235,
      0.8767118,
      0.87678105,
      0.8768168,
      0.8768283,
      0.8768292,
      0.8768054,
      0.8767782,
      0.8766761,
      0.8765405,
      0.87637025,
      0.87619115,
      0.8759631,
      0.87566895,
      0.8753576,
      0.8750459,
      0.8746666,
      0.874268,
      0.87387555,
      0.8734281,
      0.87293525,
      0.87243975,
      0.8718997,
      0.87135525,
      0.8708112,
      0.87017855,
      0.86954705,
      0.8688423,
      0.8681944,
      0.86755015,
      0.86686775,
      0.8661703,
      0.86542855,
      0.8647556,
      0.8640581,
      0.8633605,
      0.8627247,
      0.86210915,
      0.86143785,
      0.86077165,
      0.86014395,
      0.8594818,
      0.85886375,
      0.85824115,
      0.8575556,
      0.8569198,
      0.85627625,
      0.85567915,
      0.85496125,
      0.85422385,
      0.853485,
      0.85272415,
      0.85205225,
      0.8513666,
      0.8507237,
      0.8500297,
      0.8493385,
      0.8486068,
      0.8478759,
      0.8471792,
      0.84644445,
      0.84574875,
      0.84505335,
      0.8443509,
      0.84370205,
      0.8430763,
      0.84242415,
      0.8417891,
      0.8412693,
      0.8407624,
      0.84030575,
      0.8398932,
      0.83948005,
      0.8390819,
      0.8386785,
      0.83832385,
      0.8379791,
      0.83767235,
      0.8374182,
      0.8371799,
      0.8369796,
      0.83678685,
      0.83659295,
      0.8364188,
      0.8362524,
      0.83610945,
      0.83600115,
      0.83589515,
      0.8358126,
      0.83573965,
      0.83568355,
      0.8356248,
      0.83556165,
      0.8354985,
      0.8354409,
      0.8353777,
      0.8353153,
      0.83525445,
      0.83519785,
      0.83513885,
      0.83508685,
      0.83503245,
      0.8349781,
      0.8349261,
      0.8348763,
      0.8348253,
      0.8347725,
      0.8347274,
      0.83467915,
      0.83463225,
      0.83459185,
      0.83455435,
      0.8345205,
      0.834489,
      0.83445615,
      0.8344313,
      0.8344037,
      0.83437695,
      0.83434875,
      0.83432245,
      0.8342957,
      0.83426955,
      0.83424325,
      0.83421885,
      0.8341932,
      0.8341672,
      0.83413685,
      0.8341021,
      0.83407055,
      0.83403765,
      0.833999,
      0.8339567,
      0.8339099,
      0.8338624,
      0.8338112,
      0.83375925,
      0.8336991,
      0.8336383,
      0.83357405,
      0.8335098,
      0.8334376,
      0.8333602,
      0.8332745,
      0.83318165,
      0.8330894,
      0.8329904,
      0.8328904,
      0.8327953,
      0.83269065,
      0.832579,
      0.83246675,
      0.83235955,
      0.8322587,
      0.8321591,
      0.8320646,
      0.8319713,
      0.83188955,
      0.8318089,
      0.83173315,
      0.83165925,
      0.83159385,
      0.8315355,
      0.83148695,
      0.83144555,
      0.83140535,
      0.83137355,
      0.83134905,
      0.8313449,
      0.83135085,
      0.8313661,
      0.8314071,
      0.83148545,
      0.8315644,
      0.83166105,
      0.8317589,
      0.8318753,
      0.831992,
      0.83212555,
      0.83229255,
      0.83245975,
      0.8326558,
      0.832853,
      0.83305025,
      0.83330845,
      0.8335942,
      0.8338826,
      0.8342042,
      0.8345063,
      0.8348087,
      0.83514325,
      0.8354929,
      0.83583905,
      0.8361864,
      0.8365471,
      0.83690835,
      0.83729355,
      0.8376808,
      0.83809125,
      0.83851065,
      0.8389305,
      0.83936295,
      0.8397943,
      0.8402257,
      0.8405989,
      0.84097555,
      0.841387,
      0.84179595,
      0.84220555,
      0.84264685,
      0.843089,
      0.84349845,
      0.84387445,
      0.84425285,
      0.84460045,
      0.84495075,
      0.84526395,
      0.8455789,
      0.84585855,
      0.84609615,
      0.84633345,
      0.84653805,
      0.8467477,
      0.8469541,
      0.8471291,
      0.84726995,
      0.8473424,
      0.84734375,
      0.8473155,
      0.8472511,
      0.8471196,
      0.8469472,
      0.84676625,
      0.84649855,
      0.8461926,
      0.84587935,
      0.84555505,
      0.84510655,
      0.8446481,
      0.8441856,
      0.8436862,
      0.84310455,
      0.84240385,
      0.84170345,
      0.84102825,
      0.8403094,
      0.8395449,
      0.8388152,
      0.8380749,
      0.8372872,
      0.8364993,
      0.8357023,
      0.8349095,
      0.83416515,
      0.8334402,
      0.83269635,
      0.8319374,
      0.83128405,
      0.8305974,
      0.82991555,
      0.82927105,
      0.82870635,
      0.8282725,
      0.8278428,
      0.82741735,
      0.82705345,
      0.82677685,
      0.8265135,
      0.8262959,
      0.8261382,
      0.82600955,
      0.8259158,
      0.8258036,
      0.8256909,
      0.82559145,
      0.82549915,
      0.8253671,
      0.82523775,
      0.8250767,
      0.8248668,
      0.8245437,
      0.82420335,
      0.8237982,
      0.8233662,
      0.8227699,
      0.82215765,
      0.82150325,
      0.8208384,
      0.8200945,
      0.81931495,
      0.8185756,
      0.81786975,
      0.8173167,
      0.8168025,
      0.8164839,
      0.81635115,
      0.8164555,
      0.81658525,
      0.8169755,
      0.81745515,
      0.8181958,
      0.8191376,
      0.82014355,
      0.82132275,
      0.8226917,
      0.82425215,
      0.8260015,
      0.8277864,
      0.8298336,
      0.83209785,
      0.8345159,
      0.8369148,
      0.8393736,
      0.8418444,
      0.8442989,
      0.84677245,
      0.84920605,
      0.8516796,
      0.8541531,
      0.85677525,
      0.85941855,
      0.86189155,
      0.86434885,
      0.86684865,
      0.86944375,
      0.8719173,
      0.8745986,
      0.87733855,
      0.8799644,
      0.88247605,
      0.88485935,
      0.8872502,
      0.8894574,
      0.89172445,
      0.8938936,
      0.8959486,
      0.89794305,
      0.89996,
      0.90182735,
      0.90353985,
      0.9051587,
      0.90683365,
      0.9085081,
      0.9100684,
      0.91139895,
      0.9125597,
      0.91343135,
      0.9140923,
      0.9146194,
      0.9150456,
      0.9153839,
      0.9157902,
      0.91602745,
      0.91604665,
      0.91606585,
      0.916085,
      0.91564755,
      0.9152101,
      0.9148081,
      0.91402095,
      0.9128986,
      0.91177625,
      0.91072995,
      0.90968365,
      0.90882165,
      0.90813685,
      0.9067099,
      0.90531585,
      0.90397085,
      0.90272675,
      0.90158535,
      0.90044395,
      0.89954105,
      0.8980952,
      0.89664935,
      0.8952035,
      0.89421425,
      0.893225,
      0.89170525,
      0.8903047,
      0.88923935,
      0.888174,
      0.88710865,
      0.8860433,
      0.88429305,
      0.88254275,
      0.8815346,
      0.8806889,
      0.87924405,
      0.8777982,
      0.8763523,
      0.8749064,
      0.87346055,
      0.8726411,
      0.8712912,
      0.8696931,
      0.86809505,
      0.866497,
      0.86542945,
      0.8646685,
      0.86322265,
      0.8617768,
      0.86033095,
      0.8588851,
      0.85755905,
      0.8560371,
      0.85451515,
      0.8529932,
      0.85215515,
      0.8513181,
      0.84979615,
      0.8482742,
      0.8467522,
      0.8453648,
      0.8443743,
      0.8434877,
      0.8420418,
      0.84059595,
      0.8391501,
      0.83770425,
      0.8369433,
      0.8355695,
      0.83404755,
      0.8325256,
      0.83156875,
      0.8308078,
      0.82941845,
      0.8278965,
      0.82637455,
      0.8248526,
      0.82401555,
      0.8231785,
      0.82165655,
      0.8201346,
      0.8187462,
      0.8175021,
      0.8166467,
      0.81566465,
      0.8142949,
      0.81292515,
      0.8115554,
      0.81062345,
      0.8096587,
      0.8086124,
      0.80749985,
      0.80633665,
      0.80574585,
      0.8052133,
      0.80467805,
      0.80406945,
      0.8034101,
      0.8027191,
      0.80264325,
      0.80249755,
      0.80232395,
      0.8020769,
      0.8019925,
      0.80196655,
      0.80226005,
      0.8024817,
      0.80268685,
      0.8029954,
      0.80346595,
      0.80394745,
      0.80442235,
      0.80488795,
      0.80536175,
      0.80583775,
      0.80625805,
      0.80667875,
      0.80707515,
      0.8074419,
      0.80780375,
      0.80817695,
      0.80850515,
      0.8088318,
      0.80910495,
      0.8093717,
      0.809653,
      0.80993225,
      0.8101654,
      0.81040205,
      0.8106039,
      0.81080135,
      0.81100065,
      0.8111855,
      0.81137785,
      0.81156855,
      0.8117448,
      0.81192005,
      0.8121424,
      0.8123536,
      0.8125855,
      0.81280195,
      0.813029,
      0.8132702,
      0.8135247,
      0.8137876,
      0.81403205,
      0.81427835,
      0.8145524,
      0.8148494,
      0.815127,
      0.81540555,
      0.8157078,
      0.81601265,
      0.8163321,
      0.816656,
      0.8169804,
      0.8173053,
      0.8176456,
      0.8179885,
      0.8183134,
      0.81863895,
      0.8189936,
      0.81934025,
      0.81973505,
      0.82014085,
      0.82055415,
      0.82096615,
      0.8213589,
      0.82178655,
      0.8222216,
      0.8226868,
      0.82312585,
      0.82358285,
      0.82401705,
      0.8244931,
      0.82496885,
      0.82544445,
      0.82587125,
      0.8263282,
      0.82683595,
      0.8273621,
      0.8278876,
      0.828409,
      0.8288743,
      0.82932935,
      0.82982205,
      0.83032005,
      0.830804,
      0.83129035,
      0.8317731,
      0.8322821,
      0.8327994,
      0.8333352,
      0.83385975,
      0.83433915,
      0.834818,
      0.83531525,
      0.83584665,
      0.83634845,
      0.83684285,
      0.83733785,
      0.83781105,
      0.8382935,
      0.83877215,
      0.8392481,
      0.8396781,
      0.8401624,
      0.84064945,
      0.8410723,
      0.84150505,
      0.8418895,
      0.8422815,
      0.84264055,
      0.84303855,
      0.84345395,
      0.8438722,
      0.8443191,
      0.84473795,
      0.84515465,
      0.84554685,
      0.8459175,
      0.84626795,
      0.8466442,
      0.8470044,
      0.84735685,
      0.84770635,
      0.8480026,
      0.84829205,
      0.8486172,
      0.84892605,
      0.8492379,
      0.8495718,
      0.84989955,
      0.8501972,
      0.8504742,
      0.850754,
      0.85104335,
      0.8513289,
      0.85161155,
      0.85189165,
      0.8521805,
      0.85248875,
      0.85276505,
      0.8530435,
      0.8533492,
      0.85365495,
      0.8539898,
      0.85432095,
      0.85461265,
      0.8549114,
      0.8552016,
      0.85550805,
      0.8558133,
      0.8560938,
      0.8563879,
      0.8566757,
      0.85692565,
      0.8571587,
      0.8573902,
      0.85762985,
      0.8578607,
      0.85807205,
      0.85828145,
      0.85849275,
      0.85873705,
      0.85898155,
      0.8591883,
      0.85940535,
      0.8596271,
      0.85990215,
      0.86017305,
      0.86039715,
      0.8606222,
      0.8608703,
      0.8611048,
      0.8613657,
      0.86160555,
      0.86184845,
      0.86212505,
      0.86237325,
      0.8626215,
      0.862875,
      0.86312445,
      0.8634022,
      0.86361965,
      0.86384,
      0.86408605,
      0.8643253,
      0.86458455,
      0.86478545,
      0.8649867,
      0.86519475,
      0.8654015,
      0.8656039,
      0.8658082,
      0.86600085,
      0.8661945,
      0.8664029,
      0.86657755,
      0.86675355,
      0.8669468,
      0.8671348,
      0.86732265,
      0.86747805,
      0.8676334,
      0.86778665,
      0.86793165,
      0.86807305,
      0.8681896,
      0.8683039,
      0.868427,
      0.86853355,
      0.86864925,
      0.868745,
      0.8688361,
      0.8688375,
      0.86883705,
      0.8688185,
      0.86877105,
      0.8686945,
      0.86859765,
      0.8685001,
      0.8684014,
      0.86823095,
      0.86805605,
      0.8678081,
      0.867537,
      0.86726535,
      0.86692325,
      0.8665795,
      0.8662254,
      0.8658423,
      0.86542395,
      0.86499905,
      0.864508,
      0.8640823,
      0.8636563,
      0.86316265,
      0.86269265,
      0.86224605,
      0.8617354,
      0.8612168,
      0.8606976,
      0.8601841,
      0.85966385,
      0.8591504,
      0.8586358,
      0.85811815,
      0.85766875,
      0.8571568,
      0.8566196,
      0.855985,
      0.8554525,
      0.85490765,
      0.854427,
      0.85384475,
      0.8532332,
      0.8525675,
      0.851983,
      0.8513855,
      0.8507198,
      0.8500349,
      0.84934535,
      0.84860355,
      0.8479489,
      0.8473234,
      0.846588,
      0.84584535,
      0.84503945,
      0.8442897,
      0.84356415,
      0.8429264,
      0.8421846,
      0.84144575,
      0.8407072,
      0.8400225,
      0.83935905,
      0.83881015,
      0.8381445,
      0.83747785,
      0.83694175,
      0.83637985,
      0.8357482,
      0.83522825,
      0.8346267,
      0.8340252,
      0.83351175,
      0.83299035,
      0.83249595,
      0.8320027,
      0.8314881,
      0.8310079,
      0.8305135,
      0.8300279,
      0.8295446,
      0.82909245,
      0.8286261,
      0.82816265,
      0.82772805,
      0.82729795,
      0.8268643,
      0.82648325,
      0.826173,
      0.8258642,
      0.825557,
      0.82528175,
      0.82503475,
      0.8248065,
      0.82461665,
      0.82443365,
      0.82427235,
      0.8241147,
      0.8239906,
      0.8238736,
      0.82375405,
      0.82365515,
      0.82357385,
      0.82349625,
      0.8234212,
      0.82335955,
      0.8233029,
      0.82323965,
      0.82318065,
      0.82311625,
      0.82305575,
      0.8229991,
      0.8229425,
      0.8228859,
      0.8228293,
      0.82277265,
      0.82271745,
      0.82266255,
      0.8226163,
      0.8225709,
      0.82253,
      0.8224924,
      0.8224604,
      0.82242925,
      0.8224074,
      0.82238575,
      0.82236715,
      0.8223569,
      0.82235305,
      0.8223686,
      0.82238475,
      0.822401,
      0.82242045,
      0.82243845,
      0.8224517,
      0.82246025,
      0.8224686,
      0.82247295,
      0.82247335,
      0.82247375,
      0.8224741,
      0.8224745,
      0.8224749,
      0.8224753,
      0.82247565,
      0.82247265,
      0.8224582,
      0.8224291,
      0.8223937,
      0.8223409,
      0.82227555,
      0.82220145,
      0.82212335,
      0.82204585,
      0.82195725,
      0.821871,
      0.8217762,
      0.8216848,
      0.8215972,
      0.82150025,
      0.82140225,
      0.82128845,
      0.82117465,
      0.82106085,
      0.82094705,
      0.8208366,
      0.8207376,
      0.82065245,
      0.82057145,
      0.82049955,
      0.8204397,
      0.8204073,
      0.82037845,
      0.820382,
      0.8204044,
      0.82042965,
      0.8204982,
      0.82057075,
      0.82064645,
      0.82074735,
      0.820868,
      0.82100465,
      0.8211572,
      0.82131415,
      0.8214993,
      0.8216846,
      0.8218752,
      0.8220848,
      0.8222962,
      0.82250745,
      0.8227892,
      0.82305565,
      0.82334765,
      0.82361405,
      0.8238967,
      0.8241829,
      0.8244355,
      0.82472145,
      0.82503875,
      0.8253453,
      0.82568515,
      0.82602775,
      0.826356,
      0.82668715,
      0.82705435,
      0.8274265,
      0.8278644,
      0.8282848,
      0.82872855,
      0.82917335,
      0.82955315,
      0.8299495,
      0.83035695,
      0.8307951,
      0.8312166,
      0.8316396,
      0.83208275,
      0.83251695,
      0.83291745,
      0.8333506,
      0.8337346,
      0.8341505,
      0.8345727,
      0.83500825,
      0.83540785,
      0.8358026,
      0.8361284,
      0.8365077,
      0.83686115,
      0.83721985,
      0.8375833,
      0.8379354,
      0.83825075,
      0.83853645,
      0.8388011,
      0.83905845,
      0.83927195,
      0.83944355,
      0.83958675,
      0.8396629,
      0.8397311,
      0.8396953,
      0.8396498,
      0.83957335,
      0.8394028,
      0.83922975,
      0.83902895,
      0.83876465,
      0.8384771,
      0.8381067,
      0.83769785,
      0.83727735,
      0.8368487,
      0.83639245,
      0.8359171,
      0.83540495,
      0.83490835,
      0.8344113,
      0.83388765,
      0.833365,
      0.832839,
      0.8323618,
      0.83187855,
      0.8313374,
      0.8308335,
      0.83032345,
      0.829819,
      0.8292806,
      0.82872925,
      0.82825375,
      0.82779795,
      0.82729665,
      0.8267993,
      0.82631125,
      0.825857,
      0.8254198,
      0.82498695,
      0.82456195,
      0.82419015,
      0.82383555,
      0.8234732,
      0.82313115,
      0.82279705,
      0.8225311,
      0.82229375,
      0.8220648,
      0.8218559,
      0.82168945,
      0.8215567,
      0.82142415,
      0.8213,
      0.82122435,
      0.8211421,
      0.82106585,
      0.8209877,
      0.82090675,
      0.82081495,
      0.82071085,
      0.8206056,
      0.8204894,
      0.8203757,
      0.820243,
      0.82010335,
      0.81991355,
      0.81970215,
      0.81943625,
      0.81916975,
      0.8188277,
      0.81847535,
      0.818111,
      0.81769285,
      0.8172723,
      0.81671,
      0.81622905,
      0.81560155,
      0.8149961,
      0.8143946,
      0.813748,
      0.8130794,
      0.81238105,
      0.8116943,
      0.8109716,
      0.81026695,
      0.8096139,
      0.80898885,
      0.8084944,
      0.80807665,
      0.8078105,
      0.80755575,
      0.807388,
      0.80733115,
      0.80729565,
      0.8074861,
      0.80767655,
      0.80809525,
      0.8085923,
      0.80917525,
      0.8098984,
      0.8107219,
      0.8116733,
      0.8127029,
      0.81386355,
      0.8150892,
      0.81631865,
      0.8175531,
      0.81876475,
      0.8199064,
      0.82104805,
      0.82226465,
      0.82348235,
      0.82471905,
      0.8260129,
      0.82730675,
      0.8286006,
      0.82989445,
      0.83117775,
      0.8323955,
      0.83361325,
      0.834831,
      0.83604875,
      0.8372009,
      0.8383412,
      0.83940675,
      0.84054315,
      0.8417442,
      0.84296805,
      0.8442619,
      0.84555575,
      0.846834,
      0.8480518,
      0.8492696,
      0.85048735,
      0.8517051,
      0.8529229,
      0.8541407,
      0.85536905,
      0.8566629,
      0.85788445,
      0.85908255,
      0.8602163,
      0.8613475,
      0.86249055,
      0.8637083,
      0.86492605,
      0.8661438,
      0.86736155,
      0.86857935,
      0.86979715,
      0.87103055,
      0.8723001,
      0.87351785,
      0.8747356,
      0.87595335,
      0.8771711,
      0.8783748,
      0.8795136,
      0.88057915,
      0.881717,
      0.8828783,
      0.88410395,
      0.88536945,
      0.8865872,
      0.88780495,
      0.8890227,
      0.89024045,
      0.89145825,
      0.892676,
      0.89389375,
      0.8951115,
      0.89635355,
      0.8976474,
      0.898908,
      0.90011635,
      0.901258,
      0.9024137,
      0.9036343,
      0.90492815,
      0.906222,
      0.90749285,
      0.90871065,
      0.90992025,
      0.91113805,
      0.9123088,
      0.9134315,
      0.9144911,
      0.91547735,
      0.91639075,
      0.9171116,
      0.9176445,
      0.9181774,
      0.9187103,
      0.91892175,
      0.91885525,
      0.9188554,
      0.91885555,
      0.9188557,
      0.9188559,
      0.9184517,
      0.9177139,
      0.91702915,
      0.9163809,
      0.9157722,
      0.9147906,
      0.9136553,
      0.9124115,
      0.9111211,
      0.90990355,
      0.90887855,
      0.9080415,
      0.90720445,
      0.9056825,
      0.90451525,
      0.9036782,
      0.90284115,
      0.9015023,
      0.89982815,
      0.89815395,
      0.89688415,
      0.89597095,
      0.89505775,
      0.8941446,
      0.8926118,
      0.8912399,
      0.889883,
      0.8886977,
      0.8876323,
      0.88656695,
      0.8855016,
      0.8843982,
      0.88325675,
      0.88196305,
      0.8806694,
      0.87937575,
      0.8780821,
      0.87729025,
      0.87632605,
      0.8751085,
      0.87389095,
      0.8726734,
      0.87145585,
      0.8697301,
      0.86852335,
      0.86757565,
      0.8668147,
      0.86563505,
      0.8642653,
      0.8628955,
      0.86152575,
      0.86019405,
      0.85875805,
      0.85754055,
      0.856323,
      0.85510545,
      0.8538879,
      0.85267035,
      0.8513318,
      0.85003815,
      0.8487445,
      0.84745085,
      0.8461572,
      0.8453717,
      0.84400195,
      0.8426322,
      0.84126245,
      0.8403114,
      0.839451,
      0.83841905,
      0.8369067,
      0.8352325,
      0.83370065,
      0.83278745,
      0.83187425,
      0.8309611,
      0.8292869,
      0.82761275,
      0.82656725,
      0.8257302,
      0.82489315,
      0.82356955,
      0.8221237,
      0.82067785,
      0.8198045,
      0.818622,
      0.8172494,
      0.81561455,
      0.8139828,
      0.81241115,
      0.8112411,
      0.8101901,
      0.80908455,
      0.80790515,
      0.80667325,
      0.80541775,
      0.80491595,
      0.8043454,
      0.8037044,
      0.8030309,
      0.8023041,
      0.80199185,
      0.80180185,
      0.80153965,
      0.8013635,
      0.8014239,
      0.80160625,
      0.80202015,
      0.8024581,
      0.80293605,
      0.80346025,
      0.80397905,
      0.80449255,
      0.8050088,
      0.80553445,
      0.80601045,
      0.80642515,
      0.8068566,
      0.8073033,
      0.80771105,
      0.8081389,
      0.80857365,
      0.80893555,
      0.8092954,
      0.8096384,
      0.8100003,
      0.81035685,
      0.81067235,
      0.81102885,
      0.8113861,
      0.8117341,
      0.8120595,
      0.8123716,
      0.81269325,
      0.81303315,
      0.81337605,
      0.81371285,
      0.81402845,
      0.8143719,
      0.8147148,
      0.8150167,
      0.81534055,
      0.81568345,
      0.81603925,
      0.8163866,
      0.81672505,
      0.81706535,
      0.8174094,
      0.81777675,
      0.81814,
      0.8184891,
      0.8188685,
      0.81925075,
      0.81964225,
      0.82001005,
      0.8204002,
      0.8208369,
      0.8212731,
      0.82167115,
      0.8220868,
      0.82250395,
      0.82292685,
      0.8233338,
      0.8237571,
      0.8241767,
      0.8246192,
      0.8250849,
      0.8255531,
      0.8259596,
      0.8263963,
      0.82683355,
      0.82726385,
      0.8276959,
      0.82810435,
      0.82849745,
      0.8289057,
      0.82928655,
      0.8297411,
      0.8302402,
      0.8307366,
      0.8312316,
      0.83169185,
      0.8321625,
      0.83260225,
      0.8330499,
      0.8334687,
      0.833936,
      0.8343974,
      0.83487555,
      0.83533085,
      0.83579495,
      0.8362405,
      0.83668705,
      0.83718085,
      0.8376826,
      0.8382126,
      0.83872655,
      0.8391671,
      0.83957065,
      0.83998385,
      0.84039525,
      0.8408101,
      0.84126465,
      0.8417387,
      0.8421902,
      0.8426983,
      0.8431361,
      0.8435973,
      0.8440356,
      0.84447755,
      0.84491585,
      0.84534425,
      0.84576435,
      0.8461462,
      0.84655125,
      0.8468933,
      0.847236,
      0.84757995,
      0.84797785,
      0.8484045,
      0.8488314,
      0.8492692,
      0.84967955,
      0.85007045,
      0.85046535,
      0.8508242,
      0.8511817,
      0.8515153,
      0.8519248,
      0.85232255,
      0.8527158,
      0.853109,
      0.85350215,
      0.85388625,
      0.8542557,
      0.85461665,
      0.8549727,
      0.8553249,
      0.8556069,
      0.8558351,
      0.8560635,
      0.85633455,
      0.8565911,
      0.85690905,
      0.85721725,
      0.8575109,
      0.85780065,
      0.85810955,
      0.8583435,
      0.85860435,
      0.858844,
      0.85908365,
      0.8593279,
      0.8596038,
      0.85985125,
      0.8601038,
      0.860395,
      0.86074995,
      0.86110325,
      0.86146225,
      0.86182325,
      0.8621474,
      0.8624572,
      0.862737,
      0.8630177,
      0.8633166,
      0.8636155,
      0.86389125,
      0.864204,
      0.86449265,
      0.86478315,
      0.8650981,
      0.86540845,
      0.86571565,
      0.8660228,
      0.86632485,
      0.86658955,
      0.8667922,
      0.86702585,
      0.8672527,
      0.86747715,
      0.8676788,
      0.86791715,
      0.8681241,
      0.86833005,
      0.86850445,
      0.8686785,
      0.8688554,
      0.86902025,
      0.869182,
      0.86934185,
      0.8694773,
      0.8696127,
      0.86974425,
      0.8698795,
      0.87001475,
      0.8701499,
      0.87030905,
      0.8704384,
      0.87059215,
      0.8707474,
      0.87090155,
      0.87101985,
      0.87115315,
      0.8712894,
      0.87142555,
      0.8715789,
      0.8717309,
      0.87187395,
      0.872019,
      0.8721639,
      0.87230865,
      0.87245325,
      0.87257305,
      0.872689,
      0.8728048,
      0.8729192,
      0.8730077,
      0.8730721,
      0.8731076,
      0.87313725,
      0.87316655,
      0.8731707,
      0.8731557,
      0.873137,
      0.8731181,
      0.8730626,
      0.8730053,
      0.8729153,
      0.87281945,
      0.8727093,
      0.87259805,
      0.8724841,
      0.87235895,
      0.8720394,
      0.8717187,
      0.8713968,
      0.8709253,
      0.8704318,
      0.8698015,
      0.8691456,
      0.8684796,
      0.86777975,
      0.867076,
      0.86636855,
      0.8656399,
      0.86492295,
      0.8641862,
      0.86346075,
      0.86272645,
      0.86195075,
      0.8611682,
      0.8603543,
      0.85954085,
      0.85892155,
      0.85827235,
      0.85760965,
      0.8570451,
      0.8565231,
      0.8561321,
      0.8557552,
      0.8553281,
      0.8549466,
      0.85453375,
      0.8541196,
      0.8537095,
      0.85325675,
      0.8528225,
      0.8523851,
      0.85190495,
      0.8513269,
      0.8507373,
      0.85025785,
      0.84986525,
      0.84937485,
      0.848633,
      0.84798195,
      0.8474585,
      0.8467241,
      0.84598225,
      0.84523365,
      0.84449705,
      0.8437725,
      0.8430822,
      0.8423742,
      0.84163055,
      0.8409129,
      0.8401491,
      0.83938825,
      0.83867325,
      0.83809425,
      0.8375063,
      0.83684055,
      0.83608585,
      0.8354063,
      0.8349937,
      0.83448265,
      0.83389305,
      0.8335064,
      0.83311795,
      0.83274015,
      0.8323945,
      0.83204735,
      0.83169095,
      0.83131935,
      0.8309796,
      0.83066305,
      0.8303925,
      0.8301146,
      0.8298402,
      0.8295766,
      0.82932785,
      0.8290789,
      0.82884015,
      0.8286197,
      0.82841255,
      0.8282253,
      0.8280358,
      0.82785615,
      0.8276934,
      0.82754235,
      0.82740765,
      0.82727485,
      0.8271562,
      0.82707595,
      0.82701935,
      0.8269731,
      0.82693475,
      0.82690855,
      0.8268885,
      0.8268772,
      0.8268865,
      0.826905,
      0.8269284,
      0.8269567,
      0.8269875,
      0.82702745,
      0.82707435,
      0.82712665,
      0.82718125,
      0.8272381,
      0.82729555,
      0.82735955,
      0.82742585,
      0.82749255,
      0.8275635,
      0.82762725,
      0.82768535,
      0.8277431,
      0.8278006,
      0.82785615,
      0.82790465,
      0.8279459,
      0.82798315,
      0.82801785,
      0.8280518,
      0.82807705,
      0.8280931,
      0.8281015,
      0.8281047,
      0.82810045,
      0.82809485,
      0.82808005,
      0.82805665,
      0.82802985,
      0.8279951,
      0.82795365,
      0.8279096,
      0.8278585,
      0.8278049,
      0.82774905,
      0.8276923,
      0.82763235,
      0.8275697,
      0.82750795,
      0.82743855,
      0.82736615,
      0.82728675,
      0.8272085,
      0.82713275,
      0.8270581,
      0.82695885,
      0.8268562,
      0.82675725,
      0.82665235,
      0.826545,
      0.8264376,
      0.8263272,
      0.82620975,
      0.8260933,
      0.8259786,
      0.82586285,
      0.8257409,
      0.82562015,
      0.8254963,
      0.82537615,
      0.8252593,
      0.8251541,
      0.8250431,
      0.82493035,
      0.82482095,
      0.8247366,
      0.824661,
      0.82460215,
      0.8245571,
      0.82452505,
      0.82449755,
      0.82448125,
      0.8244796,
      0.82448275,
      0.8244928,
      0.82452365,
      0.8245639,
      0.8246138,
      0.82467035,
      0.8247322,
      0.82480025,
      0.8248707,
      0.8249671,
      0.82507575,
      0.82520895,
      0.8253452,
      0.82548535,
      0.82561365,
      0.82574075,
      0.82587105,
      0.82600805,
      0.8261421,
      0.82627935,
      0.82642335,
      0.82658355,
      0.82673015,
      0.8268921,
      0.82705,
      0.82721245,
      0.8273892,
      0.82756475,
      0.82774025,
      0.8279038,
      0.82806105,
      0.8282005,
      0.82833735,
      0.82850505,
      0.8286916,
      0.8288858,
      0.82910165,
      0.8293197,
      0.82955555,
      0.82979935,
      0.83003995,
      0.8302583,
      0.83052125,
      0.83077735,
      0.83107415,
      0.83138005,
      0.83167945,
      0.8320173,
      0.83237675,
      0.8327421,
      0.8331348,
      0.8335378,
      0.83394685,
      0.834322,
      0.83471695,
      0.83512225,
      0.8355068,
      0.8358843,
      0.83626345,
      0.8366528,
      0.83704575,
      0.8374405,
      0.8378071,
      0.83821745,
      0.83858735,
      0.8389738,
      0.839365,
      0.8397231,
      0.84007105,
      0.84041445,
      0.84075665,
      0.84108725,
      0.84141455,
      0.84173795,
      0.84200775,
      0.8422487,
      0.8424583,
      0.84266565,
      0.84283935,
      0.84298625,
      0.8431004,
      0.8432015,
      0.8432731,
      0.8432682,
      0.84323965,
      0.8431669,
      0.84308005,
      0.84296305,
      0.8428181,
      0.84262755,
      0.8423905,
      0.842141,
      0.84185595,
      0.8415705,
      0.841238,
      0.84089705,
      0.8405728,
      0.84020045,
      0.83983145,
      0.83939125,
      0.8389726,
      0.8385485,
      0.83812425,
      0.8376922,
      0.8372802,
      0.83681995,
      0.83634535,
      0.83589015,
      0.83538595,
      0.83491065,
      0.83440605,
      0.83389815,
      0.8334219,
      0.8329218,
      0.8324499,
      0.8319702,
      0.8314757,
      0.83099295,
      0.83051705,
      0.8301007,
      0.8296636,
      0.82923165,
      0.82880045,
      0.8283719,
      0.8279193,
      0.82752405,
      0.82713595,
      0.8267449,
      0.82639545,
      0.82605335,
      0.82575715,
      0.82546915,
      0.8251755,
      0.82489885,
      0.8246523,
      0.8244288,
      0.8242212,
      0.8240503,
      0.8238801,
      0.82372645,
      0.82359425,
      0.82347325,
      0.8233468,
      0.82324185,
      0.8231456,
      0.82305095,
      0.82296415,
      0.8227302,
      0.8225962,
      0.8223112,
      0.82192805,
      0.82150295,
      0.8210753,
      0.8207313,
      0.82038635,
      0.819892,
      0.8193406,
      0.8187871,
      0.81815955,
      0.81748105,
      0.8167804,
      0.81607675,
      0.81538575,
      0.81470265,
      0.81403595,
      0.8133703,
      0.8127047,
      0.81219765,
      0.81214095,
      0.8123125,
      0.8126584,
      0.81309585,
      0.8135641,
      0.81396085,
      0.81436305,
      0.81499115,
      0.81575245,
      0.81659195,
      0.81758155,
      0.81869815,
      0.819913,
      0.82120695,
      0.8225699,
      0.82393995,
      0.82538605,
      0.82690825,
      0.8285066,
      0.8301747,
      0.83139245,
      0.8326102,
      0.8337918,
      0.8349481,
      0.8360975,
      0.8373184,
      0.8386123,
      0.83989415,
      0.84111195,
      0.84232975,
      0.84354755,
      0.84476535,
      0.84598315,
      0.84720095,
      0.8484259,
      0.8497198,
      0.8509726,
      0.8521743,
      0.85331595,
      0.8544576,
      0.85559925,
      0.85674095,
      0.8579188,
      0.8591513,
      0.86042245,
      0.8616402,
      0.86285795,
      0.86408775,
      0.8653816,
      0.86667545,
      0.8682358,
      0.8701937,
      0.87224865,
      0.8743036,
      0.8763585,
      0.87836055,
      0.88034845,
      0.88234335,
      0.8843983,
      0.8866117,
      0.88931355,
      0.8919569,
      0.8945472,
      0.89709685,
      0.8996692,
      0.9022563,
      0.904806,
      0.9073184,
      0.90979195,
      0.91220545,
      0.91433645,
      0.91584935,
      0.9170482,
      0.91824705,
      0.9194459,
      0.9206976,
      0.9216216,
      0.9225039,
      0.9230178,
      0.92337325,
      0.92324025,
      0.9231657,
      0.9231442,
      0.92255455,
      0.92196495,
      0.921414,
      0.9207192,
      0.9198775,
      0.91861025,
      0.9169321,
      0.9150487,
      0.91338585,
      0.91194,
      0.9102529,
      0.90819825,
      0.90614365,
      0.904472,
      0.90288615,
      0.90118925,
      0.89921075,
      0.89723225,
      0.89525375,
      0.89327525,
      0.89190555,
      0.89004475,
      0.8881049,
      0.8860793,
      0.8842379,
      0.88286085,
      0.88195475,
      0.8806611,
      0.87936745,
      0.8780738,
      0.8770214,
      0.87608425,
      0.874973,
      0.87367935,
      0.8723857,
      0.8705222,
      0.86885645,
      0.86701105,
      0.86516565,
      0.86332025,
      0.86109305,
      0.85905385,
      0.8570937,
      0.85540055,
      0.8537074,
      0.85201425,
      0.84966155,
      0.8477401,
      0.84581865,
      0.8438972,
      0.84197575,
      0.8403066,
      0.83818895,
      0.83611525,
      0.83404155,
      0.8330171,
      0.8320765,
      0.83088885,
      0.8294972,
      0.82797525,
      0.8268351,
      0.825998,
      0.8251609,
      0.82379115,
      0.8224214,
      0.82105165,
      0.8200551,
      0.81914195,
      0.81815275,
      0.81708745,
      0.81598585,
      0.8148205,
      0.81420655,
      0.81372735,
      0.8131758,
      0.81260035,
      0.81196445,
      0.81168225,
      0.81153315,
      0.81151435,
      0.8114956,
      0.81144965,
      0.81133085,
      0.81167355,
      0.8119655,
      0.81222625,
      0.81270315,
      0.8132331,
      0.81376425,
      0.81430985,
      0.81482215,
      0.8153457,
      0.8158978,
      0.8163962,
      0.8168955,
      0.8173448,
      0.81781005,
      0.8182953,
      0.81880615,
      0.81924415,
      0.8196224,
      0.8199556,
      0.8203107,
      0.8206861,
      0.82104165,
      0.8213605,
      0.82167645,
      0.8219688,
      0.82227545,
      0.82258955,
      0.82292215,
      0.8232377,
      0.82352345,
      0.8238573,
      0.82419585,
      0.8245443,
      0.8248845,
      0.8252185,
      0.8255265,
      0.82584935,
      0.82619065,
      0.8265323,
      0.8268984,
      0.8272468,
      0.82761355,
      0.8280184,
      0.82842625,
      0.8288434,
      0.8292706,
      0.82968675,
      0.83014765,
      0.8306084,
      0.8311005,
      0.83153125,
      0.83196925,
      0.8324713,
      0.83299595,
      0.8335197,
      0.83405285,
      0.8345877,
      0.835125,
      0.83568875,
      0.83623305,
      0.8367852,
      0.83733295,
      0.83787205,
      0.83844995,
      0.8390226,
      0.83958555,
      0.8401889,
      0.8407474,
      0.84136305,
      0.84195625,
      0.8425767,
      0.84319195,
      0.84374405,
      0.84431355,
      0.84487985,
      0.8454749,
      0.84608335,
      0.84667955,
      0.8472489,
      0.8478354,
      0.84844395,
      0.8490527,
      0.8496448,
      0.8501962,
      0.8507734,
      0.8513445,
      0.8518832,
      0.85242225,
      0.85290115,
      0.85336765,
      0.8538109,
      0.8543189,
      0.8548392,
      0.85529855,
      0.8557758,
      0.8562152,
      0.85663405,
      0.85707875,
      0.8575201,
      0.85793895,
      0.8583408,
      0.85874615,
      0.85913475,
      0.8595285,
      0.8599324,
      0.86035125,
      0.8608026,
      0.8612535,
      0.8617206,
      0.86219365,
      0.86265805,
      0.8630959,
      0.86354705,
      0.8640143,
      0.86448955,
      0.8649725,
      0.8654431,
      0.86588715,
      0.8663566,
      0.8668014,
      0.86725705,
      0.86769645,
      0.86814355,
      0.8686011,
      0.8690359,
      0.86944615,
      0.8698136,
      0.8701944,
      0.87056825,
      0.8709385,
      0.87133075,
      0.87172675,
      0.87210695,
      0.8724954,
      0.8728752,
      0.87326155,
      0.87368465,
      0.8740863,
      0.87447925,
      0.8748988,
      0.87531685,
      0.8757476,
      0.8762149,
      0.87666735,
      0.87711395,
      0.87758045,
      0.8780605,
      0.87851735,
      0.87901485,
      0.8795326,
      0.88005125,
      0.8805196,
      0.88099435,
      0.8814513,
      0.88188945,
      0.8823285,
      0.88275915,
      0.8831871,
      0.88360595,
      0.8840157,
      0.88440125,
      0.8847714,
      0.8850933,
      0.88544855,
      0.88579125,
      0.88615555,
      0.8865055,
      0.8868407,
      0.8871301,
      0.88741955,
      0.88769065,
      0.88799685,
      0.88828365,
      0.88857445,
      0.88886235,
      0.8891587,
      0.88943355,
      0.8897157,
      0.8899919,
      0.8902505,
      0.8905036,
      0.89076525,
      0.89102675,
      0.8912501,
      0.8914717,
      0.8916559,
      0.89183245,
      0.8920114,
      0.89215995,
      0.8922855,
      0.89238335,
      0.89243665,
      0.89247995,
      0.89251015,
      0.8925271,
      0.8924979,
      0.89244715,
      0.89238865,
      0.8922897,
      0.8921851,
      0.89205355,
      0.89188685,
      0.89171975,
      0.8915451,
      0.891339,
      0.8911182,
      0.8908589,
      0.8905968,
      0.8903535,
      0.8900733,
      0.88978365,
      0.8894932,
      0.8890625,
      0.888631,
      0.88820345,
      0.8877835,
      0.88745405,
      0.8870292,
      0.88659415,
      0.8861638,
      0.88575935,
      0.88537635,
      0.8849885,
      0.8845689,
      0.88413705,
      0.88371515,
      0.88333115,
      0.88294665,
      0.8824902,
      0.8820706,
      0.88168245,
      0.88127175,
      0.8809949,
      0.88069885,
      0.88040225,
      0.880115,
      0.8797512,
      0.8794826,
      0.87926205,
      0.8789697,
      0.87866965,
      0.8783696,
      0.87807375,
      0.87781625,
      0.87745325,
      0.8771668,
      0.8768675,
      0.87656795,
      0.8762741,
      0.8759129,
      0.87554355,
      0.8752035,
      0.8748275,
      0.8744471,
      0.8740583,
      0.8736695,
      0.87321545,
      0.87275055,
      0.8722857,
      0.87186115,
      0.87141335,
      0.8709538,
      0.8704318,
      0.8698972,
      0.86946345,
      0.8689309,
      0.86840085,
      0.86782555,
      0.86730535,
      0.8668219,
      0.8663169,
      0.86579615,
      0.8652581,
      0.86472745,
      0.86416875,
      0.86358955,
      0.863058,
      0.8625373,
      0.86198805,
      0.86144265,
      0.8609097,
      0.86038895,
      0.85993115,
      0.8594864,
      0.85902625,
      0.85856705,
      0.8581182,
      0.85766175,
      0.85719745,
      0.85673005,
      0.8562736,
      0.8558244,
      0.85542555,
      0.8550246,
      0.8546423,
      0.8542557,
      0.85383635,
      0.85341315,
      0.8530136,
      0.85263125,
      0.85225965,
      0.8518803,
      0.851495,
      0.8511087,
      0.85077715,
      0.8504598,
      0.85013995,
      0.8498739,
      0.849631,
      0.849422,
      0.84923215,
      0.84905445,
      0.8488915,
      0.8487507,
      0.8486354,
      0.848547,
      0.84850945,
      0.8484762,
      0.84844835,
      0.84843185,
      0.8484241,
      0.8484246,
      0.84843155,
      0.84843995,
      0.8484489,
      0.8484654,
      0.84848495,
      0.8485045,
      0.84852405,
      0.84855005,
      0.84857755,
      0.8486023,
      0.84862935,
      0.8486566,
      0.8486755,
      0.84869065,
      0.8487052,
      0.84870985,
      0.8487103,
      0.84871075,
      0.84871115,
      0.84871155,
      0.84871195,
      0.84871235,
      0.84871275,
      0.84871315,
      0.848712,
      0.8487087,
      0.8487028,
      0.84868575,
      0.8486653,
      0.84863825,
      0.8486008,
      0.84855645,
      0.8485135,
      0.8484678,
      0.84840925,
      0.84835515,
      0.84830515,
      0.84825045,
      0.8481937,
      0.8481352,
      0.84807665,
      0.84800905,
      0.84793785,
      0.8478634,
      0.8477891,
      0.8477145,
      0.8476424,
      0.84756565,
      0.8474863,
      0.8474081,
      0.8473276,
      0.8472466,
      0.84716175,
      0.8470792,
      0.84700705,
      0.84692775,
      0.8468414,
      0.84674785,
      0.846653,
      0.8465588,
      0.8464599,
      0.84636765,
      0.8462739,
      0.84618145,
      0.84608665,
      0.8459953,
      0.84590655,
      0.84582935,
      0.8457595,
      0.8456965,
      0.8456413,
      0.8455925,
      0.8455483,
      0.8455106,
      0.8454854,
      0.84547355,
      0.84547355,
      0.84549805,
      0.8455364,
      0.84557905,
      0.84563755,
      0.8456995,
      0.84579225,
      0.845903,
      0.84602175,
      0.84615665,
      0.84629055,
      0.84645635,
      0.8466233,
      0.84680735,
      0.8469981,
      0.8472044,
      0.84741785,
      0.84767015,
      0.8479388,
      0.84821425,
      0.8484893,
      0.8487758,
      0.84909955,
      0.849423,
      0.84975515,
      0.8500948,
      0.85042715,
      0.85075085,
      0.8511464,
      0.8515271,
      0.8519265,
      0.85229985,
      0.8526881,
      0.85306495,
      0.85343495,
      0.85385285,
      0.85427175,
      0.8546494,
      0.8550205,
      0.85539305,
      0.8557824,
      0.85615385,
      0.85647865,
      0.8568289,
      0.8571791,
      0.85752865,
      0.85786055,
      0.858214,
      0.85849985,
      0.85878365,
      0.8590847,
      0.8593778,
      0.8596802,
      0.85999575,
      0.86031065,
      0.8605658,
      0.86081335,
      0.86107795,
      0.8613386,
      0.8616135,
      0.8618628,
      0.8621043,
      0.86233955,
      0.862539,
      0.8627184,
      0.86287175,
      0.86300995,
      0.8631177,
      0.86319475,
      0.8632707,
      0.8632864,
      0.86329765,
      0.86325225,
      0.86318465,
      0.8630878,
      0.8629766,
      0.8628319,
      0.86266395,
      0.8624804,
      0.8622714,
      0.8620098,
      0.8617185,
      0.861428,
      0.8611147,
      0.86079155,
      0.86041665,
      0.8600521,
      0.8596869,
      0.8592868,
      0.8588591,
      0.8584466,
      0.858004,
      0.8575842,
      0.85708985,
      0.8565982,
      0.8561126,
      0.8556369,
      0.8551591,
      0.85460875,
      0.85405735,
      0.85352945,
      0.8529904,
      0.85243355,
      0.85190365,
      0.85138185,
      0.8509004,
      0.85036865,
      0.8498373,
      0.849362,
      0.8488853,
      0.8483732,
      0.8478923,
      0.8473986,
      0.8469654,
      0.8465353,
      0.84610625,
      0.845688,
      0.8452662,
      0.8449027,
      0.8445416,
      0.8442095,
      0.8439153,
      0.8436392,
      0.8433656,
      0.84308935,
      0.8428424,
      0.8426271,
      0.84241135,
      0.8421841,
      0.84198605,
      0.84180305,
      0.84161145,
      0.8414359,
      0.84126165,
      0.8411084,
      0.8409291,
      0.8407383,
      0.8405533,
      0.8403816,
      0.84013465,
      0.83974455,
      0.839356,
      0.8389701,
      0.8386611,
      0.83820455,
      0.83763725,
      0.83707045,
      0.83658195,
      0.836105,
      0.83547935,
      0.83487975,
      0.8341398,
      0.8334783,
      0.83267885,
      0.83188005,
      0.831109,
      0.8303669,
      0.8296366,
      0.8289078,
      0.8282612,
      0.82781125,
      0.82737165,
      0.8269343,
      0.82649695,
      0.82628795,
      0.82626605,
      0.8263424,
      0.82634415,
      0.82641765,
      0.8266443,
      0.8269489,
      0.8274818,
      0.82800035,
      0.8286855,
      0.8294467,
      0.83028375,
      0.8311847,
      0.83208405,
      0.8329845,
      0.83395495,
      0.83495255,
      0.83601025,
      0.83707585,
      0.83814145,
      0.839207,
      0.84026875,
      0.84125825,
      0.84232235,
      0.84339175,
      0.84451365,
      0.8455673,
      0.8465568,
      0.84756065,
      0.8486262,
      0.849678,
      0.8506675,
      0.851664,
      0.85273095,
      0.853857,
      0.854915,
      0.8559045,
      0.8569027,
      0.85796825,
      0.8590338,
      0.8600752,
      0.8610505,
      0.86203995,
      0.8630294,
      0.86401885,
      0.8650281,
      0.8661056,
      0.86724725,
      0.86838895,
      0.86953065,
      0.8706861,
      0.87190385,
      0.8731216,
      0.87433935,
      0.8755489,
      0.8767596,
      0.87797735,
      0.8791951,
      0.88041285,
      0.8816306,
      0.8828725,
      0.8841843,
      0.88555425,
      0.8869242,
      0.88829415,
      0.8896641,
      0.89103405,
      0.8923695,
      0.8936341,
      0.8948325,
      0.89597415,
      0.8971158,
      0.89825745,
      0.8993991,
      0.90056455,
      0.90179695,
      0.9030908,
      0.90438465,
      0.9056785,
      0.90697235,
      0.9082319,
      0.9094497,
      0.91066745,
      0.9118852,
      0.91306255,
      0.9141792,
      0.91524475,
      0.91626875,
      0.91725745,
      0.9182092,
      0.91903155,
      0.91973,
      0.9202819,
      0.9208338,
      0.9213857,
      0.92161115,
      0.92155425,
      0.92149735,
      0.92144045,
      0.92138355,
      0.92084575,
      0.9199517,
      0.91905765,
      0.9181636,
      0.91730995,
      0.916517,
      0.91577515,
      0.91510935,
      0.91401965,
      0.91281765,
      0.91155795,
      0.91042215,
      0.9094329,
      0.90844365,
      0.90732405,
      0.90645185,
      0.9056727,
      0.9047025,
      0.9035765,
      0.90237135,
      0.9015549,
      0.90113645,
      0.90025815,
      0.8992309,
      0.89820365,
      0.8971764,
      0.89614915,
      0.8951219,
      0.894156,
      0.8931132,
      0.8923143,
      0.8915154,
      0.8907165,
      0.88947565,
      0.8881222,
      0.88684775,
      0.8857626,
      0.8848685,
      0.8834155,
      0.88191925,
      0.8805495,
      0.87917975,
      0.87826985,
      0.8775089,
      0.87674795,
      0.87522415,
      0.87361975,
      0.8717173,
      0.8702835,
      0.86914205,
      0.86800055,
      0.86685905,
      0.86571755,
      0.865018,
      0.8635721,
      0.8621262,
      0.8606803,
      0.8592344,
      0.8585032,
      0.8578944,
      0.85651305,
      0.85491495,
      0.85331685,
      0.85171875,
      0.85012065,
      0.8492854,
      0.8485308,
      0.8479749,
      0.8466328,
      0.8451108,
      0.8435888,
      0.8420668,
      0.84054485,
      0.8390229,
      0.83849025,
      0.83734185,
      0.8360482,
      0.83475455,
      0.8334609,
      0.83204135,
      0.8313179,
      0.83073395,
      0.83006195,
      0.8292703,
      0.82839525,
      0.8274688,
      0.8264945,
      0.82554485,
      0.82530805,
      0.8251782,
      0.8250218,
      0.824791,
      0.82448675,
      0.8241085,
      0.82366805,
      0.82380655,
      0.82401605,
      0.82415155,
      0.8242247,
      0.82436655,
      0.8245293,
      0.8246951,
      0.82487425,
      0.8251059,
      0.82535745,
      0.82559695,
      0.8258094,
      0.8260217,
      0.8262323,
      0.8264463,
      0.8266202,
      0.8268019,
      0.82698405,
      0.82717045,
      0.82734415,
      0.8274795,
      0.82763545,
      0.8277937,
      0.8279482,
      0.8280851,
      0.8282238,
      0.8283651,
      0.828515,
      0.8286572,
      0.8288012,
      0.8289584,
      0.8291294,
      0.82930105,
      0.8294811,
      0.829672,
      0.8298635,
      0.8300467,
      0.8302408,
      0.83043575,
      0.8306445,
      0.83086805,
      0.83107435,
      0.8312974,
      0.8315255,
      0.83177325,
      0.8320078,
      0.83225285,
      0.83249055,
      0.83273835,
      0.83298545,
      0.8332332,
      0.8334809,
      0.83372865,
      0.83398065,
      0.83424175,
      0.83450445,
      0.8347693,
      0.83502205,
      0.83530045,
      0.8355691,
      0.8358234,
      0.83609305,
      0.8363437,
      0.83658995,
      0.83681865,
      0.83704025,
      0.83725885,
      0.8374939,
      0.83772045,
      0.8379496,
      0.83815235,
      0.83834485,
      0.83853745,
      0.83874775,
      0.83895065,
      0.83914205,
      0.83933235,
      0.83952295,
      0.83968365,
      0.8398581,
      0.84005975,
      0.84024105,
      0.84042655,
      0.8406433,
      0.84088245,
      0.84111975,
      0.8413484,
      0.84156285,
      0.84183415,
      0.8420896,
      0.84236095,
      0.8426502,
      0.84296605,
      0.84325635,
      0.84353095,
      0.84383245,
      0.8441319,
      0.8444311,
      0.84476355,
      0.84507725,
      0.8453626,
      0.84565455,
      0.84598715,
      0.84628795,
      0.846566,
      0.8468722,
      0.84717595,
      0.8475121,
      0.84778875,
      0.8480713,
      0.8483697,
      0.8486457,
      0.8489019,
      0.8491816,
      0.8494638,
      0.84974285,
      0.85002205,
      0.8503279,
      0.85060175,
      0.8509403,
      0.8512791,
      0.8516218,
      0.8519199,
      0.8522383,
      0.8525902,
      0.8529154,
      0.85324485,
      0.8535864,
      0.8539281,
      0.8542708,
      0.8545859,
      0.85496285,
      0.8553314,
      0.85567995,
      0.8560561,
      0.85644355,
      0.85683365,
      0.85719955,
      0.85756385,
      0.85791115,
      0.8582645,
      0.85864095,
      0.85901525,
      0.85939735,
      0.8597431,
      0.8600909,
      0.8604936,
      0.86085565,
      0.8612178,
      0.8615795,
      0.86193785,
      0.86225925,
      0.8625804,
      0.86292085,
      0.86323005,
      0.86353925,
      0.8638493,
      0.86418355,
      0.86452365,
      0.86489055,
      0.8652523,
      0.8656084,
      0.86596515,
      0.8663051,
      0.8666515,
      0.8670317,
      0.8673905,
      0.8678153,
      0.86824105,
      0.86866005,
      0.8690995,
      0.8695489,
      0.86999865,
      0.87045725,
      0.87091415,
      0.8713735,
      0.8718292,
      0.8722565,
      0.8726965,
      0.8730691,
      0.8734405,
      0.8738122,
      0.8742207,
      0.87460835,
      0.87498955,
      0.8753335,
      0.87563905,
      0.87587005,
      0.8760999,
      0.87635605,
      0.87660115,
      0.87681225,
      0.87702295,
      0.87719635,
      0.87736935,
      0.87750485,
      0.8776403,
      0.8778303,
      0.8779996,
      0.8781635,
      0.8783458,
      0.8785362,
      0.87869025,
      0.87886285,
      0.87905255,
      0.87925595,
      0.8794646,
      0.879674,
      0.87990735,
      0.8801155,
      0.88032135,
      0.8805298,
      0.88073815,
      0.88094635,
      0.8811544,
      0.8813623,
      0.8815701,
      0.88166605,
      0.8816098,
      0.88155115,
      0.88155,
      0.8814879,
      0.88122055,
      0.8810102,
      0.88067075,
      0.880277,
      0.8798571,
      0.8793047,
      0.8788042,
      0.8781503,
      0.8774857,
      0.8768081,
      0.87608455,
      0.87534275,
      0.8745439,
      0.873669,
      0.872792,
      0.87196735,
      0.8712336,
      0.87049185,
      0.8695979,
      0.8686713,
      0.86794315,
      0.8671296,
      0.86636655,
      0.8655212,
      0.86466695,
      0.86402035,
      0.8630694,
      0.8623467,
      0.8615934,
      0.8607305,
      0.85989665,
      0.8589703,
      0.8580764,
      0.85733465,
      0.85636665,
      0.85547945,
      0.85450095,
      0.85352015,
      0.8526066,
      0.8516936,
      0.85073635,
      0.84978105,
      0.848811,
      0.8479591,
      0.8471335,
      0.8462144,
      0.8454917,
      0.84466865,
      0.84386725,
      0.84317945,
      0.8424927,
      0.84190295,
      0.8413371,
      0.84069125,
      0.84027305,
      0.83975715,
      0.8393875,
      0.839026,
      0.8386655,
      0.8383615,
      0.83810725,
      0.8378604,
      0.8376896,
      0.83752335,
      0.83736035,
      0.8372148,
      0.8371011,
      0.83701165,
      0.8369339,
      0.83686145,
      0.83680485,
      0.83676195,
      0.8367198,
      0.83668155,
      0.83664395,
      0.836628,
      0.83661795,
      0.8366105,
      0.83661095,
      0.8366114,
      0.8366118,
      0.8366122,
      0.83661265,
      0.83661305,
      0.83661345,
      0.83661385,
      0.83661425,
      0.8366147,
      0.8366151,
      0.8366155,
      0.8366159,
      0.8366163,
      0.8366123,
      0.836607,
      0.83659705,
      0.836587,
      0.8365687,
      0.8365501,
      0.83652905,
      0.83648275,
      0.83642695,
      0.83637025,
      0.8363127,
      0.83625045,
      0.83617885,
      0.836107,
      0.8360316,
      0.83595585,
      0.8358747,
      0.83578885,
      0.83569915,
      0.83560855,
      0.83552205,
      0.835433,
      0.8353474,
      0.83524455,
      0.83514835,
      0.835043,
      0.83493935,
      0.83485325,
      0.83477525,
      0.83468745,
      0.8345985,
      0.83450175,
      0.83441425,
      0.8343156,
      0.8342205,
      0.8341257,
      0.8340363,
      0.8339516,
      0.8338707,
      0.83379075,
      0.8337111,
      0.8336353,
      0.8335607,
      0.83350285,
      0.8334453,
      0.83337955,
      0.83330585,
      0.83323005,
      0.83315565,
      0.8330919,
      0.83303015,
      0.8329809,
      0.8329318,
      0.8328941,
      0.8328571,
      0.8328231,
      0.83280395,
      0.8328049,
      0.83282435,
      0.83287155,
      0.83292,
      0.83297325,
      0.83304975,
      0.83315565,
      0.8332903,
      0.8334434,
      0.83362425,
      0.8338183,
      0.83402785,
      0.834256,
      0.8344847,
      0.83475805,
      0.83506215,
      0.8353669,
      0.8357259,
      0.8360824,
      0.8364257,
      0.83682355,
      0.8372044,
      0.83756945,
      0.8379939,
      0.838417,
      0.83884385,
      0.83927195,
      0.8397082,
      0.84014425,
      0.84056855,
      0.8410193,
      0.84145735,
      0.8419339,
      0.84243305,
      0.84288835,
      0.84334295,
      0.84381655,
      0.84423615,
      0.8446567,
      0.84512975,
      0.8455311,
      0.84595235,
      0.8463762,
      0.84674035,
      0.8471212,
      0.8475002,
      0.84785085,
      0.8481709,
      0.8485156,
      0.848853,
      0.84916055,
      0.84946535,
      0.84971305,
      0.84993755,
      0.8501493,
      0.85030665,
      0.8504426,
      0.8505743,
      0.85068065,
      0.85070305,
      0.8506511,
      0.85057435,
      0.85048175,
      0.85037845,
      0.8501886,
      0.84991715,
      0.8496434,
      0.84935945,
      0.8490216,
      0.8486415,
      0.8482331,
      0.8477959,
      0.84734765,
      0.84684185,
      0.84634055,
      0.8457875,
      0.84523555,
      0.84461145,
      0.844002,
      0.8433793,
      0.84282325,
      0.84219455,
      0.8415664,
      0.8409223,
      0.84033745,
      0.8397855,
      0.83916085,
      0.8385386,
      0.83794405,
      0.8373875,
      0.8368074,
      0.8362443,
      0.83569085,
      0.8351804,
      0.83466715,
      0.83422555,
      0.8337793,
      0.8334043,
      0.8330373,
      0.8327095,
      0.83239085,
      0.8321536,
      0.83191775,
      0.83171585,
      0.8315215,
      0.83135075,
      0.83125325,
      0.83115855,
      0.83106485,
      0.83097535,
      0.8309373,
      0.8308874,
      0.83083525,
      0.83078735,
      0.8306985,
      0.8306082,
      0.83052025,
      0.83036335,
      0.8301388,
      0.8299109,
      0.8296631,
      0.8293494,
      0.82902655,
      0.8285643,
      0.828089,
      0.82768975,
      0.8271383,
      0.82656965,
      0.8259478,
      0.82532025,
      0.82468725,
      0.8240076,
      0.8232785,
      0.82255575,
      0.8219078,
      0.82128025,
      0.8207317,
      0.82026195,
      0.8199249,
      0.8196017,
      0.8193701,
      0.81927515,
      0.8192648,
      0.81946165,
      0.81974725,
      0.82003285,
      0.82048485,
      0.82100575,
      0.82159005,
      0.82225615,
      0.8230042,
      0.8238986,
      0.82484655,
      0.82587415,
      0.82695425,
      0.8280959,
      0.82923755,
      0.8304497,
      0.83167415,
      0.83296805,
      0.83426195,
      0.83553975,
      0.8367536,
      0.8378953,
      0.839037,
      0.8401787,
      0.84131335,
      0.84246525,
      0.843683,
      0.84490075,
      0.84611855,
      0.84731665,
      0.848534,
      0.84975175,
      0.8509695,
      0.85218725,
      0.8533957,
      0.85453735,
      0.855679,
      0.8570481,
      0.8584837,
      0.86030745,
      0.8621951,
      0.8641549,
      0.8660851,
      0.8679519,
      0.86982845,
      0.8717122,
      0.87359595,
      0.8754797,
      0.8773572,
      0.8791845,
      0.8810682,
      0.8829519,
      0.88483565,
      0.8866858,
      0.88850275,
      0.89038645,
      0.89227015,
      0.8939264,
      0.89551615,
      0.89671075,
      0.8978524,
      0.89899405,
      0.9001653,
      0.90139995,
      0.9026557,
      0.90387345,
      0.9050912,
      0.90630895,
      0.90751925,
      0.90873705,
      0.90995485,
      0.91117265,
      0.9123623,
      0.9135376,
      0.9147554,
      0.9159732,
      0.9171483,
      0.91829,
      0.9194317,
      0.92038725,
      0.92139575,
      0.92219425,
      0.9228616,
      0.9233987,
      0.92383655,
      0.92431235,
      0.92478815,
      0.92473125,
      0.924688,
      0.92470715,
      0.9247263,
      0.9244537,
      0.92389215,
      0.92337855,
      0.92286495,
      0.92196365,
      0.920946,
      0.91982365,
      0.9187013,
      0.9177882,
      0.9168751,
      0.91578125,
      0.9144483,
      0.9132456,
      0.91218025,
      0.9111149,
      0.91004955,
      0.9087559,
      0.90746225,
      0.9061686,
      0.90487495,
      0.90387305,
      0.9028664,
      0.90169945,
      0.90034875,
      0.89938575,
      0.89836195,
      0.8973917,
      0.8961654,
      0.8947386,
      0.8933118,
      0.89227575,
      0.89160995,
      0.89025925,
      0.88890855,
      0.88755785,
      0.88620715,
      0.88561745,
      0.8846163,
      0.88343065,
      0.88207995,
      0.88072925,
      0.87970035,
      0.87875425,
      0.87761285,
      0.87647145,
      0.87527245,
      0.87397885,
      0.8729413,
      0.87210425,
      0.87078575,
      0.8693399,
      0.86789405,
      0.8671331,
      0.8663722,
      0.8651056,
      0.86358365,
      0.86206175,
      0.8609513,
      0.86002535,
      0.85926445,
      0.85837875,
      0.8570244,
      0.8555039,
      0.85436245,
      0.853221,
      0.852357,
      0.85125775,
      0.8499641,
      0.84867045,
      0.84785825,
      0.84680005,
      0.84565865,
      0.84451725,
      0.84313705,
      0.8421003,
      0.8408964,
      0.8393511,
      0.8376911,
      0.8357887,
      0.8338338,
      0.83196115,
      0.8304829,
      0.8292014,
      0.82785925,
      0.82647065,
      0.82504035,
      0.8239521,
      0.82298305,
      0.8219424,
      0.8208912,
      0.82014245,
      0.8194012,
      0.8185988,
      0.81796645,
      0.81743565,
      0.81726115,
      0.81735505,
      0.81750245,
      0.81782635,
      0.8181365,
      0.81842895,
      0.81872175,
      0.8189956,
      0.81926375,
      0.8195306,
      0.8197634,
      0.81999575,
      0.8202571,
      0.8205376,
      0.82075605,
      0.82098505,
      0.8212325,
      0.8214794,
      0.821738,
      0.8219859,
      0.82222715,
      0.82248095,
      0.82273435,
      0.8230044,
      0.8232759,
      0.82353605,
      0.82379405,
      0.82406365,
      0.82435875,
      0.824645,
      0.8249367,
      0.82522925,
      0.82550745,
      0.8257932,
      0.826126,
      0.82644925,
      0.826754,
      0.82704865,
      0.8273705,
      0.8276942,
      0.82803645,
      0.828375,
      0.828703,
      0.82902355,
      0.8293623,
      0.82974325,
      0.83012665,
      0.83050045,
      0.83084275,
      0.83118095,
      0.83151755,
      0.83187975,
      0.83223945,
      0.8325931,
      0.8329044,
      0.833232,
      0.83356425,
      0.8339159,
      0.8342272,
      0.8345923,
      0.83493605,
      0.8352831,
      0.83562935,
      0.83597595,
      0.8362974,
      0.83660205,
      0.83693575,
      0.83726755,
      0.83762345,
      0.83797035,
      0.8383369,
      0.8386768,
      0.83903385,
      0.8393975,
      0.83976255,
      0.8401337,
      0.84050605,
      0.84087875,
      0.8412481,
      0.84158315,
      0.8419222,
      0.8422489,
      0.84264565,
      0.84304835,
      0.8434473,
      0.8438444,
      0.84421275,
      0.8445732,
      0.84497575,
      0.84537565,
      0.84577755,
      0.84617645,
      0.84657375,
      0.8469419,
      0.84730445,
      0.8477036,
      0.84810345,
      0.8485471,
      0.84898955,
      0.84941285,
      0.84985775,
      0.85030325,
      0.8507007,
      0.85107485,
      0.8514819,
      0.85186235,
      0.8522812,
      0.85272385,
      0.85309705,
      0.853469,
      0.8538413,
      0.85421365,
      0.8545589,
      0.8549777,
      0.855211526
  ],
  bloodPressureValues: [
      0.625403,
      0.625572,
      0.626935,
      0.627069,
      0.627094,
      0.628617,
      0.630139,
      0.631595,
      0.631599,
      0.631662,
      0.632016,
      0.632664,
      0.632745,
      0.632804,
      0.63423,
      0.634326,
      0.635651,
      0.635849,
      0.63725,
      0.637372,
      0.637812,
      0.638754,
      0.638894,
      0.639385,
      0.639406,
      0.639408,
      0.639971,
      0.639975,
      0.640037,
      0.641366,
      0.64156,
      0.641974,
      0.642854,
      0.64287,
      0.643082,
      0.64437,
      0.644375,
      0.644605,
      0.645555,
      0.645562,
      0.645747,
      0.645944,
      0.646375,
      0.646387,
      0.647129,
      0.64727,
      0.648489,
      0.648493,
      0.648793,
      0.649998,
      0.650058,
      0.650316,
      0.650684,
      0.651502,
      0.651838,
      0.653006,
      0.653089,
      0.653361,
      0.654503,
      0.656025,
      0.657158,
      0.657548,
      0.657872,
      0.65866,
      0.658665,
      0.658771,
      0.65907,
      0.660169,
      0.660593,
      0.661894,
      0.66196,
      0.662116,
      0.662343,
      0.663258,
      0.664549,
      0.664621,
      0.664782,
      0.665069,
      0.666304,
      0.667493,
      0.667827,
      0.668969,
      0.669999,
      0.670492,
      0.670747,
      0.671659,
      0.672014,
      0.673536,
      0.673959,
      0.674085,
      0.674238,
      0.674299,
      0.674425,
      0.674807,
      0.674826,
      0.674902,
      0.675062,
      0.674839,
      0.674789,
      0.674726,
      0.674684,
      0.674158,
      0.674083,
      0.673988,
      0.673925,
      0.673851,
      0.673814,
      0.673578,
      0.673567,
      0.673547,
      0.673099,
      0.672407,
      0.672236,
      0.672207,
      0.672107,
      0.671982,
      0.671647,
      0.671475,
      0.671446,
      0.671093,
      0.670888,
      0.670364,
      0.670277,
      0.669367,
      0.669316,
      0.668937,
      0.668909,
      0.668608,
      0.668449,
      0.66807,
      0.667087,
      0.666617,
      0.66639,
      0.665946,
      0.665536,
      0.664964,
      0.664901,
      0.664806,
      0.664718,
      0.66415,
      0.663727,
      0.663666,
      0.663483,
      0.662145,
      0.661727,
      0.66166,
      0.661431,
      0.661005,
      0.659483,
      0.65876,
      0.658487,
      0.658419,
      0.658343,
      0.657478,
      0.657251,
      0.657203,
      0.656052,
      0.655681,
      0.655241,
      0.65416,
      0.651634,
      0.651498,
      0.65302,
      0.652082,
      0.650647,
      0.649976,
      0.648454,
      0.647248,
      0.647059,
      0.646933,
      0.646425,
      0.645411,
      0.644186,
      0.64389,
      0.643356,
      0.642368,
      0.641833,
      0.641591,
      0.641228,
      0.639977,
      0.639707,
      0.638657,
      0.638219,
      0.638186,
      0.637045,
      0.636446,
      0.635763,
      0.635523,
      0.632541,
      0.63248,
      0.634002,
      0.631371,
      0.630958,
      0.630315,
      0.629437,
      0.628092,
      0.62809,
      0.627915,
      0.627246,
      0.626554,
      0.626394,
      0.62639,
      0.625565,
      0.625307,
      0.625253,
      0.623732,
      0.623377,
      0.623033,
      0.622956,
      0.622211,
      0.621939,
      0.621681,
      0.62107,
      0.620327,
      0.619635,
      0.61963,
      0.619549,
      0.61953,
      0.619006,
      0.618694,
      0.618408,
      0.616938,
      0.616887,
      0.616852,
      0.616497,
      0.615383,
      0.615366,
      0.614804,
      0.614495,
      0.614225,
      0.614139,
      0.612704,
      0.612396,
      0.612133,
      0.611564,
      0.611557,
      0.610725,
      0.610042,
      0.609986,
      0.60927,
      0.608852,
      0.608521,
      0.60738,
      0.605859,
      0.605833,
      0.60579,
      0.605415,
      0.604338,
      0.603431,
      0.602816,
      0.602124,
      0.601675,
      0.601637,
      0.601595,
      0.600154,
      0.600073,
      0.599206,
      0.598633,
      0.597111,
      0.597043,
      0.59697,
      0.59638,
      0.59597,
      0.595924,
      0.595162,
      0.594714,
      0.594449,
      0.592928,
      0.59289,
      0.592848,
      0.592549,
      0.591787,
      0.591737,
      0.591273,
      0.590915,
      0.590266,
      0.590166,
      0.588745,
      0.588707,
      0.58835,
      0.588139,
      0.588137,
      0.587605,
      0.58736,
      0.586533,
      0.586083,
      0.586046,
      0.585105,
      0.584943,
      0.584893,
      0.584051,
      0.583422,
      0.583384,
      0.583006,
      0.582281,
      0.582256,
      0.582001,
      0.581877,
      0.581832,
      0.581498,
      0.581141,
      0.580858,
      0.580357,
      0.580311,
      0.580095,
      0.580002,
      0.578861,
      0.578424,
      0.57825,
      0.57818,
      0.577721,
      0.577574,
      0.577304,
      0.577193,
      0.577137,
      0.576962,
      0.576763,
      0.576437,
      0.576405,
      0.576254,
      0.576203,
      0.576185,
      0.576184,
      0.575933,
      0.575746,
      0.575682,
      0.575681,
      0.575443,
      0.575437,
      0.575418,
      0.575341,
      0.575311,
      0.575311,
      0.575186,
      0.575088,
      0.575065,
      0.575058,
      0.575026,
      0.574719,
      0.57468,
      0.574528,
      0.574491,
      0.574491,
      0.574306,
      0.574301,
      0.574254,
      0.574094,
      0.573794,
      0.573546,
      0.573535,
      0.573533,
      0.573226,
      0.57315,
      0.573149,
      0.573147,
      0.573145,
      0.573087,
      0.572762,
      0.57276,
      0.572758,
      0.572407,
      0.572386,
      0.572385,
      0.572384,
      0.572191,
      0.572129,
      0.572127,
      0.572126,
      0.572125,
      0.572124,
      0.571869,
      0.571868,
      0.571866,
      0.571865,
      0.571847,
      0.571683,
      0.571647,
      0.571592,
      0.571591,
      0.571589,
      0.571586,
      0.571204,
      0.571201,
      0.5712,
      0.571199,
      0.570816,
      0.570814,
      0.570811,
      0.570809,
      0.570805,
      0.570557,
      0.570507,
      0.570387,
      0.570386,
      0.570382,
      0.570379,
      0.570375,
      0.570372,
      0.57037,
      0.570368,
      0.569793,
      0.56979,
      0.569786,
      0.569783,
      0.569781,
      0.569779,
      0.569437,
      0.569367,
      0.569204,
      0.569201,
      0.569197,
      0.569194,
      0.569192,
      0.56919,
      0.569187,
      0.568669,
      0.568612,
      0.568608,
      0.568605,
      0.568603,
      0.568601,
      0.568598,
      0.568595,
      0.568591,
      0.568227,
      0.568086,
      0.568085,
      0.568084,
      0.568082,
      0.568079,
      0.568077,
      0.568075,
      0.568074,
      0.568072,
      0.567889,
      0.56769,
      0.567687,
      0.567685,
      0.567683,
      0.567682,
      0.56768,
      0.567298,
      0.567295,
      0.567293,
      0.567087,
      0.566913,
      0.566733,
      0.56673,
      0.566728,
      0.566404,
      0.565961,
      0.565956,
      0.565951,
      0.565566,
      0.565184,
      0.565181,
      0.565179,
      0.564875,
      0.564625,
      0.56442,
      0.564108,
      0.564045,
      0.563483,
      0.563476,
      0.563469,
      0.562904,
      0.562536,
      0.562531,
      0.562529,
      0.562196,
      0.561788,
      0.561786,
      0.561781,
      0.561778,
      0.561383,
      0.561241,
      0.561124,
      0.56112,
      0.561117,
      0.560846,
      0.560566,
      0.560563,
      0.560561,
      0.560559,
      0.560556,
      0.560242,
      0.560008,
      0.560007,
      0.560005,
      0.560002,
      0.559998,
      0.559701,
      0.559527,
      0.559453,
      0.559451,
      0.559447,
      0.559444,
      0.55944,
      0.559102,
      0.55883,
      0.558828,
      0.558823,
      0.558819,
      0.558814,
      0.558809,
      0.558372,
      0.558091,
      0.558088,
      0.558084,
      0.558079,
      0.558075,
      0.55807,
      0.557581,
      0.557411,
      0.557409,
      0.557407,
      0.557404,
      0.5574,
      0.557397,
      0.557393,
      0.557027,
      0.55686,
      0.556857,
      0.556855,
      0.556853,
      0.55685,
      0.556846,
      0.556843,
      0.556441,
      0.556254,
      0.556251,
      0.556247,
      0.556242,
      0.556238,
      0.555693,
      0.555503,
      0.555498,
      0.555493,
      0.55492,
      0.554799,
      0.554795,
      0.554794,
      0.554792,
      0.554788,
      0.554353,
      0.554241,
      0.55424,
      0.554238,
      0.554234,
      0.554231,
      0.554193,
      0.553779,
      0.553687,
      0.553685,
      0.553684,
      0.55368,
      0.553677,
      0.553673,
      0.553207,
      0.55313,
      0.553126,
      0.553123,
      0.553119,
      0.552639,
      0.552545,
      0.55254,
      0.552536,
      0.552533,
      0.552428,
      0.551868,
      0.551801,
      0.551796,
      0.551794,
      0.551791,
      0.551787,
      0.551118,
      0.551065,
      0.551061,
      0.550534,
      0.550496,
      0.549978,
      0.549941,
      0.549869,
      0.549559,
      0.549205,
      0.549177,
      0.548838,
      0.548728,
      0.548414,
      0.548062,
      0.547698,
      0.547627,
      0.546959,
      0.546557,
      0.546538,
      0.546507,
      0.546505,
      0.545775,
      0.545744,
      0.545418,
      0.545189,
      0.544542,
      0.543897,
      0.543815,
      0.542756,
      0.541804,
      0.539712,
      0.541234,
      0.541008,
      0.53978,
      0.538572,
      0.538152,
      0.538121,
      0.538068,
      0.537432,
      0.536798,
      0.536751,
      0.536673,
      0.536291,
      0.536178,
      0.535151,
      0.534946,
      0.53481,
      0.532936,
      0.530206,
      0.530122,
      0.530076,
      0.529433,
      0.529066,
      0.528184,
      0.528122,
      0.527545,
      0.527026,
      0.52669,
      0.526405,
      0.526308,
      0.525625,
      0.525264,
      0.525165,
      0.524817,
      0.524784,
      0.524125,
      0.523971,
      0.523912,
      0.522984,
      0.522842,
      0.52201,
      0.521936,
      0.521463,
      0.520323,
      0.52021,
      0.520156,
      0.519532,
      0.519182,
      0.518721,
      0.517661,
      0.5172,
      0.517131,
      0.516781,
      0.516521,
      0.516399,
      0.516018,
      0.515761,
      0.515678,
      0.51561,
      0.515255,
      0.514872,
      0.514621,
      0.514492,
      0.514392,
      0.513852,
      0.513481,
      0.512965,
      0.512631,
      0.512584,
      0.512566,
      0.512341,
      0.512387,
      0.512643,
      0.512647,
      0.512723,
      0.512814,
      0.513246,
      0.513629,
      0.513866,
      0.513917,
      0.514023,
      0.514042,
      0.51417,
      0.51417,
      0.514249,
      0.514548,
      0.515185,
      0.515305,
      0.515772,
      0.516565,
      0.516915,
      0.517204,
      0.517846,
      0.517971,
      0.518438,
      0.518661,
      0.519221,
      0.519233,
      0.519234,
      0.51958,
      0.519705,
      0.520377,
      0.520723,
      0.521414,
      0.521432,
      0.521847,
      0.522247,
      0.522699,
      0.523389,
      0.523655,
      0.523692,
      0.524306,
      0.524453,
      0.524912,
      0.526434,
      0.527956,
      0.529479,
      0.530054,
      0.531001,
      0.532523,
      0.534045,
      0.534603,
      0.535568,
      0.536019,
      0.537091,
      0.537267,
      0.537501,
      0.538233,
      0.539755,
      0.540294,
      0.541277,
      0.5428,
      0.543205,
      0.544322,
      0.544582,
      0.54518,
      0.545344,
      0.545845,
      0.547367,
      0.547741,
      0.547877,
      0.54889,
      0.550412,
      0.551935,
      0.552303,
      0.553077,
      0.55322,
      0.553568,
      0.5546,
      0.554927,
      0.556122,
      0.557644,
      0.558116,
      0.559167,
      0.560689,
      0.560788,
      0.562212,
      0.563734,
      0.564003,
      0.564176,
      0.565256,
      0.566778,
      0.568301,
      0.569823,
      0.570233,
      0.571346,
      0.572868,
      0.572881,
      0.573065,
      0.573732,
      0.574391,
      0.575913,
      0.577435,
      0.578958,
      0.58048,
      0.580745,
      0.581601,
      0.581622,
      0.583144,
      0.583289,
      0.584667,
      0.586189,
      0.586507,
      0.587712,
      0.587827,
      0.588012,
      0.589161,
      0.589235,
      0.590757,
      0.590963,
      0.591899,
      0.592162,
      0.593422,
      0.593498,
      0.593671,
      0.594836,
      0.594945,
      0.595251,
      0.597256,
      0.599058,
      0.601035,
      0.601212,
      0.602403,
      0.602557,
      0.602712,
      0.602716,
      0.60408,
      0.604221,
      0.605598,
      0.605603,
      0.607125,
      0.607238,
      0.608647,
      0.610169,
      0.610248,
      0.610255,
      0.61148,
      0.611692,
      0.613214,
      0.614356,
      0.614399,
      0.615809,
      0.615878,
      0.6174,
      0.617417,
      0.617421,
      0.618912,
      0.618921,
      0.618923,
      0.620445,
      0.621929,
      0.621968,
      0.62349,
      0.62472,
      0.624941,
      0.624946,
      0.625013,
      0.625708,
      0.625734,
      0.626488,
      0.626536,
      0.627945,
      0.628058,
      0.629261,
      0.629422,
      0.62945,
      0.629581,
      0.630611,
      0.630614,
      0.630723,
      0.632082,
      0.632246,
      0.633586,
      0.633768,
      0.63529,
      0.636449,
      0.636604,
      0.636608,
      0.636813,
      0.637301,
      0.637956,
      0.639478,
      0.641,
      0.642258,
      0.642281,
      0.642523,
      0.644045,
      0.645567,
      0.647089,
      0.648174,
      0.648305,
      0.648611,
      0.649809,
      0.650134,
      0.650747,
      0.651425,
      0.651485,
      0.651657,
      0.652799,
      0.653761,
      0.653806,
      0.653822,
      0.653942,
      0.655465,
      0.655849,
      0.65591,
      0.656466,
      0.656608,
      0.657241,
      0.657275,
      0.657283,
      0.657751,
      0.658177,
      0.658694,
      0.658894,
      0.658967,
      0.659147,
      0.659156,
      0.659254,
      0.659277,
      0.659278,
      0.659278,
      0.659279,
      0.659279,
      0.659036,
      0.658236,
      0.658231,
      0.658139,
      0.657724,
      0.65738,
      0.65719,
      0.657187,
      0.656711,
      0.656683,
      0.656621,
      0.656404,
      0.656121,
      0.655582,
      0.655549,
      0.655481,
      0.65529,
      0.654811,
      0.654722,
      0.654431,
      0.654054,
      0.653962,
      0.652972,
      0.652441,
      0.651852,
      0.65092,
      0.650546,
      0.65048,
      0.649905,
      0.649779,
      0.648517,
      0.648429,
      0.648258,
      0.647312,
      0.647243,
      0.647239,
      0.647118,
      0.645596,
      0.644394,
      0.644075,
      0.643692,
      0.643618,
      0.642934,
      0.641912,
      0.641413,
      0.640803,
      0.640139,
      0.640035,
      0.639892,
      0.63837,
      0.637442,
      0.637229,
      0.636183,
      0.635971,
      0.635708,
      0.634423,
      0.634187,
      0.632924,
      0.632666,
      0.631384,
      0.631144,
      0.630345,
      0.630003,
      0.627403,
      0.627186,
      0.627179,
      0.62696,
      0.628482,
      0.625819,
      0.625165,
      0.624514,
      0.6244,
      0.624298,
      0.622975,
      0.622777,
      0.621255,
      0.620425,
      0.620114,
      0.618767,
      0.61876,
      0.618593,
      0.617071,
      0.615943,
      0.615692,
      0.61555,
      0.615037,
      0.614556,
      0.614456,
      0.614409,
      0.611366,
      0.612888,
      0.610027,
      0.609844,
      0.609411,
      0.60932,
      0.609305,
      0.608732,
      0.608704,
      0.607182,
      0.60601,
      0.605766,
      0.60571,
      0.605661,
      0.604651,
      0.604532,
      0.60452,
      0.603092,
      0.603015,
      0.602999,
      0.601477,
      0.600284,
      0.599956,
      0.59995,
      0.599185,
      0.599183,
      0.598468,
      0.598435,
      0.597533,
      0.597294,
      0.597272,
      0.595801,
      0.595773,
      0.595726,
      0.594864,
      0.594632,
      0.59311,
      0.593078,
      0.592555,
      0.592081,
      0.59197,
      0.591157,
      0.590449,
      0.590407,
      0.589988,
      0.589888,
      0.589309,
      0.589248,
      0.587929,
      0.587796,
      0.587788,
      0.587169,
      0.586647,
      0.585398,
      0.585126,
      0.584555,
      0.583985,
      0.583931,
      0.582942,
      0.582845,
      0.582841,
      0.58201,
      0.581324,
      0.580687,
      0.580554,
      0.580488,
      0.579803,
      0.57969,
      0.578662,
      0.578585,
      0.577253,
      0.577141,
      0.577113,
      0.577024,
      0.576,
      0.575161,
      0.574479,
      0.574461,
      0.573984,
      0.573849,
      0.573339,
      0.572501,
      0.572499,
      0.571818,
      0.571788,
      0.57174,
      0.571148,
      0.570297,
      0.570239,
      0.5697,
      0.569157,
      0.569098,
      0.568648,
      0.568016,
      0.567996,
      0.567978,
      0.567294,
      0.567219,
      0.566876,
      0.566821,
      0.566274,
      0.56625,
      0.565736,
      0.565736,
      0.565736,
      0.565737,
      0.565738,
      0.565738,
      0.565721,
      0.565538,
      0.565184,
      0.565152,
      0.564979,
      0.56498,
      0.564981,
      0.564981,
      0.564981,
      0.564982,
      0.564982,
      0.564982,
      0.564983,
      0.564983,
      0.564984,
      0.564984,
      0.564984,
      0.564984,
      0.564984,
      0.564985,
      0.564986,
      0.564986,
      0.564986,
      0.564986,
      0.564986,
      0.564982,
      0.564982,
      0.564888,
      0.564803,
      0.564795,
      0.564794,
      0.5647,
      0.564698,
      0.564608,
      0.564603,
      0.564116,
      0.564095,
      0.564093,
      0.56409,
      0.563849,
      0.563833,
      0.563832,
      0.563831,
      0.563828,
      0.563826,
      0.563824,
      0.563441,
      0.56344,
      0.563439,
      0.563436,
      0.563434,
      0.563107,
      0.563051,
      0.563049,
      0.563048,
      0.563046,
      0.563044,
      0.562709,
      0.56268,
      0.562637,
      0.562634,
      0.56263,
      0.562629,
      0.562055,
      0.562052,
      0.562049,
      0.562045,
      0.562042,
      0.56159,
      0.561568,
      0.561502,
      0.561501,
      0.561498,
      0.561496,
      0.561494,
      0.561168,
      0.561111,
      0.56111,
      0.561108,
      0.561106,
      0.561104,
      0.560721,
      0.560719,
      0.560718,
      0.560717,
      0.560715,
      0.560439,
      0.560428,
      0.560283,
      0.56028,
      0.560276,
      0.560275,
      0.560273,
      0.559825,
      0.559698,
      0.559694,
      0.559691,
      0.559687,
      0.559686,
      0.559684,
      0.559288,
      0.559109,
      0.559105,
      0.559102,
      0.559098,
      0.559097,
      0.559095,
      0.559091,
      0.558729,
      0.558516,
      0.558513,
      0.55851,
      0.558508,
      0.558506,
      0.558503,
      0.558148,
      0.557854,
      0.557849,
      0.557845,
      0.557842,
      0.55784,
      0.557835,
      0.557831,
      0.557337,
      0.557063,
      0.557059,
      0.557056,
      0.557054,
      0.557049,
      0.557045,
      0.556627,
      0.556365,
      0.556361,
      0.55636,
      0.556358,
      0.55606,
      0.555783,
      0.555486,
      0.555379,
      0.554759,
      0.553965,
      0.553695,
      0.553693,
      0.55339,
      0.553132,
      0.553129,
      0.553125,
      0.552824,
      0.552764,
      0.5525,
      0.552496,
      0.552491,
      0.552487,
      0.552484,
      0.552482,
      0.552477,
      0.551761,
      0.551756,
      0.551752,
      0.551747,
      0.551745,
      0.551742,
      0.551303,
      0.551281,
      0.550873,
      0.550866,
      0.550859,
      0.550855,
      0.550852,
      0.550162,
      0.549332,
      0.549114,
      0.548642,
      0.548434,
      0.54843,
      0.548428,
      0.548427,
      0.548051,
      0.547879,
      0.547876,
      0.547874,
      0.547873,
      0.547869,
      0.547866,
      0.547501,
      0.547428,
      0.547267,
      0.547262,
      0.54726,
      0.547257,
      0.547253,
      0.547248,
      0.546527,
      0.546522,
      0.54652,
      0.546518,
      0.546513,
      0.546508,
      0.54598,
      0.545944,
      0.545783,
      0.54578,
      0.545778,
      0.545773,
      0.545769,
      0.545043,
      0.545041,
      0.545039,
      0.545034,
      0.545029,
      0.545025,
      0.544459,
      0.544398,
      0.54435,
      0.544346,
      0.544343,
      0.544341,
      0.544339,
      0.544336,
      0.544332,
      0.544329,
      0.543795,
      0.543792,
      0.543789,
      0.543787,
      0.543785,
      0.543782,
      0.543319,
      0.543284,
      0.543241,
      0.543238,
      0.543234,
      0.543233,
      0.543231,
      0.543227,
      0.542687,
      0.542685,
      0.542684,
      0.54268,
      0.542679,
      0.542677,
      0.542673,
      0.542178,
      0.542106,
      0.542104,
      0.542099,
      0.541345,
      0.540657,
      0.540617,
      0.540027,
      0.540019,
      0.539517,
      0.53888,
      0.538377,
      0.538315,
      0.538281,
      0.53752,
      0.536856,
      0.53621,
      0.536207,
      0.535715,
      0.535681,
      0.535281,
      0.534899,
      0.534575,
      0.534505,
      0.534472,
      0.533054,
      0.533014,
      0.532766,
      0.532523,
      0.532506,
      0.532295,
      0.530774,
      0.530118,
      0.529633,
      0.529571,
      0.529531,
      0.528493,
      0.528376,
      0.527645,
      0.527587,
      0.526972,
      0.526289,
      0.525831,
      0.525645,
      0.525599,
      0.524546,
      0.522208,
      0.522157,
      0.521268,
      0.520614,
      0.520127,
      0.520038,
      0.519678,
      0.519309,
      0.518987,
      0.51885,
      0.51831,
      0.517847,
      0.516961,
      0.516894,
      0.516326,
      0.516178,
      0.515645,
      0.515185,
      0.51478,
      0.513664,
      0.51363,
      0.513436,
      0.513422,
      0.51306,
      0.512905,
      0.512854,
      0.512833,
      0.512326,
      0.512146,
      0.512128,
      0.512032,
      0.511929,
      0.511834,
      0.511768,
      0.51175,
      0.511748,
      0.511739,
      0.511549,
      0.511389,
      0.511389,
      0.51139,
      0.51139,
      0.511391,
      0.511391,
      0.51141,
      0.511505,
      0.511518,
      0.511614,
      0.51171,
      0.511774,
      0.511811,
      0.511811,
      0.512028,
      0.51222,
      0.512383,
      0.512537,
      0.512611,
      0.512668,
      0.51343,
      0.513431,
      0.51368,
      0.513751,
      0.513813,
      0.514133,
      0.514443,
      0.514576,
      0.514892,
      0.515339,
      0.515586,
      0.515657,
      0.515721,
      0.516413,
      0.516485,
      0.516729,
      0.517006,
      0.517275,
      0.518252,
      0.518793,
      0.519774,
      0.521297,
      0.521411,
      0.521562,
      0.52282,
      0.523003,
      0.523218,
      0.523962,
      0.525484,
      0.527007,
      0.527101,
      0.527263,
      0.52853,
      0.528739,
      0.529041,
      0.530052,
      0.530221,
      0.530559,
      0.530564,
      0.531575,
      0.533098,
      0.533286,
      0.533735,
      0.53424,
      0.535762,
      0.535894,
      0.536255,
      0.537285,
      0.538808,
      0.54033,
      0.540379,
      0.540508,
      0.540938,
      0.541473,
      0.541947,
      0.541952,
      0.542995,
      0.544517,
      0.544983,
      0.54604,
      0.547562,
      0.547604,
      0.548348,
      0.548549,
      0.549085,
      0.550607,
      0.55213,
      0.553652,
      0.553693,
      0.554078,
      0.555175,
      0.555176,
      0.555904,
      0.556317,
      0.55784,
      0.559362,
      0.559364,
      0.55977,
      0.560852,
      0.560885,
      0.561086,
      0.561847,
      0.562397,
      0.562408,
      0.563878,
      0.56393,
      0.565072,
      0.565461,
      0.566595,
      0.568117,
      0.569639,
      0.570008,
      0.571088,
      0.571162,
      0.572684,
      0.574087,
      0.574207,
      0.575729,
      0.577251,
      0.577569,
      0.578774,
      0.580165,
      0.580297,
      0.581819,
      0.5821,
      0.583166,
      0.583341,
      0.584864,
      0.585117,
      0.585122,
      0.586386,
      0.587908,
      0.587994,
      0.58841,
      0.588971,
      0.589051,
      0.590573,
      0.590776,
      0.592096,
      0.59228,
      0.593618,
      0.59514,
      0.595217,
      0.595219,
      0.595774,
      0.595785,
      0.595974,
      0.596664,
      0.598186,
      0.598305,
      0.599708,
      0.60123,
      0.602752,
      0.602835,
      0.603987,
      0.604275,
      0.604333,
      0.604342,
      0.605478,
      0.605798,
      0.60588,
      0.60589,
      0.609055,
      0.612229,
      0.612268,
      0.61344,
      0.613753,
      0.61379,
      0.614935,
      0.615248,
      0.615253,
      0.615258,
      0.615313,
      0.616835,
      0.618266,
      0.618358,
      0.61988,
      0.621283,
      0.621402,
      0.622924,
      0.624447,
      0.625525,
      0.625543,
      0.625813,
      0.625969,
      0.627491,
      0.627995,
      0.627996,
      0.628563,
      0.628634,
      0.630156,
      0.631213,
      0.631462,
      0.631679,
      0.633201,
      0.634221,
      0.634479,
      0.634723,
      0.635983,
      0.636246,
      0.636869,
      0.637507,
      0.637515,
      0.637626,
      0.637769,
      0.63869,
      0.638911,
      0.640221,
      0.641628,
      0.643251,
      0.64386,
      0.645032,
      0.645382,
      0.646332,
      0.646905,
      0.64805,
      0.648428,
      0.64912,
      0.64957,
      0.650489,
      0.651093,
      0.652615,
      0.65322,
      0.653274,
      0.653758,
      0.654093,
      0.654173,
      0.654901,
      0.655011,
      0.655039,
      0.655202,
      0.655583,
      0.655611,
      0.655664,
      0.655519,
      0.654905,
      0.65476,
      0.654758,
      0.65472,
      0.654145,
      0.654,
      0.653999,
      0.653959,
      0.653386,
      0.653223,
      0.652935,
      0.65289,
      0.652369,
      0.65232,
      0.652247,
      0.651342,
      0.651249,
      0.651106,
      0.65078,
      0.649966,
      0.649514,
      0.648768,
      0.648626,
      0.648445,
      0.647545,
      0.647304,
      0.646617,
      0.646425,
      0.646163,
      0.645361,
      0.644842,
      0.644758,
      0.644643,
      0.643502,
      0.643055,
      0.642148,
      0.641981,
      0.641082,
      0.641033,
      0.64046,
      0.639561,
      0.639319,
      0.638718,
      0.637798,
      0.637455,
      0.637435,
      0.636776,
      0.636657,
      0.635737,
      0.635136,
      0.634768,
      0.63411,
      0.633995,
      0.633066,
      0.632474,
      0.631213,
      0.631099,
      0.630953,
      0.630601,
      0.629813,
      0.628575,
      0.628291,
      0.627577,
      0.627525,
      0.627359,
      0.62715,
      0.625764,
      0.625629,
      0.625269,
      0.62467,
      0.624489,
      0.623245,
      0.623092,
      0.622968,
      0.622443,
      0.621447,
      0.619165,
      0.618784,
      0.620306,
      0.617312,
      0.61574,
      0.617262,
      0.615077,
      0.614219,
      0.61287,
      0.612697,
      0.61167,
      0.611176,
      0.610055,
      0.609801,
      0.609654,
      0.608268,
      0.608133,
      0.607344,
      0.607084,
      0.606992,
      0.60547,
      0.604317,
      0.604046,
      0.603949,
      0.602808,
      0.602268,
      0.601323,
      0.601287,
      0.600108,
      0.599765,
      0.59829,
      0.598244,
      0.597412,
      0.597103,
      0.594087,
      0.594059,
      0.595581,
      0.593143,
      0.592538,
      0.592533,
      0.591016,
      0.590993,
      0.589856,
      0.589495,
      0.589458,
      0.588405,
      0.586832,
      0.588354,
      0.588242,
      0.585977,
      0.585311,
      0.583914,
      0.58379,
      0.583755,
      0.583183,
      0.582765,
      0.582649,
      0.582623,
      0.581949,
      0.58186,
      0.581584,
      0.581509,
      0.580889,
      0.580456,
      0.580369,
      0.580321,
      0.579956,
      0.578849,
      0.57879,
      0.578361,
      0.577807,
      0.577708,
      0.577648,
      0.577078,
      0.576568,
      0.57625,
      0.576239,
      0.575767,
      0.575428,
      0.575008,
      0.574727,
      0.574718,
      0.574288,
      0.573658,
      0.573231,
      0.57322,
      0.573148,
      0.573109,
      0.57273,
      0.57235,
      0.572061,
      0.572008,
      0.571506,
      0.571382,
      0.570867,
      0.570832,
      0.570156,
      0.570073,
      0.569771,
      0.569728,
      0.569106,
      0.568654,
      0.568587,
      0.568553,
      0.568174,
      0.567868,
      0.567489,
      0.567447,
      0.567398,
      0.566935,
      0.56636,
      0.566307,
      0.566241,
      0.565622,
      0.564786,
      0.564753,
      0.564437,
      0.563994,
      0.563681,
      0.563674,
      0.563646,
      0.563027,
      0.562506,
      0.562473,
      0.562159,
      0.562149,
      0.561387,
      0.561366,
      0.561089,
      0.56088,
      0.560872,
      0.560607,
      0.559875,
      0.559086,
      0.559048,
      0.558734,
      0.558337,
      0.557946,
      0.557887,
      0.556844,
      0.556817,
      0.556805,
      0.556781,
      0.55678,
      0.55653,
      0.556528,
      0.556278,
      0.556046,
      0.555986,
      0.555332,
      0.555295,
      0.555232,
      0.55523,
      0.555227,
      0.554525,
      0.5545,
      0.554124,
      0.554121,
      0.553766,
      0.553406,
      0.553385,
      0.553354,
      0.553352,
      0.552779,
      0.552775,
      0.552245,
      0.552186,
      0.552181,
      0.55152,
      0.551477,
      0.551413,
      0.551411,
      0.551409,
      0.551404,
      0.550724,
      0.550695,
      0.550693,
      0.550692,
      0.550691,
      0.55069,
      0.550689,
      0.550687,
      0.550432,
      0.550431,
      0.55043,
      0.550429,
      0.550428,
      0.550426,
      0.550213,
      0.550171,
      0.55017,
      0.550169,
      0.550168,
      0.550167,
      0.549975,
      0.549965,
      0.549805,
      0.549801,
      0.549796,
      0.549794,
      0.549029,
      0.549024,
      0.548444,
      0.547857,
      0.5477,
      0.547691,
      0.547321,
      0.547303,
      0.547175,
      0.547169,
      0.546783,
      0.546777,
      0.546391,
      0.546385,
      0.546163,
      0.546135,
      0.545834,
      0.545822,
      0.545421,
      0.545048,
      0.545036,
      0.544642,
      0.544357,
      0.543777,
      0.543768,
      0.543502,
      0.543474,
      0.543188,
      0.54294,
      0.542609,
      0.542362,
      0.541557,
      0.541177,
      0.540841,
      0.54053,
      0.540273,
      0.539967,
      0.5397,
      0.539413,
      0.539404,
      0.539091,
      0.538859,
      0.53885,
      0.53856,
      0.538333,
      0.538326,
      0.53805,
      0.537833,
      0.537349,
      0.537341,
      0.537333,
      0.537039,
      0.536994,
      0.536833,
      0.536825,
      0.536279,
      0.53627,
      0.535899,
      0.535891,
      0.535716,
      0.535153,
      0.534759,
      0.534707,
      0.534599,
      0.534591,
      0.534045,
      0.534037,
      0.533618,
      0.533605,
      0.533482,
      0.532991,
      0.53292,
      0.532478,
      0.532378,
      0.53237,
      0.531886,
      0.531878,
      0.531448,
      0.531409,
      0.531386,
      0.530957,
      0.530876,
      0.529817,
      0.529025,
      0.528964,
      0.528946,
      0.528296,
      0.527156,
      0.526356,
      0.526295,
      0.526266,
      0.525635,
      0.524494,
      0.524061,
      0.52371,
      0.523354,
      0.523206,
      0.522485,
      0.521833,
      0.52122,
      0.520692,
      0.520037,
      0.519552,
      0.519141,
      0.518708,
      0.518412,
      0.518354,
      0.517619,
      0.517272,
      0.517213,
      0.516477,
      0.516133,
      0.515692,
      0.515281,
      0.514993,
      0.514967,
      0.514445,
      0.514233,
      0.513601,
      0.513093,
      0.512648,
      0.512613,
      0.51229,
      0.511953,
      0.511656,
      0.511194,
      0.511148,
      0.510367,
      0.510054,
      0.51003,
      0.51,
      0.509451,
      0.509295,
      0.509278,
      0.50902,
      0.509005,
      0.508916,
      0.508942,
      0.509522,
      0.50968,
      0.509714,
      0.509758,
      0.510442,
      0.510493,
      0.510507,
      0.511324,
      0.511586,
      0.511647,
      0.512016,
      0.512348,
      0.512851,
      0.513159,
      0.513169,
      0.513491,
      0.514634,
      0.514727,
      0.514883,
      0.515506,
      0.516157,
      0.5173,
      0.517387,
      0.517552,
      0.518165,
      0.518823,
      0.519668,
      0.520346,
      0.521488,
      0.521985,
      0.52301,
      0.523099,
      0.523845,
      0.524533,
      0.526056,
      0.527578,
      0.52806,
      0.529101,
      0.529145,
      0.529155,
      0.530244,
      0.531766,
      0.533288,
      0.533383,
      0.533756,
      0.534811,
      0.534921,
      0.536334,
      0.537856,
      0.538998,
      0.539065,
      0.539088,
      0.539454,
      0.540521,
      0.542044,
      0.543566,
      0.545088,
      0.545123,
      0.545146,
      0.546611,
      0.548133,
      0.548555,
      0.549656,
      0.551178,
      0.551559,
      0.5527,
      0.554223,
      0.555365,
      0.555745,
      0.557267,
      0.55879,
      0.560312,
      0.561779,
      0.561835,
      0.563357,
      0.563704,
      0.564829,
      0.564879,
      0.566021,
      0.567543,
      0.568972,
      0.569066,
      0.570588,
      0.572019,
      0.572111,
      0.573633,
      0.57394,
      0.575032,
      0.575156,
      0.576678,
      0.578079,
      0.578201,
      0.579723,
      0.581246,
      0.582602,
      0.582768,
      0.584139,
      0.584291,
      0.585432,
      0.586955,
      0.588477,
      0.589999,
      0.590225,
      0.59131,
      0.591522,
      0.593044,
      0.594357,
      0.594566,
      0.595854,
      0.596089,
      0.597611,
      0.599134,
      0.600656,
      0.60193,
      0.602179,
      0.603425,
      0.603701,
      0.603904,
      0.607848,
      0.608269,
      0.609485,
      0.609791,
      0.61059,
      0.611168,
      0.611314,
      0.612456,
      0.613654,
      0.613979,
      0.614009,
      0.615501,
      0.616701,
      0.617024,
      0.618201,
      0.618528,
      0.618547,
      0.620069,
      0.621591,
      0.622761,
      0.623045,
      0.623114,
      0.624261,
      0.624637,
      0.625697,
      0.625779,
      0.626917,
      0.62693,
      0.627301,
      0.628823,
      0.630346,
      0.631703,
      0.631869,
      0.632977,
      0.632988,
      0.633391,
      0.634914,
      0.636022,
      0.63622,
      0.636437,
      0.636846,
      0.636916,
      0.637579,
      0.638667,
      0.638678,
      0.639102,
      0.640624,
      0.641849,
      0.642147,
      0.642553,
      0.643118,
      0.643289,
      0.644478,
      0.644812,
      0.646334,
      0.647394,
      0.647857,
      0.648378,
      0.649146,
      0.64938,
      0.650159,
      0.650522,
      0.651572,
      0.651646,
      0.652045,
      0.65256,
      0.653568,
      0.654607,
      0.655091,
      0.655473,
      0.656051,
      0.656233,
      0.657186,
      0.657195,
      0.657376,
      0.658899,
      0.659067,
      0.659071,
      0.659078,
      0.659577,
      0.659581,
      0.659661,
      0.65998,
      0.660174,
      0.660425,
      0.660425,
      0.660425,
      0.660426,
      0.660426,
      0.660233,
      0.660228,
      0.659661,
      0.659657,
      0.659287,
      0.658891,
      0.658311,
      0.658147,
      0.657746,
      0.657165,
      0.657006,
      0.655702,
      0.655701,
      0.655485,
      0.655067,
      0.654501,
      0.654345,
      0.65321,
      0.652823,
      0.65183,
      0.651683,
      0.651106,
      0.65035,
      0.650162,
      0.64864,
      0.648204,
      0.648199,
      0.647645,
      0.6475,
      0.645979,
      0.644786,
      0.644776,
      0.644457,
      0.642936,
      0.64248,
      0.641906,
      0.641795,
      0.64056,
      0.640274,
      0.638887,
      0.638753,
      0.637564,
      0.637231,
      0.63609,
      0.635452,
      0.634569,
      0.633269,
      0.633048,
      0.631526,
      0.631036,
      0.63103,
      0.630386,
      0.628864,
      0.628402,
      0.628366,
      0.62836,
      0.627724,
      0.626359,
      0.626202,
      0.624681,
      0.623318,
      0.62329,
      0.623159,
      0.622062,
      0.622019,
      0.620569,
      0.620498,
      0.619425,
      0.619357,
      0.61871,
      0.617897,
      0.617873,
      0.617836,
      0.616315,
      0.614818,
      0.614794,
      0.613722,
      0.613653,
      0.612157,
      0.612131,
      0.611454,
      0.610615,
      0.61061,
      0.608046,
      0.607947,
      0.609089,
      0.607304,
      0.606496,
      0.606427,
      0.605312,
      0.605286,
      0.605249,
      0.603764,
      0.602243,
      0.601487,
      0.601448,
      0.600753,
      0.600722,
      0.599581,
      0.599495,
      0.598059,
      0.596538,
      0.596491,
      0.593494,
      0.593875,
      0.595016,
      0.594768,
      0.591973,
      0.591347,
      0.590832,
      0.590769,
      0.589311,
      0.587789,
      0.587731,
      0.58761,
      0.586268,
      0.586175,
      0.584746,
      0.584097,
      0.583606,
      0.582084,
      0.582039,
      0.581999,
      0.580944,
      0.580129,
      0.580061,
      0.579423,
      0.579283,
      0.577902,
      0.57723,
      0.576761,
      0.576681,
      0.575944,
      0.57524,
      0.575192,
      0.574705,
      0.574481,
      0.574455,
      0.574406,
      0.573341,
      0.57333,
      0.573321,
      0.573241,
      0.573132,
      0.572963,
      0.572957,
      0.572947,
      0.572809,
      0.572584,
      0.572551,
      0.572323,
      0.572021,
      0.571825,
      0.571393,
      0.571358,
      0.571056,
      0.570685,
      0.57025,
      0.569835,
      0.569545,
      0.569033,
      0.569,
      0.568977,
      0.568786,
      0.567265,
      0.567173,
      0.567119,
      0.566124,
      0.565644,
      0.5653,
      0.564984,
      0.564387,
      0.564259,
      0.563844,
      0.563396,
      0.562704,
      0.562598,
      0.562306,
      0.561944,
      0.561909,
      0.5617,
      0.561558,
      0.561186,
      0.561138,
      0.560926,
      0.560859,
      0.560426,
      0.560424,
      0.5602,
      0.56018,
      0.559667,
      0.559285,
      0.559212,
      0.559172,
      0.558908,
      0.55889,
      0.558116,
      0.558105,
      0.557387,
      0.557331,
      0.557319,
      0.55715,
      0.556545,
      0.556533,
      0.556467,
      0.555866,
      0.555705,
      0.555688,
      0.554725,
      0.554658,
      0.554652,
      0.554646,
      0.554261,
      0.55426,
      0.554254,
      0.554221,
      0.553868,
      0.553862,
      0.553585,
      0.553476,
      0.55347,
      0.553084,
      0.552739,
      0.552699,
      0.552698,
      0.552692,
      0.552445,
      0.552235,
      0.551305,
      0.550993,
      0.550989,
      0.550984,
      0.550365,
      0.550284,
      0.550217,
      0.550212,
      0.550207,
      0.550203,
      0.550198,
      0.550193,
      0.549784,
      0.549516,
      0.549512,
      0.549509,
      0.548937,
      0.548934,
      0.548643,
      0.547916,
      0.547907,
      0.547122,
      0.546837,
      0.546715,
      0.546584,
      0.546577,
      0.545981,
      0.545721,
      0.545717,
      0.545714,
      0.54516,
      0.545156,
      0.545153,
      0.54484,
      0.544686,
      0.544684,
      0.544682,
      0.544679,
      0.544677,
      0.544675,
      0.544362,
      0.544321,
      0.544317,
      0.544315,
      0.544313,
      0.54431,
      0.544308,
      0.543948,
      0.543946,
      0.543944,
      0.543941,
      0.543939,
      0.5437,
      0.543579,
      0.543577,
      0.543575,
      0.543572,
      0.54357,
      0.543208,
      0.543206,
      0.543204,
      0.543201,
      0.54284,
      0.542837,
      0.542835,
      0.542832,
      0.54283,
      0.542799,
      0.542561,
      0.542425,
      0.542421,
      0.542418,
      0.542414,
      0.542411,
      0.541867,
      0.541864,
      0.54186,
      0.541857,
      0.54142,
      0.541349,
      0.541347,
      0.541344,
      0.541342,
      0.54134,
      0.540978,
      0.540975,
      0.540973,
      0.540971,
      0.540937,
      0.540896,
      0.540609,
      0.540606,
      0.540604,
      0.540602,
      0.540599,
      0.54028,
      0.540108,
      0.540099,
      0.540089,
      0.54008,
      0.538759,
      0.538725,
      0.538723,
      0.538721,
      0.538718,
      0.538716,
      0.538354,
      0.538352,
      0.538349,
      0.538347,
      0.538271,
      0.538231,
      0.53798,
      0.537978,
      0.537976,
      0.537619,
      0.536097,
      0.536068,
      0.53587,
      0.53536,
      0.534957,
      0.534911,
      0.533436,
      0.533352,
      0.532969,
      0.532295,
      0.532247,
      0.532122,
      0.531155,
      0.531013,
      0.529633,
      0.529553,
      0.529395,
      0.52931,
      0.528112,
      0.528068,
      0.527305,
      0.527231,
      0.527188,
      0.526972,
      0.525451,
      0.525373,
      0.524311,
      0.52406,
      0.523973,
      0.52279,
      0.522703,
      0.521649,
      0.521457,
      0.521391,
      0.520509,
      0.520467,
      0.520169,
      0.52014,
      0.51996,
      0.519749,
      0.518858,
      0.518814,
      0.51861,
      0.518565,
      0.51785,
      0.517781,
      0.517716,
      0.517674,
      0.51671,
      0.516662,
      0.516366,
      0.516338,
      0.516154,
      0.515951,
      0.51511,
      0.514811,
      0.514653,
      0.514531,
      0.51329,
      0.512981,
      0.512941,
      0.512914,
      0.51253,
      0.512359,
      0.511009,
      0.51095,
      0.510912,
      0.510886,
      0.51025,
      0.51011,
      0.510027,
      0.50997,
      0.50911,
      0.508964,
      0.508389,
      0.507969,
      0.508071,
      0.508096,
      0.508292,
      0.508732,
      0.508801,
      0.509088,
      0.509112,
      0.50931,
      0.509495,
      0.510467,
      0.510559,
      0.510626,
      0.511018,
      0.511747,
      0.512161,
      0.51232,
      0.512955,
      0.513002,
      0.513303,
      0.513464,
      0.514446,
      0.514589,
      0.514642,
      0.515099,
      0.515189,
      0.51597,
      0.516297,
      0.517112,
      0.517547,
      0.517686,
      0.517801,
      0.518635,
      0.519612,
      0.519681,
      0.520158,
      0.520593,
      0.52168,
      0.522175,
      0.522823,
      0.523036,
      0.523099,
      0.524346,
      0.524772,
      0.524986,
      0.525868,
      0.526407,
      0.527391,
      0.527703,
      0.528533,
      0.52895,
      0.529065,
      0.530056,
      0.530473,
      0.530659,
      0.531578,
      0.533101,
      0.534623,
      0.536145,
      0.537667,
      0.539189,
      0.540711,
      0.541073,
      0.541177,
      0.542234,
      0.543756,
      0.543932,
      0.544743,
      0.544776,
      0.545279,
      0.546801,
      0.547142,
      0.548324,
      0.548665,
      0.548757,
      0.549847,
      0.550326,
      0.551369,
      0.551617,
      0.551688,
      0.552512,
      0.554034,
      0.555556,
      0.557078,
      0.5586,
      0.558895,
      0.558987,
      0.560123,
      0.561645,
      0.563167,
      0.563444,
      0.563561,
      0.56469,
      0.566212,
      0.567734,
      0.569257,
      0.570779,
      0.572301,
      0.572529,
      0.572614,
      0.573823,
      0.575345,
      0.576868,
      0.577077,
      0.577176,
      0.57839,
      0.579912,
      0.580113,
      0.580186,
      0.581435,
      0.582957,
      0.58448,
      0.586002,
      0.586172,
      0.587524,
      0.589046,
      0.590568,
      0.590785,
      0.590794,
      0.592091,
      0.593613,
      0.593747,
      0.595136,
      0.595283,
      0.596278,
      0.596402,
      0.596465,
      0.597801,
      0.599323,
      0.600845,
      0.601013,
      0.602368,
      0.602462,
      0.603891,
      0.605413,
      0.605542,
      0.606935,
      0.608457,
      0.609979,
      0.611502,
      0.613024,
      0.613125,
      0.614546,
      0.616068,
      0.61759,
      0.619112,
      0.620635,
      0.62067,
      0.622142,
      0.622158,
      0.622208,
      0.623646,
      0.62368,
      0.624822,
      0.626345,
      0.6278,
      0.627856,
      0.627867,
      0.627888,
      0.629305,
      0.62939,
      0.630809,
      0.630913,
      0.630922,
      0.632313,
      0.632394,
      0.632435,
      0.633958,
      0.634991,
      0.6351,
      0.636459,
      0.636604,
      0.636623,
      0.637963,
      0.638065,
      0.638145,
      0.639467,
      0.639636,
      0.639668,
      0.640972,
      0.641191,
      0.642476,
      0.642603,
      0.642672,
      0.642713,
      0.64398,
      0.644236,
      0.645167,
      0.645174,
      0.64534,
      0.645378,
      0.646603,
      0.646901,
      0.647502,
      0.647506,
      0.647591,
      0.648256,
      0.648393,
      0.648424,
      0.649585,
      0.649594,
      0.649946,
      0.650377,
      0.650457,
      0.650943,
      0.651062,
      0.651089,
      0.652612,
      0.65372,
      0.654051,
      0.654134,
      0.655224,
      0.655461,
      0.655657,
      0.656463,
      0.656732,
      0.656799,
      0.65733,
      0.658084,
      0.658214,
      0.658322,
      0.659035,
      0.659598,
      0.659846,
      0.660227,
      0.66038,
      0.6609,
      0.660988,
      0.661934,
      0.662093,
      0.662131,
      0.662609,
      0.663072,
      0.663274,
      0.663805,
      0.664416,
      0.664659,
      0.664731,
      0.665151,
      0.66556,
      0.66556,
      0.665561,
      0.665561,
      0.665561,
      0.664638,
      0.664225,
      0.664073,
      0.664041,
      0.663347,
      0.663281,
      0.662922,
      0.662657,
      0.662207,
      0.662141,
      0.661026,
      0.661001,
      0.660507,
      0.659604,
      0.65948,
      0.658536,
      0.658358,
      0.658339,
      0.65786,
      0.657199,
      0.655932,
      0.655693,
      0.655678,
      0.655194,
      0.654538,
      0.653973,
      0.653583,
      0.65348,
      0.653397,
      0.651876,
      0.650355,
      0.650351,
      0.649962,
      0.649861,
      0.649214,
      0.648147,
      0.648074,
      0.648048,
      0.647004,
      0.646552,
      0.645031,
      0.643509,
      0.64348,
      0.642833,
      0.642201,
      0.641989,
      0.640848,
      0.640164,
      0.640046,
      0.639327,
      0.638487,
      0.638186,
      0.637495,
      0.636665,
      0.636625,
      0.636242,
      0.635524,
      0.634124,
      0.634003,
      0.633939,
      0.63267,
      0.632482,
      0.62964,
      0.629438,
      0.63096,
      0.627917,
      0.627854,
      0.626906,
      0.626776,
      0.625332,
      0.625255,
      0.623733,
      0.623562,
      0.621344,
      0.621118,
      0.621071,
      0.622593,
      0.619549,
      0.619437,
      0.618171,
      0.618048,
      0.618028,
      0.616991,
      0.616888,
      0.616643,
      0.615366,
      0.613861,
      0.613845,
      0.612563,
      0.612323,
      0.60928,
      0.610802,
      0.61023,
      0.608171,
      0.607758,
      0.607751,
      0.606688,
      0.606618,
      0.605096,
      0.604775,
      0.603738,
      0.603575,
      0.603535,
      0.602053,
      0.601015,
      0.600912,
      0.599512,
      0.599391,
      0.59936,
      0.599212,
      0.597921,
      0.59787,
      0.596432,
      0.596349,
      0.595208,
      0.595166,
      0.595016,
      0.59448,
      0.593687,
      0.593134,
      0.593081,
      0.59297,
      0.592546,
      0.591053,
      0.591025,
      0.589503,
      0.589461,
      0.588935,
      0.588778,
      0.588363,
      0.586841,
      0.586838,
      0.58681,
      0.586075,
      0.585974,
      0.585701,
      0.585082,
      0.584561,
      0.584344,
      0.583778,
      0.58304,
      0.583017,
      0.582714,
      0.582526,
      0.582281,
      0.581694,
      0.581656,
      0.58114,
      0.581128,
      0.580992,
      0.580115,
      0.580076,
      0.57962,
      0.579545,
      0.579362,
      0.57911,
      0.578861,
      0.578802,
      0.578693,
      0.578282,
      0.57772,
      0.577336,
      0.5773,
      0.577227,
      0.576957,
      0.57658,
      0.576506,
      0.576326,
      0.5763,
      0.576074,
      0.575821,
      0.575746,
      0.575568,
      0.575287,
      0.575063,
      0.575062,
      0.574987,
      0.574558,
      0.574527,
      0.574302,
      0.574228,
      0.573798,
      0.573723,
      0.573543,
      0.573532,
      0.573421,
      0.57326,
      0.573249,
      0.573165,
      0.573093,
      0.57263,
      0.572407,
      0.572405,
      0.572334,
      0.571646,
      0.571614,
      0.571566,
      0.571362,
      0.570997,
      0.570759,
      0.570507,
      0.570021,
      0.569747,
      0.569367,
      0.569322,
      0.568881,
      0.568607,
      0.568227,
      0.568181,
      0.567848,
      0.567741,
      0.56747,
      0.567467,
      0.566904,
      0.566827,
      0.566737,
      0.566335,
      0.566327,
      0.565771,
      0.565767,
      0.565203,
      0.565186,
      0.565113,
      0.564635,
      0.564454,
      0.564067,
      0.564046,
      0.563559,
      0.562965,
      0.562887,
      0.562543,
      0.56254,
      0.562536,
      0.562533,
      0.562526,
      0.56139,
      0.561385,
      0.561383,
      0.561379,
      0.561374,
      0.56137,
      0.561365,
      0.560597,
      0.560593,
      0.560588,
      0.560584,
      0.560579,
      0.560574,
      0.56057,
      0.560516,
      0.560401,
      0.559864,
      0.559818,
      0.559814,
      0.559811,
      0.559807,
      0.559804,
      0.559229,
      0.559225,
      0.559222,
      0.559218,
      0.558723,
      0.558616,
      0.558612,
      0.558607,
      0.558603,
      0.558497,
      0.557849,
      0.557835,
      0.55783,
      0.557826,
      0.557821,
      0.557816,
      0.557812,
      0.557202,
      0.557084,
      0.557081,
      0.557077,
      0.557074,
      0.556499,
      0.556495,
      0.556492,
      0.556488,
      0.556459,
      0.556062,
      0.555943,
      0.555863,
      0.555859,
      0.555854,
      0.555849,
      0.555082,
      0.555077,
      0.555073,
      0.555068,
      0.555063,
      0.554541,
      0.554357,
      0.554354,
      0.55435,
      0.554347,
      0.553878,
      0.553794,
      0.553772,
      0.553769,
      0.553765,
      0.553762,
      0.553401,
      0.553187,
      0.553183,
      0.55318,
      0.553176,
      0.552601,
      0.552598,
      0.552594,
      0.552591,
      0.552587,
      0.552584,
      0.55226,
      0.55202,
      0.551925,
      0.55192,
      0.551915,
      0.551911,
      0.551906,
      0.551371,
      0.551139,
      0.550739,
      0.550001,
      0.549218,
      0.548936,
      0.548545,
      0.54846,
      0.548363,
      0.548077,
      0.547341,
      0.547332,
      0.547322,
      0.546556,
      0.545857,
      0.545847,
      0.545838,
      0.545829,
      0.545819,
      0.545034,
      0.544363,
      0.544354,
      0.544345,
      0.544335,
      0.543513,
      0.543401,
      0.543315,
      0.543276,
      0.543273,
      0.543269,
      0.543266,
      0.543262,
      0.542719,
      0.542715,
      0.542712,
      0.542708,
      0.542372,
      0.541825,
      0.541815,
      0.541806,
      0.541797,
      0.541788,
      0.541778,
      0.540851,
      0.540734,
      0.540664,
      0.54066,
      0.540657,
      0.540653,
      0.54065,
      0.540648,
      0.540646,
      0.54011,
      0.540106,
      0.540103,
      0.540099,
      0.540096,
      0.53971,
      0.539499,
      0.539494,
      0.53949,
      0.539485,
      0.53879,
      0.53875,
      0.538746,
      0.538741,
      0.538189,
      0.538098,
      0.538096,
      0.538093,
      0.538091,
      0.538052,
      0.537729,
      0.537727,
      0.537724,
      0.537722,
      0.53772,
      0.53743,
      0.53729,
      0.537286,
      0.537281,
      0.537276,
      0.537272,
      0.536546,
      0.536541,
      0.536537,
      0.536532,
      0.536504,
      0.53639,
      0.535909,
      0.535806,
      0.535802,
      0.535797,
      0.535793,
      0.535788,
      0.535067,
      0.535062,
      0.535058,
      0.535053,
      0.535048,
      0.534388,
      0.534276,
      0.534269,
      0.534131,
      0.533247,
      0.532669,
      0.531725,
      0.531623,
      0.530204,
      0.530164,
      0.529496,
      0.529063,
      0.529003,
      0.528765,
      0.528235,
      0.527542,
      0.527487,
      0.527401,
      0.526745,
      0.526402,
      0.526361,
      0.525978,
      0.525926,
      0.525262,
      0.525192,
      0.525032,
      0.524122,
      0.524054,
      0.523992,
      0.523409,
      0.523036,
      0.522601,
      0.522453,
      0.521461,
      0.521373,
      0.521224,
      0.52074,
      0.52032,
      0.520279,
      0.519708,
      0.519561,
      0.519464,
      0.519408,
      0.518421,
      0.517822,
      0.517776,
      0.517698,
      0.5169,
      0.516792,
      0.51576,
      0.515689,
      0.51565,
      0.515456,
      0.515001,
      0.514922,
      0.514895,
      0.514836,
      0.513861,
      0.513779,
      0.513754,
      0.513313,
      0.513016,
      0.512721,
      0.511987,
      0.51158,
      0.511491,
      0.51141,
      0.510728,
      0.510711,
      0.510441,
      0.510322,
      0.510303,
      0.510224,
      0.510133,
      0.510062,
      0.509823,
      0.509786,
      0.509628,
      0.509304,
      0.509338,
      0.509468,
      0.509489,
      0.509593,
      0.509686,
      0.510656,
      0.511209,
      0.511368,
      0.511464,
      0.511937,
      0.512352,
      0.513874,
      0.514275,
      0.514284,
      0.514529,
      0.515397,
      0.515603,
      0.516367,
      0.51692,
      0.517206,
      0.517235,
      0.517393,
      0.518062,
      0.519584,
      0.521107,
      0.521258,
      0.521832,
      0.521902,
      0.52225,
      0.522595,
      0.523772,
      0.524176,
      0.524346,
      0.525295,
      0.525447,
      0.525987,
      0.52602,
      0.526075,
      0.526438,
      0.52796,
      0.529482,
      0.529876,
      0.531005,
      0.531203,
      0.531264,
      0.531908,
      0.531967,
      0.532528,
      0.53405,
      0.534541,
      0.535573,
      0.537095,
      0.538617,
      0.538892,
      0.539759,
      0.539875,
      0.539943,
      0.539984,
      0.540707,
      0.541283,
      0.542805,
      0.543171,
      0.544327,
      0.545849,
      0.546252,
      0.547372,
      0.547462,
      0.547546,
      0.54831,
      0.548325,
      0.548895,
      0.550417,
      0.551939,
      0.553461,
      0.554984,
      0.556506,
      0.558028,
      0.55955,
      0.559652,
      0.559841,
      0.559854,
      0.561073,
      0.561363,
      0.562596,
      0.563737,
      0.564009,
      0.56402,
      0.56526,
      0.565324,
      0.566782,
      0.568304,
      0.569827,
      0.57008,
      0.571349,
      0.572871,
      0.572976,
      0.573753,
      0.574394,
      0.574399,
      0.574586,
      0.574639,
      0.575917,
      0.577439,
      0.578282,
      0.578315,
      0.57895,
      0.578962,
      0.580104,
      0.581627,
      0.58176,
      0.581843,
      0.583149,
      0.584671,
      0.586194,
      0.587716,
      0.589238,
      0.589316,
      0.589415,
      0.590662,
      0.590761,
      0.592283,
      0.592336,
      0.593805,
      0.595328,
      0.59685,
      0.598372,
      0.599894,
      0.600016,
      0.601405,
      0.601416,
      0.602938,
      0.604461,
      0.605983,
      0.6073,
      0.607505,
      0.609027,
      0.609101,
      0.610474,
      0.61055,
      0.61198,
      0.612073,
      0.613595,
      0.613648,
      0.614864,
      0.615117,
      0.616639,
      0.618024,
      0.618162,
      0.618677,
      0.618744,
      0.619305,
      0.620537,
      0.620657,
      0.620827,
      0.622161,
      0.62235,
      0.62387,
      0.623873,
      0.625073,
      0.625179,
      0.625395,
      0.626917,
      0.628196,
      0.628403,
      0.62844,
      0.629962,
      0.631485,
      0.633007,
      0.633934,
      0.634149,
      0.635285,
      0.635368,
      0.635584,
      0.635672,
      0.637194,
      0.638716,
      0.640238,
      0.641411,
      0.641761,
      0.642848,
      0.643081,
      0.643284,
      0.644431,
      0.644585,
      0.644806,
      0.645935,
      0.646329,
      0.647384,
      0.647605,
      0.647852,
      0.648266,
      0.648325,
      0.648834,
      0.648994,
      0.650516,
      0.651749,
      0.652039,
      0.653057,
      0.653099,
      0.653253,
      0.653562,
      0.654757,
      0.655084,
      0.655861,
      0.65597,
      0.656226,
      0.657217,
      0.657245,
      0.65739,
      0.657749,
      0.658749,
      0.658875,
      0.658885,
      0.658894,
      0.659272,
      0.659818,
      0.659823,
      0.659828,
      0.660523,
      0.660573,
      0.660795,
      0.661515,
      0.66152,
      0.661593,
      0.661937,
      0.662983,
      0.66346,
      0.6644,
      0.664487,
      0.664982,
      0.665449,
      0.666204,
      0.666249,
      0.666506,
      0.667411,
      0.668028,
      0.668694,
      0.668767,
      0.66917,
      0.670073,
      0.670693,
      0.671554,
      0.671653,
      0.672216,
      0.673738,
      0.674053,
      0.674066,
      0.67409,
      0.67462,
      0.674881,
      0.675289,
      0.676102,
      0.676404,
      0.676725,
      0.677273,
      0.677547,
      0.677743,
      0.678121,
      0.678139,
      0.678154,
      0.67869,
      0.67888,
      0.679639,
      0.67966,
      0.679676,
      0.679833,
      0.679457,
      0.679205,
      0.679189,
      0.679177,
      0.679074,
      0.6787,
      0.678315,
      0.677954,
      0.677897,
      0.67786,
      0.676794,
      0.676612,
      0.676204,
      0.676034,
      0.675763,
      0.675687,
      0.674893,
      0.674532,
      0.674468,
      0.673372,
      0.67283,
      0.672662,
      0.672231,
      0.671389,
      0.671336,
      0.671091,
      0.669849,
      0.66957,
      0.668855,
      0.668689,
      0.668049,
      0.66721,
      0.666908,
      0.666417,
      0.665789,
      0.665701,
      0.665387,
      0.663866,
      0.663594,
      0.663492,
      0.662955,
      0.662725,
      0.6616,
      0.661204,
      0.66066,
      0.660444,
      0.660063,
      0.659702,
      0.6596,
      0.658943,
      0.658542,
      0.657422,
      0.657313,
      0.657268,
      0.657022,
      0.655881,
      0.65561,
      0.655493,
      0.654955,
      0.654741,
      0.654014,
      0.653219,
      0.652375,
      0.652288,
      0.652255,
      0.652079,
      0.650953,
      0.650558,
      0.65016,
      0.649622,
      0.649417,
      0.649049,
      0.647897,
      0.647153,
      0.646906,
      0.646818,
      0.646375,
      0.645526,
      0.645235,
      0.642191,
      0.643713,
      0.641673,
      0.641481,
      0.64067,
      0.639995,
      0.638007,
      0.639149,
      0.636998,
      0.636259,
      0.635974,
      0.634963,
      0.636486,
      0.633442,
      0.633044,
      0.632089,
      0.631921,
      0.631119,
      0.630842,
      0.630399,
      0.628878,
      0.628269,
      0.627967,
      0.627737,
      0.627333,
      0.626425,
      0.626216,
      0.624976,
      0.624695,
      0.623975,
      0.622378,
      0.622032,
      0.623554,
      0.621063,
      0.619725,
      0.618988,
      0.620511,
      0.617467,
      0.617042,
      0.616816,
      0.616126,
      0.615946,
      0.61423,
      0.613278,
      0.612902,
      0.614425,
      0.612065,
      0.611761,
      0.611327,
      0.611167,
      0.61108,
      0.610241,
      0.609806,
      0.60955,
      0.60872,
      0.608393,
      0.608268,
      0.608196,
      0.607579,
      0.606705,
      0.60616,
      0.606058,
      0.605601,
      0.60516,
      0.60495,
      0.604917,
      0.604482,
      0.603431,
      0.603396,
      0.60307,
      0.602935,
      0.602848,
      0.602502,
      0.602256,
      0.601062,
      0.600874,
      0.600755,
      0.600735,
      0.599594,
      0.59916,
      0.598087,
      0.598073,
      0.597747,
      0.597602,
      0.596933,
      0.596715,
      0.596559,
      0.596237,
      0.595957,
      0.5958,
      0.595793,
      0.594931,
      0.594271,
      0.593898,
      0.593676,
      0.593572,
      0.593138,
      0.593131,
      0.592236,
      0.592002,
      0.591991,
      0.59167,
      0.59162,
      0.590861,
      0.590851,
      0.590524,
      0.590366,
      0.589726,
      0.589711,
      0.588811,
      0.588586,
      0.58857,
      0.588245,
      0.588203,
      0.58759,
      0.587443,
      0.58743,
      0.586995,
      0.586434,
      0.586122,
      0.58591,
      0.584808,
      0.584769,
      0.584273,
      0.583629,
      0.583179,
      0.582891,
      0.582108,
      0.581439,
      0.581369,
      0.580893,
      0.580587,
      0.579512,
      0.57946,
      0.579446,
      0.578989,
      0.577925,
      0.57701,
      0.576845,
      0.576794,
      0.576785,
      0.575264,
      0.574343,
      0.574177,
      0.574123,
      0.573364,
      0.572602,
      0.572248,
      0.571511,
      0.571462,
      0.57146,
      0.570959,
      0.570956,
      0.570454,
      0.57013,
      0.569982,
      0.569952,
      0.569949,
      0.569941,
      0.569386,
      0.568817,
      0.568801,
      0.568249,
      0.567869,
      0.567703,
      0.567681,
      0.567661,
      0.56614,
      0.5652,
      0.565036,
      0.565013,
      0.564999,
      0.564231,
      0.564229,
      0.564227,
      0.563991,
      0.563883,
      0.563859,
      0.563836,
      0.563832,
      0.563829,
      0.563825,
      0.56325,
      0.563247,
      0.563244,
      0.56324,
      0.562719,
      0.562683,
      0.562681,
      0.562678,
      0.562296,
      0.562293,
      0.562291,
      0.562289,
      0.562088,
      0.56198,
      0.561906,
      0.561903,
      0.561901,
      0.561579,
      0.561488,
      0.561484,
      0.561481,
      0.561477,
      0.560902,
      0.560899,
      0.560895,
      0.560892,
      0.560627,
      0.560439,
      0.560276,
      0.560272,
      0.560267,
      0.560262,
      0.560258,
      0.560253,
      0.559714,
      0.559486,
      0.559481,
      0.559476,
      0.559472,
      0.559467,
      0.558918,
      0.557777,
      0.557499,
      0.557495,
      0.55749,
      0.557255,
      0.557045,
      0.556723,
      0.556718,
      0.556256,
      0.555423,
      0.555289,
      0.555115,
      0.554758,
      0.554753,
      0.554376,
      0.553986,
      0.553595,
      0.553218,
      0.553057,
      0.552451,
      0.552074,
      0.551521,
      0.551514,
      0.551507,
      0.5515,
      0.55095,
      0.550933,
      0.550584,
      0.55058,
      0.550575,
      0.55057,
      0.550566,
      0.549835,
      0.549831,
      0.549826,
      0.549622,
      0.549412,
      0.548846,
      0.548271,
      0.547989,
      0.547984,
      0.54798,
      0.547714,
      0.547245,
      0.54724,
      0.547236,
      0.547231,
      0.54675,
      0.546259,
      0.546249,
      0.54624,
      0.546231,
      0.546221,
      0.545229,
      0.545226,
      0.545056,
      0.545052,
      0.545049,
      0.545045,
      0.544498,
      0.544495,
      0.544491,
      0.544488,
      0.544231,
      0.544088,
      0.543882,
      0.543877,
      0.542567,
      0.54256,
      0.542455,
      0.542452,
      0.542448,
      0.542134,
      0.541901,
      0.541898,
      0.541894,
      0.541891,
      0.541887,
      0.541427,
      0.54132,
      0.541316,
      0.541311,
      0.541306,
      0.541302,
      0.54065,
      0.540576,
      0.540571,
      0.540567,
      0.540562,
      0.540081,
      0.539906,
      0.53985,
      0.539847,
      0.539843,
      0.53984,
      0.539289,
      0.539286,
      0.539282,
      0.538766,
      0.538721,
      0.538178,
      0.53815,
      0.53775,
      0.537625,
      0.537559,
      0.536104,
      0.535991,
      0.535201,
      0.534963,
      0.5349,
      0.534796,
      0.533255,
      0.531715,
      0.530399,
      0.530366,
      0.530316,
      0.529934,
      0.529258,
      0.52914,
      0.527737,
      0.527699,
      0.527266,
      0.527071,
      0.526597,
      0.525701,
      0.5252,
      0.525076,
      0.525032,
      0.524398,
      0.523936,
      0.523111,
      0.523024,
      0.52253,
      0.522415,
      0.520893,
      0.519752,
      0.519586,
      0.519095,
      0.518915,
      0.518231,
      0.51753,
      0.517091,
      0.516757,
      0.51667,
      0.516621,
      0.515951,
      0.51592,
      0.51584,
      0.515685,
      0.515572,
      0.515524,
      0.515404,
      0.515172,
      0.515144,
      0.514813,
      0.514746,
      0.514587,
      0.514278,
      0.514238,
      0.514054,
      0.513884,
      0.513811,
      0.513463,
      0.513431,
      0.513314,
      0.513295,
      0.513296,
      0.513297,
      0.513297,
      0.513297,
      0.513297,
      0.513298,
      0.513174,
      0.513098,
      0.513022,
      0.512984,
      0.512919,
      0.513422,
      0.513647,
      0.513725,
      0.513805,
      0.514063,
      0.514188,
      0.514825,
      0.514951,
      0.51517,
      0.515246,
      0.515333,
      0.515968,
      0.516161,
      0.516484,
      0.516734,
      0.517111,
      0.517167,
      0.517878,
      0.518254,
      0.518776,
      0.519613,
      0.519776,
      0.520601,
      0.520798,
      0.521299,
      0.52264,
      0.522822,
      0.524344,
      0.524533,
      0.525107,
      0.525487,
      0.525584,
      0.526807,
      0.527009,
      0.527514,
      0.528532,
      0.530054,
      0.530303,
      0.530848,
      0.531464,
      0.531577,
      0.531948,
      0.532719,
      0.533215,
      0.534242,
      0.534737,
      0.535521,
      0.535765,
      0.535781,
      0.536773,
      0.537288,
      0.537781,
      0.538555,
      0.538811,
      0.539952,
      0.540438,
      0.54147,
      0.541475,
      0.542723,
      0.542997,
      0.543474,
      0.54452,
      0.546043,
      0.547565,
      0.548021,
      0.548783,
      0.549037,
      0.549087,
      0.549544,
      0.55061,
      0.551066,
      0.552133,
      0.552584,
      0.552875,
      0.553246,
      0.553275,
      0.553732,
      0.554798,
      0.555394,
      0.555786,
      0.556273,
      0.556321,
      0.556777,
      0.557844,
      0.558286,
      0.558585,
      0.558987,
      0.559443,
      0.560387,
      0.560509,
      0.561383,
      0.561651,
      0.562101,
      0.563174,
      0.564697,
      0.566219,
      0.566647,
      0.56735,
      0.567573,
      0.567741,
      0.569263,
      0.570786,
      0.570992,
      0.572102,
      0.572309,
      0.573831,
      0.57423,
      0.57514,
      0.575354,
      0.576496,
      0.576888,
      0.577574,
      0.578019,
      0.579541,
      0.579922,
      0.580815,
      0.581064,
      0.582586,
      0.584108,
      0.585631,
      0.587153,
      0.588675,
      0.589684,
      0.590198,
      0.59172,
      0.592031,
      0.593242,
      0.594764,
      0.595946,
      0.596287,
      0.597809,
      0.599331,
      0.599589,
      0.600283,
      0.600854,
      0.602376,
      0.603899,
      0.60412,
      0.604829,
      0.605421,
      0.606563,
      0.606761,
      0.607673,
      0.608085,
      0.609608,
      0.61113,
      0.611291,
      0.612024,
      0.612653,
      0.612796,
      0.614175,
      0.615697,
      0.61722,
      0.617316,
      0.617326,
      0.618265,
      0.618742,
      0.620264,
      0.621786,
      0.623308,
      0.623359,
      0.624135,
      0.624831,
      0.626353,
      0.627875,
      0.627889,
      0.629393,
      0.629398,
      0.63092,
      0.631705,
      0.631881,
      0.632413,
      0.632443,
      0.633965,
      0.635487,
      0.637009,
      0.638531,
      0.639968,
      0.640054,
      0.641576,
      0.643098,
      0.64462,
      0.646002,
      0.646011,
      0.646142,
      0.647664,
      0.648356,
      0.649019,
      0.649187,
      0.650709,
      0.65155,
      0.652036,
      0.652232,
      0.653754,
      0.655053,
      0.655277,
      0.656799,
      0.657439,
      0.65807,
      0.658321,
      0.659263,
      0.659463,
      0.660251,
      0.6607,
      0.660986,
      0.662207,
      0.662509,
      0.663117,
      0.663711,
      0.664031,
      0.665215,
      0.665554,
      0.666305,
      0.66672,
      0.667077,
      0.668599,
      0.669172,
      0.669737,
      0.670121,
      0.671643,
      0.673165,
      0.674688,
      0.675779,
      0.67621,
      0.677732,
      0.678532,
      0.678874,
      0.679545,
      0.679922,
      0.680397,
      0.680901,
      0.68192,
      0.682942,
      0.683442,
      0.684447,
      0.684965,
      0.685204,
      0.685715,
      0.686108,
      0.686601,
      0.687631,
      0.687806,
      0.687863,
      0.688774,
      0.688935,
      0.689105,
      0.689384,
      0.689536,
      0.689848,
      0.689976,
      0.690185,
      0.6903,
      0.690424,
      0.690456,
      0.690625,
      0.690682,
      0.690683,
      0.690683,
      0.690684,
      0.690533,
      0.690187,
      0.689683,
      0.689544,
      0.688832,
      0.688369,
      0.688024,
      0.687657,
      0.686883,
      0.686697,
      0.686647,
      0.685743,
      0.684743,
      0.684222,
      0.684028,
      0.683978,
      0.683081,
      0.682068,
      0.68156,
      0.681019,
      0.680038,
      0.679699,
      0.678517,
      0.678093,
      0.677737,
      0.677376,
      0.676677,
      0.675855,
      0.674333,
      0.674035,
      0.67304,
      0.672812,
      0.672197,
      0.672081,
      0.671291,
      0.6694,
      0.668628,
      0.67015,
      0.667864,
      0.666852,
      0.665585,
      0.667107,
      0.665188,
      0.664444,
      0.662854,
      0.662193,
      0.661401,
      0.662923,
      0.659879,
      0.659164,
      0.658358,
      0.657526,
      0.656836,
      0.656281,
      0.655967,
      0.655695,
      0.654174,
      0.653744,
      0.65303,
      0.652827,
      0.652653,
      0.651131,
      0.650836,
      0.650228,
      0.649991,
      0.648795,
      0.648469,
      0.647656,
      0.646948,
      0.64603,
      0.645426,
      0.643738,
      0.642383,
      0.643905,
      0.642825,
      0.641819,
      0.641743,
      0.641242,
      0.639721,
      0.638456,
      0.638199,
      0.637864,
      0.637833,
      0.637059,
      0.63577,
      0.635537,
      0.635079,
      0.635037,
      0.634124,
      0.634016,
      0.632998,
      0.632495,
      0.632142,
      0.631998,
      0.631354,
      0.630313,
      0.630008,
      0.629833,
      0.62935,
      0.62839,
      0.628312,
      0.627515,
      0.627171,
      0.626678,
      0.626479,
      0.625712,
      0.62565,
      0.624562,
      0.624129,
      0.623368,
      0.623063,
      0.622988,
      0.621671,
      0.621507,
      0.621467,
      0.62043,
      0.619945,
      0.619096,
      0.618848,
      0.618804,
      0.617283,
      0.616218,
      0.615794,
      0.615761,
      0.613412,
      0.612718,
      0.61424,
      0.611196,
      0.611191,
      0.609675,
      0.608563,
      0.608463,
      0.608153,
      0.60813,
      0.607012,
      0.606602,
      0.605982,
      0.605622,
      0.605492,
      0.605025,
      0.604351,
      0.604293,
      0.60283,
      0.602399,
      0.602354,
      0.601689,
      0.601622,
      0.600401,
      0.600168,
      0.598646,
      0.598567,
      0.597467,
      0.597125,
      0.594461,
      0.594081,
      0.595603,
      0.595526,
      0.594194,
      0.593094,
      0.592941,
      0.592639,
      0.592538,
      0.591841,
      0.591801,
      0.591769,
      0.5912,
      0.590774,
      0.590661,
      0.590317,
      0.590261,
      0.589883,
      0.589554,
      0.589521,
      0.58906,
      0.588924,
      0.58838,
      0.58837,
      0.588147,
      0.587864,
      0.587668,
      0.587639,
      0.587621,
      0.587227,
      0.586504,
      0.586481,
      0.586468,
      0.58617,
      0.585742,
      0.585709,
      0.585341,
      0.585134,
      0.584847,
      0.584827,
      0.584582,
      0.58427,
      0.583836,
      0.58381,
      0.583442,
      0.582888,
      0.582402,
      0.582302,
      0.582054,
      0.582039,
      0.581586,
      0.581542,
      0.581042,
      0.581016,
      0.580022,
      0.579547,
      0.579452,
      0.578882,
      0.578857,
      0.578307,
      0.577833,
      0.577741,
      0.577717,
      0.577149,
      0.576601,
      0.576591,
      0.576586,
      0.576583,
      0.576081,
      0.575662,
      0.575579,
      0.575576,
      0.575573,
      0.57508,
      0.575073,
      0.574509,
      0.574505,
      0.573941,
      0.57394,
      0.573917,
      0.573449,
      0.5728,
      0.572762,
      0.572033,
      0.571376,
      0.571279,
      0.57127,
      0.571266,
      0.570691,
      0.570688,
      0.570685,
      0.570673,
      0.570138,
      0.5701,
      0.570095,
      0.570091,
      0.569468,
      0.569323,
      0.569319,
      0.569314,
      0.568617,
      0.568532,
      0.568511,
      0.568504,
      0.568497,
      0.567476,
      0.567388,
      0.567383,
      0.567379,
      0.56665,
      0.566611,
      0.566607,
      0.566602,
      0.566034,
      0.565955,
      0.565713,
      0.565704,
      0.565694,
      0.564434,
      0.564154,
      0.564145,
      0.564136,
      0.562912,
      0.562749,
      0.562595,
      0.562586,
      0.562577,
      0.562568,
      0.561517,
      0.561391,
      0.561255,
      0.561252,
      0.561248,
      0.561245,
      0.561241,
      0.561238,
      0.560663,
      0.560659,
      0.560656,
      0.560652,
      0.560649,
      0.560645,
      0.56025,
      0.560181,
      0.56007,
      0.560067,
      0.560063,
      0.56006,
      0.560056,
      0.560053,
      0.559478,
      0.559475,
      0.559471,
      0.559468,
      0.559464,
      0.55915,
      0.55911,
      0.55901,
      0.558815,
      0.55881,
      0.558806,
      0.558801,
      0.558797,
      0.558029,
      0.558024,
      0.55802,
      0.558015,
      0.558011,
      0.557589,
      0.557416,
      0.557414,
      0.557412,
      0.557232,
      0.557155,
      0.557029,
      0.556648,
      0.556449,
      0.556089,
      0.555728,
      0.555572,
      0.55534,
      0.555336,
      0.555331,
      0.554928,
      0.554429,
      0.554422,
      0.554415,
      0.554408,
      0.553787,
      0.553179,
      0.55317,
      0.553161,
      0.553151,
      0.553142,
      0.553133,
      0.553123,
      0.552266,
      0.552003,
      0.551686,
      0.551676,
      0.551667,
      0.551658,
      0.550791,
      0.550744,
      0.550295,
      0.549838,
      0.549613,
      0.549604,
      0.549462,
      0.54946,
      0.549152,
      0.549093,
      0.549091,
      0.549088,
      0.549086,
      0.549084,
      0.54885,
      0.548722,
      0.548719,
      0.548717,
      0.548715,
      0.548465,
      0.548293,
      0.54829,
      0.548286,
      0.548283,
      0.547779,
      0.547743,
      0.547739,
      0.547736,
      0.547732,
      0.547729,
      0.547725,
      0.547327,
      0.547324,
      0.547195,
      0.547192,
      0.547188,
      0.547185,
      0.547182,
      0.547178,
      0.547175,
      0.546645,
      0.546641,
      0.546638,
      0.546634,
      0.546631,
      0.546627,
      0.546184,
      0.546064,
      0.54606,
      0.546055,
      0.54605,
      0.546046,
      0.546041,
      0.546021,
      0.545418,
      0.545329,
      0.545325,
      0.54532,
      0.545315,
      0.545311,
      0.545306,
      0.544663,
      0.544372,
      0.544356,
      0.544339,
      0.542,
      0.543522,
      0.543461,
      0.542818,
      0.540478,
      0.540283,
      0.538957,
      0.537435,
      0.537398,
      0.537325,
      0.53724,
      0.535914,
      0.535846,
      0.535736,
      0.535609,
      0.534773,
      0.533987,
      0.53395,
      0.533908,
      0.533634,
      0.532112,
      0.531494,
      0.531438,
      0.531375,
      0.530972,
      0.52945,
      0.527929,
      0.527775,
      0.527623,
      0.527456,
      0.526407,
      0.524886,
      0.523364,
      0.522856,
      0.521843,
      0.521794,
      0.521756,
      0.521083,
      0.520908,
      0.520507,
      0.520457,
      0.519562,
      0.519427,
      0.519127,
      0.51909,
      0.518802,
      0.518285,
      0.517984,
      0.517946,
      0.517663,
      0.517141,
      0.516841,
      0.516804,
      0.516523,
      0.516307,
      0.515855,
      0.515382,
      0.515331,
      0.515119,
      0.514969,
      0.51476,
      0.514624,
      0.514924,
      0.514977,
      0.515203,
      0.515386,
      0.515916,
      0.516216,
      0.516255,
      0.51653,
      0.517323,
      0.517672,
      0.517805,
      0.517884,
      0.519195,
      0.519352,
      0.519414,
      0.520338,
      0.520589,
      0.521861,
      0.522266,
      0.52243,
      0.523383,
      0.524526,
      0.524754,
      0.526048,
      0.52757,
      0.52776,
      0.527841,
      0.529093,
      0.530616,
      0.532138,
      0.532318,
      0.532495,
      0.532654,
      0.533661,
      0.534803,
      0.536325,
      0.536495,
      0.537333,
      0.537848,
      0.53937,
      0.539504,
      0.5397,
      0.540893,
      0.542415,
      0.542735,
      0.543938,
      0.54546,
      0.545769,
      0.545905,
      0.546983,
      0.548505,
      0.548583,
      0.550028,
      0.55155,
      0.553072,
      0.553344,
      0.554594,
      0.554797,
      0.554889,
      0.555737,
      0.555773,
      0.557259,
      0.558781,
      0.559035,
      0.560304,
      0.561826,
      0.562178,
      0.563349,
      0.563584,
      0.564853,
      0.564871,
      0.566394,
      0.566617,
      0.567917,
      0.569439,
      0.569653,
      0.56975,
      0.570961,
      0.572417,
      0.572484,
      0.572638,
      0.573626,
      0.575148,
      0.575344,
      0.575429,
      0.576671,
      0.578193,
      0.578379,
      0.579715,
      0.581118,
      0.581238,
      0.581415,
      0.581485,
      0.582761,
      0.582936,
      0.584283,
      0.585805,
      0.587328,
      0.58885,
      0.588998,
      0.590198,
      0.590373,
      0.591895,
      0.592033,
      0.592081,
      0.593418,
      0.59494,
      0.596082,
      0.596202,
      0.597604,
      0.59843,
      0.604546,
      0.606346,
      0.614348,
      0.614376,
      0.615871,
      0.615889,
      0.616318,
      0.616451,
      0.617014,
      0.617787,
      0.618537,
      0.618547,
      0.61855,
      0.618977,
      0.61968,
      0.619707,
      0.621202,
      0.621225,
      0.622725,
      0.624247,
      0.624256,
      0.625378,
      0.62577,
      0.627292,
      0.62805,
      0.628804,
      0.628815,
      0.630337,
      0.631859,
      0.633381,
      0.634903,
      0.636425,
      0.63748,
      0.63789,
      0.637948,
      0.63947,
      0.640992,
      0.642514,
      0.644036,
      0.645178,
      0.646637,
      0.646649,
      0.646701,
      0.647197,
      0.648171,
      0.648224,
      0.649366,
      0.650056,
      0.650612,
      0.650832,
      0.650889,
      0.651975,
      0.652032,
      0.652509,
      0.652733,
      0.653555,
      0.654636,
      0.654698,
      0.65501,
      0.65516,
      0.655665,
      0.656164,
      0.656166,
      0.656169,
      0.656222,
      0.656722,
      0.656725,
      0.657139,
      0.657288,
      0.657365,
      0.657851,
      0.657855,
      0.658418,
      0.658438,
      0.658507,
      0.65866,
      0.65872,
      0.658973,
      0.659225,
      0.659239,
      0.65927,
      0.659271,
      0.659271,
      0.659272,
      0.659272,
      0.659272,
      0.659272,
      0.659171,
      0.659071,
      0.659046,
      0.659046,
      0.658922,
      0.658921,
      0.658893,
      0.658747,
      0.658726,
      0.65859,
      0.658559,
      0.658558,
      0.658372,
      0.658371,
      0.658183,
      0.658157,
      0.658135,
      0.658065,
      0.657983,
      0.657971,
      0.657782,
      0.657767,
      0.657756,
      0.65768,
      0.657539,
      0.657378,
      0.65732,
      0.657293,
      0.657215,
      0.65701,
      0.656999,
      0.656764,
      0.656725,
      0.65586,
      0.655503,
      0.655346,
      0.654875,
      0.654719,
      0.653476,
      0.653198,
      0.652852,
      0.652538,
      0.652058,
      0.651691,
      0.650964,
      0.650918,
      0.650351,
      0.649659,
      0.649397,
      0.648256,
      0.647545,
      0.646918,
      0.646735,
      0.646224,
      0.645257,
      0.645214,
      0.644073,
      0.643552,
      0.643486,
      0.642552,
      0.641824,
      0.64126,
      0.641031,
      0.640135,
      0.63989,
      0.638588,
      0.63839,
      0.638369,
      0.636848,
      0.636391,
      0.635863,
      0.635707,
      0.634202,
      0.634186,
      0.633193,
      0.633152,
      0.633045,
      0.631525,
      0.630376,
      0.630276,
      0.630003,
      0.628482,
      0.621633,
      0.621603,
      0.621028,
      0.620982,
      0.620112,
      0.618971,
      0.617737,
      0.61745,
      0.617364,
      0.616119,
      0.615928,
      0.614539,
      0.614407,
      0.613266,
      0.613155,
      0.611915,
      0.611745,
      0.610456,
      0.610223,
      0.60718,
      0.608702,
      0.604526,
      0.604136,
      0.605658,
      0.60538,
      0.603319,
      0.602995,
      0.601474,
      0.599952,
      0.59858,
      0.598524,
      0.598431,
      0.59833,
      0.59691,
      0.596389,
      0.5958,
      0.595769,
      0.59555,
      0.594248,
      0.593719,
      0.593108,
      0.591642,
      0.591586,
      0.591495,
      0.591048,
      0.590446,
      0.589704,
      0.58896,
      0.588925,
      0.588828,
      0.588363,
      0.587784,
      0.587234,
      0.587217,
      0.586644,
      0.586371,
      0.585122,
      0.584751,
      0.584739,
      0.584291,
      0.583983,
      0.583955,
      0.582475,
      0.582461,
      0.582444,
      0.581321,
      0.58129,
      0.58117,
      0.580558,
      0.580523,
      0.5798,
      0.578994,
      0.578279,
      0.578261,
      0.57804,
      0.577138,
      0.577082,
      0.576316,
      0.575617,
      0.575599,
      0.57492,
      0.574476,
      0.574455,
      0.574422,
      0.573336,
      0.573257,
      0.572399,
      0.571815,
      0.571785,
      0.571178,
      0.570675,
      0.570628,
      0.570493,
      0.569818,
      0.569154,
      0.568746,
      0.5683,
      0.568014,
      0.567997,
      0.567877,
      0.567635,
      0.567635,
      0.567635,
      0.567636,
      0.567636,
      0.567637,
      0.567629,
      0.567626,
      0.567614,
      0.567532,
      0.567345,
      0.567343,
      0.56733,
      0.567259,
      0.567154,
      0.567061,
      0.566966,
      0.566952,
      0.566881,
      0.566862,
      0.566484,
      0.566482,
      0.566295,
      0.566264,
      0.566122,
      0.566111,
      0.56586,
      0.565856,
      0.565743,
      0.565648,
      0.565334,
      0.564603,
      0.564576,
      0.564563,
      0.564507,
      0.563816,
      0.563464,
      0.563422,
      0.562985,
      0.562676,
      0.562324,
      0.561688,
      0.561183,
      0.561031,
      0.560567,
      0.560043,
      0.559616,
      0.55956,
      0.558903,
      0.558853,
      0.558261,
      0.557763,
      0.557551,
      0.556242,
      0.556185,
      0.556166,
      0.555101,
      0.554882,
      0.554262,
      0.55358,
      0.553522,
      0.5535,
      0.552954,
      0.55244,
      0.552368,
      0.552213,
      0.55161,
      0.551568,
      0.550919,
      0.550872,
      0.550868,
      0.550304,
      0.550175,
      0.549779,
      0.549736,
      0.549692,
      0.549168,
      0.548638,
      0.548612,
      0.54814,
      0.54785,
      0.547848,
      0.547819,
      0.547498,
      0.547448,
      0.547444,
      0.547441,
      0.546866,
      0.546862,
      0.546859,
      0.546855,
      0.546748,
      0.546358,
      0.546306,
      0.546304,
      0.546302,
      0.546299,
      0.546297,
      0.545916,
      0.545914,
      0.545912,
      0.54591,
      0.545527,
      0.545524,
      0.545522,
      0.54552,
      0.545218,
      0.545126,
      0.545096,
      0.545093,
      0.545089,
      0.545035,
      0.544514,
      0.544511,
      0.544507,
      0.544504,
      0.544078,
      0.543879,
      0.543874,
      0.54387,
      0.543865,
      0.543192,
      0.543098,
      0.543093,
      0.542557,
      0.542368,
      0.541775,
      0.541771,
      0.541768,
      0.541416,
      0.541321,
      0.541193,
      0.541189,
      0.541186,
      0.541182,
      0.540654,
      0.540607,
      0.540604,
      0.5406,
      0.540597,
      0.540276,
      0.540022,
      0.540018,
      0.540015,
      0.540011,
      0.540008,
      0.539608,
      0.539433,
      0.539429,
      0.539426,
      0.539422,
      0.539135,
      0.53888,
      0.538876,
      0.538873,
      0.538454,
      0.538362,
      0.538359,
      0.538356,
      0.538034,
      0.537845,
      0.537842,
      0.537839,
      0.537615,
      0.537292,
      0.537289,
      0.536723,
      0.53672,
      0.536475,
      0.536374,
      0.536275,
      0.536162,
      0.536159,
      0.536155,
      0.535605,
      0.535601,
      0.535598,
      0.535594,
      0.535334,
      0.534946,
      0.534942,
      0.534937,
      0.534436,
      0.534302,
      0.534202,
      0.534198,
      0.534193,
      0.534188,
      0.533813,
      0.533551,
      0.533547,
      0.533544,
      0.53354,
      0.533537,
      0.532996,
      0.532993,
      0.532989,
      0.532986,
      0.532982,
      0.532673,
      0.532531,
      0.53235,
      0.532345,
      0.531634,
      0.531592,
      0.531152,
      0.530924,
      0.53092,
      0.530917,
      0.530914,
      0.53037,
      0.530366,
      0.530363,
      0.530359,
      0.530356,
      0.530011,
      0.529863,
      0.529741,
      0.529736,
      0.529732,
      0.529728,
      0.528987,
      0.528983,
      0.528491,
      0.528302,
      0.528298,
      0.528295,
      0.527806,
      0.527744,
      0.527741,
      0.527737,
      0.527734,
      0.52735,
      0.526921,
      0.526912,
      0.526903,
      0.526893,
      0.526884,
      0.526768,
      0.525829,
      0.525428,
      0.525419,
      0.525409,
      0.5254,
      0.524307,
      0.524117,
      0.524112,
      0.524107,
      0.523381,
      0.523363,
      0.523359,
      0.522786,
      0.522559,
      0.521646,
      0.52118,
      0.521112,
      0.52082,
      0.520506,
      0.519833,
      0.519805,
      0.519365,
      0.519262,
      0.519064,
      0.518606,
      0.518532,
      0.518518,
      0.51845,
      0.517466,
      0.517349,
      0.517331,
      0.516658,
      0.516326,
      0.515401,
      0.514805,
      0.514743,
      0.514445,
      0.514242,
      0.514046,
      0.513729,
      0.513685,
      0.513481,
      0.513286,
      0.512862,
      0.512859,
      0.512826,
      0.512528,
      0.512646,
      0.512664,
      0.512743,
      0.512838,
      0.51291,
      0.514052,
      0.514241,
      0.51437,
      0.514997,
      0.515576,
      0.516647,
      0.517099,
      0.517367,
      0.517369,
      0.518241,
      0.519179,
      0.519764,
      0.52011,
      0.520359,
      0.521287,
      0.521755,
      0.52243,
      0.522554,
      0.522561,
      0.522647,
      0.523573,
      0.523747,
      0.524616,
      0.525096,
      0.525214,
      0.525796,
      0.526239,
      0.527173,
      0.527273,
      0.527762,
      0.528059,
      0.529285,
      0.529535,
      0.530427,
      0.530947,
      0.531949,
      0.532276,
      0.533472,
      0.533737,
      0.534995,
      0.53531,
      0.536517,
      0.538039,
      0.538346,
      0.538512,
      0.539562,
      0.540161,
      0.544285,
      0.547554,
      0.547966,
      0.549077,
      0.549332,
      0.550599,
      0.550661,
      0.551265,
      0.551742,
      0.552124,
      0.553265,
      0.553511,
      0.554787,
      0.554925,
      0.55631,
      0.557101,
      0.557496,
      0.567322,
      0.571913,
      0.571945,
      0.573435,
      0.573491,
      0.573528,
      0.574578,
      0.574728,
      0.576101,
      0.576105,
      0.577623,
      0.577762,
      0.577842,
      0.579146,
      0.579284,
      0.580641,
      0.580669,
      0.580807,
      0.582191,
      0.58238,
      0.583714,
      0.583809,
      0.584813,
      0.584856,
      0.58568,
      0.586379,
      0.590946,
      0.591079,
      0.592468,
      0.59399,
      0.607308,
      0.59399
  ],
  bloodVolumeValues: [
      0.209285,
      0.209349,
      0.209435,
      0.209443,
      0.209445,
      0.209541,
      0.209542,
      0.209634,
      0.209634,
      0.209638,
      0.209668,
      0.209722,
      0.209729,
      0.209734,
      0.209824,
      0.20983,
      0.209914,
      0.209927,
      0.210015,
      0.210023,
      0.210051,
      0.210051,
      0.210051,
      0.210052,
      0.210052,
      0.210052,
      0.210052,
      0.210052,
      0.210052,
      0.210053,
      0.210053,
      0.210053,
      0.210127,
      0.210128,
      0.210146,
      0.210255,
      0.210255,
      0.210274,
      0.210381,
      0.210382,
      0.210402,
      0.210435,
      0.210543,
      0.210547,
      0.210733,
      0.210768,
      0.210922,
      0.210922,
      0.21096,
      0.211112,
      0.21112,
      0.211152,
      0.211199,
      0.211199,
      0.211199,
      0.211199,
      0.211199,
      0.2112,
      0.2112,
      0.2112,
      0.211201,
      0.211201,
      0.211201,
      0.2113,
      0.211301,
      0.211314,
      0.211352,
      0.21149,
      0.211544,
      0.21187,
      0.211887,
      0.211926,
      0.211964,
      0.212067,
      0.212283,
      0.212295,
      0.212322,
      0.212346,
      0.212424,
      0.212499,
      0.212521,
      0.212617,
      0.212682,
      0.212713,
      0.212729,
      0.212882,
      0.212942,
      0.212943,
      0.213366,
      0.213492,
      0.213607,
      0.213653,
      0.213779,
      0.214161,
      0.21418,
      0.214255,
      0.214469,
      0.214917,
      0.215018,
      0.215114,
      0.215178,
      0.215706,
      0.215781,
      0.215971,
      0.216098,
      0.216393,
      0.216543,
      0.217019,
      0.217041,
      0.217083,
      0.217307,
      0.217769,
      0.21794,
      0.217969,
      0.218069,
      0.218163,
      0.218415,
      0.218545,
      0.218567,
      0.218833,
      0.21914,
      0.219401,
      0.219445,
      0.219899,
      0.219976,
      0.220356,
      0.220384,
      0.220686,
      0.220739,
      0.220928,
      0.221418,
      0.221731,
      0.221882,
      0.222079,
      0.222262,
      0.222516,
      0.222544,
      0.222586,
      0.222645,
      0.223212,
      0.223636,
      0.223697,
      0.223788,
      0.224233,
      0.224419,
      0.224449,
      0.224551,
      0.224835,
      0.225213,
      0.225694,
      0.225876,
      0.225922,
      0.225972,
      0.226837,
      0.227064,
      0.227112,
      0.227398,
      0.22749,
      0.227599,
      0.227957,
      0.228914,
      0.228966,
      0.228969,
      0.229123,
      0.229242,
      0.229297,
      0.229549,
      0.22975,
      0.229781,
      0.229802,
      0.229886,
      0.230263,
      0.23072,
      0.23083,
      0.231029,
      0.231519,
      0.232231,
      0.232552,
      0.232793,
      0.233104,
      0.233172,
      0.233695,
      0.234022,
      0.234047,
      0.234615,
      0.234838,
      0.235008,
      0.235067,
      0.235436,
      0.235443,
      0.235445,
      0.23577,
      0.235821,
      0.235981,
      0.236308,
      0.236809,
      0.23681,
      0.236875,
      0.237124,
      0.237467,
      0.237547,
      0.237551,
      0.238647,
      0.238905,
      0.238959,
      0.239525,
      0.23979,
      0.240047,
      0.240105,
      0.240662,
      0.240932,
      0.241276,
      0.242087,
      0.242456,
      0.242713,
      0.242715,
      0.242745,
      0.242764,
      0.243287,
      0.243598,
      0.243883,
      0.24443,
      0.244449,
      0.244475,
      0.244741,
      0.245481,
      0.245492,
      0.245991,
      0.246265,
      0.246534,
      0.246566,
      0.2471,
      0.247407,
      0.247669,
      0.248238,
      0.24824,
      0.24855,
      0.248889,
      0.248944,
      0.249657,
      0.250073,
      0.250238,
      0.250617,
      0.250995,
      0.251008,
      0.25103,
      0.251217,
      0.252022,
      0.252359,
      0.252967,
      0.253882,
      0.254031,
      0.25405,
      0.254071,
      0.25479,
      0.25481,
      0.255025,
      0.25531,
      0.256064,
      0.256109,
      0.256158,
      0.256548,
      0.256752,
      0.256787,
      0.257356,
      0.257691,
      0.257889,
      0.258456,
      0.258494,
      0.258535,
      0.258834,
      0.259845,
      0.259895,
      0.260357,
      0.260535,
      0.260859,
      0.260884,
      0.261237,
      0.261263,
      0.2615,
      0.261711,
      0.261713,
      0.262244,
      0.262335,
      0.262643,
      0.26281,
      0.262848,
      0.263786,
      0.26393,
      0.263963,
      0.264523,
      0.264941,
      0.264974,
      0.265309,
      0.266032,
      0.26607,
      0.266452,
      0.266576,
      0.266621,
      0.266955,
      0.267312,
      0.267595,
      0.268345,
      0.268414,
      0.268738,
      0.268831,
      0.269591,
      0.269881,
      0.270056,
      0.270125,
      0.270583,
      0.271024,
      0.272103,
      0.272547,
      0.272715,
      0.273241,
      0.27369,
      0.274667,
      0.274761,
      0.275213,
      0.275329,
      0.275369,
      0.275371,
      0.275937,
      0.276356,
      0.276548,
      0.276552,
      0.277264,
      0.277302,
      0.277418,
      0.277879,
      0.278013,
      0.278014,
      0.278579,
      0.279022,
      0.279162,
      0.279187,
      0.279315,
      0.280545,
      0.280856,
      0.282068,
      0.282177,
      0.282179,
      0.282734,
      0.282745,
      0.282851,
      0.28321,
      0.283661,
      0.284033,
      0.284044,
      0.284047,
      0.284354,
      0.284404,
      0.284405,
      0.284406,
      0.284407,
      0.284446,
      0.284663,
      0.284664,
      0.284666,
      0.2849,
      0.284921,
      0.284921,
      0.284923,
      0.285116,
      0.285256,
      0.285259,
      0.285263,
      0.285265,
      0.285266,
      0.28584,
      0.285843,
      0.285847,
      0.28585,
      0.285891,
      0.286259,
      0.286331,
      0.286404,
      0.286406,
      0.286409,
      0.286412,
      0.286922,
      0.286925,
      0.286926,
      0.286928,
      0.287438,
      0.287441,
      0.287444,
      0.287447,
      0.287453,
      0.287783,
      0.287857,
      0.287977,
      0.287979,
      0.287982,
      0.287986,
      0.287989,
      0.287993,
      0.287995,
      0.287996,
      0.28857,
      0.288573,
      0.288577,
      0.28858,
      0.288582,
      0.288584,
      0.288925,
      0.288956,
      0.289029,
      0.28903,
      0.289032,
      0.289033,
      0.289034,
      0.289035,
      0.289036,
      0.289266,
      0.289292,
      0.289294,
      0.289295,
      0.289296,
      0.289297,
      0.289298,
      0.2893,
      0.289301,
      0.289463,
      0.289557,
      0.289558,
      0.289558,
      0.28956,
      0.289561,
      0.289563,
      0.289565,
      0.289565,
      0.289566,
      0.289688,
      0.289888,
      0.28989,
      0.289893,
      0.289895,
      0.289896,
      0.289897,
      0.29028,
      0.290282,
      0.290285,
      0.290491,
      0.290578,
      0.290668,
      0.290669,
      0.29067,
      0.290831,
      0.291163,
      0.291166,
      0.29117,
      0.291458,
      0.291743,
      0.291745,
      0.291747,
      0.291974,
      0.292469,
      0.292877,
      0.293497,
      0.293559,
      0.293931,
      0.293936,
      0.293941,
      0.294315,
      0.294681,
      0.294686,
      0.294688,
      0.29502,
      0.295325,
      0.295326,
      0.29533,
      0.295332,
      0.295627,
      0.295769,
      0.295886,
      0.295889,
      0.295893,
      0.296163,
      0.296442,
      0.296446,
      0.296447,
      0.296449,
      0.296453,
      0.296765,
      0.296998,
      0.297,
      0.297002,
      0.297005,
      0.297009,
      0.297305,
      0.297478,
      0.297553,
      0.297554,
      0.297558,
      0.297561,
      0.297565,
      0.297902,
      0.298105,
      0.298107,
      0.298111,
      0.298114,
      0.298118,
      0.298121,
      0.298448,
      0.298728,
      0.29873,
      0.298735,
      0.298739,
      0.298744,
      0.298749,
      0.299235,
      0.299462,
      0.299464,
      0.299466,
      0.299471,
      0.299476,
      0.29948,
      0.299485,
      0.299971,
      0.300193,
      0.300198,
      0.3002,
      0.300202,
      0.300207,
      0.300212,
      0.300216,
      0.30075,
      0.300936,
      0.300939,
      0.300943,
      0.300948,
      0.300953,
      0.301495,
      0.301637,
      0.30164,
      0.301644,
      0.302072,
      0.302193,
      0.302196,
      0.302198,
      0.3022,
      0.302203,
      0.302637,
      0.302786,
      0.302789,
      0.302791,
      0.302796,
      0.3028,
      0.30285,
      0.3034,
      0.303523,
      0.303525,
      0.303527,
      0.303532,
      0.303536,
      0.303541,
      0.30416,
      0.304238,
      0.304241,
      0.304245,
      0.304248,
      0.304727,
      0.304797,
      0.304801,
      0.304804,
      0.304806,
      0.304885,
      0.305303,
      0.305353,
      0.305357,
      0.305359,
      0.30536,
      0.305364,
      0.305864,
      0.305917,
      0.30592,
      0.306446,
      0.306471,
      0.306816,
      0.306853,
      0.306925,
      0.307235,
      0.307589,
      0.307608,
      0.307834,
      0.307908,
      0.308117,
      0.308352,
      0.308716,
      0.308763,
      0.309208,
      0.309475,
      0.309495,
      0.309542,
      0.309544,
      0.310638,
      0.310669,
      0.310995,
      0.311109,
      0.311432,
      0.311754,
      0.311781,
      0.312133,
      0.31225,
      0.312508,
      0.312511,
      0.312575,
      0.312924,
      0.313439,
      0.314067,
      0.314088,
      0.314123,
      0.314548,
      0.31483,
      0.314861,
      0.314913,
      0.315167,
      0.315242,
      0.315926,
      0.315973,
      0.316004,
      0.316434,
      0.31706,
      0.317116,
      0.317137,
      0.317422,
      0.317586,
      0.317879,
      0.317895,
      0.318039,
      0.318299,
      0.318467,
      0.31861,
      0.318642,
      0.318794,
      0.318875,
      0.318908,
      0.319025,
      0.319033,
      0.319199,
      0.319224,
      0.319234,
      0.31939,
      0.319407,
      0.319512,
      0.319521,
      0.31958,
      0.319771,
      0.31979,
      0.319808,
      0.320016,
      0.320133,
      0.32019,
      0.320322,
      0.320553,
      0.320554,
      0.320554,
      0.320554,
      0.320555,
      0.320555,
      0.320556,
      0.320556,
      0.320578,
      0.320697,
      0.320825,
      0.320909,
      0.320938,
      0.320971,
      0.321152,
      0.321275,
      0.321534,
      0.321701,
      0.321713,
      0.321718,
      0.321774,
      0.321809,
      0.322001,
      0.322004,
      0.322061,
      0.322084,
      0.322085,
      0.322085,
      0.322086,
      0.322086,
      0.322086,
      0.322105,
      0.322233,
      0.322233,
      0.322312,
      0.322362,
      0.322469,
      0.322514,
      0.322689,
      0.323087,
      0.323262,
      0.323371,
      0.323612,
      0.323633,
      0.323712,
      0.323761,
      0.323887,
      0.323889,
      0.32389,
      0.323967,
      0.323995,
      0.32422,
      0.324335,
      0.324595,
      0.324602,
      0.324758,
      0.324859,
      0.324909,
      0.324986,
      0.325031,
      0.325037,
      0.32514,
      0.325177,
      0.325293,
      0.325294,
      0.325295,
      0.325296,
      0.325369,
      0.325488,
      0.325489,
      0.32549,
      0.32556,
      0.325682,
      0.325739,
      0.325874,
      0.325903,
      0.325943,
      0.326065,
      0.326067,
      0.326134,
      0.326258,
      0.32626,
      0.326311,
      0.326451,
      0.326517,
      0.326667,
      0.326667,
      0.326667,
      0.326667,
      0.326667,
      0.326667,
      0.326668,
      0.326668,
      0.326668,
      0.326669,
      0.326669,
      0.326669,
      0.326698,
      0.326785,
      0.326813,
      0.326913,
      0.326914,
      0.326954,
      0.327042,
      0.327043,
      0.327051,
      0.327052,
      0.327052,
      0.327052,
      0.327052,
      0.327052,
      0.327052,
      0.327053,
      0.327053,
      0.327053,
      0.327054,
      0.327054,
      0.327054,
      0.327054,
      0.327054,
      0.327055,
      0.327055,
      0.327055,
      0.327055,
      0.327055,
      0.327055,
      0.327056,
      0.327056,
      0.327056,
      0.327056,
      0.327056,
      0.327057,
      0.327057,
      0.327058,
      0.327058,
      0.327058,
      0.327058,
      0.327058,
      0.327059,
      0.327059,
      0.327059,
      0.327059,
      0.32706,
      0.32706,
      0.32706,
      0.32706,
      0.32706,
      0.327061,
      0.327061,
      0.327062,
      0.327062,
      0.327062,
      0.327063,
      0.327053,
      0.327043,
      0.327043,
      0.326958,
      0.326949,
      0.326863,
      0.326863,
      0.326862,
      0.326855,
      0.326767,
      0.326766,
      0.326761,
      0.326761,
      0.326684,
      0.326684,
      0.326684,
      0.326684,
      0.326685,
      0.326685,
      0.326685,
      0.326685,
      0.326685,
      0.326685,
      0.326686,
      0.326686,
      0.326686,
      0.326686,
      0.326686,
      0.326686,
      0.326686,
      0.326687,
      0.326687,
      0.326687,
      0.326687,
      0.326687,
      0.326687,
      0.326688,
      0.326688,
      0.326688,
      0.326689,
      0.326689,
      0.326689,
      0.326689,
      0.326689,
      0.32669,
      0.32669,
      0.32669,
      0.32669,
      0.32669,
      0.326691,
      0.326691,
      0.326691,
      0.326691,
      0.326682,
      0.326681,
      0.326668,
      0.326588,
      0.326479,
      0.326478,
      0.326478,
      0.326399,
      0.326398,
      0.326383,
      0.326382,
      0.326381,
      0.326381,
      0.326313,
      0.32628,
      0.326203,
      0.325902,
      0.32582,
      0.325513,
      0.325173,
      0.325153,
      0.325096,
      0.324841,
      0.324414,
      0.324394,
      0.324386,
      0.324333,
      0.323825,
      0.323654,
      0.323613,
      0.323243,
      0.323148,
      0.322515,
      0.322481,
      0.322472,
      0.322004,
      0.32172,
      0.321375,
      0.321286,
      0.321093,
      0.320615,
      0.320579,
      0.320191,
      0.3201,
      0.319476,
      0.319197,
      0.31894,
      0.318878,
      0.318716,
      0.318317,
      0.318314,
      0.318269,
      0.317958,
      0.317829,
      0.317758,
      0.317757,
      0.317579,
      0.317569,
      0.317546,
      0.317474,
      0.31738,
      0.317201,
      0.317171,
      0.317112,
      0.31692,
      0.316442,
      0.316352,
      0.316159,
      0.315907,
      0.315846,
      0.315682,
      0.31555,
      0.315257,
      0.314792,
      0.314543,
      0.314499,
      0.314116,
      0.314032,
      0.313403,
      0.313337,
      0.313209,
      0.312262,
      0.312232,
      0.31223,
      0.312176,
      0.311924,
      0.311725,
      0.311673,
      0.311503,
      0.311404,
      0.310492,
      0.309982,
      0.309796,
      0.309339,
      0.308842,
      0.308807,
      0.308759,
      0.308508,
      0.308302,
      0.308255,
      0.308082,
      0.308003,
      0.307905,
      0.306942,
      0.306785,
      0.306366,
      0.30628,
      0.305855,
      0.305775,
      0.305421,
      0.305308,
      0.304662,
      0.304608,
      0.304606,
      0.304552,
      0.304549,
      0.304173,
      0.303847,
      0.303522,
      0.303465,
      0.303414,
      0.303085,
      0.303036,
      0.302658,
      0.302382,
      0.302279,
      0.301944,
      0.301942,
      0.301901,
      0.301522,
      0.301242,
      0.301148,
      0.301095,
      0.300583,
      0.300102,
      0.300057,
      0.300036,
      0.299786,
      0.299785,
      0.299549,
      0.299534,
      0.299342,
      0.299302,
      0.299295,
      0.299041,
      0.299029,
      0.298777,
      0.298583,
      0.298492,
      0.298471,
      0.298452,
      0.297443,
      0.297364,
      0.297356,
      0.297001,
      0.296981,
      0.296977,
      0.296599,
      0.296303,
      0.296221,
      0.296218,
      0.295836,
      0.295835,
      0.295479,
      0.295462,
      0.295163,
      0.295083,
      0.295072,
      0.294339,
      0.294325,
      0.294309,
      0.294023,
      0.293972,
      0.29372,
      0.293706,
      0.293473,
      0.293264,
      0.293189,
      0.292784,
      0.292431,
      0.292403,
      0.292124,
      0.292079,
      0.291823,
      0.291803,
      0.291364,
      0.29132,
      0.291317,
      0.291043,
      0.290812,
      0.290605,
      0.290588,
      0.290494,
      0.2904,
      0.290391,
      0.290227,
      0.290227,
      0.290227,
      0.290228,
      0.290228,
      0.290229,
      0.290245,
      0.290253,
      0.290339,
      0.290349,
      0.290435,
      0.290444,
      0.290611,
      0.290625,
      0.290628,
      0.290635,
      0.290721,
      0.290826,
      0.290911,
      0.290914,
      0.290994,
      0.291017,
      0.291102,
      0.291207,
      0.291207,
      0.291293,
      0.291297,
      0.291303,
      0.291377,
      0.291484,
      0.291493,
      0.291584,
      0.291675,
      0.291684,
      0.29176,
      0.29204,
      0.292054,
      0.292066,
      0.292523,
      0.292541,
      0.292627,
      0.292637,
      0.292728,
      0.292732,
      0.292818,
      0.292905,
      0.292952,
      0.293197,
      0.293668,
      0.293702,
      0.293736,
      0.294102,
      0.294811,
      0.294844,
      0.295017,
      0.295218,
      0.295415,
      0.295575,
      0.295636,
      0.295985,
      0.296005,
      0.296396,
      0.296718,
      0.29701,
      0.297244,
      0.297257,
      0.297262,
      0.297481,
      0.297558,
      0.298124,
      0.298623,
      0.298652,
      0.298653,
      0.298654,
      0.298894,
      0.298904,
      0.298906,
      0.299157,
      0.299386,
      0.299403,
      0.299403,
      0.299593,
      0.299597,
      0.299779,
      0.299783,
      0.300149,
      0.300181,
      0.300184,
      0.300189,
      0.300551,
      0.300566,
      0.300568,
      0.300569,
      0.300571,
      0.300573,
      0.300576,
      0.300958,
      0.30096,
      0.300961,
      0.300963,
      0.300965,
      0.301293,
      0.30133,
      0.301331,
      0.301332,
      0.301333,
      0.301334,
      0.301558,
      0.301571,
      0.30159,
      0.301591,
      0.301593,
      0.301594,
      0.301849,
      0.30185,
      0.301852,
      0.301853,
      0.301855,
      0.302055,
      0.30207,
      0.302136,
      0.302138,
      0.30214,
      0.302142,
      0.302145,
      0.302471,
      0.302527,
      0.302529,
      0.30253,
      0.302532,
      0.302534,
      0.302917,
      0.302919,
      0.30292,
      0.302921,
      0.302924,
      0.303199,
      0.303206,
      0.303271,
      0.303272,
      0.303274,
      0.303274,
      0.303275,
      0.303474,
      0.303531,
      0.303532,
      0.303534,
      0.303535,
      0.303536,
      0.303537,
      0.303713,
      0.303792,
      0.303794,
      0.303796,
      0.303797,
      0.303798,
      0.303799,
      0.3038,
      0.303961,
      0.304032,
      0.304033,
      0.304035,
      0.304035,
      0.304036,
      0.304037,
      0.304155,
      0.304229,
      0.30423,
      0.304231,
      0.304232,
      0.304232,
      0.304233,
      0.304234,
      0.304358,
      0.304426,
      0.304427,
      0.304428,
      0.304429,
      0.30443,
      0.304431,
      0.304535,
      0.304623,
      0.304624,
      0.304625,
      0.304625,
      0.304725,
      0.304848,
      0.304979,
      0.304997,
      0.3051,
      0.305232,
      0.305352,
      0.305353,
      0.305487,
      0.305602,
      0.305603,
      0.305605,
      0.305739,
      0.305759,
      0.305847,
      0.305848,
      0.30585,
      0.305851,
      0.305852,
      0.305853,
      0.305854,
      0.306093,
      0.306094,
      0.306096,
      0.306097,
      0.306098,
      0.306099,
      0.306245,
      0.30625,
      0.306318,
      0.306319,
      0.30632,
      0.306321,
      0.306322,
      0.306436,
      0.306644,
      0.306698,
      0.306816,
      0.306886,
      0.306887,
      0.306888,
      0.306888,
      0.307013,
      0.30709,
      0.307091,
      0.307092,
      0.307093,
      0.307094,
      0.307096,
      0.307258,
      0.307282,
      0.307336,
      0.307337,
      0.307338,
      0.307339,
      0.307341,
      0.307342,
      0.307582,
      0.307584,
      0.307584,
      0.307585,
      0.307587,
      0.307588,
      0.307764,
      0.307776,
      0.307817,
      0.307817,
      0.307818,
      0.307819,
      0.30782,
      0.308001,
      0.308002,
      0.308003,
      0.308004,
      0.308005,
      0.308006,
      0.308147,
      0.308168,
      0.308184,
      0.308185,
      0.308186,
      0.308187,
      0.308187,
      0.308189,
      0.30819,
      0.308191,
      0.308369,
      0.30837,
      0.308371,
      0.308372,
      0.308372,
      0.308374,
      0.308528,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.30854,
      0.308541,
      0.308541,
      0.308541,
      0.308541,
      0.308541,
      0.308542,
      0.308542,
      0.308542,
      0.308542,
      0.308543,
      0.308544,
      0.308544,
      0.308544,
      0.308544,
      0.308545,
      0.308545,
      0.308546,
      0.308546,
      0.308546,
      0.308546,
      0.30828,
      0.308026,
      0.307811,
      0.307787,
      0.307783,
      0.307607,
      0.307592,
      0.307499,
      0.307409,
      0.307396,
      0.307238,
      0.307049,
      0.306831,
      0.306671,
      0.30665,
      0.306641,
      0.306412,
      0.306392,
      0.306271,
      0.306271,
      0.306272,
      0.306272,
      0.306273,
      0.306273,
      0.306273,
      0.306273,
      0.306662,
      0.306671,
      0.306819,
      0.307037,
      0.307253,
      0.307312,
      0.307553,
      0.3078,
      0.30788,
      0.307903,
      0.307994,
      0.308071,
      0.308182,
      0.308191,
      0.308262,
      0.308287,
      0.308376,
      0.308453,
      0.308478,
      0.308548,
      0.308565,
      0.308695,
      0.308705,
      0.308947,
      0.308948,
      0.308948,
      0.308948,
      0.308949,
      0.30895,
      0.30895,
      0.309047,
      0.309151,
      0.309247,
      0.309314,
      0.309332,
      0.309335,
      0.309354,
      0.309736,
      0.310059,
      0.310096,
      0.310096,
      0.310096,
      0.310097,
      0.310097,
      0.310097,
      0.310098,
      0.310114,
      0.310242,
      0.31037,
      0.310455,
      0.31048,
      0.31048,
      0.310589,
      0.310685,
      0.310767,
      0.310845,
      0.310863,
      0.310877,
      0.311069,
      0.311069,
      0.311132,
      0.31115,
      0.311165,
      0.311246,
      0.311401,
      0.311468,
      0.311626,
      0.31185,
      0.311974,
      0.312009,
      0.312031,
      0.312262,
      0.312286,
      0.312368,
      0.312391,
      0.312425,
      0.312548,
      0.312617,
      0.31274,
      0.312741,
      0.31277,
      0.312808,
      0.313124,
      0.313155,
      0.313155,
      0.313155,
      0.313156,
      0.313156,
      0.313156,
      0.313156,
      0.313157,
      0.313157,
      0.313138,
      0.313075,
      0.313064,
      0.313043,
      0.313043,
      0.31298,
      0.312885,
      0.312853,
      0.312779,
      0.312554,
      0.312553,
      0.312531,
      0.31247,
      0.312298,
      0.312043,
      0.312041,
      0.312019,
      0.311977,
      0.311833,
      0.311655,
      0.311596,
      0.311595,
      0.311464,
      0.311463,
      0.311405,
      0.311272,
      0.311271,
      0.311261,
      0.311012,
      0.310945,
      0.310766,
      0.310765,
      0.31051,
      0.310508,
      0.310501,
      0.310453,
      0.310315,
      0.310315,
      0.310072,
      0.309935,
      0.309744,
      0.309743,
      0.309743,
      0.309675,
      0.309493,
      0.309488,
      0.309421,
      0.309167,
      0.308983,
      0.308983,
      0.308984,
      0.308984,
      0.308984,
      0.308984,
      0.308984,
      0.308985,
      0.308985,
      0.308985,
      0.308985,
      0.308981,
      0.30898,
      0.308893,
      0.308885,
      0.308885,
      0.308789,
      0.308769,
      0.308694,
      0.308607,
      0.308585,
      0.308584,
      0.308537,
      0.308358,
      0.308329,
      0.308327,
      0.308285,
      0.308284,
      0.308072,
      0.308071,
      0.308033,
      0.307848,
      0.307755,
      0.307741,
      0.307741,
      0.307728,
      0.307646,
      0.307634,
      0.307551,
      0.30755,
      0.30754,
      0.30754,
      0.307471,
      0.30747,
      0.307407,
      0.307177,
      0.307175,
      0.307155,
      0.30692,
      0.306919,
      0.306917,
      0.306903,
      0.30671,
      0.306674,
      0.306667,
      0.306666,
      0.306523,
      0.306483,
      0.306478,
      0.306478,
      0.30629,
      0.306101,
      0.306099,
      0.305952,
      0.305932,
      0.30593,
      0.305858,
      0.305839,
      0.305838,
      0.305838,
      0.305834,
      0.305834,
      0.305745,
      0.305739,
      0.305738,
      0.30565,
      0.305643,
      0.305642,
      0.305642,
      0.305574,
      0.305573,
      0.305528,
      0.305502,
      0.3055,
      0.305277,
      0.305276,
      0.305024,
      0.304993,
      0.304991,
      0.304814,
      0.304783,
      0.304756,
      0.304754,
      0.304626,
      0.304594,
      0.304563,
      0.304405,
      0.304372,
      0.304217,
      0.304057,
      0.304055,
      0.304018,
      0.30397,
      0.303765,
      0.303716,
      0.303513,
      0.303296,
      0.302919,
      0.302778,
      0.302483,
      0.302395,
      0.302156,
      0.30206,
      0.301868,
      0.301805,
      0.301651,
      0.301551,
      0.301397,
      0.301321,
      0.30132,
      0.301018,
      0.300991,
      0.300749,
      0.300638,
      0.300602,
      0.300279,
      0.300132,
      0.300095,
      0.299879,
      0.299118,
      0.299062,
      0.298957,
      0.298739,
      0.298124,
      0.297979,
      0.297978,
      0.29794,
      0.297365,
      0.29722,
      0.297218,
      0.297159,
      0.296297,
      0.29608,
      0.295696,
      0.295635,
      0.29494,
      0.294874,
      0.294777,
      0.294175,
      0.294113,
      0.294018,
      0.293801,
      0.293258,
      0.293033,
      0.292661,
      0.29259,
      0.2925,
      0.291901,
      0.291794,
      0.291642,
      0.2916,
      0.291542,
      0.291141,
      0.290752,
      0.29069,
      0.290603,
      0.290225,
      0.290002,
      0.289322,
      0.289197,
      0.288861,
      0.288852,
      0.288745,
      0.288522,
      0.288461,
      0.288237,
      0.287893,
      0.287722,
      0.287709,
      0.28727,
      0.287191,
      0.286962,
      0.286813,
      0.286568,
      0.28613,
      0.286053,
      0.285822,
      0.285724,
      0.285305,
      0.285268,
      0.285219,
      0.285063,
      0.284538,
      0.284231,
      0.28416,
      0.283923,
      0.283911,
      0.283875,
      0.283829,
      0.283368,
      0.283323,
      0.283164,
      0.282765,
      0.282644,
      0.282024,
      0.281986,
      0.281955,
      0.281824,
      0.281576,
      0.281415,
      0.281388,
      0.281387,
      0.281265,
      0.281179,
      0.281178,
      0.280998,
      0.280928,
      0.280704,
      0.280676,
      0.280506,
      0.280465,
      0.280372,
      0.280352,
      0.280339,
      0.280225,
      0.280214,
      0.280127,
      0.280105,
      0.280098,
      0.280004,
      0.279933,
      0.279916,
      0.27991,
      0.279816,
      0.279749,
      0.279749,
      0.279749,
      0.27975,
      0.27975,
      0.279751,
      0.279751,
      0.279751,
      0.279802,
      0.279989,
      0.279991,
      0.279992,
      0.280143,
      0.28018,
      0.280181,
      0.28037,
      0.280373,
      0.280514,
      0.280574,
      0.280581,
      0.280781,
      0.281079,
      0.281081,
      0.28109,
      0.281277,
      0.281318,
      0.281667,
      0.281698,
      0.28171,
      0.281901,
      0.28204,
      0.282092,
      0.28211,
      0.282559,
      0.282619,
      0.282803,
      0.282853,
      0.283128,
      0.283321,
      0.283359,
      0.283383,
      0.283566,
      0.283982,
      0.284001,
      0.284145,
      0.284329,
      0.284373,
      0.2844,
      0.284653,
      0.28488,
      0.285092,
      0.285097,
      0.285334,
      0.285504,
      0.285714,
      0.285855,
      0.285861,
      0.286148,
      0.286428,
      0.286618,
      0.286623,
      0.286655,
      0.286681,
      0.286934,
      0.287187,
      0.287381,
      0.28742,
      0.287671,
      0.287733,
      0.28799,
      0.288017,
      0.288524,
      0.28858,
      0.288781,
      0.28881,
      0.289087,
      0.289287,
      0.289317,
      0.28934,
      0.289593,
      0.289797,
      0.29005,
      0.290216,
      0.290347,
      0.291573,
      0.293095,
      0.293131,
      0.293164,
      0.293472,
      0.293889,
      0.293922,
      0.294239,
      0.294902,
      0.295371,
      0.295381,
      0.295409,
      0.295821,
      0.296168,
      0.296201,
      0.296515,
      0.296524,
      0.297667,
      0.297698,
      0.298321,
      0.298791,
      0.29881,
      0.299605,
      0.300333,
      0.300596,
      0.300621,
      0.300831,
      0.301096,
      0.301357,
      0.301383,
      0.301847,
      0.301859,
      0.30186,
      0.30187,
      0.30187,
      0.301965,
      0.301965,
      0.30206,
      0.302147,
      0.302155,
      0.302237,
      0.302241,
      0.302262,
      0.302263,
      0.302264,
      0.302498,
      0.302515,
      0.302766,
      0.302767,
      0.303004,
      0.303185,
      0.303195,
      0.303206,
      0.303206,
      0.303397,
      0.303399,
      0.303576,
      0.30359,
      0.303592,
      0.303757,
      0.303767,
      0.303789,
      0.303789,
      0.30379,
      0.303792,
      0.304018,
      0.304047,
      0.304049,
      0.30405,
      0.304051,
      0.304052,
      0.304053,
      0.304055,
      0.304311,
      0.304312,
      0.304313,
      0.304314,
      0.304315,
      0.304317,
      0.30453,
      0.304546,
      0.304547,
      0.304547,
      0.304547,
      0.304548,
      0.30462,
      0.304624,
      0.304644,
      0.304645,
      0.304645,
      0.304645,
      0.304741,
      0.304742,
      0.304815,
      0.304913,
      0.304939,
      0.304941,
      0.305003,
      0.305006,
      0.305038,
      0.305039,
      0.305136,
      0.305138,
      0.305235,
      0.305236,
      0.305292,
      0.305296,
      0.305334,
      0.305335,
      0.305385,
      0.305432,
      0.305434,
      0.305483,
      0.305531,
      0.305628,
      0.305629,
      0.305674,
      0.305679,
      0.305742,
      0.305798,
      0.305871,
      0.305926,
      0.306061,
      0.306061,
      0.306061,
      0.306062,
      0.306062,
      0.306062,
      0.306062,
      0.306063,
      0.306063,
      0.306063,
      0.306141,
      0.306143,
      0.30624,
      0.306325,
      0.306328,
      0.306432,
      0.306513,
      0.306695,
      0.306698,
      0.306701,
      0.306811,
      0.306826,
      0.306853,
      0.306855,
      0.306946,
      0.306947,
      0.30701,
      0.307011,
      0.30704,
      0.307134,
      0.307201,
      0.307209,
      0.307257,
      0.307261,
      0.307503,
      0.307507,
      0.307693,
      0.307699,
      0.307753,
      0.307972,
      0.308004,
      0.3082,
      0.30825,
      0.308254,
      0.308496,
      0.3085,
      0.308715,
      0.308735,
      0.308743,
      0.308904,
      0.308931,
      0.309285,
      0.309483,
      0.309498,
      0.309496,
      0.309415,
      0.309226,
      0.309127,
      0.30912,
      0.309115,
      0.30901,
      0.308885,
      0.308741,
      0.308507,
      0.30827,
      0.308221,
      0.307982,
      0.307819,
      0.307616,
      0.30744,
      0.307223,
      0.307062,
      0.306856,
      0.306641,
      0.306493,
      0.306464,
      0.30622,
      0.306105,
      0.306085,
      0.305902,
      0.305817,
      0.305707,
      0.305708,
      0.305708,
      0.305708,
      0.305709,
      0.30571,
      0.30571,
      0.305711,
      0.305712,
      0.305689,
      0.305473,
      0.305249,
      0.304952,
      0.30478,
      0.304768,
      0.304574,
      0.304496,
      0.304484,
      0.30447,
      0.304196,
      0.303988,
      0.303954,
      0.303436,
      0.303425,
      0.303359,
      0.303346,
      0.303058,
      0.30298,
      0.302967,
      0.302951,
      0.302696,
      0.302683,
      0.30268,
      0.302136,
      0.301961,
      0.301921,
      0.301921,
      0.301922,
      0.301922,
      0.301923,
      0.301923,
      0.301923,
      0.301924,
      0.301925,
      0.301925,
      0.301925,
      0.302007,
      0.302199,
      0.30221,
      0.302231,
      0.302308,
      0.302308,
      0.302309,
      0.30231,
      0.30231,
      0.30231,
      0.30231,
      0.30231,
      0.302404,
      0.302491,
      0.302587,
      0.302588,
      0.302618,
      0.302684,
      0.302691,
      0.302693,
      0.302876,
      0.302876,
      0.302973,
      0.302979,
      0.303002,
      0.303069,
      0.303076,
      0.303313,
      0.303568,
      0.303823,
      0.303835,
      0.303839,
      0.3039,
      0.304079,
      0.304335,
      0.304336,
      0.304592,
      0.304597,
      0.304601,
      0.304786,
      0.304787,
      0.30484,
      0.304979,
      0.305171,
      0.305171,
      0.305172,
      0.305364,
      0.305365,
      0.305365,
      0.305367,
      0.30575,
      0.305753,
      0.306122,
      0.306135,
      0.306138,
      0.306225,
      0.306508,
      0.306512,
      0.30664,
      0.306641,
      0.306761,
      0.306769,
      0.30677,
      0.30689,
      0.306913,
      0.307296,
      0.307373,
      0.307648,
      0.307679,
      0.307681,
      0.308033,
      0.308064,
      0.308066,
      0.308449,
      0.30879,
      0.308832,
      0.309177,
      0.309202,
      0.309203,
      0.309459,
      0.30946,
      0.309462,
      0.3095,
      0.309682,
      0.309717,
      0.309719,
      0.309939,
      0.309992,
      0.310316,
      0.310375,
      0.310377,
      0.31076,
      0.310762,
      0.311083,
      0.311124,
      0.311333,
      0.31138,
      0.311403,
      0.311845,
      0.311916,
      0.312222,
      0.312299,
      0.312699,
      0.312989,
      0.313038,
      0.313039,
      0.31324,
      0.313294,
      0.313299,
      0.31355,
      0.313751,
      0.313832,
      0.314128,
      0.314211,
      0.314215,
      0.314598,
      0.3146,
      0.314895,
      0.314966,
      0.314983,
      0.315272,
      0.315366,
      0.315721,
      0.315748,
      0.316034,
      0.316038,
      0.3161,
      0.316102,
      0.316357,
      0.316585,
      0.316613,
      0.316799,
      0.316801,
      0.316952,
      0.317526,
      0.317943,
      0.317993,
      0.318048,
      0.318321,
      0.318368,
      0.31881,
      0.319084,
      0.319087,
      0.319246,
      0.31925,
      0.319711,
      0.319823,
      0.320229,
      0.320607,
      0.320721,
      0.32102,
      0.321104,
      0.321106,
      0.321373,
      0.321547,
      0.321938,
      0.322515,
      0.322749,
      0.323269,
      0.323511,
      0.324038,
      0.324057,
      0.324157,
      0.324415,
      0.32492,
      0.325182,
      0.325364,
      0.325747,
      0.326324,
      0.326567,
      0.327836,
      0.327848,
      0.328029,
      0.328602,
      0.328982,
      0.32899,
      0.329005,
      0.330124,
      0.330133,
      0.330254,
      0.33089,
      0.331276,
      0.332276,
      0.332787,
      0.332799,
      0.333563,
      0.333679,
      0.333937,
      0.333943,
      0.335077,
      0.335085,
      0.335579,
      0.335842,
      0.336229,
      0.336393,
      0.336793,
      0.337371,
      0.337477,
      0.338128,
      0.338128,
      0.338236,
      0.338515,
      0.339079,
      0.339235,
      0.339657,
      0.339753,
      0.340415,
      0.340513,
      0.340801,
      0.341052,
      0.341115,
      0.341367,
      0.341561,
      0.341563,
      0.34181,
      0.341874,
      0.342127,
      0.342324,
      0.342326,
      0.342405,
      0.342784,
      0.343087,
      0.343469,
      0.343543,
      0.34385,
      0.343921,
      0.344613,
      0.344657,
      0.344855,
      0.34491,
      0.345163,
      0.345375,
      0.345596,
      0.345758,
      0.345785,
      0.345975,
      0.346139,
      0.346141,
      0.346427,
      0.34668,
      0.346886,
      0.346901,
      0.346902,
      0.347009,
      0.347094,
      0.347104,
      0.347199,
      0.347284,
      0.347284,
      0.347284,
      0.347285,
      0.347285,
      0.347286,
      0.34731,
      0.347548,
      0.347563,
      0.347778,
      0.348049,
      0.348052,
      0.348057,
      0.348152,
      0.34834,
      0.348343,
      0.348432,
      0.348438,
      0.348531,
      0.348533,
      0.348618,
      0.348723,
      0.348724,
      0.348815,
      0.348822,
      0.348823,
      0.349205,
      0.349378,
      0.349393,
      0.349578,
      0.349581,
      0.349584,
      0.349708,
      0.349834,
      0.34996,
      0.349965,
      0.350052,
      0.350056,
      0.350151,
      0.350157,
      0.350247,
      0.350342,
      0.350343,
      0.350529,
      0.350529,
      0.35053,
      0.350545,
      0.350718,
      0.350927,
      0.351099,
      0.351106,
      0.351228,
      0.351354,
      0.351359,
      0.351369,
      0.351481,
      0.351489,
      0.351578,
      0.351687,
      0.351769,
      0.351864,
      0.351871,
      0.351878,
      0.352055,
      0.352157,
      0.352166,
      0.352245,
      0.352254,
      0.352483,
      0.352782,
      0.35299,
      0.353017,
      0.353109,
      0.353198,
      0.353216,
      0.3534,
      0.3534,
      0.3534,
      0.3534,
      0.353402,
      0.353402,
      0.353419,
      0.353581,
      0.353801,
      0.354142,
      0.35415,
      0.354165,
      0.354442,
      0.354895,
      0.354928,
      0.3551,
      0.355327,
      0.355475,
      0.355691,
      0.355715,
      0.355916,
      0.356164,
      0.356454,
      0.356662,
      0.356807,
      0.357193,
      0.357217,
      0.357229,
      0.357325,
      0.357579,
      0.3576,
      0.357609,
      0.357775,
      0.357896,
      0.357982,
      0.357983,
      0.357984,
      0.357984,
      0.357984,
      0.357985,
      0.357986,
      0.357986,
      0.357986,
      0.357987,
      0.357987,
      0.358092,
      0.358164,
      0.358352,
      0.35837,
      0.35837,
      0.35837,
      0.358371,
      0.358371,
      0.358372,
      0.358382,
      0.35864,
      0.358737,
      0.358755,
      0.358755,
      0.358755,
      0.358755,
      0.358756,
      0.358756,
      0.358756,
      0.358757,
      0.358757,
      0.358757,
      0.358757,
      0.358757,
      0.358757,
      0.358858,
      0.358875,
      0.358877,
      0.358984,
      0.359007,
      0.359009,
      0.35901,
      0.359139,
      0.359139,
      0.359139,
      0.359139,
      0.35914,
      0.35914,
      0.35914,
      0.359141,
      0.359141,
      0.359141,
      0.359142,
      0.359142,
      0.359142,
      0.359142,
      0.359142,
      0.359142,
      0.359143,
      0.359143,
      0.359143,
      0.359143,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359144,
      0.359145,
      0.359145,
      0.359145,
      0.359145,
      0.359145,
      0.359145,
      0.359146,
      0.359146,
      0.359146,
      0.359146,
      0.359146,
      0.359146,
      0.359146,
      0.359147,
      0.359147,
      0.359147,
      0.359147,
      0.359147,
      0.359147,
      0.359147,
      0.359148,
      0.359148,
      0.359148,
      0.359148,
      0.359148,
      0.359148,
      0.359148,
      0.359148,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.359149,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.35915,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359151,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359152,
      0.359153,
      0.359153,
      0.359153,
      0.359153,
      0.359153,
      0.359153,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359154,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359155,
      0.359145,
      0.359083,
      0.359082,
      0.359082,
      0.358993,
      0.358899,
      0.358894,
      0.358862,
      0.358777,
      0.358643,
      0.358632,
      0.358265,
      0.358251,
      0.358187,
      0.358076,
      0.35806,
      0.358018,
      0.357804,
      0.357793,
      0.357679,
      0.357666,
      0.357639,
      0.357618,
      0.35732,
      0.357298,
      0.356917,
      0.356881,
      0.35687,
      0.356816,
      0.356722,
      0.356709,
      0.356534,
      0.356502,
      0.356481,
      0.356186,
      0.356158,
      0.355808,
      0.355744,
      0.355729,
      0.355534,
      0.355514,
      0.355365,
      0.355365,
      0.355365,
      0.355366,
      0.355367,
      0.355378,
      0.35543,
      0.355447,
      0.355716,
      0.355734,
      0.35575,
      0.355761,
      0.356003,
      0.356021,
      0.356133,
      0.356143,
      0.356213,
      0.35629,
      0.35643,
      0.35648,
      0.3565,
      0.356516,
      0.356723,
      0.356878,
      0.356898,
      0.356908,
      0.357053,
      0.357075,
      0.357244,
      0.357266,
      0.357281,
      0.357307,
      0.357944,
      0.358006,
      0.358043,
      0.358053,
      0.358197,
      0.358221,
      0.358317,
      0.358388,
      0.358426,
      0.358444,
      0.358592,
      0.358922,
      0.358974,
      0.359189,
      0.359213,
      0.359412,
      0.359596,
      0.359921,
      0.359952,
      0.359961,
      0.36001,
      0.360132,
      0.360202,
      0.360229,
      0.360335,
      0.360356,
      0.36049,
      0.360562,
      0.361,
      0.361071,
      0.361098,
      0.36127,
      0.361303,
      0.361597,
      0.361652,
      0.361789,
      0.361844,
      0.361861,
      0.361904,
      0.362218,
      0.362953,
      0.363004,
      0.363163,
      0.363236,
      0.363419,
      0.363529,
      0.363674,
      0.363746,
      0.363766,
      0.364391,
      0.364499,
      0.364552,
      0.364774,
      0.36491,
      0.365403,
      0.365611,
      0.366166,
      0.366375,
      0.366433,
      0.366682,
      0.366787,
      0.366834,
      0.367065,
      0.367067,
      0.367069,
      0.367452,
      0.367455,
      0.367457,
      0.367459,
      0.36755,
      0.367576,
      0.367974,
      0.367978,
      0.36811,
      0.368719,
      0.368743,
      0.369121,
      0.369125,
      0.369253,
      0.369698,
      0.369827,
      0.369862,
      0.370408,
      0.370649,
      0.371172,
      0.371337,
      0.371385,
      0.371798,
      0.371801,
      0.372375,
      0.372378,
      0.372382,
      0.372493,
      0.372527,
      0.372908,
      0.372911,
      0.372914,
      0.373007,
      0.373046,
      0.373424,
      0.373427,
      0.37343,
      0.37394,
      0.373943,
      0.373946,
      0.374023,
      0.374051,
      0.374507,
      0.37451,
      0.374514,
      0.374593,
      0.37463,
      0.375087,
      0.375091,
      0.375166,
      0.375194,
      0.375508,
      0.37551,
      0.375893,
      0.375895,
      0.375938,
      0.376278,
      0.37628,
      0.376283,
      0.376337,
      0.376341,
      0.376829,
      0.376833,
      0.376883,
      0.377406,
      0.37748,
      0.377813,
      0.377844,
      0.37786,
      0.378196,
      0.378198,
      0.378581,
      0.378623,
      0.378964,
      0.378987,
      0.379347,
      0.379349,
      0.379381,
      0.379732,
      0.379734,
      0.379736,
      0.379739,
      0.379741,
      0.379766,
      0.380005,
      0.380007,
      0.380008,
      0.38001,
      0.380265,
      0.380271,
      0.380518,
      0.380521,
      0.380529,
      0.38077,
      0.380776,
      0.380777,
      0.381033,
      0.381277,
      0.381287,
      0.381288,
      0.381292,
      0.381826,
      0.381858,
      0.382392,
      0.382431,
      0.382435,
      0.382697,
      0.382712,
      0.38272,
      0.383008,
      0.383267,
      0.383295,
      0.383551,
      0.383578,
      0.383583,
      0.38392,
      0.383946,
      0.383966,
      0.384298,
      0.384341,
      0.384357,
      0.385011,
      0.385121,
      0.385766,
      0.385829,
      0.385864,
      0.385879,
      0.386357,
      0.386453,
      0.38692,
      0.386923,
      0.387007,
      0.387019,
      0.387327,
      0.387402,
      0.387703,
      0.387706,
      0.387748,
      0.388081,
      0.38815,
      0.388165,
      0.388457,
      0.38846,
      0.388548,
      0.388836,
      0.388889,
      0.389214,
      0.389293,
      0.38932,
      0.389893,
      0.390311,
      0.390436,
      0.390477,
      0.391024,
      0.391143,
      0.391241,
      0.39178,
      0.391959,
      0.391982,
      0.392247,
      0.392626,
      0.39269,
      0.392745,
      0.393102,
      0.393525,
      0.39371,
      0.394092,
      0.394245,
      0.394592,
      0.394651,
      0.395282,
      0.395388,
      0.395405,
      0.395618,
      0.395824,
      0.395914,
      0.396151,
      0.396423,
      0.396585,
      0.396633,
      0.396914,
      0.397323,
      0.397635,
      0.397943,
      0.398057,
      0.398159,
      0.398333,
      0.398411,
      0.39844,
      0.398456,
      0.398919,
      0.398963,
      0.399202,
      0.399335,
      0.399561,
      0.399594,
      0.399966,
      0.39997,
      0.400032,
      0.400145,
      0.400161,
      0.400319,
      0.400349,
      0.400352,
      0.400432,
      0.400542,
      0.400701,
      0.400731,
      0.400736,
      0.400952,
      0.401243,
      0.401494,
      0.401559,
      0.401577,
      0.40159,
      0.401686,
      0.401876,
      0.401877,
      0.401942,
      0.401959,
      0.402067,
      0.402246,
      0.402258,
      0.40226,
      0.402433,
      0.402508,
      0.40276,
      0.403013,
      0.403022,
      0.403104,
      0.403183,
      0.40321,
      0.403305,
      0.403391,
      0.403405,
      0.403585,
      0.403725,
      0.403775,
      0.403948,
      0.404155,
      0.404169,
      0.404339,
      0.404658,
      0.40489,
      0.40491,
      0.404931,
      0.40509,
      0.405114,
      0.405202,
      0.405208,
      0.405209,
      0.405304,
      0.405314,
      0.405473,
      0.405494,
      0.405585,
      0.40559,
      0.405685,
      0.405697,
      0.406118,
      0.406161,
      0.40617,
      0.406171,
      0.406422,
      0.40646,
      0.406618,
      0.406634,
      0.406636,
      0.40681,
      0.406827,
      0.406842,
      0.406922,
      0.407016,
      0.407017,
      0.407097,
      0.407112,
      0.407207,
      0.407207,
      0.407225,
      0.407289,
      0.407302,
      0.407303,
      0.407481,
      0.407493,
      0.407588,
      0.407608,
      0.407673,
      0.407683,
      0.407685,
      0.407778,
      0.407865,
      0.407873,
      0.407961,
      0.407968,
      0.407972,
      0.407991,
      0.407992,
      0.407992,
      0.407992,
      0.407992,
      0.407993,
      0.407993,
      0.407993,
      0.407815,
      0.407551,
      0.407307,
      0.407283,
      0.407234,
      0.407164,
      0.407071,
      0.40707,
      0.406976,
      0.406969,
      0.406882,
      0.406855,
      0.406787,
      0.406693,
      0.406692,
      0.406685,
      0.406502,
      0.406477,
      0.406296,
      0.406021,
      0.40579,
      0.405718,
      0.40553,
      0.405285,
      0.405261,
      0.404959,
      0.404818,
      0.404634,
      0.404439,
      0.404427,
      0.404255,
      0.404251,
      0.4042,
      0.403762,
      0.403743,
      0.403515,
      0.40344,
      0.403165,
      0.402787,
      0.402412,
      0.402374,
      0.402301,
      0.402119,
      0.401869,
      0.401613,
      0.40159,
      0.401541,
      0.401136,
      0.40057,
      0.400401,
      0.400221,
      0.400195,
      0.399969,
      0.399716,
      0.399642,
      0.399375,
      0.398952,
      0.398616,
      0.398613,
      0.398502,
      0.397856,
      0.397811,
      0.397473,
      0.397362,
      0.396067,
      0.395841,
      0.395435,
      0.39537,
      0.3947,
      0.394052,
      0.394006,
      0.393668,
      0.393561,
      0.393097,
      0.392874,
      0.392873,
      0.392801,
      0.391768,
      0.391725,
      0.391661,
      0.391252,
      0.390521,
      0.390203,
      0.389867,
      0.389381,
      0.389198,
      0.388945,
      0.388915,
      0.388622,
      0.388348,
      0.387968,
      0.387922,
      0.387589,
      0.387482,
      0.387211,
      0.387208,
      0.386833,
      0.386782,
      0.386722,
      0.386522,
      0.386517,
      0.38624,
      0.386238,
      0.385957,
      0.385948,
      0.385912,
      0.385673,
      0.385583,
      0.385325,
      0.385311,
      0.384946,
      0.384501,
      0.384443,
      0.384271,
      0.384269,
      0.384268,
      0.384266,
      0.384263,
      0.384011,
      0.38401,
      0.38401,
      0.384008,
      0.384007,
      0.384005,
      0.384004,
      0.383749,
      0.383747,
      0.383746,
      0.383744,
      0.383743,
      0.383741,
      0.38374,
      0.383722,
      0.383684,
      0.383505,
      0.383485,
      0.383483,
      0.383482,
      0.38348,
      0.383479,
      0.383224,
      0.383222,
      0.383221,
      0.383219,
      0.383,
      0.382964,
      0.382963,
      0.382961,
      0.382959,
      0.382924,
      0.382709,
      0.382705,
      0.382703,
      0.382701,
      0.3827,
      0.382698,
      0.382697,
      0.382494,
      0.382442,
      0.38244,
      0.382439,
      0.382437,
      0.382182,
      0.382181,
      0.382179,
      0.382178,
      0.382165,
      0.382033,
      0.382003,
      0.381984,
      0.381982,
      0.381981,
      0.38198,
      0.381789,
      0.381788,
      0.381787,
      0.381786,
      0.381784,
      0.381654,
      0.381593,
      0.381592,
      0.381591,
      0.38159,
      0.381434,
      0.381406,
      0.381401,
      0.381401,
      0.3814,
      0.381399,
      0.381319,
      0.381272,
      0.381271,
      0.38127,
      0.38127,
      0.381143,
      0.381142,
      0.381141,
      0.38114,
      0.38114,
      0.381139,
      0.381067,
      0.381027,
      0.381016,
      0.381015,
      0.381014,
      0.381014,
      0.381013,
      0.380947,
      0.380918,
      0.380869,
      0.380823,
      0.380775,
      0.380728,
      0.380663,
      0.380649,
      0.380617,
      0.380522,
      0.380431,
      0.38043,
      0.380428,
      0.380333,
      0.380247,
      0.380246,
      0.380244,
      0.380243,
      0.380242,
      0.380145,
      0.380061,
      0.38006,
      0.380059,
      0.380058,
      0.379956,
      0.379919,
      0.37989,
      0.379882,
      0.379881,
      0.37988,
      0.379879,
      0.379879,
      0.379759,
      0.379758,
      0.379757,
      0.379756,
      0.379682,
      0.379637,
      0.379636,
      0.379635,
      0.379634,
      0.379634,
      0.379633,
      0.379556,
      0.379531,
      0.379515,
      0.379514,
      0.379514,
      0.379513,
      0.379512,
      0.379512,
      0.379511,
      0.379333,
      0.379332,
      0.37933,
      0.379329,
      0.379328,
      0.3792,
      0.379148,
      0.379146,
      0.379145,
      0.379144,
      0.378971,
      0.378961,
      0.37896,
      0.378959,
      0.378822,
      0.378776,
      0.378775,
      0.378774,
      0.378773,
      0.378753,
      0.378538,
      0.378537,
      0.378535,
      0.378533,
      0.378532,
      0.378339,
      0.378292,
      0.378291,
      0.378289,
      0.378288,
      0.378286,
      0.378045,
      0.378044,
      0.378042,
      0.378041,
      0.378031,
      0.377994,
      0.377934,
      0.377921,
      0.377921,
      0.37792,
      0.377919,
      0.377919,
      0.377829,
      0.377829,
      0.377828,
      0.377828,
      0.377827,
      0.377745,
      0.377736,
      0.377735,
      0.377724,
      0.377651,
      0.377615,
      0.377498,
      0.377486,
      0.37731,
      0.377296,
      0.377074,
      0.376931,
      0.376916,
      0.376857,
      0.376769,
      0.376654,
      0.376642,
      0.376623,
      0.376478,
      0.376364,
      0.376343,
      0.376152,
      0.376127,
      0.375795,
      0.375772,
      0.375719,
      0.375315,
      0.375282,
      0.37525,
      0.37496,
      0.37496,
      0.374961,
      0.374961,
      0.374962,
      0.374962,
      0.374962,
      0.374882,
      0.374812,
      0.374797,
      0.374584,
      0.374529,
      0.374513,
      0.374503,
      0.37434,
      0.374228,
      0.37422,
      0.374205,
      0.374057,
      0.374039,
      0.373868,
      0.373842,
      0.373827,
      0.37373,
      0.373503,
      0.373477,
      0.373468,
      0.373448,
      0.37345,
      0.37345,
      0.37345,
      0.373451,
      0.373451,
      0.373452,
      0.373452,
      0.373453,
      0.373453,
      0.373453,
      0.373454,
      0.373454,
      0.373454,
      0.373455,
      0.373455,
      0.373456,
      0.373456,
      0.373457,
      0.373457,
      0.373457,
      0.373379,
      0.373217,
      0.373192,
      0.373095,
      0.373079,
      0.37308,
      0.37308,
      0.373081,
      0.373081,
      0.373081,
      0.373081,
      0.372766,
      0.372489,
      0.372106,
      0.372005,
      0.372003,
      0.371942,
      0.371723,
      0.37162,
      0.371237,
      0.37096,
      0.370864,
      0.370854,
      0.370802,
      0.370578,
      0.370575,
      0.370192,
      0.370092,
      0.369709,
      0.369662,
      0.369313,
      0.369183,
      0.368738,
      0.368586,
      0.368522,
      0.368163,
      0.368011,
      0.36747,
      0.367436,
      0.367381,
      0.367139,
      0.366756,
      0.366754,
      0.366655,
      0.366371,
      0.366272,
      0.366241,
      0.365757,
      0.365712,
      0.36529,
      0.365286,
      0.365101,
      0.364581,
      0.364576,
      0.364571,
      0.364387,
      0.363805,
      0.363689,
      0.363621,
      0.36358,
      0.363217,
      0.362929,
      0.362927,
      0.362834,
      0.362544,
      0.362541,
      0.36244,
      0.362017,
      0.361949,
      0.361886,
      0.361311,
      0.3613,
      0.360727,
      0.360723,
      0.360718,
      0.360713,
      0.359946,
      0.359941,
      0.359937,
      0.359932,
      0.359881,
      0.359786,
      0.359779,
      0.359319,
      0.359209,
      0.358744,
      0.358741,
      0.358638,
      0.358635,
      0.358324,
      0.358308,
      0.357941,
      0.357939,
      0.357936,
      0.357873,
      0.357553,
      0.357551,
      0.357498,
      0.356718,
      0.356074,
      0.356072,
      0.355977,
      0.355957,
      0.355475,
      0.355471,
      0.354837,
      0.354812,
      0.354334,
      0.354325,
      0.354322,
      0.353747,
      0.353697,
      0.353655,
      0.352997,
      0.352992,
      0.352224,
      0.35222,
      0.352215,
      0.352176,
      0.352101,
      0.351157,
      0.351082,
      0.351075,
      0.351035,
      0.350294,
      0.350289,
      0.350285,
      0.35028,
      0.350275,
      0.350214,
      0.349514,
      0.349508,
      0.349503,
      0.349499,
      0.349494,
      0.34883,
      0.348727,
      0.348722,
      0.348685,
      0.347993,
      0.347935,
      0.346852,
      0.346805,
      0.346801,
      0.346774,
      0.346161,
      0.346033,
      0.346029,
      0.345331,
      0.345191,
      0.343809,
      0.343742,
      0.34318,
      0.342714,
      0.342669,
      0.342497,
      0.341148,
      0.341052,
      0.340286,
      0.340285,
      0.33968,
      0.339627,
      0.339408,
      0.339398,
      0.338105,
      0.338027,
      0.338013,
      0.338009,
      0.337434,
      0.337431,
      0.336965,
      0.336676,
      0.335527,
      0.335443,
      0.335334,
      0.33529,
      0.334522,
      0.334518,
      0.334513,
      0.333922,
      0.33379,
      0.333379,
      0.333292,
      0.333215,
      0.332782,
      0.332626,
      0.332402,
      0.33126,
      0.331062,
      0.33053,
      0.330419,
      0.330294,
      0.329739,
      0.329621,
      0.328598,
      0.328384,
      0.328379,
      0.327758,
      0.327612,
      0.327098,
      0.327077,
      0.327019,
      0.326903,
      0.326451,
      0.326328,
      0.325937,
      0.32579,
      0.325445,
      0.324443,
      0.324416,
      0.324269,
      0.323905,
      0.322894,
      0.32283,
      0.322826,
      0.322821,
      0.32263,
      0.322081,
      0.322077,
      0.322072,
      0.321373,
      0.321298,
      0.320963,
      0.320237,
      0.320232,
      0.320183,
      0.319952,
      0.319425,
      0.319185,
      0.318711,
      0.318667,
      0.318417,
      0.317948,
      0.31719,
      0.317122,
      0.316735,
      0.316049,
      0.315425,
      0.314528,
      0.314479,
      0.314208,
      0.313753,
      0.313441,
      0.313007,
      0.312969,
      0.312757,
      0.312182,
      0.311866,
      0.311831,
      0.311769,
      0.310345,
      0.30982,
      0.309204,
      0.30866,
      0.308458,
      0.308172,
      0.307684,
      0.30695,
      0.306162,
      0.305021,
      0.304985,
      0.304955,
      0.303881,
      0.3035,
      0.30236,
      0.302318,
      0.302285,
      0.301971,
      0.300839,
      0.299317,
      0.29928,
      0.299254,
      0.299021,
      0.298177,
      0.297017,
      0.296656,
      0.296627,
      0.296609,
      0.296077,
      0.295896,
      0.295076,
      0.294736,
      0.294375,
      0.294298,
      0.293505,
      0.293235,
      0.293171,
      0.292075,
      0.291714,
      0.29163,
      0.291415,
      0.290573,
      0.290443,
      0.289841,
      0.287557,
      0.287045,
      0.286389,
      0.286307,
      0.285988,
      0.284868,
      0.284566,
      0.284199,
      0.283728,
      0.283662,
      0.283427,
      0.282859,
      0.282588,
      0.282519,
      0.282162,
      0.282009,
      0.281448,
      0.280653,
      0.279926,
      0.279637,
      0.279129,
      0.278405,
      0.278251,
      0.277264,
      0.276863,
      0.275743,
      0.275688,
      0.275666,
      0.275543,
      0.274784,
      0.274603,
      0.274486,
      0.273948,
      0.273734,
      0.273462,
      0.273166,
      0.272322,
      0.272234,
      0.272202,
      0.272025,
      0.271182,
      0.270985,
      0.27072,
      0.270361,
      0.270225,
      0.270042,
      0.269467,
      0.269282,
      0.269159,
      0.269115,
      0.268894,
      0.267761,
      0.267567,
      0.267191,
      0.267189,
      0.266937,
      0.266913,
      0.266813,
      0.266621,
      0.265772,
      0.265769,
      0.265481,
      0.265348,
      0.265297,
      0.265116,
      0.265111,
      0.264358,
      0.26396,
      0.263245,
      0.263119,
      0.262819,
      0.262716,
      0.262551,
      0.261982,
      0.261679,
      0.261276,
      0.260968,
      0.260157,
      0.259704,
      0.2596,
      0.259292,
      0.259222,
      0.259018,
      0.258107,
      0.25791,
      0.257905,
      0.257497,
      0.257387,
      0.257327,
      0.257325,
      0.256949,
      0.256737,
      0.25651,
      0.255821,
      0.255641,
      0.255216,
      0.255039,
      0.25497,
      0.254966,
      0.254465,
      0.254401,
      0.254075,
      0.253915,
      0.253828,
      0.252989,
      0.252554,
      0.252362,
      0.25174,
      0.251414,
      0.251289,
      0.251217,
      0.2506,
      0.250274,
      0.250002,
      0.249951,
      0.249341,
      0.248753,
      0.248332,
      0.248266,
      0.247612,
      0.246561,
      0.246526,
      0.246091,
      0.24573,
      0.245497,
      0.244569,
      0.244323,
      0.243429,
      0.243241,
      0.243121,
      0.243101,
      0.242342,
      0.241908,
      0.241104,
      0.241094,
      0.240767,
      0.240622,
      0.239953,
      0.239627,
      0.239314,
      0.238668,
      0.238106,
      0.237475,
      0.237447,
      0.236585,
      0.236338,
      0.235777,
      0.235444,
      0.235375,
      0.235086,
      0.235082,
      0.234685,
      0.234607,
      0.234603,
      0.234443,
      0.234418,
      0.23404,
      0.234035,
      0.233926,
      0.233856,
      0.233572,
      0.233566,
      0.233167,
      0.233067,
      0.23306,
      0.232843,
      0.232815,
      0.232407,
      0.232309,
      0.232301,
      0.232083,
      0.231804,
      0.231648,
      0.231569,
      0.231386,
      0.23138,
      0.231215,
      0.231001,
      0.230889,
      0.230794,
      0.230533,
      0.230311,
      0.230288,
      0.23013,
      0.230028,
      0.229552,
      0.229529,
      0.229523,
      0.229371,
      0.229017,
      0.228611,
      0.228538,
      0.228516,
      0.228512,
      0.22826,
      0.227852,
      0.227815,
      0.227803,
      0.227678,
      0.227551,
      0.227473,
      0.227351,
      0.227343,
      0.227343,
      0.227249,
      0.227249,
      0.227155,
      0.227095,
      0.227067,
      0.227062,
      0.227061,
      0.22706,
      0.226968,
      0.226874,
      0.226871,
      0.22678,
      0.226717,
      0.226744,
      0.226748,
      0.226752,
      0.226942,
      0.2271,
      0.2271,
      0.2271,
      0.2271,
      0.227101,
      0.227101,
      0.227101,
      0.227101,
      0.227129,
      0.227134,
      0.227138,
      0.227139,
      0.22714,
      0.22714,
      0.227236,
      0.227237,
      0.227237,
      0.227238,
      0.227325,
      0.227334,
      0.227335,
      0.227335,
      0.227432,
      0.227432,
      0.227433,
      0.227433,
      0.227484,
      0.22752,
      0.227545,
      0.227546,
      0.227546,
      0.227654,
      0.227675,
      0.227675,
      0.227676,
      0.227677,
      0.227805,
      0.227806,
      0.227807,
      0.227807,
      0.227866,
      0.227929,
      0.22797,
      0.227971,
      0.227972,
      0.227973,
      0.227974,
      0.227976,
      0.22811,
      0.228167,
      0.228169,
      0.22817,
      0.228171,
      0.228172,
      0.228309,
      0.228499,
      0.228569,
      0.22857,
      0.228571,
      0.22863,
      0.228665,
      0.228719,
      0.228719,
      0.228796,
      0.228982,
      0.229012,
      0.229089,
      0.229208,
      0.22921,
      0.229335,
      0.229465,
      0.229596,
      0.229721,
      0.229775,
      0.229926,
      0.23002,
      0.230112,
      0.230114,
      0.230115,
      0.230116,
      0.230207,
      0.23021,
      0.230297,
      0.230298,
      0.2303,
      0.230301,
      0.230302,
      0.230484,
      0.230486,
      0.230487,
      0.230538,
      0.230573,
      0.230699,
      0.230827,
      0.230874,
      0.230875,
      0.230876,
      0.23092,
      0.231037,
      0.231038,
      0.23104,
      0.231041,
      0.231161,
      0.231222,
      0.231223,
      0.231225,
      0.231226,
      0.231227,
      0.23135,
      0.231351,
      0.231408,
      0.231409,
      0.231411,
      0.231412,
      0.231594,
      0.231595,
      0.231597,
      0.231598,
      0.231683,
      0.231715,
      0.23175,
      0.23175,
      0.231969,
      0.231971,
      0.231994,
      0.231995,
      0.231996,
      0.232066,
      0.232118,
      0.232118,
      0.232119,
      0.23212,
      0.232121,
      0.232223,
      0.232241,
      0.232242,
      0.232243,
      0.232243,
      0.232244,
      0.232353,
      0.232365,
      0.232366,
      0.232367,
      0.232368,
      0.232448,
      0.232492,
      0.23251,
      0.232511,
      0.232513,
      0.232514,
      0.232697,
      0.232699,
      0.2327,
      0.232872,
      0.232887,
      0.233068,
      0.233078,
      0.233211,
      0.233211,
      0.233211,
      0.233212,
      0.233212,
      0.233213,
      0.233233,
      0.233237,
      0.233243,
      0.233339,
      0.233436,
      0.233518,
      0.233523,
      0.233532,
      0.233596,
      0.233709,
      0.233724,
      0.233899,
      0.233906,
      0.233978,
      0.234022,
      0.234127,
      0.234277,
      0.234361,
      0.234376,
      0.234384,
      0.23449,
      0.234567,
      0.23467,
      0.234681,
      0.234743,
      0.234763,
      0.234889,
      0.235016,
      0.235044,
      0.235126,
      0.235193,
      0.235449,
      0.2358,
      0.236019,
      0.236269,
      0.236299,
      0.236315,
      0.236539,
      0.236571,
      0.236652,
      0.236963,
      0.237189,
      0.237253,
      0.237414,
      0.237648,
      0.237676,
      0.238007,
      0.238058,
      0.238178,
      0.238333,
      0.238353,
      0.238446,
      0.23856,
      0.238657,
      0.239123,
      0.239166,
      0.239323,
      0.239335,
      0.239372,
      0.239628,
      0.239705,
      0.239765,
      0.239819,
      0.240018,
      0.240391,
      0.240622,
      0.240849,
      0.2409,
      0.240987,
      0.241156,
      0.241231,
      0.241251,
      0.241271,
      0.241335,
      0.241367,
      0.241527,
      0.241559,
      0.241614,
      0.241665,
      0.241723,
      0.242147,
      0.242232,
      0.242377,
      0.242418,
      0.242482,
      0.242491,
      0.24261,
      0.242673,
      0.242706,
      0.242759,
      0.242759,
      0.24276,
      0.24276,
      0.242761,
      0.242761,
      0.242792,
      0.242793,
      0.242877,
      0.243133,
      0.243302,
      0.243319,
      0.243524,
      0.243558,
      0.243642,
      0.243813,
      0.243815,
      0.243898,
      0.24408,
      0.244287,
      0.244343,
      0.244467,
      0.244726,
      0.24485,
      0.245108,
      0.245233,
      0.24543,
      0.245491,
      0.245499,
      0.245996,
      0.246255,
      0.246378,
      0.246573,
      0.246669,
      0.246672,
      0.246855,
      0.247244,
      0.247245,
      0.247716,
      0.247785,
      0.247905,
      0.248167,
      0.24855,
      0.248553,
      0.248667,
      0.248859,
      0.248923,
      0.248935,
      0.24905,
      0.249318,
      0.249433,
      0.249701,
      0.250002,
      0.250196,
      0.250444,
      0.250464,
      0.250579,
      0.250847,
      0.251146,
      0.251277,
      0.251439,
      0.251456,
      0.251532,
      0.251711,
      0.251908,
      0.252041,
      0.25222,
      0.252297,
      0.252455,
      0.252476,
      0.252671,
      0.252701,
      0.252739,
      0.252829,
      0.252957,
      0.252958,
      0.252994,
      0.253053,
      0.253109,
      0.253152,
      0.253154,
      0.253537,
      0.25364,
      0.254197,
      0.254231,
      0.254232,
      0.254266,
      0.254342,
      0.25436,
      0.254488,
      0.254521,
      0.254579,
      0.254635,
      0.254827,
      0.254874,
      0.254987,
      0.255018,
      0.25502,
      0.255021,
      0.255213,
      0.255214,
      0.255215,
      0.255342,
      0.255471,
      0.255474,
      0.255552,
      0.255856,
      0.255859,
      0.256156,
      0.256241,
      0.256244,
      0.256246,
      0.256311,
      0.256485,
      0.2567,
      0.256704,
      0.257277,
      0.257361,
      0.257628,
      0.257777,
      0.257779,
      0.257828,
      0.258058,
      0.258161,
      0.258544,
      0.258547,
      0.258587,
      0.258771,
      0.258929,
      0.258965,
      0.259312,
      0.259315,
      0.259317,
      0.259341,
      0.259343,
      0.25958,
      0.2597,
      0.259702,
      0.259704,
      0.259707,
      0.259719,
      0.259915,
      0.260089,
      0.260092,
      0.260094,
      0.260098,
      0.260476,
      0.260477,
      0.260479,
      0.260677,
      0.260721,
      0.260854,
      0.260862,
      0.260864,
      0.260867,
      0.260869,
      0.260871,
      0.261233,
      0.261254,
      0.261256,
      0.261259,
      0.261261,
      0.261609,
      0.261611,
      0.261644,
      0.261646,
      0.26182,
      0.261931,
      0.26196,
      0.261961,
      0.262102,
      0.262184,
      0.262217,
      0.262218,
      0.262436,
      0.262474,
      0.262475,
      0.262583,
      0.262623,
      0.262638,
      0.262718,
      0.262735,
      0.262784,
      0.262813,
      0.262831,
      0.262908,
      0.262927,
      0.262965,
      0.263016,
      0.263042,
      0.263142,
      0.263171,
      0.263234,
      0.263269,
      0.263299,
      0.263299,
      0.263348,
      0.263383,
      0.263408,
      0.263408,
      0.263409,
      0.263409,
      0.263478,
      0.263506,
      0.263506,
      0.263574,
      0.263602,
      0.263645,
      0.263669,
      0.263699,
      0.263731,
      0.263795,
      0.26386,
      0.263891,
      0.263955,
      0.263987,
      0.264027,
      0.264113,
      0.264201,
      0.264284,
      0.264456,
      0.264496,
      0.264508,
      0.264712,
      0.264793,
      0.264878,
      0.265087,
      0.265202,
      0.265513,
      0.265641,
      0.265781,
      0.265857,
      0.266023,
      0.266086,
      0.266423,
      0.266538,
      0.266787,
      0.266828,
      0.267003,
      0.267053,
      0.267169,
      0.267422,
      0.267491,
      0.267758,
      0.267932,
      0.268019,
      0.268101,
      0.268273,
      0.268314,
      0.268337,
      0.268738,
      0.268904,
      0.268991,
      0.269077,
      0.269099,
      0.269498,
      0.269666,
      0.26975,
      0.26984,
      0.270084,
      0.270253,
      0.270842,
      0.270983,
      0.271101,
      0.271221,
      0.271395,
      0.271599,
      0.271978,
      0.272126,
      0.27287,
      0.27304,
      0.273269,
      0.273312,
      0.273607,
      0.274412,
      0.274631,
      0.274634,
      0.274821,
      0.274903,
      0.275007,
      0.275009,
      0.275555,
      0.275766,
      0.275963,
      0.276044,
      0.276142,
      0.276145,
      0.27652,
      0.276698,
      0.276998,
      0.277308,
      0.277565,
      0.277841,
      0.277945,
      0.278036,
      0.278414,
      0.278628,
      0.278984,
      0.279136,
      0.279266,
      0.279833,
      0.280127,
      0.280532,
      0.28069,
      0.280987,
      0.281068,
      0.28127,
      0.281534,
      0.281876,
      0.282101,
      0.282413,
      0.282664,
      0.282667,
      0.283127,
      0.283556,
      0.283577,
      0.283719,
      0.284098,
      0.284412,
      0.284476,
      0.284699,
      0.28472,
      0.285235,
      0.285555,
      0.285613,
      0.285842,
      0.285863,
      0.286318,
      0.286372,
      0.286625,
      0.28675,
      0.286985,
      0.287081,
      0.28751,
      0.287768,
      0.287844,
      0.287888,
      0.288129,
      0.288448,
      0.288474,
      0.288651,
      0.288727,
      0.288891,
      0.28899,
      0.289373,
      0.289404,
      0.289674,
      0.289782,
      0.290035,
      0.290102,
      0.290119,
      0.290557,
      0.290612,
      0.290625,
      0.290797,
      0.290838,
      0.290932,
      0.29096,
      0.290964,
      0.291091,
      0.29118,
      0.29118,
      0.29118,
      0.29118,
      0.29118,
      0.29118,
      0.291181,
      0.291181,
      0.291181,
      0.291182,
      0.291182,
      0.291182,
      0.291182,
      0.291183,
      0.291183,
      0.291184,
      0.291184,
      0.291184,
      0.291185,
      0.291185,
      0.291185,
      0.291186,
      0.291186,
      0.291186,
      0.291187,
      0.291187,
      0.291188,
      0.291188,
      0.291188,
      0.291188,
      0.291189,
      0.291189,
      0.291189,
      0.291189,
      0.291189,
      0.291189,
      0.29119,
      0.29119,
      0.291191,
      0.291191,
      0.291191,
      0.291192,
      0.291192,
      0.291192,
      0.291193,
      0.291193,
      0.291193,
      0.291194,
      0.291194,
      0.291195,
      0.291195,
      0.291195,
      0.291196,
      0.291196,
      0.291196,
      0.291196,
      0.291197,
      0.291197,
      0.291198,
      0.291187,
      0.29118,
      0.291082,
      0.290903,
      0.290897,
      0.290894,
      0.29082,
      0.290534,
      0.290513,
      0.290268,
      0.29006,
      0.289773,
      0.289754,
      0.289508,
      0.289301,
      0.289084,
      0.289071,
      0.288888,
      0.288704,
      0.288542,
      0.28852,
      0.288396,
      0.288389,
      0.288163,
      0.288147,
      0.288054,
      0.288049,
      0.287864,
      0.287785,
      0.287785,
      0.287786,
      0.287786,
      0.287786,
      0.287787,
      0.287817,
      0.287825,
      0.288015,
      0.288198,
      0.288202,
      0.288203,
      0.288204,
      0.288393,
      0.28855,
      0.28855,
      0.28855,
      0.28855,
      0.288551,
      0.288551,
      0.288551,
      0.288551,
      0.288552,
      0.288552,
      0.288552,
      0.288552,
      0.288841,
      0.288854,
      0.289096,
      0.289315,
      0.289331,
      0.289333,
      0.289334,
      0.289462,
      0.289463,
      0.289464,
      0.289466,
      0.289585,
      0.289592,
      0.289593,
      0.289593,
      0.289697,
      0.289716,
      0.289716,
      0.289717,
      0.289804,
      0.289811,
      0.289813,
      0.289814,
      0.289814,
      0.289899,
      0.28991,
      0.289911,
      0.289912,
      0.290003,
      0.290008,
      0.290008,
      0.290009,
      0.29008,
      0.290106,
      0.290147,
      0.290148,
      0.29015,
      0.290359,
      0.290405,
      0.290407,
      0.290408,
      0.290611,
      0.290638,
      0.290664,
      0.290665,
      0.290667,
      0.290669,
      0.290843,
      0.290859,
      0.290904,
      0.290905,
      0.290906,
      0.290907,
      0.290908,
      0.29091,
      0.291101,
      0.291103,
      0.291104,
      0.291105,
      0.291106,
      0.291107,
      0.291239,
      0.291262,
      0.291299,
      0.2913,
      0.291301,
      0.291303,
      0.291304,
      0.291305,
      0.291497,
      0.291498,
      0.291499,
      0.2915,
      0.291501,
      0.291606,
      0.291615,
      0.291632,
      0.291664,
      0.291665,
      0.291666,
      0.291667,
      0.291667,
      0.291796,
      0.291796,
      0.291797,
      0.291798,
      0.291799,
      0.291869,
      0.291927,
      0.291928,
      0.291928,
      0.291988,
      0.29204,
      0.292124,
      0.292378,
      0.292511,
      0.292631,
      0.292751,
      0.292771,
      0.2928,
      0.2928,
      0.292801,
      0.292851,
      0.292893,
      0.292894,
      0.292894,
      0.292895,
      0.292947,
      0.292985,
      0.292985,
      0.292986,
      0.292986,
      0.292987,
      0.292988,
      0.292988,
      0.293042,
      0.293058,
      0.293078,
      0.293079,
      0.293079,
      0.29308,
      0.293134,
      0.293142,
      0.293442,
      0.293746,
      0.293897,
      0.29391,
      0.294123,
      0.294127,
      0.294588,
      0.294676,
      0.294679,
      0.294683,
      0.294686,
      0.29469,
      0.295039,
      0.295168,
      0.29517,
      0.295173,
      0.295175,
      0.295425,
      0.295539,
      0.295542,
      0.295544,
      0.295546,
      0.295881,
      0.295906,
      0.295908,
      0.29591,
      0.295913,
      0.295915,
      0.295917,
      0.296183,
      0.296184,
      0.29627,
      0.296272,
      0.296275,
      0.296277,
      0.296279,
      0.296282,
      0.296284,
      0.296637,
      0.296639,
      0.296641,
      0.296644,
      0.296646,
      0.296648,
      0.296944,
      0.297003,
      0.297006,
      0.297008,
      0.29701,
      0.297013,
      0.297015,
      0.297025,
      0.297326,
      0.297392,
      0.297395,
      0.297399,
      0.297402,
      0.297406,
      0.297409,
      0.29789,
      0.297951,
      0.297955,
      0.297958,
      0.298454,
      0.298457,
      0.298469,
      0.298548,
      0.298837,
      0.298886,
      0.299215,
      0.299593,
      0.299612,
      0.299667,
      0.29973,
      0.300721,
      0.300755,
      0.300779,
      0.300807,
      0.300992,
      0.301517,
      0.301542,
      0.30157,
      0.301753,
      0.302006,
      0.30228,
      0.302305,
      0.302333,
      0.302512,
      0.302765,
      0.303017,
      0.303043,
      0.303062,
      0.303083,
      0.303213,
      0.303403,
      0.303592,
      0.303655,
      0.303782,
      0.303806,
      0.303831,
      0.304281,
      0.304368,
      0.304569,
      0.304594,
      0.305041,
      0.305131,
      0.305332,
      0.305369,
      0.305656,
      0.306174,
      0.306475,
      0.306512,
      0.306796,
      0.307318,
      0.307618,
      0.307655,
      0.307936,
      0.30808,
      0.30838,
      0.308591,
      0.308659,
      0.308942,
      0.309143,
      0.309562,
      0.309836,
      0.310286,
      0.310306,
      0.310391,
      0.31046,
      0.310594,
      0.310669,
      0.310695,
      0.310878,
      0.311232,
      0.311387,
      0.311432,
      0.311442,
      0.311607,
      0.311633,
      0.311644,
      0.311799,
      0.311815,
      0.311922,
      0.311956,
      0.311969,
      0.31205,
      0.312178,
      0.312197,
      0.312116,
      0.312115,
      0.312092,
      0.312082,
      0.311926,
      0.31183,
      0.31183,
      0.311819,
      0.311808,
      0.311798,
      0.311735,
      0.31164,
      0.311639,
      0.311618,
      0.311513,
      0.311449,
      0.311449,
      0.31144,
      0.31144,
      0.311441,
      0.311441,
      0.311441,
      0.311441,
      0.311442,
      0.311442,
      0.311442,
      0.311443,
      0.311443,
      0.311443,
      0.311564,
      0.311565,
      0.311566,
      0.311589,
      0.311694,
      0.311717,
      0.311727,
      0.311822,
      0.311825,
      0.311825,
      0.311825,
      0.311826,
      0.311826,
      0.311826,
      0.311826,
      0.311827,
      0.311827,
      0.311827,
      0.311826,
      0.311731,
      0.311717,
      0.311636,
      0.311635,
      0.311622,
      0.311616,
      0.31154,
      0.311449,
      0.311449,
      0.311449,
      0.311449,
      0.311449,
      0.31145,
      0.31145,
      0.31145,
      0.31145,
      0.31145,
      0.311451,
      0.311451,
      0.311451,
      0.311451,
      0.311451,
      0.311452,
      0.311452,
      0.311452,
      0.311452,
      0.311453,
      0.311453,
      0.311453,
      0.311453,
      0.311464,
      0.311465,
      0.311474,
      0.311477,
      0.311561,
      0.311562,
      0.311658,
      0.311666,
      0.311754,
      0.311764,
      0.311836,
      0.311836,
      0.311837,
      0.311837,
      0.311837,
      0.311838,
      0.311838,
      0.311816,
      0.311722,
      0.311626,
      0.311533,
      0.311531,
      0.311531,
      0.31146,
      0.311343,
      0.311341,
      0.311248,
      0.311246,
      0.311153,
      0.311152,
      0.311152,
      0.311081,
      0.311049,
      0.311048,
      0.310922,
      0.310796,
      0.310795,
      0.310794,
      0.310793,
      0.310792,
      0.310791,
      0.310791,
      0.310703,
      0.310634,
      0.310624,
      0.310623,
      0.310621,
      0.31062,
      0.310618,
      0.310617,
      0.31013,
      0.310126,
      0.310109,
      0.309943,
      0.309822,
      0.309815,
      0.30972,
      0.309634,
      0.309565,
      0.309529,
      0.309519,
      0.309278,
      0.309266,
      0.309186,
      0.30913,
      0.308925,
      0.308565,
      0.308544,
      0.308427,
      0.308428,
      0.308428,
      0.308429,
      0.308429,
      0.308429,
      0.308429,
      0.308429,
      0.308429,
      0.30843,
      0.30843,
      0.30843,
      0.30843,
      0.30843,
      0.308431,
      0.308431,
      0.308431,
      0.308431,
      0.308409,
      0.308315,
      0.308221,
      0.308216,
      0.308204,
      0.308053,
      0.308074,
      0.30817,
      0.308265,
      0.308272,
      0.308283,
      0.30836,
      0.308436,
      0.30846,
      0.308461,
      0.308586,
      0.308587,
      0.308615,
      0.308713,
      0.308727,
      0.308818,
      0.308849,
      0.30885,
      0.309037,
      0.309038,
      0.309227,
      0.309253,
      0.309275,
      0.309416,
      0.309582,
      0.309598,
      0.309852,
      0.309872,
      0.309886,
      0.309964,
      0.310247,
      0.310571,
      0.310727,
      0.310779,
      0.310938,
      0.311351,
      0.311372,
      0.31149,
      0.311503,
      0.311793,
      0.311872,
      0.311907,
      0.312012,
      0.312047,
      0.312254,
      0.312289,
      0.312376,
      0.312455,
      0.312576,
      0.312637,
      0.31296,
      0.312981,
      0.313169,
      0.3134,
      0.313465,
      0.313655,
      0.313833,
      0.31399,
      0.314035,
      0.314163,
      0.314485,
      0.314499,
      0.314752,
      0.314926,
      0.314959,
      0.315425,
      0.315788,
      0.316069,
      0.316145,
      0.316344,
      0.316398,
      0.316832,
      0.316898,
      0.316905,
      0.317157,
      0.31736,
      0.317595,
      0.317664,
      0.317914,
      0.317916,
      0.318357,
      0.318376,
      0.318423,
      0.31893,
      0.31912,
      0.319133,
      0.319167,
      0.319356,
      0.319732,
      0.31974,
      0.319883,
      0.319899,
      0.320188,
      0.320441,
      0.320646,
      0.320694,
      0.320708,
      0.320914,
      0.320946,
      0.321409,
      0.321453,
      0.321705,
      0.321724,
      0.32193,
      0.321958,
      0.322172,
      0.32221,
      0.322461,
      0.322463,
      0.322691,
      0.322712,
      0.322714,
      0.32274,
      0.322934,
      0.32298,
      0.323358,
      0.323737,
      0.324078,
      0.324087,
      0.324102,
      0.324136,
      0.324609,
      0.32484,
      0.325102,
      0.325116,
      0.325152,
      0.325368,
      0.325603,
      0.32601,
      0.326375,
      0.326388,
      0.326449,
      0.326746,
      0.327014,
      0.327261,
      0.327509,
      0.327527,
      0.327591,
      0.327901,
      0.328286,
      0.328652,
      0.32866,
      0.328915,
      0.32896,
      0.329167,
      0.329415,
      0.329423,
      0.329722,
      0.329928,
      0.329932,
      0.330178,
      0.330181,
      0.330192,
      0.330941,
      0.330956,
      0.331016,
      0.331321,
      0.331333,
      0.331574,
      0.331842,
      0.33208,
      0.332084,
      0.332157,
      0.332457,
      0.332485,
      0.332867,
      0.333216,
      0.333227,
      0.333679,
      0.333975,
      0.333989,
      0.334005,
      0.334548,
      0.334578,
      0.334899,
      0.335118,
      0.335133,
      0.335537,
      0.335872,
      0.335895,
      0.33594,
      0.336165,
      0.336386,
      0.336658,
      0.337105,
      0.337391,
      0.337442,
      0.337801,
      0.338532,
      0.338582,
      0.338626,
      0.338945,
      0.339209,
      0.339685,
      0.339707,
      0.339716,
      0.339748,
      0.339968,
      0.34047,
      0.340475,
      0.34051,
      0.3407,
      0.340982,
      0.341233,
      0.341329,
      0.341343,
      0.341415,
      0.341424,
      0.341615,
      0.341616,
      0.341616,
      0.341616,
      0.341617,
      0.341617,
      0.341618,
      0.341618,
      0.341533,
      0.341509,
      0.341431,
      0.34125,
      0.341243,
      0.34124,
      0.341221,
      0.340992,
      0.340875,
      0.340861,
      0.340752,
      0.340675,
      0.340588,
      0.340483,
      0.340483,
      0.340483,
      0.340484,
      0.340484,
      0.340485,
      0.340447,
      0.34001,
      0.339988,
      0.339726,
      0.339615,
      0.33958,
      0.339363,
      0.339351,
      0.339347,
      0.338993,
      0.338938,
      0.338784,
      0.338615,
      0.338595,
      0.338588,
      0.338467,
      0.338354,
      0.338342,
      0.338316,
      0.338216,
      0.338209,
      0.337994,
      0.337973,
      0.337971,
      0.337721,
      0.337664,
      0.337488,
      0.337469,
      0.33745,
      0.337276,
      0.3371,
      0.337087,
      0.336851,
      0.336706,
      0.336705,
      0.336691,
      0.336585,
      0.336573,
      0.336573,
      0.336572,
      0.336445,
      0.336444,
      0.336443,
      0.336442,
      0.336419,
      0.336332,
      0.336315,
      0.336315,
      0.336314,
      0.336313,
      0.336312,
      0.336059,
      0.336057,
      0.336056,
      0.336054,
      0.335799,
      0.335798,
      0.335796,
      0.335795,
      0.335594,
      0.335553,
      0.335533,
      0.335531,
      0.335529,
      0.335493,
      0.335146,
      0.335143,
      0.335141,
      0.335139,
      0.334855,
      0.334756,
      0.334754,
      0.334751,
      0.334749,
      0.334413,
      0.334366,
      0.334364,
      0.334096,
      0.333971,
      0.333576,
      0.333573,
      0.333571,
      0.333337,
      0.333273,
      0.333217,
      0.333215,
      0.333213,
      0.333212,
      0.332978,
      0.332957,
      0.332955,
      0.332954,
      0.332952,
      0.33281,
      0.332697,
      0.332696,
      0.332694,
      0.332693,
      0.332691,
      0.332514,
      0.332397,
      0.332395,
      0.332393,
      0.332391,
      0.332199,
      0.332008,
      0.332005,
      0.332003,
      0.331689,
      0.33162,
      0.331618,
      0.331616,
      0.331374,
      0.33128,
      0.331278,
      0.331277,
      0.331165,
      0.331022,
      0.33102,
      0.33077,
      0.330768,
      0.330659,
      0.330615,
      0.330571,
      0.330521,
      0.330519,
      0.330518,
      0.330274,
      0.330272,
      0.330271,
      0.330269,
      0.330154,
      0.330025,
      0.330024,
      0.330022,
      0.329855,
      0.329811,
      0.329778,
      0.329776,
      0.329775,
      0.329773,
      0.329649,
      0.329532,
      0.329531,
      0.329529,
      0.329528,
      0.329526,
      0.329287,
      0.329285,
      0.329283,
      0.329282,
      0.32928,
      0.329143,
      0.329096,
      0.329074,
      0.329073,
      0.328985,
      0.32898,
      0.328925,
      0.328887,
      0.328887,
      0.328886,
      0.328886,
      0.328796,
      0.328795,
      0.328794,
      0.328794,
      0.328793,
      0.328736,
      0.328718,
      0.328677,
      0.328676,
      0.328674,
      0.328673,
      0.328427,
      0.328426,
      0.328262,
      0.328178,
      0.328177,
      0.328175,
      0.327958,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.327959,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.32796,
      0.327961,
      0.327959,
      0.327958,
      0.327887,
      0.327849,
      0.327698,
      0.327583,
      0.32756,
      0.327463,
      0.327359,
      0.32721,
      0.327204,
      0.327058,
      0.32698,
      0.326832,
      0.326489,
      0.326452,
      0.326445,
      0.326445,
      0.326447,
      0.326447,
      0.326447,
      0.326335,
      0.32628,
      0.326165,
      0.326092,
      0.326068,
      0.326069,
      0.32607,
      0.32607,
      0.326071,
      0.326071,
      0.326071,
      0.326072,
      0.326073,
      0.326073,
      0.32609,
      0.32624,
      0.326359,
      0.326376,
      0.326456,
      0.326331,
      0.326235,
      0.326108,
      0.326077,
      0.326093,
      0.326172,
      0.326245,
      0.32638,
      0.326437,
      0.32646,
      0.32646,
      0.326557,
      0.326715,
      0.326813,
      0.326842,
      0.326842,
      0.326842,
      0.326843,
      0.326844,
      0.326844,
      0.326844,
      0.326844,
      0.326845,
      0.326845,
      0.326846,
      0.326846,
      0.326847,
      0.326847,
      0.326848,
      0.326848,
      0.326848,
      0.326849,
      0.326849,
      0.326926,
      0.326947,
      0.327022,
      0.327055,
      0.327119,
      0.327139,
      0.327215,
      0.327232,
      0.327337,
      0.327364,
      0.327466,
      0.327466,
      0.327492,
      0.327506,
      0.327594,
      0.327614,
      0.327614,
      0.327615,
      0.327615,
      0.327615,
      0.327616,
      0.327616,
      0.327616,
      0.327617,
      0.327617,
      0.327617,
      0.327618,
      0.327618,
      0.327618,
      0.327618,
      0.327532,
      0.327517,
      0.32751,
      0.327327,
      0.327242,
      0.32724,
      0.327147,
      0.327138,
      0.327132,
      0.326957,
      0.326948,
      0.326862,
      0.326862,
      0.326608,
      0.326585,
      0.326571,
      0.326353,
      0.32633,
      0.326103,
      0.326098,
      0.326075,
      0.325843,
      0.325811,
      0.325588,
      0.325567,
      0.325343,
      0.325334,
      0.325059,
      0.324825,
      0.324821,
      0.324798,
      0.324566,
      0.324564,
      0.324551,
      0.324296
  ],
  bloodOxygenationValues: [
      0.100243,
      0.100496,
      0.100839,
      0.100872,
      0.100878,
      0.101261,
      0.101264,
      0.10163,
      0.101631,
      0.101646,
      0.101765,
      0.101982,
      0.102023,
      0.102052,
      0.102589,
      0.102626,
      0.103124,
      0.103174,
      0.103526,
      0.103557,
      0.103668,
      0.103905,
      0.10394,
      0.104268,
      0.104282,
      0.104283,
      0.104659,
      0.104661,
      0.104703,
      0.105037,
      0.105085,
      0.10519,
      0.105411,
      0.105415,
      0.105468,
      0.105792,
      0.105793,
      0.105851,
      0.106169,
      0.106171,
      0.106233,
      0.106332,
      0.106548,
      0.106554,
      0.106926,
      0.106997,
      0.107303,
      0.107304,
      0.10738,
      0.107682,
      0.107698,
      0.107762,
      0.107855,
      0.108061,
      0.108145,
      0.108439,
      0.10846,
      0.108528,
      0.10891,
      0.108913,
      0.109198,
      0.109295,
      0.109377,
      0.109575,
      0.109576,
      0.109603,
      0.109678,
      0.109954,
      0.110061,
      0.110713,
      0.110746,
      0.110824,
      0.1109,
      0.111207,
      0.111854,
      0.111889,
      0.11197,
      0.112042,
      0.112353,
      0.112652,
      0.112708,
      0.112963,
      0.113136,
      0.113218,
      0.113261,
      0.113414,
      0.113504,
      0.113506,
      0.114139,
      0.114328,
      0.114558,
      0.114618,
      0.114787,
      0.115295,
      0.11532,
      0.115421,
      0.115635,
      0.116083,
      0.116196,
      0.11634,
      0.116436,
      0.117227,
      0.117377,
      0.117567,
      0.117694,
      0.117989,
      0.118089,
      0.118723,
      0.118752,
      0.118794,
      0.119018,
      0.119365,
      0.119493,
      0.119515,
      0.119615,
      0.119741,
      0.120076,
      0.120249,
      0.120278,
      0.120632,
      0.120837,
      0.121012,
      0.121041,
      0.121344,
      0.121395,
      0.121775,
      0.121803,
      0.12203,
      0.12207,
      0.122164,
      0.12241,
      0.122567,
      0.122668,
      0.122865,
      0.123047,
      0.123301,
      0.123329,
      0.123351,
      0.12338,
      0.12357,
      0.123712,
      0.123752,
      0.123813,
      0.124259,
      0.124445,
      0.124474,
      0.124551,
      0.124693,
      0.124883,
      0.125124,
      0.125215,
      0.125238,
      0.125255,
      0.125544,
      0.12562,
      0.125632,
      0.125704,
      0.125727,
      0.125755,
      0.125822,
      0.126003,
      0.126016,
      0.126017,
      0.126055,
      0.126115,
      0.126142,
      0.126269,
      0.126369,
      0.126385,
      0.126393,
      0.126425,
      0.126488,
      0.126565,
      0.126583,
      0.126616,
      0.126678,
      0.126768,
      0.126808,
      0.126869,
      0.126947,
      0.126964,
      0.127096,
      0.127151,
      0.127156,
      0.127283,
      0.127333,
      0.12739,
      0.12741,
      0.127533,
      0.127535,
      0.127535,
      0.127617,
      0.12763,
      0.12767,
      0.127725,
      0.127809,
      0.127809,
      0.12782,
      0.127862,
      0.127905,
      0.127915,
      0.127916,
      0.127916,
      0.127917,
      0.127917,
      0.127917,
      0.127918,
      0.127918,
      0.127918,
      0.127918,
      0.127919,
      0.127919,
      0.12792,
      0.12792,
      0.12792,
      0.12792,
      0.12792,
      0.12792,
      0.128037,
      0.128106,
      0.12817,
      0.128292,
      0.128297,
      0.128302,
      0.128303,
      0.128303,
      0.128303,
      0.128304,
      0.128304,
      0.128305,
      0.128305,
      0.128305,
      0.128305,
      0.128306,
      0.128306,
      0.128306,
      0.128307,
      0.128307,
      0.128307,
      0.128307,
      0.128308,
      0.128308,
      0.128309,
      0.128309,
      0.128309,
      0.128309,
      0.128263,
      0.128129,
      0.128073,
      0.128035,
      0.127978,
      0.127941,
      0.127936,
      0.127931,
      0.127932,
      0.127932,
      0.127932,
      0.127933,
      0.127933,
      0.127933,
      0.127933,
      0.127934,
      0.127934,
      0.127934,
      0.127934,
      0.127935,
      0.127935,
      0.127935,
      0.127935,
      0.127936,
      0.127869,
      0.127701,
      0.127693,
      0.127616,
      0.127557,
      0.127557,
      0.127557,
      0.127558,
      0.127558,
      0.127558,
      0.127558,
      0.127558,
      0.127559,
      0.127559,
      0.127508,
      0.12748,
      0.127474,
      0.127318,
      0.127291,
      0.127285,
      0.127181,
      0.127181,
      0.127181,
      0.127182,
      0.127182,
      0.127182,
      0.127183,
      0.127183,
      0.127183,
      0.1271,
      0.127011,
      0.126941,
      0.126816,
      0.126805,
      0.126805,
      0.126805,
      0.126806,
      0.126807,
      0.126807,
      0.126807,
      0.126706,
      0.126608,
      0.126428,
      0.126429,
      0.126429,
      0.126429,
      0.12643,
      0.12643,
      0.12643,
      0.126374,
      0.126355,
      0.126348,
      0.126348,
      0.126254,
      0.126185,
      0.126161,
      0.12616,
      0.126071,
      0.126067,
      0.126052,
      0.125976,
      0.125946,
      0.125946,
      0.12582,
      0.125722,
      0.125699,
      0.125695,
      0.125673,
      0.125674,
      0.125674,
      0.125675,
      0.125675,
      0.125675,
      0.125676,
      0.125676,
      0.125676,
      0.125616,
      0.125504,
      0.125412,
      0.125409,
      0.125408,
      0.125332,
      0.125313,
      0.125313,
      0.125312,
      0.125312,
      0.125297,
      0.125217,
      0.125216,
      0.125216,
      0.125128,
      0.125121,
      0.12512,
      0.12512,
      0.125048,
      0.125025,
      0.125024,
      0.125023,
      0.125023,
      0.125023,
      0.124928,
      0.124927,
      0.124927,
      0.124926,
      0.124919,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.12492,
      0.124921,
      0.124921,
      0.124921,
      0.124921,
      0.124921,
      0.124859,
      0.124847,
      0.124827,
      0.124827,
      0.124826,
      0.124826,
      0.124825,
      0.124824,
      0.124824,
      0.124824,
      0.124729,
      0.124728,
      0.124728,
      0.124727,
      0.124727,
      0.124726,
      0.12467,
      0.124658,
      0.124631,
      0.124631,
      0.12463,
      0.12463,
      0.124629,
      0.124629,
      0.124628,
      0.124543,
      0.124533,
      0.124533,
      0.124532,
      0.124532,
      0.124531,
      0.124531,
      0.12453,
      0.12453,
      0.124469,
      0.124435,
      0.124434,
      0.124434,
      0.124433,
      0.124433,
      0.124432,
      0.124432,
      0.124431,
      0.124431,
      0.124386,
      0.124336,
      0.124335,
      0.124335,
      0.124334,
      0.124334,
      0.124334,
      0.124238,
      0.124238,
      0.124237,
      0.124186,
      0.124164,
      0.124135,
      0.124134,
      0.124134,
      0.12408,
      0.124007,
      0.124006,
      0.124005,
      0.123941,
      0.123878,
      0.123878,
      0.123877,
      0.123827,
      0.123786,
      0.12376,
      0.123721,
      0.123714,
      0.123667,
      0.123667,
      0.123666,
      0.123619,
      0.123574,
      0.123573,
      0.123573,
      0.123532,
      0.123481,
      0.123481,
      0.12348,
      0.12348,
      0.123431,
      0.123407,
      0.123381,
      0.123381,
      0.12338,
      0.12332,
      0.123258,
      0.123257,
      0.123257,
      0.123257,
      0.123256,
      0.123187,
      0.123135,
      0.123135,
      0.123134,
      0.123133,
      0.123133,
      0.123067,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.123029,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.12303,
      0.123031,
      0.123031,
      0.12303,
      0.12303,
      0.12303,
      0.123029,
      0.123029,
      0.123028,
      0.122961,
      0.122938,
      0.122938,
      0.122937,
      0.122937,
      0.122936,
      0.122869,
      0.122845,
      0.122845,
      0.122844,
      0.122773,
      0.122753,
      0.122752,
      0.122752,
      0.122752,
      0.122751,
      0.122679,
      0.122661,
      0.12266,
      0.12266,
      0.122659,
      0.122659,
      0.122653,
      0.122469,
      0.122428,
      0.122427,
      0.122427,
      0.122425,
      0.122424,
      0.122422,
      0.122215,
      0.122181,
      0.122179,
      0.122178,
      0.122176,
      0.121963,
      0.121932,
      0.121931,
      0.121929,
      0.121928,
      0.121893,
      0.121824,
      0.121816,
      0.121815,
      0.121815,
      0.121814,
      0.121814,
      0.121731,
      0.121722,
      0.121722,
      0.121634,
      0.121628,
      0.121542,
      0.121533,
      0.121515,
      0.121412,
      0.121295,
      0.121285,
      0.121173,
      0.121136,
      0.121058,
      0.120971,
      0.12088,
      0.120869,
      0.120758,
      0.120691,
      0.120687,
      0.120679,
      0.120679,
      0.120497,
      0.120489,
      0.120408,
      0.12038,
      0.120273,
      0.120166,
      0.120157,
      0.12004,
      0.120001,
      0.119937,
      0.119936,
      0.11992,
      0.119833,
      0.119748,
      0.119644,
      0.119636,
      0.119623,
      0.119412,
      0.119271,
      0.119261,
      0.119244,
      0.119181,
      0.119162,
      0.118992,
      0.11898,
      0.118972,
      0.118866,
      0.118658,
      0.118639,
      0.118629,
      0.118487,
      0.118426,
      0.118317,
      0.118309,
      0.118237,
      0.118109,
      0.117885,
      0.117695,
      0.117652,
      0.117349,
      0.117289,
      0.117265,
      0.117178,
      0.11717,
      0.117006,
      0.116981,
      0.116971,
      0.116766,
      0.116742,
      0.116605,
      0.116592,
      0.116534,
      0.116345,
      0.116326,
      0.116317,
      0.116214,
      0.116137,
      0.116099,
      0.116011,
      0.115858,
      0.115835,
      0.115661,
      0.115531,
      0.11547,
      0.11528,
      0.115152,
      0.11511,
      0.115076,
      0.114959,
      0.114831,
      0.114748,
      0.11472,
      0.114698,
      0.114608,
      0.114547,
      0.114418,
      0.114335,
      0.114324,
      0.114319,
      0.114245,
      0.114198,
      0.113945,
      0.113941,
      0.113828,
      0.113782,
      0.113567,
      0.113376,
      0.113257,
      0.113182,
      0.113076,
      0.113057,
      0.112931,
      0.11293,
      0.112853,
      0.112803,
      0.112644,
      0.112614,
      0.112497,
      0.112233,
      0.112116,
      0.112044,
      0.112045,
      0.112045,
      0.112045,
      0.112045,
      0.112046,
      0.112042,
      0.112042,
      0.111926,
      0.111885,
      0.111661,
      0.111546,
      0.111287,
      0.111283,
      0.111179,
      0.111079,
      0.111029,
      0.110953,
      0.110908,
      0.110899,
      0.110746,
      0.110709,
      0.110594,
      0.110593,
      0.110592,
      0.11059,
      0.110518,
      0.110399,
      0.110398,
      0.110397,
      0.110327,
      0.110206,
      0.11015,
      0.11006,
      0.110041,
      0.110015,
      0.109933,
      0.109932,
      0.109887,
      0.109805,
      0.109805,
      0.109771,
      0.109584,
      0.109497,
      0.109298,
      0.109243,
      0.109075,
      0.109074,
      0.109011,
      0.109003,
      0.10894,
      0.108939,
      0.108844,
      0.108813,
      0.108749,
      0.10874,
      0.108718,
      0.108654,
      0.108633,
      0.108533,
      0.108533,
      0.108493,
      0.108405,
      0.108405,
      0.108396,
      0.108278,
      0.108277,
      0.108254,
      0.108233,
      0.108097,
      0.108096,
      0.107905,
      0.107904,
      0.107852,
      0.107713,
      0.107712,
      0.107709,
      0.107662,
      0.107496,
      0.107386,
      0.107385,
      0.107384,
      0.107257,
      0.107257,
      0.107227,
      0.107132,
      0.10713,
      0.107129,
      0.107117,
      0.107002,
      0.106875,
      0.106848,
      0.106748,
      0.106738,
      0.106715,
      0.106571,
      0.106561,
      0.10637,
      0.106336,
      0.10618,
      0.106147,
      0.105989,
      0.105979,
      0.105965,
      0.105867,
      0.105858,
      0.105839,
      0.105713,
      0.1056,
      0.105414,
      0.105392,
      0.105242,
      0.105223,
      0.105204,
      0.105203,
      0.105032,
      0.105014,
      0.104842,
      0.104841,
      0.10484,
      0.104831,
      0.104713,
      0.104713,
      0.104706,
      0.104705,
      0.104603,
      0.104585,
      0.104585,
      0.104584,
      0.10458,
      0.104463,
      0.104458,
      0.104458,
      0.104457,
      0.104457,
      0.104363,
      0.104363,
      0.104363,
      0.104362,
      0.104269,
      0.104267,
      0.104266,
      0.10419,
      0.104176,
      0.104175,
      0.104171,
      0.104085,
      0.104076,
      0.103824,
      0.103808,
      0.103572,
      0.103554,
      0.103352,
      0.103325,
      0.103324,
      0.103315,
      0.10323,
      0.103229,
      0.10322,
      0.103135,
      0.103125,
      0.103041,
      0.10303,
      0.103029,
      0.102957,
      0.102947,
      0.102947,
      0.10293,
      0.102822,
      0.102677,
      0.102676,
      0.102675,
      0.10257,
      0.102568,
      0.102528,
      0.102526,
      0.102525,
      0.102523,
      0.102341,
      0.102319,
      0.102268,
      0.102068,
      0.102013,
      0.101809,
      0.101724,
      0.101717,
      0.101695,
      0.1016,
      0.101441,
      0.101433,
      0.101431,
      0.101377,
      0.100869,
      0.100699,
      0.100671,
      0.100548,
      0.100517,
      0.100307,
      0.100295,
      0.100292,
      0.100059,
      0.0999171,
      0.0997449,
      0.0996785,
      0.0995337,
      0.0992957,
      0.0992835,
      0.0991549,
      0.0991092,
      0.0987978,
      0.0985889,
      0.0983961,
      0.0983654,
      0.0982848,
      0.0980191,
      0.0980173,
      0.0979717,
      0.0976605,
      0.0974027,
      0.0972606,
      0.0972586,
      0.0970209,
      0.0970072,
      0.0969759,
      0.0968797,
      0.0967546,
      0.0965157,
      0.0965009,
      0.0964562,
      0.0963128,
      0.0959543,
      0.0958872,
      0.0957422,
      0.0956168,
      0.0955864,
      0.0955047,
      0.0954609,
      0.0953634,
      0.0950537,
      0.0948879,
      0.0948589,
      0.094604,
      0.0945832,
      0.0944266,
      0.0944157,
      0.0943945,
      0.0942379,
      0.0942265,
      0.0942258,
      0.0941991,
      0.0940735,
      0.0939743,
      0.0939479,
      0.0938633,
      0.093847,
      0.0936199,
      0.093493,
      0.0934312,
      0.0932795,
      0.0931142,
      0.0930882,
      0.0930646,
      0.092939,
      0.0928367,
      0.0928132,
      0.0927269,
      0.0927094,
      0.0926768,
      0.0923569,
      0.0922982,
      0.0921416,
      0.0921096,
      0.0919506,
      0.0919109,
      0.0917343,
      0.0916587,
      0.0912283,
      0.0911925,
      0.0911913,
      0.0911732,
      0.0911725,
      0.0910475,
      0.0909392,
      0.0908313,
      0.0908125,
      0.0907871,
      0.090623,
      0.0905984,
      0.0904097,
      0.0902722,
      0.0902207,
      0.0900537,
      0.0900532,
      0.0900393,
      0.0899138,
      0.0898207,
      0.0897999,
      0.0897882,
      0.0896749,
      0.0895953,
      0.0895787,
      0.089571,
      0.0894775,
      0.089477,
      0.0893891,
      0.0893835,
      0.0893118,
      0.0892967,
      0.0892901,
      0.0890364,
      0.0890236,
      0.0887718,
      0.0885778,
      0.0885374,
      0.0885281,
      0.08852,
      0.0880723,
      0.0880198,
      0.0880144,
      0.087778,
      0.0877733,
      0.0877723,
      0.0876782,
      0.0876045,
      0.0875842,
      0.0875835,
      0.0874886,
      0.0874883,
      0.0873998,
      0.0873886,
      0.0871892,
      0.0871364,
      0.0871291,
      0.0866404,
      0.0866358,
      0.0866306,
      0.0865357,
      0.0865101,
      0.0863845,
      0.0863774,
      0.0862616,
      0.0861833,
      0.0861649,
      0.0860641,
      0.0859763,
      0.0859694,
      0.0859,
      0.0858834,
      0.0856266,
      0.0856065,
      0.0851684,
      0.0851241,
      0.0851231,
      0.0850207,
      0.0849343,
      0.0848571,
      0.0848403,
      0.0847458,
      0.0844932,
      0.0844693,
      0.0840308,
      0.0839876,
      0.0839865,
      0.0838488,
      0.0837352,
      0.0836296,
      0.0836077,
      0.0835995,
      0.0835145,
      0.0835052,
      0.0834204,
      0.0834109,
      0.0832456,
      0.0832317,
      0.0832294,
      0.0832098,
      0.0829834,
      0.0827046,
      0.0824782,
      0.0824701,
      0.0823118,
      0.082267,
      0.0820974,
      0.0818888,
      0.0818885,
      0.0817189,
      0.0817113,
      0.0817035,
      0.0816054,
      0.0814644,
      0.0814516,
      0.0813325,
      0.0812123,
      0.0811995,
      0.0810999,
      0.0809603,
      0.0809537,
      0.0809445,
      0.0806032,
      0.0805657,
      0.0803949,
      0.0803764,
      0.080195,
      0.0801896,
      0.0800759,
      0.0799611,
      0.0799377,
      0.0798161,
      0.079346,
      0.0793237,
      0.0793005,
      0.0790568,
      0.0788806,
      0.0788645,
      0.0787784,
      0.0786786,
      0.0785482,
      0.0784426,
      0.0784222,
      0.0783065,
      0.0782998,
      0.0781044,
      0.0779441,
      0.0777259,
      0.0775505,
      0.077541,
      0.0775385,
      0.0774299,
      0.0774127,
      0.0772872,
      0.0771766,
      0.0771622,
      0.0771613,
      0.0771607,
      0.0769816,
      0.0769738,
      0.0769726,
      0.0767851,
      0.0766138,
      0.076597,
      0.0765964,
      0.0764078,
      0.0764034,
      0.0762831,
      0.0762806,
      0.0760377,
      0.0760271,
      0.0760263,
      0.0760246,
      0.075844,
      0.0758362,
      0.0758356,
      0.0758351,
      0.0758339,
      0.0758328,
      0.0758316,
      0.0756406,
      0.07564,
      0.0756395,
      0.0756383,
      0.0756371,
      0.0754739,
      0.0754462,
      0.075445,
      0.0754444,
      0.0754438,
      0.0754427,
      0.0752754,
      0.0752659,
      0.0752564,
      0.0752557,
      0.0752549,
      0.0752545,
      0.0751278,
      0.075127,
      0.0751262,
      0.0751255,
      0.0751247,
      0.0750249,
      0.0750201,
      0.074998,
      0.0749976,
      0.0749968,
      0.0749961,
      0.0749953,
      0.074887,
      0.0748682,
      0.0748678,
      0.0748674,
      0.0748667,
      0.0748659,
      0.0747388,
      0.074738,
      0.0747378,
      0.0747374,
      0.0747367,
      0.0746453,
      0.0746417,
      0.0746096,
      0.0746088,
      0.074608,
      0.0746076,
      0.0746073,
      0.0745082,
      0.074466,
      0.0744649,
      0.0744637,
      0.0744626,
      0.074462,
      0.0744614,
      0.0743299,
      0.0742704,
      0.0742693,
      0.0742681,
      0.074267,
      0.0742664,
      0.0742658,
      0.0742646,
      0.0741442,
      0.0740737,
      0.0740725,
      0.0740713,
      0.0740708,
      0.0740702,
      0.074069,
      0.0739511,
      0.073878,
      0.0738769,
      0.0738757,
      0.0738752,
      0.0738746,
      0.0738734,
      0.0738723,
      0.0737495,
      0.0736585,
      0.0736569,
      0.0736561,
      0.0736554,
      0.0736538,
      0.0736523,
      0.0735135,
      0.0733974,
      0.0733958,
      0.0733951,
      0.0733943,
      0.073262,
      0.0731394,
      0.0730079,
      0.0729901,
      0.072939,
      0.0728734,
      0.0728138,
      0.0728134,
      0.0727463,
      0.0726894,
      0.0726886,
      0.0726879,
      0.0726213,
      0.0726113,
      0.0725786,
      0.0725781,
      0.0725775,
      0.0725769,
      0.0725766,
      0.0725763,
      0.0725758,
      0.0724869,
      0.0724863,
      0.0724858,
      0.0724852,
      0.0724849,
      0.0724846,
      0.0724302,
      0.0724283,
      0.0723946,
      0.0723941,
      0.0723935,
      0.0723932,
      0.0723929,
      0.072336,
      0.0722331,
      0.0721606,
      0.0720036,
      0.0719115,
      0.0719099,
      0.0719091,
      0.0719084,
      0.0717418,
      0.0716658,
      0.0716643,
      0.0716635,
      0.0716627,
      0.0716612,
      0.0716596,
      0.071498,
      0.0714737,
      0.0714537,
      0.0714532,
      0.0714529,
      0.0714526,
      0.071452,
      0.0714514,
      0.071362,
      0.0713614,
      0.0713612,
      0.0713609,
      0.0713603,
      0.0713597,
      0.0712942,
      0.0712897,
      0.0712697,
      0.0712694,
      0.0712691,
      0.0712686,
      0.071268,
      0.071178,
      0.0711777,
      0.0711774,
      0.0711769,
      0.0711763,
      0.0711757,
      0.0711056,
      0.0710955,
      0.0710847,
      0.071084,
      0.0710832,
      0.0710828,
      0.0710824,
      0.0710817,
      0.0710809,
      0.0710801,
      0.0709622,
      0.0709615,
      0.0709607,
      0.0709603,
      0.0709599,
      0.0709592,
      0.0708569,
      0.0708492,
      0.0708398,
      0.070839,
      0.0708382,
      0.0708378,
      0.0708375,
      0.0708367,
      0.0707173,
      0.0707167,
      0.0707164,
      0.0707152,
      0.0707147,
      0.0707141,
      0.0707129,
      0.0705485,
      0.0705306,
      0.07053,
      0.0705289,
      0.0703411,
      0.07017,
      0.0701564,
      0.0699605,
      0.0699579,
      0.069847,
      0.0697062,
      0.0695949,
      0.0695847,
      0.0695791,
      0.069453,
      0.069343,
      0.0692003,
      0.0691992,
      0.0690359,
      0.0690186,
      0.0688192,
      0.0686288,
      0.0684673,
      0.0684496,
      0.0684415,
      0.0682068,
      0.0681866,
      0.0680627,
      0.0679722,
      0.067966,
      0.0678871,
      0.0677931,
      0.0676845,
      0.0674697,
      0.0674423,
      0.0674242,
      0.0669641,
      0.0669252,
      0.0668346,
      0.0668274,
      0.0667511,
      0.0666381,
      0.0665623,
      0.0665469,
      0.0665419,
      0.0664258,
      0.0661681,
      0.0661597,
      0.0660124,
      0.0657954,
      0.0656336,
      0.0655894,
      0.0654093,
      0.0652869,
      0.0651801,
      0.0651498,
      0.0650305,
      0.0648765,
      0.0646561,
      0.0646393,
      0.064498,
      0.0644489,
      0.0642718,
      0.0641702,
      0.0641368,
      0.0640447,
      0.064022,
      0.063893,
      0.0638835,
      0.0636428,
      0.0635397,
      0.0635141,
      0.0635065,
      0.0633169,
      0.0632498,
      0.0632321,
      0.0631359,
      0.0628594,
      0.0626053,
      0.0624279,
      0.0623797,
      0.0623766,
      0.0623672,
      0.0621774,
      0.0620175,
      0.061999,
      0.0619983,
      0.0619843,
      0.0617309,
      0.0616449,
      0.0616195,
      0.0614308,
      0.0614063,
      0.0612159,
      0.0610255,
      0.060898,
      0.060861,
      0.0608608,
      0.0607171,
      0.06059,
      0.0604819,
      0.0604055,
      0.0603872,
      0.0603731,
      0.0601835,
      0.0601832,
      0.0601213,
      0.0601037,
      0.0600833,
      0.0599768,
      0.0598741,
      0.0598299,
      0.0597249,
      0.0595018,
      0.0593787,
      0.0593435,
      0.0593111,
      0.0589661,
      0.0589662,
      0.0589666,
      0.0589667,
      0.0589668,
      0.0589672,
      0.0589674,
      0.0589677,
      0.0589677,
      0.0589678,
      0.05893,
      0.0586156,
      0.058585,
      0.0585492,
      0.0584249,
      0.0582339,
      0.0582328,
      0.0582091,
      0.0581822,
      0.0579714,
      0.0579539,
      0.0579287,
      0.0578443,
      0.0578303,
      0.0578091,
      0.0578088,
      0.0577456,
      0.0576504,
      0.0576192,
      0.0575446,
      0.0574608,
      0.0574602,
      0.057452,
      0.0574218,
      0.0573359,
      0.0572088,
      0.057208,
      0.057197,
      0.0571686,
      0.0570732,
      0.0568952,
      0.0568357,
      0.0568351,
      0.0567042,
      0.0567031,
      0.0566447,
      0.0565121,
      0.0565109,
      0.0565005,
      0.0563144,
      0.056281,
      0.0561918,
      0.056191,
      0.0560639,
      0.0560631,
      0.0560597,
      0.0560275,
      0.055936,
      0.0559356,
      0.0558148,
      0.0557463,
      0.0556511,
      0.0556505,
      0.0556504,
      0.0556251,
      0.0555574,
      0.0555547,
      0.0555212,
      0.0553945,
      0.055303,
      0.0553013,
      0.0551786,
      0.0551719,
      0.0551711,
      0.0551222,
      0.0549801,
      0.0547891,
      0.0547879,
      0.0547417,
      0.0546062,
      0.0545969,
      0.0545958,
      0.0544198,
      0.0544199,
      0.0544199,
      0.0544204,
      0.0544206,
      0.054421,
      0.0544215,
      0.0544216,
      0.0544216,
      0.0544217,
      0.0544221,
      0.0544075,
      0.0544067,
      0.0543856,
      0.0543852,
      0.0542796,
      0.0542788,
      0.0542598,
      0.0541677,
      0.0540433,
      0.0540256,
      0.0540248,
      0.0540079,
      0.0538977,
      0.0538823,
      0.0537706,
      0.0537698,
      0.0537571,
      0.0537568,
      0.0536645,
      0.0536616,
      0.0536145,
      0.053442,
      0.0534408,
      0.0534258,
      0.0532498,
      0.0532487,
      0.0532475,
      0.0532371,
      0.0530926,
      0.0530565,
      0.0530493,
      0.0530482,
      0.0529057,
      0.0529058,
      0.0529058,
      0.0529058,
      0.0529064,
      0.052907,
      0.052907,
      0.0529074,
      0.0529075,
      0.0529075,
      0.052908,
      0.0528818,
      0.0528814,
      0.0528811,
      0.0528764,
      0.0528757,
      0.0527563,
      0.0527486,
      0.0527478,
      0.0526307,
      0.0526207,
      0.0526199,
      0.0526192,
      0.0525292,
      0.0525269,
      0.052493,
      0.0524734,
      0.0524722,
      0.0523047,
      0.0523041,
      0.0521154,
      0.0520917,
      0.0520906,
      0.0519579,
      0.0519267,
      0.0518996,
      0.0518984,
      0.0517704,
      0.0517489,
      0.0517285,
      0.0516233,
      0.0516014,
      0.0514977,
      0.0513916,
      0.0513906,
      0.0513768,
      0.0513589,
      0.0512823,
      0.0512639,
      0.0511883,
      0.0511071,
      0.0510134,
      0.0509782,
      0.0509049,
      0.050883,
      0.0508237,
      0.0507879,
      0.0507163,
      0.0506927,
      0.0506351,
      0.0505851,
      0.0505084,
      0.050458,
      0.0504572,
      0.0502563,
      0.0502428,
      0.0501225,
      0.050067,
      0.0500536,
      0.0499329,
      0.0498781,
      0.0498598,
      0.0497517,
      0.0494992,
      0.0494853,
      0.0494591,
      0.0494049,
      0.0491756,
      0.0491214,
      0.049121,
      0.049102,
      0.0488153,
      0.048743,
      0.0487422,
      0.0487275,
      0.0485134,
      0.0484594,
      0.0483639,
      0.0483488,
      0.048176,
      0.0481596,
      0.0481354,
      0.0479857,
      0.0479858,
      0.0479859,
      0.0479863,
      0.0479871,
      0.0479874,
      0.0478016,
      0.0477664,
      0.0477213,
      0.0474225,
      0.0473425,
      0.0472286,
      0.0472075,
      0.0471786,
      0.0469791,
      0.0468498,
      0.0468499,
      0.0468501,
      0.0468506,
      0.046851,
      0.0468516,
      0.0468518,
      0.0468521,
      0.0468521,
      0.0468167,
      0.0467425,
      0.0467225,
      0.0466481,
      0.0465339,
      0.0464772,
      0.0464739,
      0.0463281,
      0.046302,
      0.046226,
      0.0461764,
      0.0460951,
      0.0459862,
      0.0459672,
      0.0459097,
      0.0458732,
      0.0457168,
      0.0457027,
      0.0456846,
      0.0456262,
      0.0454958,
      0.0454193,
      0.0454018,
      0.0453429,
      0.0453386,
      0.0453386,
      0.0453388,
      0.0453398,
      0.0453399,
      0.0453402,
      0.0453408,
      0.0453008,
      0.0450949,
      0.0450695,
      0.0450489,
      0.044962,
      0.0448385,
      0.0447583,
      0.0447449,
      0.0447443,
      0.0446834,
      0.0446515,
      0.0446509,
      0.0445838,
      0.0445486,
      0.0444373,
      0.044423,
      0.0443382,
      0.0442975,
      0.044205,
      0.0441893,
      0.0441802,
      0.0440945,
      0.0440862,
      0.0440211,
      0.0439997,
      0.043992,
      0.043898,
      0.0438267,
      0.0438268,
      0.0438269,
      0.0438274,
      0.0438278,
      0.0438285,
      0.0438286,
      0.043829,
      0.0438078,
      0.0437167,
      0.0437138,
      0.0436451,
      0.0436197,
      0.0435271,
      0.0435262,
      0.0435256,
      0.0434508,
      0.043426,
      0.0434256,
      0.0433004,
      0.0432985,
      0.0432046,
      0.0431748,
      0.0431714,
      0.0430719,
      0.0430726,
      0.0430726,
      0.0430726,
      0.0430731,
      0.0430732,
      0.0430742,
      0.0430588,
      0.0430531,
      0.0429583,
      0.0428892,
      0.0428701,
      0.0428634,
      0.042696,
      0.0426664,
      0.0425746,
      0.04255,
      0.0424129,
      0.0423172,
      0.0423173,
      0.0423173,
      0.0423177,
      0.042319,
      0.042319,
      0.0423194,
      0.04232,
      0.0423201,
      0.0423202,
      0.0423207,
      0.0423212,
      0.0423217,
      0.0423217,
      0.0422044,
      0.04212,
      0.0420156,
      0.0419458,
      0.0419435,
      0.0418008,
      0.0416616,
      0.0415672,
      0.0415647,
      0.0415647,
      0.0415648,
      0.0415654,
      0.0415659,
      0.0415664,
      0.0415664,
      0.0415669,
      0.0415463,
      0.0414613,
      0.0414523,
      0.0412843,
      0.0412637,
      0.0411887,
      0.0411779,
      0.0410749,
      0.0410002,
      0.0409891,
      0.0409806,
      0.0408863,
      0.0408105,
      0.040811,
      0.0408111,
      0.0408111,
      0.0408116,
      0.0408122,
      0.0408122,
      0.0408123,
      0.0408127,
      0.0408134,
      0.0408134,
      0.0408139,
      0.0408145,
      0.040815,
      0.0408127,
      0.0408035,
      0.0406666,
      0.0405514,
      0.0405404,
      0.0404362,
      0.0404362,
      0.0404373,
      0.0404374,
      0.040438,
      0.0404385,
      0.0404353,
      0.0403362,
      0.0402455,
      0.0401476,
      0.0401383,
      0.0400602,
      0.0400608,
      0.0400614,
      0.0400615,
      0.0400625,
      0.0400625,
      0.0400625,
      0.0400626,
      0.0400626,
      0.0400632,
      0.0400632,
      0.0400637,
      0.0400642,
      0.0400643,
      0.0400648,
      0.0400602,
      0.0400524,
      0.0400521,
      0.0400518,
      0.0399647,
      0.0399584,
      0.0398649,
      0.0398643,
      0.0397761,
      0.0396865,
      0.0396866,
      0.0396866,
      0.0396866,
      0.0396872,
      0.0396872,
      0.0396877,
      0.0396877,
      0.0396877,
      0.0396882,
      0.0396883,
      0.0396883,
      0.0396883,
      0.0396883,
      0.0396883,
      0.0396888,
      0.0396889,
      0.0396889,
      0.0396889,
      0.0396889,
      0.0396889,
      0.0396889,
      0.0396889,
      0.0396895,
      0.0396895,
      0.0396895,
      0.0396895,
      0.0396895,
      0.0396895,
      0.03969,
      0.0396901,
      0.0396901,
      0.0396901,
      0.0396901,
      0.0396901,
      0.0396905,
      0.0396905,
      0.0396907,
      0.0396907,
      0.0396907,
      0.0396907,
      0.0396912,
      0.0396912,
      0.0396917,
      0.0396923,
      0.0396924,
      0.0396924,
      0.0396928,
      0.0396899,
      0.0396581,
      0.0396566,
      0.0395606,
      0.0395592,
      0.0394631,
      0.0394617,
      0.0394065,
      0.0394031,
      0.0393657,
      0.0393642,
      0.0393145,
      0.0393148,
      0.0393148,
      0.0393151,
      0.0393154,
      0.039316,
      0.039316,
      0.0393163,
      0.0393163,
      0.0393166,
      0.0393168,
      0.0393171,
      0.0393174,
      0.039318,
      0.0393183,
      0.0393185,
      0.0393188,
      0.0393191,
      0.0393194,
      0.0393197,
      0.0393199,
      0.03932,
      0.0393203,
      0.0393205,
      0.0393205,
      0.0393208,
      0.0393211,
      0.0393211,
      0.0393214,
      0.0393216,
      0.0393222,
      0.0393222,
      0.0393222,
      0.0393225,
      0.0393225,
      0.0393227,
      0.0393227,
      0.0393233,
      0.0393233,
      0.0393236,
      0.0393236,
      0.0393238,
      0.0393244,
      0.0393248,
      0.0393248,
      0.0393249,
      0.0393249,
      0.0393255,
      0.0393255,
      0.0393259,
      0.0393259,
      0.039326,
      0.0393265,
      0.0393266,
      0.039327,
      0.0393272,
      0.0393272,
      0.0393277,
      0.0393277,
      0.0393282,
      0.0393282,
      0.0393283,
      0.0393287,
      0.0393288,
      0.0393299,
      0.0393305,
      0.0393305,
      0.0393305,
      0.039331,
      0.0393321,
      0.0393327,
      0.0393328,
      0.0393328,
      0.0393333,
      0.0393338,
      0.0393345,
      0.039335,
      0.0394246,
      0.0394431,
      0.0395335,
      0.0396153,
      0.0397178,
      0.0397183,
      0.039719,
      0.0397195,
      0.0397201,
      0.0397207,
      0.0397212,
      0.0397213,
      0.0397224,
      0.0398383,
      0.039858,
      0.0401046,
      0.0401911,
      0.0403019,
      0.0404053,
      0.0404776,
      0.0404874,
      0.0406845,
      0.0407643,
      0.0408702,
      0.0408707,
      0.0408713,
      0.0408714,
      0.0408719,
      0.0409565,
      0.0410686,
      0.0412432,
      0.0412547,
      0.0415161,
      0.0416208,
      0.0416369,
      0.0416665,
      0.0422185,
      0.042375,
      0.0424002,
      0.0424014,
      0.0424014,
      0.0424018,
      0.0424019,
      0.0429821,
      0.0431397,
      0.0431652,
      0.0432093,
      0.043894,
      0.043928,
      0.0439313,
      0.0441368,
      0.0442026,
      0.044218,
      0.0443108,
      0.0445323,
      0.0448682,
      0.0450735,
      0.045077,
      0.0451848,
      0.04544,
      0.0454557,
      0.0454949,
      0.0456514,
      0.0458148,
      0.046197,
      0.046219,
      0.0462739,
      0.0464792,
      0.0466992,
      0.0469818,
      0.0472087,
      0.0472099,
      0.0472934,
      0.0474654,
      0.0474952,
      0.0477445,
      0.0479749,
      0.0482305,
      0.048232,
      0.0483128,
      0.0484876,
      0.0485073,
      0.0485094,
      0.0487527,
      0.0487534,
      0.0488816,
      0.0488895,
      0.0489599,
      0.049159,
      0.0491799,
      0.0494464,
      0.0497338,
      0.0500207,
      0.0500333,
      0.0500372,
      0.0500987,
      0.0502777,
      0.0505332,
      0.0505347,
      0.0507903,
      0.0507961,
      0.0507999,
      0.0510458,
      0.0510474,
      0.0511181,
      0.0513029,
      0.0515584,
      0.0515588,
      0.0515606,
      0.0519434,
      0.0519451,
      0.0519457,
      0.051948,
      0.0523308,
      0.0523332,
      0.0527021,
      0.0527114,
      0.0527129,
      0.0527713,
      0.0529599,
      0.0529684,
      0.0532236,
      0.0532251,
      0.0534648,
      0.0534886,
      0.0534909,
      0.0538505,
      0.0538737,
      0.0542565,
      0.0543336,
      0.0546081,
      0.054629,
      0.0546305,
      0.0548656,
      0.054886,
      0.0548876,
      0.0551431,
      0.0553708,
      0.0554125,
      0.0557571,
      0.0557954,
      0.0557971,
      0.0561799,
      0.0561822,
      0.0561845,
      0.0562413,
      0.0565141,
      0.0565674,
      0.0565697,
      0.0568998,
      0.0569525,
      0.0572763,
      0.0573157,
      0.0573172,
      0.0575728,
      0.0575743,
      0.0577881,
      0.0578299,
      0.058039,
      0.0581085,
      0.0581427,
      0.0588058,
      0.0588765,
      0.0591823,
      0.0592593,
      0.0596595,
      0.0599491,
      0.0600226,
      0.0600244,
      0.0603255,
      0.0604072,
      0.0604147,
      0.06079,
      0.0610918,
      0.0611728,
      0.0614688,
      0.061551,
      0.0615557,
      0.0619385,
      0.0619408,
      0.062235,
      0.0623063,
      0.0623236,
      0.0626121,
      0.0627535,
      0.0632853,
      0.0633261,
      0.0637547,
      0.0637582,
      0.0638515,
      0.0638538,
      0.0642366,
      0.0645777,
      0.0646194,
      0.064898,
      0.0649009,
      0.0650023,
      0.0653851,
      0.0656637,
      0.0657135,
      0.0657679,
      0.0660413,
      0.0661115,
      0.0667744,
      0.067184,
      0.0671869,
      0.0672934,
      0.0672957,
      0.0676039,
      0.0676786,
      0.0679496,
      0.0683272,
      0.0684413,
      0.0687402,
      0.0688241,
      0.0688265,
      0.0690929,
      0.0692093,
      0.0694705,
      0.0700475,
      0.0702227,
      0.0706132,
      0.0707954,
      0.071191,
      0.0712188,
      0.0713689,
      0.0717559,
      0.0722609,
      0.0725221,
      0.0726437,
      0.0728991,
      0.0732848,
      0.0734065,
      0.0740424,
      0.0740511,
      0.0742324,
      0.0748059,
      0.0751851,
      0.0751938,
      0.0752085,
      0.0763278,
      0.0763365,
      0.0765178,
      0.0774705,
      0.0778568,
      0.0783577,
      0.0786137,
      0.0786222,
      0.0796399,
      0.0797934,
      0.0801375,
      0.0801462,
      0.0812802,
      0.0812889,
      0.0820281,
      0.0824229,
      0.0830016,
      0.0831656,
      0.0835656,
      0.0839519,
      0.0840579,
      0.0847083,
      0.0847088,
      0.0848702,
      0.0852876,
      0.0858515,
      0.0859904,
      0.0863662,
      0.0864942,
      0.0873753,
      0.087522,
      0.0879532,
      0.088518,
      0.0886586,
      0.0892251,
      0.0896607,
      0.0896636,
      0.0900325,
      0.0901292,
      0.0905074,
      0.090804,
      0.0908086,
      0.0909669,
      0.0917216,
      0.0923272,
      0.0928998,
      0.09301,
      0.0934699,
      0.0935766,
      0.0946126,
      0.0946797,
      0.0949751,
      0.0950579,
      0.0954367,
      0.0957552,
      0.0964144,
      0.0968985,
      0.0970083,
      0.097763,
      0.0984148,
      0.0984217,
      0.0988509,
      0.0992291,
      0.0995372,
      0.0995609,
      0.099565,
      0.100199,
      0.100708,
      0.100766,
      0.101332,
      0.10184,
      0.10185,
      0.101899,
      0.102993,
      0.103032,
      0.103995,
      0.104042,
      0.104517,
      0.104551,
      0.105034,
      0.105642,
      0.10566,
      0.105678,
      0.106056,
      0.106803,
      0.106821,
      0.107354,
      0.107388,
      0.107945,
      0.107955,
      0.108461,
      0.109088,
      0.109091,
      0.109453,
      0.109467,
      0.109469,
      0.110231,
      0.110749,
      0.110793,
      0.111347,
      0.11136,
      0.111374,
      0.111866,
      0.112369,
      0.112872,
      0.112898,
      0.113244,
      0.11326,
      0.113639,
      0.11366,
      0.114135,
      0.114639,
      0.114647,
      0.11514,
      0.115141,
      0.115143,
      0.115184,
      0.115529,
      0.115946,
      0.116288,
      0.116304,
      0.116667,
      0.117045,
      0.117059,
      0.117089,
      0.117534,
      0.117565,
      0.118038,
      0.118613,
      0.11894,
      0.119319,
      0.119349,
      0.119375,
      0.120078,
      0.120484,
      0.120519,
      0.120836,
      0.120871,
      0.121215,
      0.121662,
      0.121974,
      0.122014,
      0.122382,
      0.122733,
      0.122805,
      0.123537,
      0.123873,
      0.123899,
      0.123948,
      0.125013,
      0.125059,
      0.125092,
      0.125415,
      0.125854,
      0.126308,
      0.126319,
      0.12634,
      0.126616,
      0.127126,
      0.127163,
      0.127421,
      0.12776,
      0.128056,
      0.128488,
      0.128522,
      0.128674,
      0.12886,
      0.129078,
      0.129286,
      0.129479,
      0.129992,
      0.130025,
      0.130049,
      0.13024,
      0.130747,
      0.130788,
      0.130811,
      0.131254,
      0.131574,
      0.131804,
      0.132014,
      0.13228,
      0.132337,
      0.132406,
      0.132519,
      0.132693,
      0.13272,
      0.132914,
      0.133156,
      0.133202,
      0.133482,
      0.133554,
      0.133742,
      0.13376,
      0.13384,
      0.133865,
      0.134082,
      0.134084,
      0.134234,
      0.134247,
      0.134762,
      0.134954,
      0.134991,
      0.135011,
      0.135011,
      0.135011,
      0.135012,
      0.135012,
      0.135012,
      0.135012,
      0.135012,
      0.135012,
      0.135088,
      0.13509,
      0.135098,
      0.135173,
      0.135187,
      0.135188,
      0.135269,
      0.135285,
      0.135287,
      0.135288,
      0.135385,
      0.135385,
      0.135387,
      0.135395,
      0.135484,
      0.135485,
      0.135555,
      0.135583,
      0.135584,
      0.135681,
      0.135768,
      0.135778,
      0.135778,
      0.135778,
      0.135778,
      0.135779,
      0.135779,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.13578,
      0.135781,
      0.135781,
      0.135781,
      0.135781,
      0.135781,
      0.135781,
      0.135782,
      0.135782,
      0.135782,
      0.135782,
      0.135782,
      0.135782,
      0.135783,
      0.135783,
      0.135783,
      0.135783,
      0.135783,
      0.135783,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135784,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135785,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135786,
      0.135787,
      0.135787,
      0.135787,
      0.135787,
      0.135787,
      0.135787,
      0.135787,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135788,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.135789,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.13579,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135791,
      0.135792,
      0.135792,
      0.135709,
      0.135708,
      0.135707,
      0.135589,
      0.135463,
      0.135456,
      0.135413,
      0.135413,
      0.135414,
      0.135414,
      0.135415,
      0.135415,
      0.135415,
      0.135415,
      0.135415,
      0.135416,
      0.135417,
      0.135417,
      0.135417,
      0.135417,
      0.135417,
      0.135417,
      0.135418,
      0.135418,
      0.135419,
      0.13542,
      0.13542,
      0.135366,
      0.135272,
      0.135259,
      0.135083,
      0.135052,
      0.135041,
      0.134895,
      0.13488,
      0.134706,
      0.134674,
      0.134663,
      0.134664,
      0.134664,
      0.134665,
      0.134665,
      0.134598,
      0.134519,
      0.134298,
      0.134287,
      0.134236,
      0.134219,
      0.133952,
      0.133935,
      0.133919,
      0.133908,
      0.13391,
      0.13391,
      0.133911,
      0.133911,
      0.133911,
      0.133911,
      0.133912,
      0.133863,
      0.133843,
      0.133828,
      0.133674,
      0.133559,
      0.133544,
      0.133534,
      0.133391,
      0.133369,
      0.133202,
      0.13318,
      0.133166,
      0.133156,
      0.132839,
      0.132808,
      0.13279,
      0.132777,
      0.132635,
      0.132611,
      0.132515,
      0.132446,
      0.132408,
      0.132399,
      0.132399,
      0.1324,
      0.1324,
      0.132401,
      0.132401,
      0.132302,
      0.13221,
      0.132049,
      0.132033,
      0.132022,
      0.131924,
      0.131682,
      0.131544,
      0.131491,
      0.131279,
      0.131263,
      0.131214,
      0.131187,
      0.131024,
      0.130997,
      0.130987,
      0.130902,
      0.130885,
      0.130886,
      0.130886,
      0.130887,
      0.130887,
      0.130887,
      0.130887,
      0.130835,
      0.130713,
      0.130705,
      0.130645,
      0.130618,
      0.13055,
      0.130509,
      0.130437,
      0.130401,
      0.130391,
      0.130183,
      0.130148,
      0.13013,
      0.130075,
      0.130041,
      0.12998,
      0.129954,
      0.129885,
      0.129858,
      0.129851,
      0.129789,
      0.129763,
      0.129752,
      0.129694,
      0.129694,
      0.129693,
      0.129598,
      0.129597,
      0.129597,
      0.129596,
      0.129574,
      0.129567,
      0.129501,
      0.1295,
      0.129479,
      0.129377,
      0.129373,
      0.12929,
      0.129289,
      0.12926,
      0.129162,
      0.129133,
      0.129126,
      0.129035,
      0.128995,
      0.128864,
      0.128822,
      0.128811,
      0.128673,
      0.128672,
      0.128481,
      0.12848,
      0.128479,
      0.128442,
      0.12843,
      0.128288,
      0.128286,
      0.128285,
      0.128251,
      0.128236,
      0.128142,
      0.128141,
      0.12814,
      0.128013,
      0.128012,
      0.128011,
      0.127992,
      0.127985,
      0.127884,
      0.127884,
      0.127883,
      0.127865,
      0.127857,
      0.127857,
      0.127857,
      0.127858,
      0.127858,
      0.127858,
      0.127858,
      0.127859,
      0.127859,
      0.127859,
      0.127859,
      0.127859,
      0.127859,
      0.127859,
      0.127859,
      0.127642,
      0.127641,
      0.127618,
      0.127386,
      0.127353,
      0.127131,
      0.12711,
      0.1271,
      0.127016,
      0.127016,
      0.126921,
      0.12691,
      0.126826,
      0.12682,
      0.12673,
      0.12673,
      0.126722,
      0.126605,
      0.126605,
      0.126604,
      0.126603,
      0.126602,
      0.126594,
      0.126475,
      0.126474,
      0.126474,
      0.126473,
      0.126346,
      0.126343,
      0.126251,
      0.12625,
      0.126247,
      0.126157,
      0.126155,
      0.126154,
      0.126059,
      0.125968,
      0.125965,
      0.125964,
      0.125962,
      0.125844,
      0.125837,
      0.125718,
      0.125709,
      0.125709,
      0.125593,
      0.125586,
      0.125581,
      0.12539,
      0.125217,
      0.125199,
      0.125028,
      0.12501,
      0.125008,
      0.12484,
      0.124827,
      0.12482,
      0.12471,
      0.124696,
      0.124693,
      0.124584,
      0.124566,
      0.124459,
      0.124448,
      0.124444,
      0.124441,
      0.124362,
      0.124346,
      0.124269,
      0.124268,
      0.124254,
      0.124251,
      0.124175,
      0.124156,
      0.124081,
      0.124081,
      0.12407,
      0.123959,
      0.123937,
      0.123931,
      0.123834,
      0.123834,
      0.123804,
      0.123709,
      0.123691,
      0.12361,
      0.123591,
      0.123586,
      0.123491,
      0.123422,
      0.123401,
      0.123396,
      0.123328,
      0.123313,
      0.12328,
      0.1231,
      0.123041,
      0.123026,
      0.122849,
      0.122597,
      0.122554,
      0.12254,
      0.122451,
      0.122381,
      0.12235,
      0.122287,
      0.122262,
      0.122175,
      0.122156,
      0.121946,
      0.121911,
      0.121903,
      0.121797,
      0.121643,
      0.121575,
      0.121398,
      0.121195,
      0.121074,
      0.121038,
      0.120898,
      0.120763,
      0.120659,
      0.120582,
      0.120554,
      0.120453,
      0.120281,
      0.120178,
      0.12014,
      0.120132,
      0.119902,
      0.119858,
      0.119619,
      0.119443,
      0.119143,
      0.119126,
      0.118942,
      0.118938,
      0.118876,
      0.118764,
      0.118723,
      0.118305,
      0.118226,
      0.118218,
      0.118005,
      0.117896,
      0.117739,
      0.117709,
      0.117707,
      0.117627,
      0.117482,
      0.117357,
      0.117271,
      0.117248,
      0.117221,
      0.117032,
      0.116653,
      0.116652,
      0.116523,
      0.116489,
      0.116346,
      0.11611,
      0.116086,
      0.116083,
      0.115954,
      0.115897,
      0.115709,
      0.11552,
      0.115513,
      0.115352,
      0.115247,
      0.115212,
      0.115086,
      0.114973,
      0.114953,
      0.114834,
      0.114742,
      0.114708,
      0.114594,
      0.114491,
      0.114484,
      0.114421,
      0.114302,
      0.114216,
      0.114186,
      0.114154,
      0.113521,
      0.113427,
      0.113076,
      0.11307,
      0.113069,
      0.112976,
      0.112965,
      0.112808,
      0.112787,
      0.112698,
      0.112691,
      0.112566,
      0.11255,
      0.11234,
      0.112319,
      0.11231,
      0.112308,
      0.112058,
      0.112021,
      0.1116,
      0.11156,
      0.111557,
      0.111385,
      0.111368,
      0.111353,
      0.111274,
      0.111181,
      0.11118,
      0.111074,
      0.111054,
      0.11093,
      0.110929,
      0.110905,
      0.110821,
      0.110804,
      0.110803,
      0.110449,
      0.110426,
      0.110237,
      0.110197,
      0.110069,
      0.110049,
      0.110044,
      0.109921,
      0.109807,
      0.109796,
      0.10968,
      0.10967,
      0.109665,
      0.109628,
      0.109307,
      0.109294,
      0.109116,
      0.109106,
      0.108917,
      0.108906,
      0.108881,
      0.108793,
      0.108661,
      0.108539,
      0.108527,
      0.108503,
      0.108409,
      0.108286,
      0.108284,
      0.108158,
      0.108149,
      0.107916,
      0.107846,
      0.107662,
      0.10741,
      0.107408,
      0.107389,
      0.107023,
      0.106972,
      0.106836,
      0.10663,
      0.106515,
      0.106479,
      0.106386,
      0.106263,
      0.106252,
      0.106025,
      0.105884,
      0.1057,
      0.105506,
      0.105493,
      0.105379,
      0.105376,
      0.105342,
      0.105124,
      0.105114,
      0.104944,
      0.104888,
      0.10475,
      0.104561,
      0.104375,
      0.104355,
      0.104331,
      0.10424,
      0.104116,
      0.103988,
      0.103977,
      0.103952,
      0.103863,
      0.103738,
      0.1037,
      0.103611,
      0.103598,
      0.103429,
      0.103239,
      0.103183,
      0.10305,
      0.102839,
      0.102727,
      0.102727,
      0.10269,
      0.102475,
      0.10246,
      0.102292,
      0.102236,
      0.101914,
      0.101858,
      0.101723,
      0.101701,
      0.101404,
      0.100972,
      0.100942,
      0.10083,
      0.100794,
      0.100563,
      0.10048,
      0.100479,
      0.100453,
      0.100196,
      0.100185,
      0.100143,
      0.0999613,
      0.0996374,
      0.0994256,
      0.099342,
      0.0992213,
      0.0991531,
      0.0990586,
      0.0990474,
      0.0987539,
      0.0985715,
      0.0983185,
      0.0982881,
      0.0980663,
      0.097995,
      0.0978145,
      0.0978126,
      0.0975627,
      0.0975287,
      0.0975089,
      0.0974201,
      0.0974182,
      0.0972953,
      0.0972945,
      0.0971697,
      0.0971661,
      0.0971499,
      0.096991,
      0.0969309,
      0.0968023,
      0.0967954,
      0.0966134,
      0.0963912,
      0.0963719,
      0.0962862,
      0.0962854,
      0.0962846,
      0.0962839,
      0.0962819,
      0.0961568,
      0.0961562,
      0.096156,
      0.0961552,
      0.0961545,
      0.0961537,
      0.0961529,
      0.0960258,
      0.096025,
      0.0960243,
      0.0960235,
      0.0960227,
      0.096022,
      0.0960212,
      0.0960123,
      0.0959837,
      0.09585,
      0.0958347,
      0.0958335,
      0.0958323,
      0.0958312,
      0.09583,
      0.095639,
      0.0956379,
      0.0956367,
      0.0956356,
      0.0954712,
      0.0954446,
      0.0954434,
      0.0954423,
      0.0954411,
      0.0954148,
      0.0952536,
      0.0952513,
      0.0952505,
      0.0952497,
      0.095249,
      0.0952482,
      0.0952474,
      0.0951465,
      0.0951203,
      0.0951196,
      0.0951188,
      0.095118,
      0.0949909,
      0.0949902,
      0.0949894,
      0.0949886,
      0.0949823,
      0.0948944,
      0.0948748,
      0.0948615,
      0.0948607,
      0.09486,
      0.0948592,
      0.0947321,
      0.0947313,
      0.0947306,
      0.0947298,
      0.094729,
      0.0946425,
      0.0946019,
      0.0946011,
      0.0946004,
      0.0945996,
      0.094496,
      0.0944679,
      0.0944607,
      0.0944596,
      0.0944584,
      0.0944572,
      0.0943373,
      0.0942663,
      0.0942651,
      0.0942639,
      0.0942628,
      0.0940718,
      0.0940706,
      0.0940695,
      0.0940683,
      0.0940672,
      0.094066,
      0.0939585,
      0.0938987,
      0.093875,
      0.0938739,
      0.0938727,
      0.0938716,
      0.0938704,
      0.0937372,
      0.0936987,
      0.0936326,
      0.0935716,
      0.093507,
      0.0934447,
      0.0933584,
      0.0933301,
      0.093298,
      0.0932029,
      0.0931116,
      0.0931105,
      0.0931093,
      0.0930143,
      0.0929276,
      0.0929264,
      0.0929253,
      0.0929241,
      0.092923,
      0.0928256,
      0.0927424,
      0.0927412,
      0.0927401,
      0.0927389,
      0.0926369,
      0.0925996,
      0.0925618,
      0.0925445,
      0.0925429,
      0.0925414,
      0.0925399,
      0.0925383,
      0.0922973,
      0.0922957,
      0.0922942,
      0.0922927,
      0.0921438,
      0.0920532,
      0.0920516,
      0.0920501,
      0.0920485,
      0.092047,
      0.0920455,
      0.091892,
      0.0918403,
      0.0918247,
      0.091824,
      0.0918232,
      0.0918224,
      0.0918216,
      0.0918213,
      0.0918209,
      0.0917022,
      0.0917015,
      0.0917007,
      0.0916999,
      0.0916992,
      0.091614,
      0.091579,
      0.0915782,
      0.0915774,
      0.0915767,
      0.0914615,
      0.0914517,
      0.0914505,
      0.0914494,
      0.0913121,
      0.0912665,
      0.0912653,
      0.0912641,
      0.091263,
      0.0912433,
      0.0910824,
      0.0910812,
      0.0910801,
      0.0910789,
      0.0910778,
      0.090933,
      0.0908983,
      0.0908972,
      0.090896,
      0.0908949,
      0.0908937,
      0.0907131,
      0.090712,
      0.0907108,
      0.0907097,
      0.0907027,
      0.0906839,
      0.0906041,
      0.0905872,
      0.0905864,
      0.0905856,
      0.0905849,
      0.0905841,
      0.0904647,
      0.0904639,
      0.0904631,
      0.0904624,
      0.0904616,
      0.0903522,
      0.0903399,
      0.0903391,
      0.0903239,
      0.0902509,
      0.0902152,
      0.0901569,
      0.0901506,
      0.0900629,
      0.0900562,
      0.0899457,
      0.089754,
      0.089734,
      0.0896549,
      0.089479,
      0.0892488,
      0.0892241,
      0.0891863,
      0.0890412,
      0.0889654,
      0.0889517,
      0.0888246,
      0.0888075,
      0.0884761,
      0.0884527,
      0.0883998,
      0.0880973,
      0.0880722,
      0.0880488,
      0.0879038,
      0.0878109,
      0.0877028,
      0.08767,
      0.0872303,
      0.0871914,
      0.0871254,
      0.0869106,
      0.0868412,
      0.0868258,
      0.0866126,
      0.0865577,
      0.0865416,
      0.0865324,
      0.0860947,
      0.0857958,
      0.085773,
      0.0857585,
      0.0856099,
      0.0855919,
      0.0854212,
      0.0853948,
      0.0853559,
      0.0851615,
      0.084706,
      0.0846536,
      0.0846355,
      0.0846159,
      0.0842922,
      0.0842649,
      0.0842567,
      0.0841472,
      0.0840732,
      0.0839999,
      0.0838784,
      0.0836983,
      0.0836389,
      0.0835849,
      0.0831307,
      0.0831191,
      0.0829394,
      0.0826228,
      0.0825715,
      0.0823598,
      0.0822683,
      0.0821979,
      0.0820786,
      0.0820604,
      0.0819815,
      0.0815484,
      0.0814806,
      0.0812222,
      0.0812063,
      0.0811289,
      0.0810601,
      0.0809393,
      0.0808703,
      0.0808439,
      0.0808011,
      0.080591,
      0.0804066,
      0.0801517,
      0.0800846,
      0.0800838,
      0.0800635,
      0.079991,
      0.0799567,
      0.0798296,
      0.0797375,
      0.0797058,
      0.0797034,
      0.0796903,
      0.0796345,
      0.079634,
      0.0795388,
      0.0795137,
      0.0794186,
      0.0794069,
      0.0793492,
      0.0793276,
      0.0791305,
      0.079063,
      0.0790345,
      0.0788756,
      0.0788081,
      0.0785682,
      0.0785626,
      0.0785535,
      0.0784934,
      0.0783982,
      0.0783976,
      0.078373,
      0.0783024,
      0.0782778,
      0.0782702,
      0.07819,
      0.0781802,
      0.0780867,
      0.078086,
      0.078045,
      0.0779589,
      0.0779581,
      0.0779573,
      0.0779267,
      0.0778304,
      0.0778112,
      0.0777941,
      0.077784,
      0.0776031,
      0.0774592,
      0.0774581,
      0.0774121,
      0.0772671,
      0.0772659,
      0.0772154,
      0.0770749,
      0.0770524,
      0.0770243,
      0.0767693,
      0.0767643,
      0.0765742,
      0.0765727,
      0.0765711,
      0.0765696,
      0.0763147,
      0.0763131,
      0.0763116,
      0.07631,
      0.0762931,
      0.0762773,
      0.0762762,
      0.0761744,
      0.0761502,
      0.0760473,
      0.0760468,
      0.0760241,
      0.0760231,
      0.0759197,
      0.0759143,
      0.0757314,
      0.0757302,
      0.0757291,
      0.0756973,
      0.0755381,
      0.0755369,
      0.0755106,
      0.0753164,
      0.0751561,
      0.0751555,
      0.0751399,
      0.0751355,
      0.0750288,
      0.075028,
      0.0748878,
      0.0748823,
      0.0747767,
      0.0747751,
      0.0747747,
      0.0746795,
      0.0746712,
      0.074666,
      0.0745843,
      0.0745838,
      0.0744886,
      0.074488,
      0.0744874,
      0.0744825,
      0.0744763,
      0.0743985,
      0.0743819,
      0.0743803,
      0.0743715,
      0.0741254,
      0.0741239,
      0.0741223,
      0.0741208,
      0.0741192,
      0.0740988,
      0.0738663,
      0.0738643,
      0.0738628,
      0.0738613,
      0.0738597,
      0.0736391,
      0.073622,
      0.0736212,
      0.0736151,
      0.0735005,
      0.0734941,
      0.0733747,
      0.073367,
      0.0733663,
      0.0733618,
      0.0732603,
      0.0732445,
      0.0732439,
      0.0731574,
      0.0731487,
      0.0730633,
      0.0730521,
      0.0729591,
      0.0728821,
      0.072862,
      0.0728335,
      0.0726102,
      0.0725786,
      0.072324,
      0.0723237,
      0.0721227,
      0.0721161,
      0.0721026,
      0.072102,
      0.0720221,
      0.0720091,
      0.0720068,
      0.0720063,
      0.0719111,
      0.0719105,
      0.0718333,
      0.0718155,
      0.0717445,
      0.0717376,
      0.0717195,
      0.0717122,
      0.0715851,
      0.0715843,
      0.0715835,
      0.0714857,
      0.0714564,
      0.0713657,
      0.0713267,
      0.0712928,
      0.0711007,
      0.0710749,
      0.0710379,
      0.0708489,
      0.070783,
      0.0706063,
      0.0705925,
      0.0705771,
      0.0705083,
      0.0704985,
      0.0704141,
      0.0703875,
      0.0703869,
      0.0703099,
      0.0702917,
      0.0702281,
      0.0702246,
      0.0702118,
      0.070186,
      0.0700862,
      0.0700589,
      0.0699726,
      0.0699604,
      0.069932,
      0.0698493,
      0.0698458,
      0.0698276,
      0.0697825,
      0.0696571,
      0.0696413,
      0.0696401,
      0.0696389,
      0.0695916,
      0.0694549,
      0.0694537,
      0.0694526,
      0.0692786,
      0.0692662,
      0.0692107,
      0.0690905,
      0.06909,
      0.0690819,
      0.0690436,
      0.0689563,
      0.0689165,
      0.0688381,
      0.0688307,
      0.0687894,
      0.0687117,
      0.0686177,
      0.0686121,
      0.0685801,
      0.0685235,
      0.0684849,
      0.0684295,
      0.0684235,
      0.0683899,
      0.0683335,
      0.0682297,
      0.0680855,
      0.0680689,
      0.0679748,
      0.0677199,
      0.0675799,
      0.0675741,
      0.0675703,
      0.0674823,
      0.0674389,
      0.0673881,
      0.0672868,
      0.0672492,
      0.0671959,
      0.0670744,
      0.0670138,
      0.0669488,
      0.0668231,
      0.0668171,
      0.0668096,
      0.0665423,
      0.0664475,
      0.0660687,
      0.0660583,
      0.0660529,
      0.0660008,
      0.0658134,
      0.0656878,
      0.0656795,
      0.0656752,
      0.0656366,
      0.0654969,
      0.0653531,
      0.0653083,
      0.0653013,
      0.0652889,
      0.0649349,
      0.0648142,
      0.0645419,
      0.0644856,
      0.0644258,
      0.0644089,
      0.0642335,
      0.0641737,
      0.0641631,
      0.0640272,
      0.0639824,
      0.0639686,
      0.063933,
      0.0637937,
      0.0637849,
      0.0637307,
      0.0635249,
      0.0634788,
      0.0634197,
      0.0634061,
      0.0633266,
      0.0630481,
      0.0629478,
      0.0628256,
      0.0626693,
      0.0626473,
      0.0625954,
      0.0624698,
      0.0624098,
      0.0623871,
      0.0622685,
      0.0622305,
      0.062091,
      0.0620419,
      0.061997,
      0.0619791,
      0.0619477,
      0.0619029,
      0.0618903,
      0.0617814,
      0.0617151,
      0.0615295,
      0.0615114,
      0.0615058,
      0.0614753,
      0.0612865,
      0.0612416,
      0.0612223,
      0.0611332,
      0.0610859,
      0.0610259,
      0.0609603,
      0.0607738,
      0.0607544,
      0.0607437,
      0.060685,
      0.0604048,
      0.0603065,
      0.0601745,
      0.0599956,
      0.0599504,
      0.0598895,
      0.0596985,
      0.0596372,
      0.0596168,
      0.0596113,
      0.059584,
      0.0594435,
      0.0593952,
      0.0593018,
      0.0593012,
      0.0592386,
      0.0592307,
      0.0591974,
      0.0591338,
      0.0589461,
      0.0589455,
      0.0588817,
      0.0588598,
      0.0588471,
      0.058802,
      0.0588008,
      0.0586133,
      0.0585142,
      0.0582767,
      0.0582348,
      0.0581354,
      0.058101,
      0.0580736,
      0.0579796,
      0.0579294,
      0.0579045,
      0.0578855,
      0.0578354,
      0.0577228,
      0.0576881,
      0.0575858,
      0.0575626,
      0.0574947,
      0.0573439,
      0.0573195,
      0.0573189,
      0.0572682,
      0.057241,
      0.057226,
      0.0572254,
      0.057132,
      0.0570793,
      0.0570512,
      0.0569657,
      0.0569359,
      0.0568655,
      0.0568265,
      0.0568111,
      0.0568103,
      0.0566996,
      0.0566853,
      0.0566134,
      0.0565869,
      0.0565761,
      0.056472,
      0.0564181,
      0.0563863,
      0.0562834,
      0.0562294,
      0.0562086,
      0.0561927,
      0.0560564,
      0.0559842,
      0.0559393,
      0.0559308,
      0.0558298,
      0.0556833,
      0.0556137,
      0.0556027,
      0.0554944,
      0.0552329,
      0.0552242,
      0.0551159,
      0.0550711,
      0.0550712,
      0.0550715,
      0.0550718,
      0.0550726,
      0.0550728,
      0.055043,
      0.0550381,
      0.0548491,
      0.0547411,
      0.0544741,
      0.0544706,
      0.0543623,
      0.054314,
      0.0541661,
      0.054094,
      0.0540421,
      0.0539352,
      0.0538655,
      0.0538265,
      0.0538248,
      0.0537715,
      0.0537308,
      0.0536379,
      0.0535827,
      0.053557,
      0.0534491,
      0.0534474,
      0.0532992,
      0.0532605,
      0.0532586,
      0.0531787,
      0.0531788,
      0.0531799,
      0.0531799,
      0.0531803,
      0.0531804,
      0.0529678,
      0.0529629,
      0.0526641,
      0.0525893,
      0.0525841,
      0.0524217,
      0.0524076,
      0.0522044,
      0.0521555,
      0.0521513,
      0.0520429,
      0.0519385,
      0.0518803,
      0.0518408,
      0.0517498,
      0.0517467,
      0.0516646,
      0.0515579,
      0.0515022,
      0.0514665,
      0.0513693,
      0.0512864,
      0.0512748,
      0.051196,
      0.0511452,
      0.0509076,
      0.0508991,
      0.0508968,
      0.05084,
      0.0507081,
      0.0505565,
      0.0505293,
      0.0505294,
      0.0505294,
      0.0505299,
      0.0505309,
      0.050531,
      0.0505221,
      0.0504279,
      0.0503335,
      0.0502749,
      0.0501528,
      0.050142,
      0.0501416,
      0.0500168,
      0.0500161,
      0.0498913,
      0.0498107,
      0.049774,
      0.0497627,
      0.0497615,
      0.0497587,
      0.049574,
      0.049385,
      0.0493798,
      0.0491964,
      0.0490701,
      0.0490152,
      0.0490152,
      0.0490152,
      0.0490164,
      0.0490173,
      0.0490175,
      0.0490125,
      0.0490094,
      0.0487544,
      0.0487537,
      0.0487529,
      0.0486748,
      0.0486387,
      0.0486329,
      0.048629,
      0.0486284,
      0.0486279,
      0.0486273,
      0.0485321,
      0.0485315,
      0.048531,
      0.0485304,
      0.0484441,
      0.0484352,
      0.0484346,
      0.0484341,
      0.0483389,
      0.0483383,
      0.0483377,
      0.0483372,
      0.0482872,
      0.0482604,
      0.048242,
      0.0482414,
      0.0482408,
      0.0481608,
      0.0481457,
      0.0481451,
      0.0481445,
      0.0481439,
      0.0480488,
      0.0480482,
      0.0480476,
      0.048047,
      0.0480033,
      0.047972,
      0.0479519,
      0.0479513,
      0.0479507,
      0.0479501,
      0.0479496,
      0.047949,
      0.0478822,
      0.0478443,
      0.0478435,
      0.0478428,
      0.047842,
      0.0478412,
      0.0477503,
      0.0476245,
      0.0475786,
      0.0475778,
      0.047577,
      0.0475382,
      0.0475034,
      0.0475036,
      0.0475036,
      0.047504,
      0.0475048,
      0.0475049,
      0.0475051,
      0.0475054,
      0.0475054,
      0.0475056,
      0.0474573,
      0.0474087,
      0.0473621,
      0.0473421,
      0.0472669,
      0.0472201,
      0.0471745,
      0.0471739,
      0.0471733,
      0.0471728,
      0.0471274,
      0.0471255,
      0.0470678,
      0.047067,
      0.0470663,
      0.0470655,
      0.0470647,
      0.0469438,
      0.046943,
      0.0469422,
      0.0469084,
      0.0468736,
      0.0467486,
      0.0466534,
      0.0466184,
      0.0466179,
      0.0466173,
      0.0465843,
      0.0465261,
      0.0465256,
      0.046525,
      0.0465244,
      0.0464648,
      0.0464344,
      0.0464338,
      0.0464333,
      0.0464327,
      0.0464321,
      0.0463708,
      0.0463703,
      0.0463421,
      0.0463415,
      0.046341,
      0.0463404,
      0.0462498,
      0.0462493,
      0.0462487,
      0.0462481,
      0.0462056,
      0.046182,
      0.0461564,
      0.0461558,
      0.0459934,
      0.0459921,
      0.0459922,
      0.0459922,
      0.0459922,
      0.0459925,
      0.0459928,
      0.0459928,
      0.0459928,
      0.0459928,
      0.0459928,
      0.0459932,
      0.0459933,
      0.0459933,
      0.0459933,
      0.0459933,
      0.0459933,
      0.0459938,
      0.0459846,
      0.045984,
      0.0459834,
      0.0459829,
      0.0459233,
      0.0459015,
      0.0458923,
      0.0458917,
      0.0458911,
      0.0458906,
      0.0457994,
      0.0457989,
      0.0457983,
      0.0457128,
      0.0457054,
      0.0456156,
      0.0456108,
      0.0455446,
      0.045524,
      0.0455158,
      0.0453354,
      0.045326,
      0.0452608,
      0.0452412,
      0.0452373,
      0.0452287,
      0.0451016,
      0.0449745,
      0.0448658,
      0.0448585,
      0.0448503,
      0.0447871,
      0.0446752,
      0.0446605,
      0.0444866,
      0.0444803,
      0.0444807,
      0.0444809,
      0.0444814,
      0.044482,
      0.0444824,
      0.0444825,
      0.0444825,
      0.0443426,
      0.0442403,
      0.0441037,
      0.0441038,
      0.0441042,
      0.0441042,
      0.0441048,
      0.0441054,
      0.0441055,
      0.0441059,
      0.044106,
      0.0440212,
      0.0439051,
      0.0438324,
      0.0437495,
      0.0437278,
      0.0437155,
      0.0435491,
      0.0435258,
      0.0434656,
      0.0433495,
      0.0432372,
      0.0432053,
      0.0431251,
      0.0429707,
      0.0429708,
      0.0429718,
      0.0429719,
      0.0429723,
      0.042973,
      0.0429731,
      0.0429735,
      0.042974,
      0.0429742,
      0.0429753,
      0.0429593,
      0.042901,
      0.0428919,
      0.0428642,
      0.0426745,
      0.042617,
      0.042597,
      0.0425734,
      0.0424855,
      0.0423201,
      0.0422182,
      0.0422187,
      0.0422189,
      0.0422193,
      0.04222,
      0.0422204,
      0.0422205,
      0.0422006,
      0.0421365,
      0.0421054,
      0.0419471,
      0.0419157,
      0.0418612,
      0.0418422,
      0.0418424,
      0.0418433,
      0.0418435,
      0.0418438,
      0.0418441,
      0.0418445,
      0.0418445,
      0.0417266,
      0.0416642,
      0.0416316,
      0.0415793,
      0.0415691,
      0.0414663,
      0.0414664,
      0.0414668,
      0.0414673,
      0.0414674,
      0.0414674,
      0.0414676,
      0.0414681,
      0.0414685,
      0.0414685,
      0.041469,
      0.0414691,
      0.0414693,
      0.0414696,
      0.0414697,
      0.0414698,
      0.0414702,
      0.0413935,
      0.0413794,
      0.0413486,
      0.0412844,
      0.0412534,
      0.0411892,
      0.0411582,
      0.0411093,
      0.041094,
      0.041092,
      0.0409685,
      0.0409042,
      0.0408734,
      0.0408251,
      0.0408091,
      0.0408086,
      0.0407783,
      0.0407138,
      0.0407138,
      0.0407142,
      0.0407143,
      0.0407145,
      0.0407149,
      0.0407155,
      0.0407155,
      0.0407157,
      0.0407159,
      0.040716,
      0.0407129,
      0.0406844,
      0.0406177,
      0.0405892,
      0.0405225,
      0.0404477,
      0.0403994,
      0.0403378,
      0.0403378,
      0.040338,
      0.0403384,
      0.0403388,
      0.0403391,
      0.0403395,
      0.0403395,
      0.0403397,
      0.0403401,
      0.0403405,
      0.0403408,
      0.0403412,
      0.0403414,
      0.0403418,
      0.0403341,
      0.0402614,
      0.0402391,
      0.040211,
      0.0401439,
      0.0400488,
      0.0400482,
      0.0400214,
      0.0399775,
      0.0399635,
      0.0399636,
      0.0399636,
      0.0399642,
      0.0399643,
      0.0399652,
      0.0399653,
      0.0399653,
      0.0399655,
      0.0399658,
      0.0399659,
      0.0399665,
      0.0399666,
      0.0399669,
      0.039967,
      0.0399676,
      0.0399677,
      0.0399681,
      0.0399682,
      0.0399682,
      0.0399682,
      0.0399688,
      0.0399688,
      0.0399688,
      0.0399691,
      0.0399693,
      0.0399693,
      0.0399695,
      0.0399699,
      0.0399699,
      0.0399704,
      0.0399705,
      0.0399705,
      0.0399705,
      0.0399706,
      0.0399708,
      0.0399711,
      0.0399711,
      0.0399716,
      0.0399717,
      0.039972,
      0.0399722,
      0.0399722,
      0.0399723,
      0.0399726,
      0.0399728,
      0.0399734,
      0.0399734,
      0.0399734,
      0.0399737,
      0.0399739,
      0.039974,
      0.0399745,
      0.0399745,
      0.0399745,
      0.0399745,
      0.0399746,
      0.0399749,
      0.0399351,
      0.0399343,
      0.0399336,
      0.0399328,
      0.0399286,
      0.0398638,
      0.0398057,
      0.0398049,
      0.0398042,
      0.039803,
      0.0396774,
      0.039677,
      0.0396763,
      0.0396107,
      0.0395961,
      0.0395963,
      0.0395963,
      0.0395963,
      0.0395963,
      0.0395963,
      0.0395963,
      0.0395969,
      0.0395969,
      0.0395969,
      0.0395969,
      0.0395969,
      0.0395974,
      0.0395974,
      0.0395975,
      0.0395975,
      0.0395977,
      0.039598,
      0.039598,
      0.0395981,
      0.0395984,
      0.0395986,
      0.0395986,
      0.0395986,
      0.0395991,
      0.0395992,
      0.0395992,
      0.0395994,
      0.0395997,
      0.0395998,
      0.0396002,
      0.0396003,
      0.0396006,
      0.0396008,
      0.0396009,
      0.0396014,
      0.0396015,
      0.0396017,
      0.0396019,
      0.0396021,
      0.0396025,
      0.0396026,
      0.0396029,
      0.0396031,
      0.0396032,
      0.0396032,
      0.0396034,
      0.0396036,
      0.0396038,
      0.0396038,
      0.0396038,
      0.0396038,
      0.0396042,
      0.0396044,
      0.0396044,
      0.0396048,
      0.0396049,
      0.0396052,
      0.0396053,
      0.0396055,
      0.0396057,
      0.0396061,
      0.0396065,
      0.0396067,
      0.039607,
      0.0396072,
      0.0396075,
      0.039608,
      0.0396084,
      0.0396087,
      0.0396095,
      0.0396097,
      0.0396097,
      0.0396107,
      0.039611,
      0.0396114,
      0.039612,
      0.0396124,
      0.0396133,
      0.0396137,
      0.0396143,
      0.0396146,
      0.0396154,
      0.0396156,
      0.0396166,
      0.0396744,
      0.0397992,
      0.0398305,
      0.0399614,
      0.0399994,
      0.0399999,
      0.0400006,
      0.0400008,
      0.0400016,
      0.0400889,
      0.0401539,
      0.0402154,
      0.0403448,
      0.040376,
      0.0403844,
      0.0405858,
      0.040669,
      0.0407124,
      0.0407556,
      0.0407667,
      0.0409168,
      0.0409801,
      0.0410119,
      0.0410456,
      0.0411069,
      0.0411494,
      0.0413468,
      0.0413939,
      0.0414334,
      0.0414735,
      0.0415317,
      0.0416341,
      0.0418236,
      0.0418982,
      0.042147,
      0.0422039,
      0.0422805,
      0.042295,
      0.042426,
      0.0427848,
      0.0429313,
      0.0429329,
      0.0430577,
      0.0430854,
      0.0431201,
      0.0431209,
      0.0433035,
      0.0433742,
      0.04344,
      0.0434945,
      0.0435598,
      0.0435613,
      0.0438122,
      0.0439309,
      0.0440647,
      0.0442027,
      0.0443171,
      0.0444401,
      0.0445097,
      0.04457,
      0.0448224,
      0.0449654,
      0.0452328,
      0.0453089,
      0.0453741,
      0.045658,
      0.0458054,
      0.0461093,
      0.0462148,
      0.0464131,
      0.0464673,
      0.0466022,
      0.0467197,
      0.046872,
      0.0469721,
      0.0471113,
      0.047223,
      0.0472246,
      0.0474295,
      0.0476205,
      0.0476348,
      0.0477299,
      0.0479823,
      0.0481922,
      0.0482347,
      0.0483836,
      0.0483975,
      0.048913,
      0.0492332,
      0.0492911,
      0.0495199,
      0.0495408,
      0.049996,
      0.0500498,
      0.0503029,
      0.050428,
      0.0506632,
      0.0507587,
      0.0511873,
      0.0514462,
      0.0514968,
      0.0515258,
      0.0516867,
      0.0520064,
      0.0520323,
      0.0522089,
      0.052323,
      0.0525692,
      0.0526682,
      0.053051,
      0.0530817,
      0.0533522,
      0.0534599,
      0.0537124,
      0.0538138,
      0.0538387,
      0.0544955,
      0.0545771,
      0.0545974,
      0.0548551,
      0.0549756,
      0.0552576,
      0.0553399,
      0.0553544,
      0.0557325,
      0.0559972,
      0.0561026,
      0.0561107,
      0.0564009,
      0.0564866,
      0.0564889,
      0.0568648,
      0.056866,
      0.057243,
      0.0575193,
      0.0575442,
      0.0576596,
      0.0576708,
      0.058227,
      0.0586869,
      0.0591519,
      0.0594212,
      0.0595191,
      0.0598301,
      0.0602784,
      0.0602929,
      0.0606566,
      0.0609433,
      0.0609734,
      0.0616363,
      0.0616614,
      0.0621161,
      0.062174,
      0.0625522,
      0.0625719,
      0.0628452,
      0.0629304,
      0.0632593,
      0.0633296,
      0.0633331,
      0.0633661,
      0.0639335,
      0.064402,
      0.0644455,
      0.0647472,
      0.064848,
      0.0655453,
      0.0655852,
      0.0656067,
      0.0659854,
      0.0662692,
      0.0663445,
      0.0666885,
      0.0667718,
      0.0673384,
      0.0678312,
      0.0678643,
      0.0681712,
      0.0682616,
      0.0686235,
      0.0686397,
      0.0689745,
      0.0696096,
      0.0700513,
      0.0701172,
      0.0701438,
      0.0705377,
      0.0712605,
      0.0712952,
      0.0713151,
      0.0717611,
      0.0724031,
      0.0724356,
      0.0728033,
      0.0731149,
      0.0735464,
      0.0735754,
      0.0739437,
      0.0742553,
      0.0746897,
      0.0747287,
      0.0752797,
      0.0758324,
      0.0763174,
      0.0764172,
      0.0769751,
      0.0769971,
      0.077677,
      0.077743,
      0.0781183,
      0.0781374,
      0.0788822,
      0.0791984,
      0.0792616,
      0.0798308,
      0.079855,
      0.0804043,
      0.0807199,
      0.0807807,
      0.0807969,
      0.0811751,
      0.08154,
      0.0815475,
      0.0815527,
      0.0815562,
      0.0821193,
      0.0825896,
      0.0826833,
      0.0826868,
      0.0826902,
      0.0830591,
      0.0830638,
      0.0834397,
      0.083442,
      0.0838178,
      0.0838184,
      0.0838335,
      0.0843003,
      0.0849484,
      0.0849762,
      0.0853399,
      0.0856677,
      0.0857163,
      0.0857221,
      0.0857245,
      0.0861073,
      0.0861096,
      0.0861119,
      0.0861194,
      0.0864756,
      0.0864947,
      0.0864971,
      0.0864994,
      0.0868098,
      0.0868822,
      0.0868845,
      0.0868868,
      0.0872343,
      0.0872627,
      0.0872731,
      0.0872766,
      0.0872801,
      0.0877876,
      0.0878536,
      0.087857,
      0.0878605,
      0.0884054,
      0.0884245,
      0.0884268,
      0.0884291,
      0.0887123,
      0.0887517,
      0.088812,
      0.0888143,
      0.0888166,
      0.0891299,
      0.0891994,
      0.0892017,
      0.0892041,
      0.0895081,
      0.0895487,
      0.0895869,
      0.0895892,
      0.0895915,
      0.0895938,
      0.089855,
      0.0898863,
      0.0899767,
      0.089979,
      0.0899813,
      0.0899836,
      0.0899859,
      0.0899882,
      0.0903711,
      0.0903734,
      0.0903757,
      0.090378,
      0.0903803,
      0.0903826,
      0.0906456,
      0.0906919,
      0.0908021,
      0.0908056,
      0.0908091,
      0.0908125,
      0.090816,
      0.0908195,
      0.091393,
      0.0913964,
      0.0913999,
      0.0914034,
      0.0914069,
      0.0917201,
      0.09176,
      0.0918346,
      0.0919319,
      0.0919342,
      0.0919365,
      0.0919389,
      0.0919412,
      0.092324,
      0.0923263,
      0.0923286,
      0.092331,
      0.0923333,
      0.0925435,
      0.0927161,
      0.0927184,
      0.0927207,
      0.0929003,
      0.0929779,
      0.0931035,
      0.0934841,
      0.0936833,
      0.0938628,
      0.0940429,
      0.0941211,
      0.0942364,
      0.0942387,
      0.094241,
      0.094442,
      0.0946076,
      0.0946099,
      0.0946123,
      0.0946146,
      0.0948208,
      0.0949719,
      0.0949742,
      0.0949766,
      0.0949789,
      0.0949812,
      0.0949835,
      0.0949858,
      0.0951989,
      0.0952644,
      0.0953432,
      0.0953455,
      0.0953478,
      0.0953501,
      0.0955656,
      0.0955771,
      0.0960266,
      0.0964829,
      0.0967082,
      0.0967169,
      0.0968594,
      0.0968617,
      0.0971698,
      0.0972283,
      0.0972306,
      0.097233,
      0.0972353,
      0.0972376,
      0.097471,
      0.0975996,
      0.0976019,
      0.0976042,
      0.0976065,
      0.0978567,
      0.0979708,
      0.0979731,
      0.0979754,
      0.0979778,
      0.0983131,
      0.0983374,
      0.0983397,
      0.0983421,
      0.0983444,
      0.0983467,
      0.098349,
      0.0986143,
      0.098616,
      0.0987017,
      0.098704,
      0.0987063,
      0.0987087,
      0.098711,
      0.0987133,
      0.0987156,
      0.0990683,
      0.0990706,
      0.0990729,
      0.0990753,
      0.0990776,
      0.0990799,
      0.0993753,
      0.0994349,
      0.0994372,
      0.0994396,
      0.0994419,
      0.0994442,
      0.0994465,
      0.0994564,
      0.0997575,
      0.0998015,
      0.0998038,
      0.0998062,
      0.0998085,
      0.0998108,
      0.0998131,
      0.100134,
      0.100175,
      0.100177,
      0.10018,
      0.10051,
      0.100513,
      0.10052,
      0.1006,
      0.100793,
      0.100825,
      0.101045,
      0.101297,
      0.10131,
      0.101334,
      0.101362,
      0.102353,
      0.102387,
      0.102442,
      0.102505,
      0.10269,
      0.103215,
      0.10324,
      0.103268,
      0.103542,
      0.103921,
      0.104332,
      0.104369,
      0.104411,
      0.10459,
      0.104843,
      0.105095,
      0.105121,
      0.105146,
      0.105174,
      0.105434,
      0.105813,
      0.106191,
      0.106317,
      0.106485,
      0.106518,
      0.106543,
      0.106992,
      0.10708,
      0.10738,
      0.107418,
      0.108088,
      0.108223,
      0.108423,
      0.108448,
      0.10864,
      0.108986,
      0.109286,
      0.109324,
      0.109608,
      0.110129,
      0.11043,
      0.110467,
      0.110748,
      0.110891,
      0.111092,
      0.111302,
      0.111371,
      0.111654,
      0.111805,
      0.112014,
      0.112152,
      0.112378,
      0.112417,
      0.112756,
      0.113031,
      0.113561,
      0.113761,
      0.113787,
      0.11397,
      0.114323,
      0.114479,
      0.114524,
      0.11455,
      0.114989,
      0.115059,
      0.115086,
      0.115498,
      0.11554,
      0.115753,
      0.115821,
      0.115849,
      0.116009,
      0.116264,
      0.116302,
      0.11652,
      0.116521,
      0.116584,
      0.116612,
      0.117031,
      0.117286,
      0.117288,
      0.117318,
      0.117348,
      0.117374,
      0.117543,
      0.117798,
      0.1178,
      0.117857,
      0.118137,
      0.118266,
      0.118268,
      0.118284,
      0.118309,
      0.118459,
      0.118461,
      0.118501,
      0.118652,
      0.118844,
      0.118883,
      0.1189,
      0.118991,
      0.118992,
      0.118998,
      0.11912,
      0.119121,
      0.119121,
      0.119144,
      0.11925,
      0.119272,
      0.119283,
      0.119472,
      0.119478,
      0.119728,
      0.119729,
      0.119772,
      0.119985,
      0.119986,
      0.120045,
      0.120193,
      0.120222,
      0.120382,
      0.120385,
      0.120576,
      0.120605,
      0.120768,
      0.120769,
      0.120796,
      0.120809,
      0.121012,
      0.121256,
      0.121267,
      0.121302,
      0.121523,
      0.121524,
      0.121557,
      0.121571,
      0.121676,
      0.121677,
      0.121692,
      0.121805,
      0.121923,
      0.121933,
      0.121948,
      0.121954,
      0.122114,
      0.122136,
      0.122306,
      0.122307,
      0.122499,
      0.1225,
      0.122519,
      0.12267,
      0.122692,
      0.122693,
      0.122711,
      0.122717,
      0.122829,
      0.12283,
      0.122958,
      0.122968,
      0.123086,
      0.123099,
      0.123291,
      0.123347,
      0.123598,
      0.123603,
      0.123854,
      0.123862,
      0.123934,
      0.123956,
      0.124051,
      0.124148,
      0.124242,
      0.124244,
      0.124245,
      0.124435,
      0.124748,
      0.124753,
      0.125004,
      0.125007,
      0.125102,
      0.125103,
      0.125103,
      0.125174,
      0.125199,
      0.1252,
      0.125295,
      0.12539,
      0.125392,
      0.125393,
      0.125393,
      0.125394,
      0.125395,
      0.125396,
      0.125485,
      0.125519,
      0.125524,
      0.125525,
      0.125525,
      0.125526,
      0.125527,
      0.125528,
      0.125772,
      0.125775,
      0.125788,
      0.125913,
      0.126158,
      0.126171,
      0.126362,
      0.126536,
      0.126629,
      0.126666,
      0.126675,
      0.126918,
      0.126928,
      0.126988,
      0.127016,
      0.127119,
      0.127301,
      0.127315,
      0.127393,
      0.127431,
      0.127558,
      0.127683,
      0.127683,
      0.127684,
      0.127694,
      0.127778,
      0.127778,
      0.127848,
      0.127873,
      0.127886,
      0.127967,
      0.127968,
      0.128062,
      0.128066,
      0.128081,
      0.128158,
      0.128188,
      0.128315,
      0.128441,
      0.128448,
      0.12846,
      0.128612,
      0.128634,
      0.128729,
      0.128824,
      0.128831,
      0.128842,
      0.128919,
      0.128995,
      0.129014,
      0.129014,
      0.129108,
      0.129109,
      0.129129,
      0.129203,
      0.129214,
      0.129214,
      0.129214,
      0.129214,
      0.129215,
      0.129215,
      0.129215,
      0.129215,
      0.129226,
      0.129297,
      0.12938,
      0.129392,
      0.129583,
      0.129598,
      0.129609,
      0.129667,
      0.129774,
      0.129896,
      0.129954,
      0.129981,
      0.129981,
      0.129983,
      0.129993,
      0.130052,
      0.130062,
      0.130279,
      0.130339,
      0.130365,
      0.130366,
      0.130366,
      0.130367,
      0.130367,
      0.130368,
      0.130447,
      0.130567,
      0.130629,
      0.13075,
      0.130758,
      0.130829,
      0.130916,
      0.130949,
      0.131044,
      0.131133,
      0.131134,
      0.131134,
      0.131134,
      0.131135,
      0.131135,
      0.131136,
      0.131136,
      0.131136,
      0.131137,
      0.131137,
      0.131208,
      0.131237,
      0.131311,
      0.131332,
      0.131495,
      0.13152,
      0.13152,
      0.131521,
      0.131521,
      0.131522,
      0.131522,
      0.131522,
      0.131522,
      0.131523,
      0.131523,
      0.131523,
      0.131525,
      0.131525,
      0.131525,
      0.131525,
      0.131526,
      0.131527,
      0.131527,
      0.131527,
      0.131527,
      0.131528,
      0.131529,
      0.131529,
      0.131529,
      0.131529,
      0.13153,
      0.13153,
      0.131531,
      0.131531,
      0.131531,
      0.131531,
      0.131532,
      0.131532,
      0.131532,
      0.131532,
      0.131533,
      0.131533,
      0.131534,
      0.131534,
      0.131534,
      0.131534,
      0.131534,
      0.131534,
      0.131535,
      0.131535,
      0.131536,
      0.131536,
      0.131536,
      0.131536,
      0.131537,
      0.131538,
      0.131538,
      0.131538,
      0.131538,
      0.131539,
      0.131539,
      0.13154,
      0.13154,
      0.13154,
      0.13154,
      0.131467,
      0.131367,
      0.131275,
      0.131183,
      0.131178,
      0.131162,
      0.131163,
      0.131163,
      0.131164,
      0.131164,
      0.131164,
      0.131165,
      0.131165,
      0.131166,
      0.131166,
      0.131166,
      0.131089,
      0.131088,
      0.130996,
      0.130995,
      0.130993,
      0.130807,
      0.130803,
      0.130788,
      0.130712,
      0.130708,
      0.130618,
      0.130518,
      0.130429,
      0.130428,
      0.13041,
      0.13041,
      0.13041,
      0.130411,
      0.130411,
      0.130411,
      0.130412,
      0.130412,
      0.130412,
      0.130413,
      0.130414,
      0.130414,
      0.130414,
      0.130318,
      0.130311,
      0.130177,
      0.130065,
      0.130058,
      0.130035,
      0.130036,
      0.130036,
      0.130037,
      0.130038,
      0.129967,
      0.129954,
      0.129865,
      0.129683,
      0.12967,
      0.12966,
      0.12966,
      0.129661,
      0.129662,
      0.129662,
      0.129662,
      0.129662,
      0.129552,
      0.129303,
      0.1293,
      0.129283,
      0.129212,
      0.129107,
      0.129014,
      0.128918,
      0.128905,
      0.128834,
      0.128824,
      0.128636,
      0.128635,
      0.128542,
      0.128526,
      0.128527,
      0.128527,
      0.128528,
      0.128528,
      0.128529,
      0.128529,
      0.128425,
      0.128182,
      0.128173,
      0.128169,
      0.12815,
      0.128151,
      0.128152,
      0.128152,
      0.128152,
      0.128075,
      0.127988,
      0.127883,
      0.127799,
      0.127774,
      0.127671,
      0.127556,
      0.127414,
      0.127395,
      0.127232,
      0.127224,
      0.127126,
      0.127043,
      0.127017,
      0.127018,
      0.127018,
      0.127018,
      0.127019,
      0.127019,
      0.126942,
      0.126858,
      0.126848,
      0.126845,
      0.126754,
      0.126669,
      0.12666,
      0.126641,
      0.126541,
      0.126534,
      0.126427,
      0.126416,
      0.126415,
      0.126291,
      0.126262,
      0.126196,
      0.126189,
      0.126182,
      0.126095,
      0.126008,
      0.126001,
      0.125884,
      0.125788,
      0.125787,
      0.125777,
      0.125671,
      0.12566,
      0.125659,
      0.125658,
      0.125531,
      0.12553,
      0.12553,
      0.125529,
      0.125505,
      0.12544,
      0.125428,
      0.125427,
      0.125426,
      0.125426,
      0.125425,
      0.125331,
      0.12533,
      0.12533,
      0.125329,
      0.125234,
      0.125233,
      0.125233,
      0.125232,
      0.125157,
      0.125142,
      0.125137,
      0.125136,
      0.125136,
      0.125127,
      0.125127,
      0.125127,
      0.125127,
      0.125127,
      0.125128,
      0.125128,
      0.125128,
      0.125128,
      0.125128,
      0.125128,
      0.125129,
      0.125129,
      0.125129,
      0.125129,
      0.124998,
      0.124997,
      0.124996,
      0.124919,
      0.124898,
      0.124869,
      0.124869,
      0.124868,
      0.124867,
      0.12475,
      0.124743,
      0.124742,
      0.124741,
      0.124741,
      0.124688,
      0.124646,
      0.124645,
      0.124644,
      0.124644,
      0.124643,
      0.124577,
      0.124548,
      0.124548,
      0.124547,
      0.124546,
      0.124499,
      0.124451,
      0.124451,
      0.12445,
      0.124372,
      0.124355,
      0.124354,
      0.124354,
      0.124294,
      0.124259,
      0.124258,
      0.124257,
      0.124216,
      0.124162,
      0.124162,
      0.124068,
      0.124068,
      0.124027,
      0.12401,
      0.123994,
      0.123969,
      0.123968,
      0.123967,
      0.123846,
      0.123845,
      0.123844,
      0.123843,
      0.123786,
      0.123722,
      0.123721,
      0.12372,
      0.123637,
      0.123615,
      0.123603,
      0.123602,
      0.123601,
      0.123601,
      0.123554,
      0.123511,
      0.12351,
      0.12351,
      0.123509,
      0.123509,
      0.123419,
      0.123419,
      0.123418,
      0.123417,
      0.123417,
      0.123366,
      0.123348,
      0.123326,
      0.123325,
      0.123237,
      0.12323,
      0.123157,
      0.123107,
      0.123106,
      0.123105,
      0.123104,
      0.122984,
      0.122983,
      0.122983,
      0.122982,
      0.122981,
      0.122905,
      0.12288,
      0.12286,
      0.122859,
      0.122859,
      0.122858,
      0.122766,
      0.122766,
      0.122704,
      0.122673,
      0.122673,
      0.122672,
      0.122591,
      0.122581,
      0.12258,
      0.12258,
      0.122579,
      0.122516,
      0.122489,
      0.122489,
      0.122488,
      0.122487,
      0.122487,
      0.12248,
      0.122422,
      0.122397,
      0.122396,
      0.122396,
      0.122395,
      0.122328,
      0.122304,
      0.122303,
      0.122303,
      0.122213,
      0.122211,
      0.12221,
      0.122139,
      0.122101,
      0.122102,
      0.122103,
      0.122103,
      0.121958,
      0.121801,
      0.121577,
      0.121568,
      0.121422,
      0.121344,
      0.121246,
      0.121017,
      0.120993,
      0.120988,
      0.120966,
      0.120967,
      0.120967,
      0.120967,
      0.120968,
      0.120857,
      0.120627,
      0.120479,
      0.120432,
      0.120209,
      0.120108,
      0.12001,
      0.119852,
      0.11983,
      0.119754,
      0.119681,
      0.11947,
      0.119468,
      0.119452,
      0.119253,
      0.119097,
      0.119073,
      0.118995,
      0.118901,
      0.11883,
      0.118735,
      0.118711,
      0.118695,
      0.118591,
      0.118494,
      0.118316,
      0.11826,
      0.118238,
      0.118237,
      0.118165,
      0.118048,
      0.117975,
      0.117953,
      0.117938,
      0.11788,
      0.117764,
      0.117596,
      0.117575,
      0.117574,
      0.11756,
      0.117355,
      0.117325,
      0.117181,
      0.117121,
      0.117102,
      0.117005,
      0.116932,
      0.116815,
      0.116803,
      0.116721,
      0.116697,
      0.116594,
      0.116566,
      0.116467,
      0.116424,
      0.116361,
      0.116341,
      0.116266,
      0.116249,
      0.116171,
      0.116151,
      0.116076,
      0.116075,
      0.116056,
      0.116046,
      0.115958,
      0.115939,
      0.115806,
      0.115701,
      0.115667,
      0.115528,
      0.115495,
      0.115337,
      0.115316,
      0.115115,
      0.114956,
      0.114908,
      0.114813,
      0.114792,
      0.114686,
      0.114674,
      0.114559,
      0.114539,
      0.114529,
      0.114347,
      0.114262,
      0.11426,
      0.114166,
      0.114157,
      0.114151,
      0.113977,
      0.113967,
      0.113882,
      0.113881,
      0.113786,
      0.113778,
      0.113773,
      0.113664,
      0.113652,
      0.113539,
      0.113537,
      0.113525,
      0.11341,
      0.113394,
      0.113311,
      0.113303,
      0.113219,
      0.113216,
      0.113113,
      0.113026,
      0.113024,
      0.113016,
      0.112929,
      0.112928,
      0.112923,
      0.112828
  ]
};
